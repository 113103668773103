import { styled } from 'styled-components';

import { IconButton } from '../../../spearhead/lib/components/elements/IconButton/IconButton';
import { InputText } from '../InputText/InputText';

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`;

export const StyledIconButton = styled(IconButton)`
  position: relative;
  background-color: var(--color-surface-light);
  border-color: var(--color-separator-dark-lighter);

  &:focus-visible::after {
    top: calc(var(--distance-static-3xs) * -1);
    right: calc(var(--distance-static-3xs) * -1);
    bottom: calc(var(--distance-static-3xs) * -1);
    left: calc(var(--distance-static-3xs) * -1);
    z-index: var(--zindex-m);
  }

  &:first-child {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus-visible::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:disabled {
      border-color: var(--color-dark-transparent);
    }
  }

  &:last-of-type {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus-visible::after {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &:disabled {
    pointer-events: none;
  }
`;

export const StyledInput = styled(InputText)`
  &&& {
    border-radius: 0;
    input {
      text-align: center;
      border-radius: 0;
      &:focus {
        outline-offset: -2px;
      }
    }
  }
`;

import { SearchAlertTooltipStyles } from '@pkgs/components/components/SearchAlert/SearchAlert.styled';
import { to } from '@pkgs/components/utils/mediaQueries';
import { Button } from '@spearhead/components/elements/Button/Button';
import { Headline } from '@spearhead/components/elements/Headline/Headline';
import { css, styled } from 'styled-components';

export const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-l);
  padding: var(--distance-static-xl) var(--distance-dynamic-m);
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-shade);
  @media screen and (${to.tabletPortrait}) {
    padding: var(--distance-static-xxl) var(--distance-dynamic-m);
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-s);
  text-align: center;
`;

export const StyledTitle = styled(Headline)`
  color: var(--color-dark-colored);
`;

export const StyledButton = styled(Button)`
  width: 100%;
  &:disabled {
    border: 1px solid var(--color-dark-20);
  }
`;

export const StyledTooltip = styled.div<{ $isCentered: boolean }>`
  ${({ $isCentered }) =>
    $isCentered
      ? css`
          left: 50%;
          transform: translateX(-50%) translateY(5%);
        `
      : css`
          transform: translateX(-81%) translateY(5%);
        `}
  ${SearchAlertTooltipStyles}
`;

// generated/search-bff/models/businessArea.ts
var BusinessArea = {
  residential: "residential",
  commercial: "commercial"
};

// generated/search-bff/models/countryCode.ts
var CountryCode = {
  AE: "AE",
  AD: "AD",
  AT: "AT",
  BS: "BS",
  BE: "BE",
  BZ: "BZ",
  CA: "CA",
  CH: "CH",
  CL: "CL",
  CO: "CO",
  CR: "CR",
  CZ: "CZ",
  DE: "DE",
  DK: "DK",
  ES: "ES",
  FR: "FR",
  GR: "GR",
  KY: "KY",
  HR: "HR",
  HK: "HK",
  HU: "HU",
  IE: "IE",
  IT: "IT",
  LI: "LI",
  LU: "LU",
  MF: "MF",
  MG: "MG",
  MU: "MU",
  MX: "MX",
  NL: "NL",
  NG: "NG",
  PL: "PL",
  PT: "PT",
  ZA: "ZA",
  TC: "TC",
  GB: "GB",
  US: "US",
  UY: "UY",
  DO: "DO",
  SA: "SA",
  LT: "LT",
  PR: "PR",
  NA: "NA",
  PE: "PE",
  DZ: "DZ",
  SV: "SV",
  AR: "AR",
  GT: "GT",
  TZ: "TZ",
  EC: "EC",
  MC: "MC",
  BR: "BR",
  BM: "BM",
  VE: "VE",
  PW: "PW",
  CV: "CV",
  BW: "BW",
  HN: "HN",
  KE: "KE",
  VG: "VG",
  PA: "PA",
  IR: "IR"
};

// generated/search-bff/models/currency.ts
var Currency = {
  EUR: "EUR",
  AED: "AED",
  BSD: "BSD",
  BZD: "BZD",
  CAD: "CAD",
  CHF: "CHF",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CZK: "CZK",
  DKK: "DKK",
  GBP: "GBP",
  HKD: "HKD",
  HUF: "HUF",
  KYD: "KYD",
  MUR: "MUR",
  MXN: "MXN",
  OMR: "OMR",
  PAB: "PAB",
  QAR: "QAR",
  RUB: "RUB",
  SEK: "SEK",
  TRY: "TRY",
  USD: "USD",
  ZAR: "ZAR"
};

// generated/search-bff/models/language.ts
var Language = {
  ca: "ca",
  cs: "cs",
  da: "da",
  nl: "nl",
  en: "en",
  fr: "fr",
  de: "de",
  el: "el",
  hu: "hu",
  it: "it",
  pt: "pt",
  ru: "ru",
  es: "es"
};

// generated/search-bff/models/measurementSystem.ts
var MeasurementSystem = {
  metric: "metric",
  imperial: "imperial"
};

// generated/search-bff/models/propertyKind.ts
var PropertyKind = {
  object: "object",
  group: "group"
};

// generated/search-bff/models/propertyMarketingType.ts
var PropertyMarketingType = {
  sale: "sale",
  rent: "rent"
};

// generated/search-bff/models/propertyType.ts
var PropertyType = {
  apartment: "apartment",
  agriculture_and_forestry: "agriculture_and_forestry",
  commercial_space: "commercial_space",
  group: "group",
  gastronomy: "gastronomy",
  house: "house",
  hotel: "hotel",
  industry: "industry",
  investment: "investment",
  land: "land",
  motor: "motor",
  office: "office",
  other: "other",
  retail_services: "retail services",
  sail: "sail",
  store: "store",
  "": ""
};

export {
  BusinessArea,
  CountryCode,
  Currency,
  Language,
  MeasurementSystem,
  PropertyKind,
  PropertyMarketingType,
  PropertyType
};

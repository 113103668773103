// src/utils/panic.ts
var panic = (message) => {
  console.error(message);
  throw new Error(message);
};
var safePanic = (message, fallback) => {
  console.error(message);
  return fallback;
};

export {
  panic,
  safePanic
};

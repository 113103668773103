import './index.css';
import 'leaflet/dist/leaflet.css';

import { type EmotionCache, CacheProvider } from '@emotion/react';
import { initSnowplowTracker } from '@ev/snowplow-library';
import { GlobalStyles } from '@ev/ui-core-components';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { GoogleTagManager } from '@next/third-parties/google';
import { SiteTrackingPlugin } from '@snowplow/browser-plugin-site-tracking';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SpeedInsights } from '@vercel/speed-insights/next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';

import { BlockedLocalStorageDialog } from '@/components/common/BlockedLocalStorage/BlockedLocalStorageDialog';
import { DIDOMI_SCRIPT } from '@/consts/didomiScript';
import { muiCustomTheme } from '@/consts/muiTheme';
import { BreakpointProvider } from '@/providers/BreakpointProvider';
import { SettingsProvider } from '@/providers/SettingsProvider';
import { SnackbarProvider } from '@/providers/SnackbarProvider';
import { WatchlistProvider } from '@/providers/WatchlistProvider';
import { type ABTestVariation } from '@/types/ab-test';
import { type ExternalLinkDomain, type SpearHeadNavigation } from '@/types/storyblok';
import { setLanguageCookie } from '@/utils/cookieUtils';
import createEmotionCache from '@/utils/createEmotionCache';
import { attachConsentListeners, hasUserAcceptedTracking } from '@/utils/didomiCMPUtils';
import { joinUs } from '@/utils/join-us';
import { isLocalStorageAvailable } from '@/utils/localStorage';
import { getLicencePartnerGAAccount } from '@/utils/tracking';

import nextI18NextConfig from '../configs/next-i18next.config';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export type AppPageProps = {
  pageLanguages: string[];
  isSnowplowTrackerInit?: boolean;
  spearHeadNavigation?: SpearHeadNavigation | null;
  externalLinkDomains?: ExternalLinkDomain[] | null;
  abTestVariation?: ABTestVariation;
};

const SearchApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [isSnowplowTrackerInit, setIsSnowplowTrackerInit] = useState(false);
  const [hasLicencePartnerGAAccount, setHasLicencePartnerGAAccount] = useState(false);
  const [isBlockedLocalStorageDialogOpen, setIsBlockedLocalStorageDialogOpen] = useState(!isLocalStorageAvailable());
  const {
    i18n: { language },
  } = useTranslation();

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    })
  );

  useEffect(() => {
    joinUs();

    if (!isSnowplowTrackerInit) {
      initSnowplowTracker({
        applicationId: 'new-search',
        hasUserAcceptedAgreement: hasUserAcceptedTracking(),
        isProd: window.location.host.includes('engelvoelkers.com'),
        initialTrackPageViewEvent: false,
        plugins: [SiteTrackingPlugin()],
      });
      setIsSnowplowTrackerInit(true);
    }
    attachConsentListeners();
    const licencePartnerGaAccount = getLicencePartnerGAAccount();
    if (licencePartnerGaAccount) {
      setHasLicencePartnerGAAccount(true);
      window.dataLayer?.push({
        event: 'licencePartnerGAAccount',
        licencePartnerGA: licencePartnerGaAccount,
      });
    }
    setLanguageCookie(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (process.env.NEXT_PUBLIC_E2E_ENV) {
      window.skipConsentCheck = true;
    }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Search</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1" />
        {/* it resolves a hydration issue on ios https://github.com/vercel/next.js/issues/38290 */}
        <meta name="format-detection" content="telephone=no" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://uploadcare.engelvoelkers.com" />
        <link rel="preconnect" href="https://fonts.engelvoelkers.com" />
        <link rel="apple-touch-icon" sizes="180x180" href="https://uploadcare.engelvoelkers.com/20367ee3-b0ec-4b47-a7a4-a9f6fe0f23e9/" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://uploadcare.engelvoelkers.com/11642d33-556d-40ea-91fd-bb8e9a93f200/" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://uploadcare.engelvoelkers.com/4af56219-669f-4b35-9760-d11efef10cb7/" />
        <link rel="icon" href="https://uploadcare.engelvoelkers.com/9b1114be-3ea4-4421-8130-4d6ce3035728/" />
        <link rel="shortcut icon" href="https://uploadcare.engelvoelkers.com/9b1114be-3ea4-4421-8130-4d6ce3035728/" />
        <link
          rel="preload"
          href="https://fonts.engelvoelkers.com/EngelVoelkersHead_W_Wght.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="https://fonts.engelvoelkers.com/EngelVoelkersText_W_Wght.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Head>
      {!process.env.NEXT_PUBLIC_E2E_ENV && (
        <>
          <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!} />
          <Script
            strategy="afterInteractive"
            id="cmp-script"
            dangerouslySetInnerHTML={{
              __html: DIDOMI_SCRIPT,
            }}
          />
          <SpeedInsights
            sampleRate={0.2}
            scriptSrc="https://new-search.engelvoelkers.com/_vercel/speed-insights/script.js"
            endpoint="https://new-search.engelvoelkers.com/_vercel/speed-insights/vitals"
          />
        </>
      )}
      <QueryClientProvider client={queryClient.current}>
        <SettingsProvider>
          <ThemeProvider theme={muiCustomTheme}>
            <GlobalStyles />
            <SnackbarProvider>
              <WatchlistProvider>
                <BreakpointProvider>
                  <CssBaseline />
                  {isBlockedLocalStorageDialogOpen ? (
                    <BlockedLocalStorageDialog onClose={() => setIsBlockedLocalStorageDialogOpen(false)} />
                  ) : null}
                  <Component
                    isSnowplowTrackerInit={isSnowplowTrackerInit}
                    hasLicencePartnerGAAccount={hasLicencePartnerGAAccount}
                    {...pageProps}
                  />
                </BreakpointProvider>
              </WatchlistProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </SettingsProvider>
      </QueryClientProvider>
    </CacheProvider>
  );
};

export default appWithTranslation<AppProps>(SearchApp, nextI18NextConfig);

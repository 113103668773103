import {
  getProperties
} from "./chunk-DMRMWGUK.js";

// src/getPropertiesByShopIds/getPropertiesByShopIds.ts
async function getPropertiesByShopIds(shopIds, filters, options, apiConfig) {
  if (shopIds.length === 0) {
    console.warn("shopIds is an empty array.");
  }
  return await getProperties(
    {
      ...filters,
      masterDataShopIds: shopIds
    },
    options,
    apiConfig
  );
}

export {
  getPropertiesByShopIds
};

import {
  type BusinessDivisionType,
  getDivisionContext,
  getPageContext,
  getShopContext,
  trackUserInteractionEvent,
  type UserActionEnum,
} from '@ev/snowplow-library';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { PageType } from '@pkgs/components/utils/tracking';

export const useLipaPortfolioTrackButtonClick = ({ elementId, action }: { elementId: string; action: UserActionEnum }) => {
  const { filters, snowPlowPageType } = useSearch();
  function trackButtonClick() {
    trackUserInteractionEvent({
      action: action,
      elementId: elementId,
      contexts: [
        getShopContext({ id: filters.shopIds?.[0] }),
        getPageContext({
          type: snowPlowPageType ?? PageType.LIPA_SEARCH_RESULT,
          isHQPage: true,
        }),
        getDivisionContext({
          name: filters.businessArea as BusinessDivisionType,
        }),
      ],
    });
  }
  return { trackButtonClick };
};

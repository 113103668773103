import {
  getTrackingByGa4Id
} from "./chunk-THP32KMZ.js";

// src/getTrackingByGa4Id/getTrackingByGa4Id.ts
function getTrackingByGa4Id2(ga4Id, apiConfig) {
  return getTrackingByGa4Id(ga4Id, apiConfig);
}

export {
  getTrackingByGa4Id2 as getTrackingByGa4Id
};

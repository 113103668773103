import { type BigDecimalRangeDto, type Language } from '@ev/search-modules-api';
import { useEffect, useState } from 'react';

import { useAppContext } from '../../hooks/useAppContext';
import { validateRangeValues } from '../../utils/numberFormatters';
import { formatRangeLabel } from '../../utils/stringUtils';
import { RangeDropdown } from '../RangeDropdown/RangeDropdown';
import { RoomsInput } from '../RangeInputs/RommsInput';
import { type FilterDropdownProps } from './FilterDropdown.types';

type RoomsFilterDropdownProps = FilterDropdownProps & {
  language: Language;
  'data-test-id'?: string;
};

export const RoomsFilterDropdown = ({ value, language, className, onChange, 'data-test-id': dataTestId }: RoomsFilterDropdownProps) => {
  const { t } = useAppContext();
  const [range, setRange] = useState<BigDecimalRangeDto | undefined>(value ? validateRangeValues(value) : undefined);
  const label = value ? formatRangeLabel(value, t('search.searchbar.rooms'), language, true) : '';

  useEffect(() => {
    setRange(value ? validateRangeValues(value) : undefined);
  }, [value]);

  return (
    <RangeDropdown
      className={className}
      label={t('search.searchbar.rooms')}
      placement="bottom end"
      value={label}
      onDone={() => {
        range && onChange({ rooms: { ...range } });
      }}
      onReset={() => {
        onChange({ rooms: undefined });
        setRange(undefined);
      }}
      data-test-id={dataTestId ? `${dataTestId}_dropdown` : 'search-components_rooms-filter_dropdown'}
    >
      <RoomsInput hiddenLabel={true} defaultRange={value} onChange={setRange} data-test-id={dataTestId} />
    </RangeDropdown>
  );
};

import {
  findAllSearchAlerts
} from "./chunk-YYWHXEGH.js";
import {
  getErrorResult
} from "./chunk-XNWIRXJJ.js";

// src/findAllSearchAlerts/findAllSearchAlerts.ts
async function findAllSearchAlerts2(accountId, params, apiConfig) {
  try {
    const response = await findAllSearchAlerts(accountId, params, apiConfig);
    return { status: "success", data: response };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  findAllSearchAlerts2 as findAllSearchAlerts
};

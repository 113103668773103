import { type GetPropertiesOptions } from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { type SearchProviderProps } from '@pkgs/components/providers/SearchProvider';
import { mapToFiltersWithArrayFields } from '@pkgs/components/utils/mapFilterFields';
import { useQuery } from '@tanstack/react-query';

import { useAppContext } from './useAppContext';

export const GEO_COORDINATES_QUERY_KEY = 'geo-coordinates';

export const useGeoCoordinates = ({
  filters,
  options,
  initialGeoResults,
}: SearchProviderProps & {
  filters: SearchModuleFilters;
  options: Required<GetPropertiesOptions>;
}) => {
  const { page, pageSize, language } = options;
  const { api, isMapEnabled } = useAppContext();
  return useQuery({
    queryKey: [GEO_COORDINATES_QUERY_KEY, filters, page, pageSize, language],
    queryFn: async () => {
      const coordinatesResult = await api.getPropertiesCoordinates(mapToFiltersWithArrayFields(filters), options);

      if (coordinatesResult.status === 'error') {
        throw coordinatesResult.error;
      }

      return coordinatesResult.data;
    },
    initialData: initialGeoResults,
    refetchOnMount: !initialGeoResults || (!filters.placeId && !filters.boundingBox),
    enabled: isMapEnabled,
  });
};

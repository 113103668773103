import {
  decodeHTML,
  ensureHttpsPrefix,
  formatShopPhoneNumber,
  getEntries,
  getExposePath,
  getVideoEmbedUrl,
  isRecord,
  prependLocaleToPath
} from "./chunk-EYGO4I34.js";
import {
  safePanic
} from "./chunk-3W4JOJMB.js";
import {
  Language,
  PropertyKind,
  PropertyType
} from "./chunk-VQQW7DNQ.js";

// src/utils/error.ts
import { isAxiosError } from "axios";
var isErrorDetails = (data) => {
  return isRecord(data) && "traceId" in data && "details" in data;
};
var createError = (error) => {
  if (isAxiosError(error) || error instanceof Error) {
    return error;
  } else if (isRecord(error) && "message" in error) {
    return new Error(error.message);
  } else if (typeof error === "string") {
    return new Error(error);
  } else {
    return new Error("An unknown error occurred.");
  }
};
var getErrorMessage = (error) => {
  if (isAxiosError(error) && error.isAxiosError && error.response) {
    const { data, status } = error.response;
    return `AxiosError: ${status} - ${isErrorDetails(data) ? data.details : JSON.stringify(data)}`;
  } else {
    return error.message;
  }
};
var getErrorResult = (error) => {
  const parsedError = createError(error);
  return {
    status: "error",
    error: parsedError,
    errorMessage: getErrorMessage(parsedError)
  };
};

// src/utils/refineProperty.ts
var DEFAULT_IMAGE = "https://uploadcare.engelvoelkers.com/4a6e9b16-f2a8-4afc-ba26-a7d0cbcb1dbb/";
function refineProperty(rawProperty, userLanguage) {
  const { profiles, agentMobile, shopPhoneNumber, shopWebURL, floorPlanImages, propertyImages, language, ...propertyData } = rawProperty;
  const images = (propertyImages && removeDuplicateImageObjects(propertyImages)) ?? getPropertyImages(rawProperty);
  const profileLanguage = getAvailableLanguage(rawProperty);
  const formattedLocation = getLocationBreadCrumb(rawProperty, userLanguage).filter(Boolean).join(", ");
  const paths = getExposePaths(rawProperty);
  const canonicalPath = paths.find((path) => path.language === profileLanguage)?.path;
  const imagesWithTitles = getImagesWithTitles(images, profileLanguage, rawProperty.useWaterMark);
  const floorPlanImagesWithTitles = floorPlanImages ? getImagesWithTitles(removeDuplicateImageObjects(floorPlanImages), profileLanguage, rawProperty.useWaterMark) : [];
  const vtourURL = rawProperty.vtourURL ? getVideoEmbedUrl(rawProperty.vtourURL) : "";
  const exposePath = getExposePath(
    propertyData.isDevelopmentProject || propertyData.objectType === PropertyType.group || propertyData.kind === PropertyKind.group,
    rawProperty.id
  );
  const location = getPropertyLocation(rawProperty, userLanguage);
  return {
    profileLanguage,
    ...propertyData,
    language: language ?? [],
    ...profiles && {
      profile: getProfileForLanguage(profiles, profileLanguage)
    },
    ...shopWebURL && { shopWebURL: ensureHttpsPrefix(shopWebURL) },
    ...shopPhoneNumber && {
      shopPhoneNumber: formatShopPhoneNumber(shopPhoneNumber)
    },
    ...agentMobile && {
      agentPhoneNumber: formatShopPhoneNumber(agentMobile)
    },
    ...location,
    formattedLocation,
    paths,
    ...canonicalPath && { canonicalPath },
    images: imagesWithTitles,
    floorPlans: floorPlanImagesWithTitles,
    vtourURL,
    exposePath
  };
}
function removeDuplicateImageObjects(imageArray) {
  const seen = /* @__PURE__ */ new Set();
  return imageArray.filter((item) => {
    if (!item.id) {
      return false;
    }
    const duplicate = seen.has(item.id);
    seen.add(item.id);
    return !duplicate;
  });
}
function getPropertyImages(property) {
  if (property.images) {
    return removeDuplicateImageObjects(property.images);
  } else if (property.uploadCareImageIds) {
    const duplicatedImageIdsRemoved = [...new Set(property.uploadCareImageIds)];
    return duplicatedImageIdsRemoved.map((img, index) => {
      return {
        id: img,
        main: false,
        type: "image",
        position: index
      };
    });
  }
  return [];
}
function getImagesWithTitles(images, availableLanguage, useWaterMark) {
  const IMAGE_WATERMARK_ID = "131ecb57-f36f-4015-aee3-5851eb9bfa9d";
  return images.sort((a, b) => (a.position ?? 0) - (b.position ?? 0)).map((img) => ({
    title: availableLanguage && img.titles?.[availableLanguage] ? img.titles[availableLanguage] : "",
    url: `https://uploadcare.engelvoelkers.com/${img.id}/${useWaterMark ? `-/overlay/${IMAGE_WATERMARK_ID}/60px60p/50p,50p/60p/` : ""}`
  }));
}
function getPropertyLocation(property, language) {
  let subLocality = (property.neighborhoodOverwrite || property.subLocality?.[language]) ?? "";
  if (property.city?.[Language.en]?.toLowerCase() === "alar\xF3") {
    subLocality = "";
  }
  const city = property.city?.[language] ?? "";
  const region = property.region?.[language] ?? "";
  const country = property.country?.[language] ?? "";
  return {
    subLocality,
    city,
    region,
    country
  };
}
function getAvailableLanguage(property) {
  if (!property.profiles) {
    return safePanic("No profile available", Language.en);
  }
  return Object.keys(property.profiles)[0] ?? safePanic("No available language found", Language.en);
}
function getProfileForLanguage(profiles, availableLanguage) {
  if (!availableLanguage || !profiles[availableLanguage]) return safePanic("Invalid available language", {});
  const profile = profiles[availableLanguage];
  const decodedProfile = decodeProfileValues(profile);
  if (decodedProfile.shopDataSecurityURL) {
    decodedProfile.shopDataSecurityURL = ensureHttpsPrefix(decodedProfile.shopDataSecurityURL);
  }
  return decodedProfile;
}
function decodeProfileValues(profile) {
  const decodedProfile = {};
  for (const [key, value] of getEntries(profile)) {
    if (value) {
      decodedProfile[key] = decodeHTML(value);
    }
  }
  return decodedProfile;
}
function getExposePaths(property) {
  if (!property.language || property.language.length === 0 || !property.id) {
    return [];
  }
  return property.language.map((language) => ({
    language,
    path: prependLocaleToPath(
      getExposePath(
        property.isDevelopmentProject || property.objectType === PropertyType.group || property.kind === PropertyKind.group,
        property.id
      ),
      language,
      property.countryAlpha2
    )
  }));
}
function getLocationBreadCrumb(property, language) {
  const { neighborhoodOverwrite } = property;
  const addressComponents = property.addressComponents ?? [];
  return addressComponents.filter((comp) => Boolean(comp.text)).map(({ text }, index, arr) => {
    const isLastAdministrativeLevel = index === arr.length - 1;
    if (isLastAdministrativeLevel && neighborhoodOverwrite) return neighborhoodOverwrite;
    return text[language] ?? Object.values(text)[0] ?? "";
  }).reverse();
}

export {
  createError,
  getErrorMessage,
  getErrorResult,
  DEFAULT_IMAGE,
  refineProperty
};

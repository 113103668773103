import { AutogenInfoRow } from '@pkgs/components/components/SearchInfoRow/AutogenInfoRow';
import { type SeoPageLink } from '@pkgs/components/components/SearchModule';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useBreakpoint } from '@pkgs/components/hooks/useBreakpoint';
import { useMapTracking } from '@pkgs/components/hooks/useMapTracking';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { Breakpoints } from '@pkgs/components/providers/BreakpointProvider';
import MapIcon from '@spearhead/assets/icons/small/Map.svg';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { DisclaimerText } from '../DisclaimerText/DisclaimerText';
import { NoResultsBanner } from '../NoResultsBanner/NoResultsBanner';
import { SearchAlertModal } from '../SearchAlert/SearchAlertModal';
import { SearchInfoRow } from '../SearchInfoRow/SearchInfoRow';
import { WiderAreaResults } from '../WiderAreaResults/WiderAreaResults';
import { SearchResultList } from './SearchResultList';
import {
  StyledContainer,
  StyledMapFallbackSkeleton,
  StyledMapFallbackWrapper,
  StyledMapViewButton,
  StyledResultsContainer,
  StyledResultsInfoWrapper,
} from './SearchResults.styled';

const MapViewer = lazy(() => import('../MapViewer/MapViewer'));

const MapFallback = () => (
  <StyledMapFallbackWrapper>
    <StyledMapFallbackSkeleton />
  </StyledMapFallbackWrapper>
);

type SearchResultsProps = {
  onIsMapViewChange?: (isMapView: boolean) => void;
  seoPagesLinks?: SeoPageLink[];
  infoRowVariant?: 'autogen' | 'search';
};

export const SearchResults = ({ onIsMapViewChange, seoPagesLinks, infoRowVariant }: SearchResultsProps) => {
  const { results, isFetching } = useSearch();
  const { isMapEnabled, t } = useAppContext();
  const { breakpoint } = useBreakpoint();
  const hasMounted = useRef(false);
  const { trackMapToggleClick } = useMapTracking();

  const [isMapView, setIsMapView] = useState(false);

  const state = useOverlayTriggerState({});

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

  const hasNoResults = !isFetching && results.totalHits === 0;

  const updateIsMapView = (isMapView: boolean, skipTracking?: boolean) => {
    if (!isMapEnabled) {
      return;
    }
    if (!skipTracking) trackMapToggleClick(isMapView ? 'on' : 'off');

    setIsMapView(isMapView);
    if (onIsMapViewChange) onIsMapViewChange(isMapView);
  };

  useEffect(() => {
    if (!isMapEnabled || hasMounted.current || !breakpoint) return;

    // enable map view by default on tablet landscape and up
    if (breakpoint >= Breakpoints.LAPTOP) {
      // skip tracking when setting initial map view because it wasn't user initiated
      updateIsMapView(true, true);
    }

    hasMounted.current = true;
  }, [breakpoint]);

  return (
    <StyledContainer>
      <>
        <StyledResultsInfoWrapper>
          <StyledResultsContainer data-test-id="search-components_results-list" $isMapView={isMapView}>
            <div>
              {infoRowVariant === 'autogen' ? (
                <AutogenInfoRow seoPagesLinks={seoPagesLinks ?? []} />
              ) : (
                <SearchInfoRow isMapView={isMapView} updateIsMapView={updateIsMapView} hasResults={!hasNoResults} />
              )}
              {hasNoResults ? (
                <NoResultsBanner {...triggerProps} />
              ) : (
                <SearchResultList triggerProps={triggerProps} isMapView={isMapView} />
              )}
              <WiderAreaResults isMapView={isMapView} />
              <DisclaimerText isMapView={isMapView} />
            </div>
            {isMapEnabled && isMapView && (
              <Suspense fallback={<MapFallback />}>
                <MapViewer updateIsMapView={updateIsMapView} />
              </Suspense>
            )}
          </StyledResultsContainer>
        </StyledResultsInfoWrapper>
      </>

      {state.isOpen && (
        <Suspense>
          <SearchAlertModal state={state} overlayProps={overlayProps} />
        </Suspense>
      )}
      {isMapEnabled && !isMapView && (
        <StyledMapViewButton
          icon={<MapIcon />}
          label={t('search.searchResults.floatingButton.label.map')}
          variant="secondaryAlt"
          size="m"
          onClick={() => updateIsMapView(true)}
        />
      )}
    </StyledContainer>
  );
};

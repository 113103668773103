import { MeasurementSystem } from '@ev/search-modules-api';

import { type InputTextSizesProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { useAppContext } from '../../hooks/useAppContext';
import { useSearch } from '../../hooks/useSearch';
import { type FilterRefinement } from '../../types/types';
import { Select } from '../Select/Select';

type SearchRadiusSelectItem = { label: `+${number}`; id: number };

const defaultRadius: SearchRadiusSelectItem = {
  label: '+0',
  id: 0,
};

const extendedRadiusValuesKm: SearchRadiusSelectItem[] = [
  defaultRadius,
  { label: '+1', id: 1 },
  { label: '+2', id: 2 },
  { label: '+5', id: 5 },
  { label: '+10', id: 10 },
  { label: '+20', id: 20 },
  { label: '+50', id: 50 },
  { label: '+100', id: 100 },
];

const extendedRadiusValuesMi: SearchRadiusSelectItem[] = [
  defaultRadius,
  { label: '+0.6', id: 1 },
  { label: '+1.2', id: 2 },
  { label: '+3', id: 5 },
  { label: '+6', id: 10 },
  { label: '+12', id: 20 },
  { label: '+30', id: 50 },
  { label: '+60', id: 100 },
];

type ExtendedSearchRadiusFilterProps = {
  searchRadius: number;
  hiddenLabel?: boolean;
  size?: InputTextSizesProps;
  className?: string;
  onChange: (refinement: FilterRefinement) => void;
  isInModal?: boolean;
  'data-test-id'?: string;
};
export const ExtendedSearchRadiusFilter = ({
  searchRadius,
  hiddenLabel,
  size,
  className,
  onChange,
  isInModal,
  'data-test-id': dataTestId,
}: ExtendedSearchRadiusFilterProps) => {
  const { t } = useAppContext();
  const {
    options: { measurementSystem },
  } = useSearch();

  const extendedRadiusValues = measurementSystem === MeasurementSystem.imperial ? extendedRadiusValuesMi : extendedRadiusValuesKm;

  const unitTranslation =
    measurementSystem === MeasurementSystem.imperial ? t('search.common.unit.mile') : t('search.common.unit.kilometer');

  return (
    <Select
      className={className}
      items={extendedRadiusValues.map((item) => ({
        id: item.id.toString(),
        label: `${item.label} ${unitTranslation}`,
      }))}
      onSelectionChange={(value) => onChange({ searchRadius: Number(value) })}
      label={t('search.common.extendedRadius.label')}
      placeholder={t('search.common.extendedRadius.label')}
      selectedKey={searchRadius.toString()}
      name="searchRadius"
      hiddenLabel={hiddenLabel}
      size={size}
      isInModal={isInModal}
      data-test-id={dataTestId}
    />
  );
};

import { styled } from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box;

  & *,
  & *::before,
  & *::after {
    box-sizing: inherit;
  }
`;

export const StyledStickyHeader = styled.div`
  position: sticky;
  top: var(--header-height, 0);
  z-index: var(--zindex-l);
`;

import {
  // for some reason in the pipeline, eslint was failing with unused var error for BigDecimalRangeDto
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type BigDecimalRangeDto,
  BusinessArea,
  getEntries,
  isRangeFilter,
  isRecord,
  ListingSortOption,
  PropertyMarketingType,
  type PropertyType,
} from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';

import { ALL_ADVANCED_FILTERS, defaultFeatures, NON_RESETTABLE_FILTERS } from '../consts/consts';
import { FilterAttributeType } from '../enums/enums';
import { type FilterRefinement } from '../types/types';
import { getSortValues } from './stringUtils';

const resetSelectFields = (filterState: FilterRefinement): FilterRefinement => {
  return {
    businessArea: filterState.businessArea ?? undefined,
    propertyType: undefined,
    propertySubType: undefined,
  };
};

export const resetAdvancedFilters = (filterState: FilterRefinement): FilterRefinement => {
  return {
    ...filterState,
    ...resetSelectFields(filterState),
    price: undefined,
    rooms: undefined,
    bedrooms: undefined,
    bathrooms: undefined,
    livingSurface: undefined,
    totalSurface: undefined,
    plotSurface: undefined,
    constructionYear: undefined,
    ...resetAllFeatures(),
    hasVtour: undefined,
  };
};

export const resetAllFeatures = (): FilterRefinement => {
  return {
    ...defaultFeatures,
  };
};

export const checkIfFilterIsSelected = (refinements: FilterRefinement): boolean => {
  return ALL_ADVANCED_FILTERS.some((filter) => {
    const refinementValue = refinements[filter.name];
    if (!refinementValue) {
      return false;
    }
    if (filter.type === FilterAttributeType.VALUE || filter.type === FilterAttributeType.BOOLEAN) {
      return Boolean(refinementValue);
    }
    if (filter.type === FilterAttributeType.RANGE) {
      const currentRef = refinementValue as BigDecimalRangeDto | undefined;
      return currentRef && (currentRef.min || currentRef.max);
    }
    return false;
  });
};

export const resetSearchStateOnObjectTypeChange = (
  filters: FilterRefinement,
  objectType?: PropertyType,
  businessArea?: BusinessArea,
): FilterRefinement => {
  if (businessArea === BusinessArea.commercial) {
    return {
      ...filters,
      livingSurface: undefined,
      rooms: undefined,
      bathrooms: undefined,
      bedrooms: undefined,
    };
  } else if (businessArea === BusinessArea.residential && objectType === 'group') {
    return {
      ...filters,
      ...resetAllFeatures(),
      price: undefined,
      rooms: undefined,
      bathrooms: undefined,
      bedrooms: undefined,
      livingSurface: undefined,
      totalSurface: undefined,
      plotSurface: undefined,
    };
  } else {
    return {
      ...filters,
    };
  }
};

export const hasResetableFilters = (filters: SearchModuleFilters): boolean => {
  return getEntries(filters).some(([key, value]) => {
    const isDevelopmentField = key === 'propertyType' && value?.[0] === 'group';
    const isResetableKeyWithValue = !NON_RESETTABLE_FILTERS.includes(key) && !isRecord(value) && value;
    const isRangeFieldWithValue = isRangeFilter(value) && (value.min || value.max);

    return !isDevelopmentField && (isResetableKeyWithValue || isRangeFieldWithValue);
  });
};

export const isEmptyObject = (value: unknown): value is Record<string, unknown> => {
  return isRecord(value) && Object.keys(value).length === 0;
};

export const getNewSortingOptions = (
  propertyMarketingType: PropertyMarketingType,
  initialSortingOption?: ListingSortOption,
): ListingSortOption[] => {
  const { sortOrder, sortField } = getSortValues(initialSortingOption);

  if (sortField && ['SALES_PRICE', 'RENT'].includes(sortField)) {
    return [propertyMarketingType === PropertyMarketingType.sale ? `SALES_PRICE_${sortOrder}` : `RENT_${sortOrder}`];
  }

  return initialSortingOption ? [initialSortingOption] : [ListingSortOption.PUBLISHED_AT_DESC];
};

import { useSearch } from '@pkgs/components/hooks/useSearch';
import { lazy, Suspense } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { checkIfFilterIsSelected } from '../../utils/searchStateUtils';
import { AdvancedFiltersButton } from './AdvancedFiltersButton';
import { AdvancedFiltersDialog } from './AdvancedFiltersDialog';

const AdvancedFiltersModal = lazy(() => import('./AdvancedFiltersModal'));

type AdvancedFiltersProps = {
  className?: string;
  variant?: 'default' | 'map-view';
};

export const AdvancedFilters = ({ variant = 'default', className, ...restProps }: AdvancedFiltersProps) => {
  const { filters } = useSearch();
  const state = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

  const isFilterButtonActive = checkIfFilterIsSelected(filters);

  return (
    <>
      <AdvancedFiltersButton
        {...triggerProps}
        className={className}
        isActive={isFilterButtonActive}
        variant={variant}
        data-test-id={`search-components_filter-bar_advanced-filters-button${isFilterButtonActive ? '-active' : ''}`}
      />
      {state.isOpen && (
        <Suspense>
          <AdvancedFiltersModal state={state}>
            <AdvancedFiltersDialog {...overlayProps} onClose={state.close} {...restProps} />
          </AdvancedFiltersModal>
        </Suspense>
      )}
    </>
  );
};

import { extractParamsFromUrl, prependLocaleToPath } from '@pkgs/api';
import Link, { type LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { forwardRef } from 'react';

import { useQueryParams } from '@/hooks/useQueryParams';
import { addParamsToUrl } from '@/utils/urlUtils';

export const LinkWithParams = forwardRef<
  HTMLAnchorElement,
  LinkProps & {
    children: React.ReactNode;
    style?: React.CSSProperties;
    bypassUrl?: boolean;
  }
>(({ href, bypassUrl, ...props }, ref) => {
  const router = useRouter();
  const { countryCode, language } = extractParamsFromUrl(router.asPath);
  const trackingQueryParams = useQueryParams();

  const targetHref = bypassUrl ? href : addParamsToUrl(href as string, trackingQueryParams);

  const targetUrl = bypassUrl ? href : prependLocaleToPath(targetHref.toString(), language || 'en', countryCode);

  return (
    <Link {...props} href={targetUrl} ref={ref}>
      {props.children}
    </Link>
  );
});

LinkWithParams.displayName = 'LinkWithParams';

import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const YoutubeIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <rect width={24} height={24} fill="currentColor" rx={2} />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.817 10.459H8.95l-.002-2.99-1.088-2.57h.937l.551 1.908.544-1.904h.947L9.817 7.466v2.993Zm1.048-3.476c-.062.212-.094.503-.094.878v1.034c0 .34.016.597.05.769.03.155.088.304.174.437.082.122.2.217.338.27.145.061.322.09.53.09.172.003.343-.033.498-.105a.851.851 0 0 0 .353-.325c.092-.144.153-.305.18-.474a5.4 5.4 0 0 0 .043-.797v-.987c0-.286-.015-.498-.045-.639a1.275 1.275 0 0 0-.17-.406.9.9 0 0 0-.352-.32 1.141 1.141 0 0 0-.546-.123c-.253 0-.46.064-.622.192a.977.977 0 0 0-.337.506Zm2.687 3.081c.04.101.109.188.198.25a.57.57 0 0 0 .337.094.523.523 0 0 0 .315-.098.76.76 0 0 0 .23-.295l-.015.32h.918v-3.9h-.723v3.037a.3.3 0 1 1-.602 0V6.435h-.753v2.632c0 .335.006.559.016.672.01.112.037.221.079.325Zm4.366 7.211v-4.19c0-1.005-.871-1.827-1.936-1.827H8.018c-1.066 0-1.937.823-1.937 1.828v4.19c0 1.005.871 1.827 1.937 1.827h7.964c1.065 0 1.936-.823 1.936-1.828Zm-4.748-2.958c-.198 0-.36.134-.36.296v2.202c0 .163.162.295.36.295.197 0 .36-.133.36-.295v-2.202c0-.162-.161-.296-.36-.296Zm2.924.97h-.78l.004-.452c0-.202.165-.365.367-.366h.05c.203 0 .367.165.368.367l-.009.452ZM8.55 17.73v-4.413h.987v-.653l-2.632-.004v.642l.822.003v4.425h.823Zm2.136-3.756h.823v3.754h-.66v-.487c-.177.382-.886.689-1.257.422-.174-.125-.19-.332-.204-.526a5.396 5.396 0 0 1-.011-.505l.001-.322-.002-2.336h.82l.002 2.373c0 .122-.002.225-.004.309-.003.14-.005.227.007.266.118.357.418.163.485-.02a.928.928 0 0 0 .006-.236 10.107 10.107 0 0 1-.006-.335v-2.357Zm3.456 2.7-.001-1.963c0-.747-.56-1.195-1.32-.59l.004-1.458-.822.001-.004 5.033.676-.01.062-.313c.864.793 1.406.25 1.406-.7Zm1.96-.257.616-.004c.282 1.672-2.08 1.948-2.08.003v-1.17c0-.35.035-.627.105-.828a.928.928 0 0 1 .346-.466c.498-.36 1.485-.248 1.586.455.032.223.042.611.042 1v.53h-1.413v.908a.337.337 0 0 0 .337.333h.121a.336.336 0 0 0 .338-.334V16.454l.001-.023v-.013ZM11.49 9.41c0 .221.164.401.365.401.201 0 .363-.18.363-.401V7.333c0-.22-.164-.4-.363-.4-.2 0-.366.18-.366.4V9.41Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

import { useSearch } from '@pkgs/components/hooks/useSearch';

import { type InputTextSizesProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { type FilterRefinement } from '../../types/types';
import { AutoComplete, type AutoSuggestOption } from '../AutoComplete/AutoComplete';

type LocationFilterProps = {
  size?: InputTextSizesProps;
  hiddenLabel?: boolean;
  className?: string;
  initialLocation: string;
  onChange: (arg: FilterRefinement) => void;
  'data-test-id'?: string;
};
export const LocationFilter = ({ onChange, ...props }: LocationFilterProps) => {
  const { updatePlaceName, isMapSearch } = useSearch();
  const handleSelection = (selectedLocation: AutoSuggestOption) => {
    updatePlaceName(selectedLocation.title); // The autosuggest returns the google place name which could differ to our place details place name
    onChange({
      placeId: selectedLocation.id,
      countryCode: undefined,
    });
  };

  return (
    <AutoComplete
      onSelectionChange={handleSelection}
      {...props}
      key={`auto-complete-map-search-${isMapSearch}`} // This key is used to reset the auto complete when the map search is toggled
    />
  );
};

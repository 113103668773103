import { useEffect } from 'react';
import { useCookie } from 'react-use';

export const useMapFeature = () => {
  const [, updateMapCookie] = useCookie('ns-map-enabled-pm');

  useEffect(() => {
    if (window.location.hash === '#ns-map-disabled') {
      updateMapCookie('false');
      window.location.hash = ''; // Clearing the hash after setting the cookie, so don't loop this code
      window.location.reload();
    } else if (window.location.hash === '#ns-map-enabled') {
      updateMapCookie('true');
      window.location.hash = '';
      window.location.reload();
    }
  }, []);
};

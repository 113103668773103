import {
  type BusinessDivisionType,
  getDivisionContext,
  getPageContext,
  getShopContext,
  trackUserInteractionEvent,
  UserActionEnum,
} from '@ev/snowplow-library';
import { PageType } from '@pkgs/components/utils/tracking';

import { useSearch } from './useSearch';

export const useCreateSearchAlertButtonClick = () => {
  const {
    filters,
    snowPlowPageType,
    options: { language },
  } = useSearch();

  function trackButtonClick(elementId: string) {
    trackUserInteractionEvent({
      elementId,
      action: UserActionEnum.click,
      contexts: [
        getPageContext({
          isHQPage: true,
          type: snowPlowPageType ?? PageType.SEARCH_RESULT,
          country_code: filters.countryCode,
          page_language: language,
        }),
        getShopContext({ id: filters.shopIds?.[0] }),
        getDivisionContext({
          name: filters.businessArea as BusinessDivisionType,
        }),
      ],
    });
  }
  return { trackButtonClick };
};

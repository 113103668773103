import React, { type ReactNode } from 'react';

import { StyledButton } from './Button.styled';
import { type ButtonSizes, type ButtonVariant } from './Button.types';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement> {
  type?: 'submit' | 'reset' | 'button';
  rel?: string;
  className?: string;
  label?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonVariant;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right' | 'withoutIcon';
  as?: 'button' | 'a';
  href?: string;
  disabled?: boolean;
  size?: ButtonSizes;
  refObject?: React.RefObject<HTMLButtonElement | HTMLAnchorElement>;
  target?: string;
  'data-test-id'?: string;
}

export const Button = ({
  className,
  label,
  onClick,
  variant = 'primary',
  icon,
  iconPosition = 'left',
  as,
  href,
  disabled,
  size = 'm',
  refObject,
  rel,
  target,
  type,
  ...props
}: ButtonProps) => {
  if (as === 'a') {
    return (
      <StyledButton
        {...props}
        className={className}
        as="a"
        $variant={variant}
        $size={size}
        $iconPosition={icon ? iconPosition : undefined}
        rel={rel}
        target={target}
        href={href ?? ''}
      >
        {Boolean(icon) && iconPosition === 'left' && icon}
        <span>{label}</span>
        {Boolean(icon) && iconPosition === 'right' && icon}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      $variant={variant}
      $size={size}
      $iconPosition={icon ? iconPosition : undefined}
      {...props}
    >
      {Boolean(icon) && iconPosition === 'left' && icon}
      <span>{label}</span>
      {Boolean(icon) && iconPosition === 'right' && icon}
    </StyledButton>
  );
};

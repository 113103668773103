// Tells typescript that this case is impossible.
// This is cleaner than non-null assertions and also
// useful for exhaustive switch statements
export const panic = (message: string): never => {
  console.error(message);
  throw new Error(message);
};

export const safePanic = (message: string, fallback: unknown): never => {
  console.error(message);
  return fallback as never;
};

import { Link, styled, Typography } from '@mui/material';
import React from 'react';

type FooterLinkProps = {
  href: string;
  children: React.ReactNode;
};

const StyledLink = styled(Link)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.red[100],
  },
}));

export const FooterLink = ({ href, children }: FooterLinkProps): React.ReactElement => {
  return (
    <Typography key={Math.random()} variant="body2" component={StyledLink} underline="none" color="textPrimary" href={href}>
      {children}
    </Typography>
  );
};

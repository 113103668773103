import React, { useRef } from 'react';
import { mergeProps, type Placement, useButton, useFocusRing, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { Button } from '../../../spearhead/lib/components/elements/Button/Button';
import { Link } from '../../../spearhead/lib/components/elements/Link/Link';
import { useResetKey } from '../../hooks/useResetKey';
import { Dialog } from './Dialog';
import { Popover } from './Popover';
import {
  StyledArrowDown,
  StyledButton,
  StyledButtonsContainer,
  StyledDialogBody,
  StyledDivider,
  StyledLabel,
  StyledText,
} from './RangeDropdown.styled';

interface RangeDropdownProps {
  children: React.ReactNode;
  label: string;
  value: string;
  className?: string;
  placement?: Placement;
  onDone: () => void;
  onReset: () => void;
  'data-test-id'?: string;
}

export const RangeDropdown = ({
  label,
  value,
  children,
  placement = 'bottom start',
  onDone,
  onReset,
  className,
  'data-test-id': dataTestId,
}: RangeDropdownProps) => {
  const ref = useRef(null);
  const state = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state, ref);
  const { buttonProps } = useButton(triggerProps, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { resetKey, resetKeys } = useResetKey(['dialogBody']);
  const onDoneClick = () => {
    onDone();
    state.close();
  };
  const onResetClick = () => {
    onReset();
    resetKey(['dialogBody']);
  };
  return (
    <>
      <StyledButton
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        $isOpen={state.isOpen}
        $isFocusVisible={isFocusVisible}
        className={className}
        data-test-id={dataTestId}
      >
        {value ? <StyledText size="l" forwardedAs="span" text={value} /> : <StyledLabel size="l" forwardedAs="span" text={label} />}
        <StyledArrowDown $isOpen={state.isOpen} />
      </StyledButton>
      {state.isOpen && (
        <Popover placement={placement} triggerRef={ref} state={{ ...state, close: onDoneClick }}>
          <Dialog {...overlayProps}>
            <StyledDialogBody key={resetKeys.dialogBody}>{children}</StyledDialogBody>
            <StyledDivider />
            <StyledButtonsContainer>
              <Link
                as="button"
                label="Reset"
                size="small"
                variant="primary"
                data-test-id={`${dataTestId}_resetButton`}
                onClick={onResetClick}
              />
              <Button variant="secondary" label="Done" size="s" data-test-id={`${dataTestId}_doneButton`} onClick={onDoneClick} />
            </StyledButtonsContainer>
          </Dialog>
        </Popover>
      )}
    </>
  );
};

import {
  customInstance
} from "./chunk-PCL3B4V6.js";

// generated/search-bff/controllers/listing-property-search/listing-property-search.ts
var postListingsByIds = (getListingsRequest, params, options) => {
  return customInstance(
    { url: `/v1/listing`, method: "POST", headers: { "Content-Type": "application/json" }, data: getListingsRequest, params },
    options
  );
};
var searchListings = (searchFilterDto, params, options) => {
  return customInstance(
    { url: `/v1/listing/search`, method: "POST", headers: { "Content-Type": "application/json" }, data: searchFilterDto, params },
    options
  );
};
var searchListingsMinified = (searchFilterDto, params, options) => {
  return customInstance(
    { url: `/v1/listing/search/minified`, method: "POST", headers: { "Content-Type": "application/json" }, data: searchFilterDto, params },
    options
  );
};
var listPropertiesForMap = (searchFilterDto, options) => {
  return customInstance(
    { url: `/v1/listing/search/geopoints`, method: "POST", headers: { "Content-Type": "application/json" }, data: searchFilterDto },
    options
  );
};
var listPropertiesForMap1 = (searchFilterDto, options) => {
  return customInstance(
    { url: `/v1/admin/listing/search/geopoints`, method: "POST", headers: { "Content-Type": "application/json" }, data: searchFilterDto },
    options
  );
};
var getListingById = (listingIdOrDisplayId, params, options) => {
  return customInstance({ url: `/v1/listing/${listingIdOrDisplayId}`, method: "GET", params }, options);
};

export {
  postListingsByIds,
  searchListings,
  searchListingsMinified,
  listPropertiesForMap,
  listPropertiesForMap1,
  getListingById
};

import { styled } from 'styled-components';

import VideoIcon from '../../assets/icons/small/Video.svg';
import VRIcon from '../../assets/icons/small/VR.svg';

export const StyledContainer = styled.span`
  position: absolute;
  bottom: var(--distance-static-s);
  left: var(--distance-static-s);
  padding: var(--distance-static-3xs) var(--distance-static-xxs);
  border-radius: var(--border-radius-s);
  background-color: rgba(13, 13, 13, 0.6);
  color: var(--color-light);
  line-height: 0;
  z-index: var(--zindex-m);
`;

const icon = {
  video: <VideoIcon />,
  interactive: <VRIcon />,
};

export const VirtualTourIcon = ({ type, 'data-test-id': dataTestId }: { type: 'interactive' | 'video'; 'data-test-id'?: string }) => {
  return <StyledContainer data-test-id={dataTestId}>{icon[type]}</StyledContainer>;
};

import { Switch } from 'react-aria-components';
import { styled } from 'styled-components';

export const StyledIndicator = styled.div`
  width: 36px;
  height: 20px;
  background: var(--color-light-shade);
  border-radius: 999px;
  transition:
    background 0.2s ease-out,
    transform 0.2s ease-out;

  &:before {
    content: '';
    display: block;
    margin: 2px;
    width: 16px;
    height: 16px;
    background: var(--color-light);
    border-radius: 50%;
    transition: all 200ms;
    box-shadow: var(--shadow-s);
  }
`;

export const StyledSwitch = styled(Switch)`
  display: flex;
  align-items: center;
  gap: var(--distance-static-xs);
  color: var(--color-dark-intense);
  cursor: pointer;

  &[data-selected] {
    ${StyledIndicator} {
      background: var(--color-dark-intense);

      &:before {
        background: var(--color-light);
        transform: translateX(100%);
      }
    }
  }

  &[data-focus-visible] ${StyledIndicator} {
    outline: 2px solid var(--color-dark-intense);
    outline-offset: 2px;
  }
`;

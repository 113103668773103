import { confirmSearchAlert } from './confirmSearchAlert/confirmSearchAlert';
import { createSearchAlert } from './createSearchAlert/createSearchAlert';
import { deleteAllSearchAlerts } from './deleteAllSearchAlert/deleteAllSearchAlert';
import { deleteSearchAlert } from './deleteSearchAlert/deleteSearchAlert';
import { editSearchAlert } from './editSearchAlert/editSearchAlert';
import { findAllSearchAlerts } from './findAllSearchAlerts/findAllSearchAlerts';
import { getLocationDetails } from './getLocationDetails/getLocationDetails';
import { getLocationSuggestions } from './getLocationSuggestions/getLocationSuggestions';
import { getPlaceLocationDetails } from './getPlaceLocationDetails/getPlaceLocationDetails';
import { getProperties } from './getProperties/getProperties';
import { getPropertiesByAgentId } from './getPropertiesByAgentId/getPropertiesByAgentId';
import { getPropertiesByDisplayIds } from './getPropertiesByDisplayIds/getPropertiesByDisplayIds';
import { getPropertiesByListingIds } from './getPropertiesByListingIds/getPropertiesByListingIds';
import { getPropertiesByLocation } from './getPropertiesByLocation/getPropertiesByLocation';
import { getPropertiesByShopIds } from './getPropertiesByShopIds/getPropertiesByShopIds';
import { getPropertiesCoordinates } from './getPropertiesCoordinates/getPropertiesCoordinates';
import { getPropertyByListingId } from './getPropertyByListingId/getPropertyByListingId';
import { getSimilarProperties } from './getSimilarProperties/getSimilarProperties';
import { getSuggestions } from './getSuggestions/getSuggestions';
import { getSuggestionsByDisplayId } from './getSuggestionsByDisplayId/getSuggestionsByDisplayId';
import { getTrackingByGa4Id } from './getTrackingByGa4Id/getTrackingByGa4Id';
import { getTrackingByShopId } from './getTrackingByShopId/getTrackingByShopId';
import { listingLead } from './listingLead/listingLead';
import {
  type ApiConfig,
  type GetPropertiesFilters,
  type GetPropertiesOptions,
  type ParametersExceptLast,
  type WithRequired,
} from './types';

export class SearchApi {
  config: WithRequired<ApiConfig, 'environment'>;

  constructor(config: ApiConfig) {
    this.config = { ...config, environment: config.environment || 'prod' };
  }

  /**
   * Fetch property listings based on provided filters and options.
   * @function
   * @param {GetPropertiesFilters} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
   * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
   * @param {boolean} [useCompactData=false] - Whether to use compact data for the search module. This will return a smaller set of data for each property listing.
   * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the property listings or error details.
   *
   * @summary Fetch property listings based on provided filters and options.
   */
  getProperties = (filters: GetPropertiesFilters, options: GetPropertiesOptions, useCompactData: boolean = false) =>
    getProperties(filters, options, this.config, useCompactData);

  /**
   * Fetch properties coordinates based on provided filters.
   * @function
   * @param {GetPropertiesFilters} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
   * @param {BaseOptions} options - Options for price & surface filters: language, currency, measurement system.
   * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to an array of geopoint objects.
   *
   * @summary Fetch properties coordinates based on provided filters.
   */
  getPropertiesCoordinates = (...params: ParametersExceptLast<typeof getPropertiesCoordinates>) =>
    getPropertiesCoordinates(...params, this.config);

  /**
   * Fetch location suggestions based on the provided query and options.
   * @function
   * @param {string} query - The location or place string representing a complete or partial city, neighborhood, postal code, etc.
   * @param {GetLocationSuggestionOptions} options - Configuration options for the suggestion fetch operation,
   * including optional session token, limit, and types of places to include in the results.
   * @returns {Promise<ApiResult<AutoSuggestionPlaceResult[]>>} A promise that resolves to either location suggestions or error details.
   *
   * @summary Fetch location suggestions based on the provided query and options.
   */
  getLocationSuggestions = (...params: ParametersExceptLast<typeof getLocationSuggestions>) =>
    getLocationSuggestions(...params, this.config);

  /**
   * Fetch property listings listed by a specific agent ID.
   * @function
   * @param {string} agentId - The ID of the agent whose properties are being requested.
   * @param {GetPropertiesByAgentIdFilter} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
   * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
   * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the the properties data or error details.
   *
   * @summary Fetch property listings based on a specific agent's ID.
   */
  getPropertiesByAgentId = (...params: ParametersExceptLast<typeof getPropertiesByAgentId>) =>
    getPropertiesByAgentId(...params, this.config);

  /**
   * Fetch property listings based on an array of display IDs.
   * @function
   * @param {string[]} displayIds - An array of display IDs of the properties to be fetched.
   * @param {BaseOptions} options - Options to customize the search: language, currency, and measurement unit.
   * @returns {Promise<ApiResult<Property[]>>} A promise that resolves to the property listings or error details.
   *
   * @summary Fetch property listings based on an array of display IDs.
   */
  getPropertiesByDisplayIds = (...params: ParametersExceptLast<typeof getPropertiesByDisplayIds>) =>
    getPropertiesByDisplayIds(...params, this.config);

  /**
   * Fetch property listings based on an array of listing IDs (UUIDs).
   * @function
   * @param {string[]} listingIds - An array of listing IDs for the properties to be fetched.
   * @param {BaseOptions} options - Options to customize the search: language, currency, and measurement unit.
   * @returns {Promise<ApiResult<Property[]>>} A promise that resolves to the property listings or error details.
   *
   * @summary Fetch property listings based on an array of listing IDs.
   */
  getPropertiesByListingIds = (...params: ParametersExceptLast<typeof getPropertiesByListingIds>) =>
    getPropertiesByListingIds(...params, this.config);

  /**
   * Fetch property listings listed by a specific place ID.
   * @function
   * @param {string} placeId - The ID of the place with properties that are being requested.
   * @param {GetPropertiesByAgentIdFilter} filters - Filters to apply to the property search. These include things like price range and property type.
   * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
   * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the the properties data or error details.
   *
   * @summary Fetch property listings based on a specific place ID.
   */
  getPropertiesByLocation = (...params: ParametersExceptLast<typeof getPropertiesByLocation>) =>
    getPropertiesByLocation(...params, this.config);

  /**
   * Fetch property listings listed by an array of shop IDs.
   * @function
   * @param {string[]} shopIds - The ID of the shop whose properties are being requested.
   * @param {GetPropertiesByAgentIdFilter} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
   * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
   * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the the properties data or error details.
   *
   * @summary Fetch property listings listed by an array of shop IDs.
   */
  getPropertiesByShopIds = (...params: ParametersExceptLast<typeof getPropertiesByShopIds>) =>
    getPropertiesByShopIds(...params, this.config);

  /**
   * Fetch detailed information about a property by its listing ID (UUID).
   * @function
   * @param {string} listingId - The UUID for the listing.
   * @param {GetPropertyOptions} options - Options to customize the search: language, currency, measurement unit, and optionally (if it exists), if information about the parent (development project) property should also be returned.
   * @returns {Promise<ApiResult<PropertyResponse>>} A promise that resolves to the result of the API call, containing either the detailed property information or an error message.
   *
   * @summary Fetch property details using its listing ID.
   */
  getPropertyByListingId = (...params: ParametersExceptLast<typeof getPropertyByListingId>) =>
    getPropertyByListingId(...params, this.config);

  /**
   * Fetch property listings that are similar to a property. The location, price +- 20%, business area, and marketing type of the property are used to find similar properties. If the property is a development project, only other development projects are returned. If it is not a development project, the search radius is set to 5 km.
   * @function
   * @param {Property} property - An object representing the property to find similar properties for.
   * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
   * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the property listings or error details.
   *
   * @summary Fetch similar properties based on a provided property listing.
   */
  getSimilarProperties = (...params: ParametersExceptLast<typeof getSimilarProperties>) => getSimilarProperties(...params, this.config);

  /**
   * Fetch either property listing suggestions or location suggestions based on the provided query.
   * @function
   * @summary Fetches suggestions for properties or locations based on query.
   * @param {string} query - The search query, which can be a display ID or a partial or complete location search term.
   * @param {GetSuggestionsOptions} options - Options to customize the suggestions, such as the number of suggestions and language of the information about the suggestions.
   * @returns {Promise<ApiResult<SuggestionResult>>} A promise that resolves to an object containing the status of the request and the suggestions data (either properties or locations), or error details.
   *
   * @summary Fetch either property listing suggestions or location suggestions based on the provided query.
   */
  getSuggestions = (...params: ParametersExceptLast<typeof getSuggestions>) => getSuggestions(...params, this.config);

  /**
   * Fetch suggestions based on a partial or full display ID.
   * @function
   * @param {string} displayId - The display ID of the property to be fetched.
   * @param {GetDisplayIdSuggestionOptions} options - Options to customize the search: language and number of suggestions to return.
   * @returns {Promise<ApiResult<Property[]>>} A promise that resolves to the result of the API call, containing either the detailed property information or error details.
   *
   * @summary Fetch suggestions based on a partial or full display ID.
   */
  getSuggestionsByDisplayId = (...params: ParametersExceptLast<typeof getSuggestionsByDisplayId>) =>
    getSuggestionsByDisplayId(...params, this.config);

  /**
   * Fetch detailed location information for a given place ID.
   * @deprecated This is deprecated and will be removed soon. Please use getLocationDetails instead.
   * @function
   * @param {string} placeId - The unique identifier of the place to fetch details for.
   * @param {Language} getPlaceLocationDetailsOptions.language - The language that the fetched location information should be in.
   * @param {string} getPlaceLocationDetailsOptions.sessionToken - Session Token.
   * @returns {Promise<ApiResult<PlaceLocation>>} A promise that resolves to either detailed location information or error details.
   *
   * @summary Fetch detailed location information for a given place ID.
   */
  getPlaceLocationDetails = (...params: ParametersExceptLast<typeof getPlaceLocationDetails>) =>
    getPlaceLocationDetails(...params, this.config);

  /**
   * Fetch location information for a given place ID.
   * @function
   * @param {string} placeId - The unique identifier of the place to fetch details for.
   * @param {Language} getLocationDetailsOptions.language - The language that the fetched location information should be in.
   * @param {string} getLocationDetailsOptions.sessionToken - Session Token.
   * @returns {Promise<ApiResult<PlaceResult>>} A promise that resolves to either location information or error details.
   *
   * @summary Fetch detailed location information for a given place ID.
   */
  getLocationDetails = (...params: ParametersExceptLast<typeof getLocationDetails>) => getLocationDetails(...params, this.config);

  /**
   * Create a search alert
   * @function
   * @param {CreateSearchAlertPayload} payload - The payload with required fields to create a search alert.
   * @param {BaseOptions} options - Options to customize the search: language, currency, and measurement unit.
   * @returns {Promise<ApiResult<CreateSearchAlertResult>>} A promise that resolves to a create search alert result containing the created search alert id and pub sub message id.
   *
   * @summary Create a search alert
   */
  createSearchAlert = (...params: ParametersExceptLast<typeof createSearchAlert>) => createSearchAlert(...params, this.config);

  /**
   * Confirm a search alert
   * @function
   * @param {string} searchAlertId - The unique identifier of the search alert to be confirmed.
   * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
   *
   * @summary Confirm a search alert
   */
  confirmSearchAlert = (...params: ParametersExceptLast<typeof confirmSearchAlert>) => confirmSearchAlert(...params, this.config);

  /**
   * Delete all search alerts of an Account
   * @function
   * @param {string} accountId - The unique identifier of an account to delete all search alerts.
   * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
   *
   * @summary Delete all search alerts of an Account
   */
  deleteAllSearchAlerts = (...params: ParametersExceptLast<typeof deleteAllSearchAlerts>) => deleteAllSearchAlerts(...params, this.config);

  /**
   * Delete a search alert
   * @function
   * @param {string} searchAlertId - The unique identifier of the search alert which should be deleted.
   * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
   *
   * @summary Delete a search alert
   */
  deleteSearchAlert = (...params: ParametersExceptLast<typeof deleteSearchAlert>) => deleteSearchAlert(...params, this.config);

  /**
   * Edit a search alert
   * @function
   * @param {string} searchAlertId - The unique identifier of the search alert which should be updated.
   * @param {EditSearchAlertRequest} editSearchAlertRequest - Object containing the fields of a search alert that should be updated like name or intervalSeconds.
   * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
   *
   * @summary Edit a search alert
   */
  editSearchAlert = (...params: ParametersExceptLast<typeof editSearchAlert>) => editSearchAlert(...params, this.config);

  /**
   * Find all search alerts of an Account
   * @function
   * @param {string} searchAlertId - The unique identifier of the search alert which should be updated.
   * @param {FindAllSearchAlertsParams} params - Object containing the fields of a search alert that should be updated like name or intervalSeconds.
   * @returns {Promise<ApiResult<FindAllSearchAlertsResponse>>} A promise that resolves with all found alerts as well as information about the count and page.
   *
   * @summary Find all search alerts of an Account
   */
  findAllSearchAlerts = (...params: ParametersExceptLast<typeof findAllSearchAlerts>) => findAllSearchAlerts(...params, this.config);

  getTrackingByGa4Id = (...params: ParametersExceptLast<typeof getTrackingByGa4Id>) => getTrackingByGa4Id(...params, this.config);

  getTrackingByShopId = (...params: ParametersExceptLast<typeof getTrackingByShopId>) => getTrackingByShopId(...params, this.config);

  listingLead = (...params: ParametersExceptLast<typeof listingLead>) => listingLead(...params, this.config);
}

import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { InputText, type InputTextProps, type InputTextSizesProps } from '@spearhead/components/elements/InputText/InputText';
import { type ForwardedRef, forwardRef, type MutableRefObject, type ReactNode } from 'react';
import { InputContext, useContextProps } from 'react-aria-components';

import SearchIcon from '../../../spearhead/lib/assets/icons/small/Search.svg';
import { ClearInputButton } from './ClearInputButton';

interface Props extends InputTextProps {
  size?: InputTextSizesProps;
  onClearInput?: () => void;
  label?: ReactNode;
  role?: string;
}

export const AutoCompleteInput = forwardRef((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { t } = useAppContext();

  const { onClearInput, ...restProps } = props;

  const [inputProps, inputRef] = useContextProps(restProps, ref, InputContext);

  return (
    <InputText
      {...inputProps}
      placeholder={t('search.searchbar.label')}
      ref={inputRef as MutableRefObject<null>}
      startAdornment={<SearchIcon aria-label="search-icon" />}
      endAdornment={props.value ? <ClearInputButton onClick={onClearInput} /> : undefined}
    />
  );
});

AutoCompleteInput.displayName = 'AutoCompleteInput';

import { Link, styled, Typography } from '@mui/material';
import React from 'react';

import { ArrowRightIcon } from '../../Icons/ArrowRightIcon';

type FooterMobileLinkProp = {
  href?: string;
  children?: React.ReactNode;
};

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: theme.palette.red[100],
  },
}));

const StyledLinkText = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const FooterMobileMainLink = ({ href, children }: FooterMobileLinkProp): React.ReactElement => {
  return (
    <StyledLink underline="none" color="textPrimary" href={href}>
      <>
        <ArrowRightIcon fontSize="inherit" />
        <StyledLinkText variant="body2">{children}</StyledLinkText>
      </>
    </StyledLink>
  );
};

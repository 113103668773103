import {
  getConsentDocumentContext,
  getPageContext,
  getPropertyContext,
  getShopContext,
  trackUserInteractionEvent,
  UserActionEnum,
} from '@ev/snowplow-library';
import { type CountryCode, type Language, type Property } from '@pkgs/api';
import { useTranslation } from 'next-i18next';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { BlockedLocalStorageDialog } from '@/components/common/BlockedLocalStorage/BlockedLocalStorageDialog';
import { SearchApiClient } from '@/consts/search-api';
import { MAX_WATCHLIST_ITEMS } from '@/consts/watchlist';
import { useSnackbar } from '@/hooks/useSnackbar';
import { type PageTypeEnum } from '@/types/tracking';
import { getShopContextCommonValues, mapPropertyDataToPropertyContext } from '@/utils/tracking';
import { orderByField } from '@/utils/watchlist';

import { useSettings } from '../hooks/useSettings';
import { isLocalStorageAvailable } from '../utils/localStorage';

export interface WatchlistItem {
  id: string;
  addedOn: number;
}

export type WatchlistContextType = {
  watchlist: WatchlistItem[];
  isWatchlistEmpty: boolean;
  isInWatchlist: (propertyId: string) => boolean;
  toggleWatchlistItem: (property: Property, pageType: PageTypeEnum, trackInteraction?: boolean) => void;
  removeItems: (propertyIds: string[]) => void;
  clearWatchlist: (pageType: PageTypeEnum) => void;
  addWatchlistItems: (propertyList: Property[], pageType: PageTypeEnum) => void;
  lastWatchlistItems: Property[];
};

export const WatchlistContext = createContext<WatchlistContextType>({
  watchlist: [],
  isWatchlistEmpty: true,
  isInWatchlist: () => false,
  toggleWatchlistItem: () => null,
  removeItems: () => null,
  clearWatchlist: () => null,
  addWatchlistItems: () => null,
  lastWatchlistItems: [],
});

const handleSnowplowEvent = (
  isPropertyInWatchlist: boolean,
  pageType: PageTypeEnum,
  property: Property,
  language: Language,
  countryCode?: CountryCode
) => {
  const consentStatus = window.Didomi?.getUserStatus?.().vendors?.consent;

  trackUserInteractionEvent({
    action: UserActionEnum.click,
    elementId: isPropertyInWatchlist
      ? `${property.isDevelopmentProject ? 'project' : 'expose'}_remove-from-watchlist`
      : `${property.isDevelopmentProject ? 'project' : 'expose'}_add-to-watchlist`,
    contexts: [
      getPageContext({
        isHQPage: true,
        type: pageType,
        page_language: language,
        ...(countryCode && { country_code: countryCode }),
      }),
      getShopContext(getShopContextCommonValues(property)),
      getPropertyContext(mapPropertyDataToPropertyContext(property)),
      getConsentDocumentContext({
        vendors_consent: consentStatus?.enabled.join(',') ?? '',
        vendors_consent_denied: consentStatus?.disabled.join(',') ?? '',
      }),
    ],
  });
};

export const WatchlistProvider = ({ children }: { children: React.ReactNode }) => {
  const { snackbar } = useSnackbar();
  const { currency, measurementSystem, countryCode } = useSettings();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [watchlist = [], setWatchlist] = useLocalStorage<WatchlistItem[]>('watchlist', []);
  const [lastWatchlistItems, setLastWatchlistItems] = useState<Property[]>([]);
  const [isWatchlistEmpty, setIsWatchlistEmpty] = useState(true);
  const [isBlockedLocalStorageDialogOpen, setIsBlockedLocalStorageDialogOpen] = useState(false);
  // 1st render or page reload: get details of last 3 added properties
  // If some getObject property fails with 404, remove that item from the watchlist (no longer available)
  useEffect(() => {
    const getLast3WatchlistItems = async () => {
      const propertyIdList = watchlist.sort(orderByField('ADDED_ON_DESC')).map(({ id }) => id);

      const lastest3WatchlistItems: Property[] = [];
      const obsoletePropertyIds: string[] = [];

      for (const propertyId of propertyIdList) {
        const result = await SearchApiClient.getPropertyByListingId(propertyId, {
          language: language as Language,
          currency,
          measurementSystem,
        });

        if (result.status === 'success') {
          lastest3WatchlistItems.push(result.data.property);
        } else {
          obsoletePropertyIds.push(propertyId);
        }

        if (lastest3WatchlistItems.length === 3) {
          break;
        }
      }

      // Remove properties that are no longer available
      if (obsoletePropertyIds.length) {
        removeItems(obsoletePropertyIds);
      }

      setLastWatchlistItems(lastest3WatchlistItems);
    };

    getLast3WatchlistItems().catch(console.error);
  }, []);

  // Update value inside useEffect because watchlist is read from localstorage on the client
  // and otherwise the initial value for isWatchlistEmpty would always be true
  useEffect(() => {
    setIsWatchlistEmpty(watchlist.length === 0);
  }, [watchlist]);

  const showChangeCouldNotBeSavedToast = () => {
    snackbar({
      label: t('search.common.changeCouldNotBeSaved'),
      variant: 'warning',
      action: {
        label: t('search.common.why'),
        onClick: () => setIsBlockedLocalStorageDialogOpen(true),
      },
    });
  };

  const isInWatchlist = useCallback(
    (propertyId: string): boolean => {
      return watchlist.some(({ id }) => id === propertyId) || false;
    },
    [watchlist]
  );

  const toggleWatchlistItem = useCallback(
    async (property: Property, pageType: PageTypeEnum, trackInteraction = true) => {
      if (!isLocalStorageAvailable()) {
        showChangeCouldNotBeSavedToast();
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const isPropertyInWatchlist = isInWatchlist(property.id!);

      if (isPropertyInWatchlist) {
        // Remove item
        const newWatchlist = watchlist.filter((watchlistItem) => watchlistItem.id !== property.id);
        const newLast3WatchlistItems = lastWatchlistItems.filter((last3WatchlistItemsItem) => last3WatchlistItemsItem.id !== property.id);

        setWatchlist(newWatchlist);

        // Rules to add on the last3WatchlistItems:
        // Less than 3 items on the watchlist >> remove it
        // More than 3 items, check if it is inside the current 3 last items:
        //  Inside >> remove it and add the 3rd last added item
        //  Outside >> remove it
        if (watchlist.length <= 3) {
          setLastWatchlistItems(newLast3WatchlistItems);
        } else {
          const removedIsInsideLast3WatchlistItems = lastWatchlistItems.some((last3WatchlistItem) => last3WatchlistItem.id === property.id);

          if (removedIsInsideLast3WatchlistItems) {
            // Get the 3rd last added item
            const propertyDetails = await SearchApiClient.getPropertyByListingId(newWatchlist[2].id, {
              language: language as Language,
              currency,
              measurementSystem,
            });
            if (propertyDetails.status === 'success') {
              newLast3WatchlistItems.push(propertyDetails.data.property);
              setLastWatchlistItems(newLast3WatchlistItems);
            }
          }
        }
      } else if (watchlist.length < MAX_WATCHLIST_ITEMS) {
        // Add item
        const newWatchlist = [...watchlist];
        const newLast3Items = [...lastWatchlistItems];

        newWatchlist.unshift({
          id: property.id,
          addedOn: new Date().getTime(),
        });
        setWatchlist(newWatchlist);

        if (lastWatchlistItems.length < 3) {
          newLast3Items.unshift(property);
        } else {
          newLast3Items.pop();
          newLast3Items.unshift(property);
        }
        setLastWatchlistItems(newLast3Items);
        snackbar({
          label: t('search.watchlist.snackbar.added'),
        });
      } else if (watchlist.length >= MAX_WATCHLIST_ITEMS) {
        snackbar({
          label: t('search.watchlist.snackbar.cantBeAdded'),
        });
      }

      if (trackInteraction) {
        handleSnowplowEvent(isPropertyInWatchlist, pageType, property, language as Language, countryCode);
      }
    },
    [isInWatchlist, setWatchlist, watchlist, lastWatchlistItems]
  );

  const addWatchlistItems = useCallback(
    (propertyList: Property[], pageType: PageTypeEnum) => {
      if (!isLocalStorageAvailable()) {
        showChangeCouldNotBeSavedToast();
        return;
      }

      // Only add properties that are not included on the watchlist
      const newProperties = propertyList.filter(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        (propertyItem) => !isInWatchlist(propertyItem.id!)
      );
      if (newProperties.length) {
        const addedOn = new Date().getTime();
        const newWatchlistItems = newProperties.map(({ id }) => ({
          id,
          addedOn,
        }));

        const newWatchList = [...newWatchlistItems, ...watchlist];
        const newLast3WatchlistItems = [...newProperties, ...lastWatchlistItems].slice(0, 3);

        setWatchlist(newWatchList);
        setLastWatchlistItems(newLast3WatchlistItems);

        newProperties.forEach((itemToAdd) => {
          handleSnowplowEvent(false, pageType, itemToAdd, language as Language, countryCode);
        });
      }
    },
    [isInWatchlist, lastWatchlistItems, setWatchlist, watchlist]
  );

  const removeItems = useCallback(
    (propertyIds: string[]) => {
      const watchlistWithoutObsoleteProperties = watchlist.filter((watchlistItem) => !propertyIds.includes(watchlistItem.id));
      setWatchlist(watchlistWithoutObsoleteProperties);
    },
    [setWatchlist, watchlist]
  );

  const clearWatchlist = useCallback(
    (pageType: PageTypeEnum) => {
      watchlist.forEach((watchlistItem) =>
        handleSnowplowEvent(
          true,
          pageType,
          {
            id: watchlistItem.id,
          } as Property,
          language as Language,
          countryCode
        )
      );

      setWatchlist([]);
      setLastWatchlistItems([]);
    },
    [watchlist]
  );

  const value = useMemo(
    () => ({
      watchlist,
      isWatchlistEmpty,
      isInWatchlist,
      toggleWatchlistItem,
      removeItems,
      clearWatchlist,
      addWatchlistItems,
      lastWatchlistItems,
    }),
    [watchlist, isWatchlistEmpty, isInWatchlist, toggleWatchlistItem, removeItems, clearWatchlist, addWatchlistItems, lastWatchlistItems]
  );
  return (
    <WatchlistContext.Provider value={value}>
      {isBlockedLocalStorageDialogOpen && <BlockedLocalStorageDialog onClose={() => setIsBlockedLocalStorageDialogOpen(false)} />}

      {children}
    </WatchlistContext.Provider>
  );
};

import { type BigDecimalRangeDto } from '@ev/search-modules-api';
import { useState } from 'react';
import { VisuallyHidden } from 'react-aria';

import { useAppContext } from '../../hooks/useAppContext';
import { useSearch } from '../../hooks/useSearch';
import { validateRangeValues } from '../../utils/numberFormatters';
import { Stepper } from '../Stepper/Stepper';
import { StyledCopy, StyledFieldset, StyledFieldsSeparator } from './RangeInput.styled';
import { type RangeInputProps } from './RangeInput.types';

export const RangeStepper = ({
  defaultRange,
  onChange,
  label,
  id,
  size = 's',
  hiddenLabel = false,
  'data-test-id': dataTestId,
}: RangeInputProps & {
  label: string;
  id: string;
  size?: 's' | 'm';
  'data-test-id'?: string;
}) => {
  const { t } = useAppContext();
  const {
    options: { language },
  } = useSearch();
  const [range, setRange] = useState(defaultRange);
  const handleOnChange = (range: BigDecimalRangeDto) => {
    setRange({ ...range });
    onChange(validateRangeValues(range));
  };
  return (
    <StyledFieldset data-test-id={dataTestId ?? 'search-components_range-stepper'}>
      {hiddenLabel ? (
        <VisuallyHidden>
          <StyledCopy forwardedAs="legend" text={label} size="m" />
        </VisuallyHidden>
      ) : (
        <StyledCopy forwardedAs="legend" text={label} size="m" />
      )}
      <Stepper
        id={`${id}Min`}
        name={`${id}Min`}
        placeholder={t('search.searchbar.minLabel')}
        aria-label={`${label} ${t('search.searchbar.minLabel')}`}
        size={size}
        minValue={1}
        maxValue={999}
        value={range?.min}
        language={language}
        onChange={(val) => {
          handleOnChange({
            ...range,
            min: Number.isNaN(val) ? undefined : val,
          });
        }}
        data-test-id={`${dataTestId}_input-min`}
      />
      <StyledFieldsSeparator size="l" forwardedAs="span" text="–" />
      <Stepper
        id={`${id}Max`}
        name={`${id}Max`}
        placeholder={t('search.searchbar.maxLabel')}
        aria-label={`${label} ${t('search.searchbar.maxLabel')}`}
        size={size}
        minValue={1}
        maxValue={999}
        value={range?.max}
        language={language}
        onChange={(val) => {
          handleOnChange({
            ...range,
            max: Number.isNaN(val) ? undefined : val,
          });
        }}
        data-test-id={`${dataTestId}_input-max`}
      />
    </StyledFieldset>
  );
};

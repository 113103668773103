import {
  Currency,
  MeasurementSystem
} from "./chunk-VQQW7DNQ.js";

// src/utils/defaultOptions.ts
function getDefaultParams(options) {
  return {
    language: [options.language],
    measurementSystem: Object.values(MeasurementSystem),
    currency: Object.values(Currency)
  };
}
function getDefaultSearchListingsParams(options) {
  const pageSize = options.pageSize ?? 20;
  return {
    ...getDefaultParams(options),
    offset: options.page ? (options.page - 1) * pageSize : 0,
    limit: pageSize
  };
}

export {
  getDefaultParams,
  getDefaultSearchListingsParams
};

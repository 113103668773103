import { styled } from 'styled-components';

import ProcessingSVG from '../../../assets/icons/large/Sent.svg';
import { Button } from '../../elements/Button/Button';
import { Copy } from '../../elements/Copy/Copy';
import { Headline } from '../../elements/Headline/Headline';
import { Overline } from '../../elements/Overline/Overline';

export const StyledStatusScreen = styled.div<{ $withMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  ${({ $withMargin }) => ($withMargin ? 'margin: var(--distance-dynamic-xl) var(--distance-static-m) var(--distance-static-m);' : '')}
  text-align: center;
  gap: var(--distance-static-s);
`;

export const StyledContent = styled.div`
  margin-top: var(--distance-static-l);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--distance-static-s);
`;

export const StyledCopy = styled(Copy)`
  margin-top: var(--distance-static-l);
`;

export const StyledOverline = styled(Overline)`
  margin-bottom: 0;
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: var(--distance-static-m);
`;

export const StyledSvgWrapper = styled.div`
  flex-shrink: 0;
  line-height: 0;
`;

export const StyledProcessingSVG = styled(ProcessingSVG)`
  & .Sent_svg__circle {
    animation: loading 3s steps(70, jump-start) infinite;
    transform-origin: center;

    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: var(--distance-static-xxl);
`;

export const StyledSubHeadline = styled(Copy)`
  margin-top: var(--distance-static-l);
  white-space: pre-line;
`;

import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const InterrogationMarkIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon viewBox="0 0 30 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ minWidth: 27, minHeight: 45 }}>
      <path
        d="M4.16232 4.99211C5.47455 3.64948 7.05992 2.60037 8.81265 1.9148C10.5654 1.22923 12.4451 0.922951 14.3264 1.01643C17.8309 0.847233 21.263 2.04572 23.8903 4.35601C25.0717 5.44566 26.0044 6.77411 26.6257 8.25198C27.2469 9.72984 27.5423 11.3228 27.4918 12.9236C27.5676 14.7159 27.1156 16.4913 26.1912 18.0323C24.6021 20.1269 22.8131 22.0641 20.8491 23.8169C19.3 25.1303 18.0049 26.7133 17.0275 28.4884C16.3359 30.3015 16.0293 32.2371 16.1271 34.1736H10.805C10.7236 31.845 11.0693 29.5211 11.8254 27.3156C12.8999 25.0375 14.4398 23.0066 16.3473 21.352L18.7883 19.0064C19.482 18.3885 20.0878 17.6796 20.589 16.8993C21.3761 15.7189 21.8065 14.3393 21.8295 12.9236C21.8625 11.0771 21.2311 9.27961 20.0488 7.8546C19.1186 7.05197 18.0295 6.45178 16.8517 6.09267C15.6739 5.73356 14.4334 5.62348 13.2101 5.76955C11.9869 5.91561 10.8079 6.31457 9.74931 6.94073C8.69067 7.56688 7.77575 8.40636 7.06346 9.40511C6.20497 11.276 5.77453 13.3127 5.803 15.3686H0.520819C0.316355 11.5689 1.62435 7.84182 4.16232 4.99211ZM10.6849 39.1631H16.6874V45.3651H10.6849V39.1631Z"
        fill="#EB0A0A"
      />

      <path
        d="M4.16232 4.99211C5.47455 3.64948 7.05992 2.60037 8.81265 1.9148C10.5654 1.22923 12.4451 0.922951 14.3264 1.01643C17.8309 0.847233 21.263 2.04572 23.8903 4.35601C25.0717 5.44566 26.0044 6.77411 26.6257 8.25198C27.2469 9.72984 27.5423 11.3228 27.4918 12.9236C27.5676 14.7159 27.1156 16.4913 26.1912 18.0323C24.6021 20.1269 22.8131 22.0641 20.8491 23.8169C19.3 25.1303 18.0049 26.7133 17.0275 28.4884C16.3359 30.3015 16.0293 32.2371 16.1271 34.1736H10.805C10.7236 31.845 11.0693 29.5211 11.8254 27.3156C12.8999 25.0375 14.4398 23.0066 16.3473 21.352L18.7883 19.0064C19.482 18.3885 20.0878 17.6796 20.589 16.8993C21.3761 15.7189 21.8065 14.3393 21.8295 12.9236C21.8625 11.0771 21.2311 9.27961 20.0488 7.8546C19.1186 7.05197 18.0295 6.45178 16.8517 6.09267C15.6739 5.73356 14.4334 5.62348 13.2101 5.76955C11.9869 5.91561 10.8079 6.31457 9.74931 6.94073C8.69067 7.56688 7.77575 8.40636 7.06346 9.40511C6.20497 11.276 5.77453 13.3127 5.803 15.3686H0.520819C0.316355 11.5689 1.62435 7.84182 4.16232 4.99211ZM10.6849 39.1631H16.6874V45.3651H10.6849V39.1631Z"
        fill="#EB0A0A"
      />
      <path
        d="M6.16232 4.99211C7.47455 3.64948 9.05992 2.60037 10.8126 1.9148C12.5654 1.22923 14.4451 0.922951 16.3264 1.01643C19.8309 0.847233 23.263 2.04572 25.8903 4.35601C27.0717 5.44566 28.0045 6.77411 28.6257 8.25198C29.2469 9.72984 29.5423 11.3228 29.4918 12.9236C29.5676 14.7159 29.1156 16.4913 28.1912 18.0323C26.6021 20.1269 24.8131 22.0641 22.8491 23.8169C21.3 25.1303 20.0049 26.7133 19.0275 28.4884C18.3359 30.3015 18.0293 32.2371 18.1271 34.1736H12.805C12.7236 31.845 13.0693 29.5211 13.8254 27.3156C14.8999 25.0376 16.4398 23.0066 18.3473 21.352L20.7883 19.0064C21.482 18.3885 22.0878 17.6796 22.589 16.8993C23.3761 15.7189 23.8065 14.3393 23.8295 12.9236C23.8625 11.0771 23.2311 9.27961 22.0488 7.8546C21.1186 7.05197 20.0295 6.45178 18.8517 6.09267C17.6739 5.73356 16.4334 5.62348 15.2101 5.76955C13.9869 5.91561 12.8079 6.31457 11.7493 6.94073C10.6907 7.56688 9.77575 8.40636 9.06346 9.40511C8.20497 11.276 7.77453 13.3127 7.803 15.3686H2.52082C2.31636 11.5689 3.62435 7.84182 6.16232 4.99211ZM12.6849 39.1631H18.6874V45.3651H12.6849V39.1631Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
        color="none"
        fillOpacity="0.01"
      />
    </SvgIcon>
  );
};

import React from 'react';

import CheckCircle from '../../../assets/icons/small/Check-Circle.svg';
import Close from '../../../assets/icons/small/Cross-Naked.svg';
import Info from '../../../assets/icons/small/Info.svg';
import Warning from '../../../assets/icons/small/Warning.svg';
import {
  StyledAlert,
  StyledCloseButton,
  StyledContainer,
  StyledDescription,
  StyledDialog,
  StyledHeadline,
  StyledIcon,
} from './Alert.styled';
import { type AlertVariants, alertVariants } from './Alert.types';

export interface IAlertProps {
  className?: string;
  variant: AlertVariants;
  title?: string;
  onClose?: () => void;
  children: React.ReactNode;
}

const alertIcons = {
  [alertVariants.success]: <CheckCircle />,
  [alertVariants.warning]: <Warning />,
  [alertVariants.error]: <Info />,
  [alertVariants.info]: <Info />,
};

export const Alert = ({ className, title, children, onClose, variant }: IAlertProps) => {
  const critical = (['warning', 'error'] as AlertVariants[]).includes(variant);

  return (
    <StyledAlert
      className={className}
      $variant={variant}
      role={critical ? 'alert' : 'status'}
      // eslint-disable-next-line jsx-expressions/strict-logical-expressions
      aria-labelledby={title && `${variant}-alert-title`}
      aria-describedby={`${variant}-alert-description`}
    >
      {onClose && <StyledCloseButton icon={<Close aria-hidden="true" />} variant="transparent" onClick={onClose} />}
      <StyledDialog>
        {Boolean(title) && (
          <StyledContainer>
            <StyledIcon $variant={variant} aria-hidden="true">
              {alertIcons[variant]}
            </StyledIcon>
            <StyledHeadline text={title} size="m" bold id={`${variant}-alert-title`} />
          </StyledContainer>
        )}
        <StyledDescription id={`${variant}-alert-description`} $hasTitle={Boolean(title)}>
          {!title && (
            <StyledIcon $variant={variant} aria-hidden="true">
              {alertIcons[variant]}
            </StyledIcon>
          )}
          {children}
        </StyledDescription>
      </StyledDialog>
    </StyledAlert>
  );
};

import { type CountryCode, type GetPropertiesOptions, getSearchResultPageUrl, parseSearchParams, safePanic } from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { type SearchProviderProps } from '@pkgs/components/providers/SearchProvider';
import { mapToFiltersWithArrayFields, mapToFiltersWithoutArrayFields } from '@pkgs/components/utils/mapFilterFields';
import { useCallback } from 'react';

import { DEFAULT_OPTIONS } from './useSearchState';

type NextHistoryState = {
  __N: true;
  key: string;
  url: string;
  as: string;
  options: { shallow?: boolean };
};

function createKey() {
  return Math.random().toString(36).slice(2, 10);
}

export async function createSerpUrl(
  filters: SearchModuleFilters,
  options: GetPropertiesOptions,
  countryCode?: CountryCode,
  placeName?: string,
) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window) return safePanic('createSerpUrl should only be called on the client side', '');

  const currentUrl = new URL(window.location.href);
  if (placeName) {
    currentUrl.searchParams.set('placeName', placeName);
  } else {
    currentUrl.searchParams.delete('placeName');
  }

  const url = await getSearchResultPageUrl(
    currentUrl.href,
    mapToFiltersWithArrayFields(filters),
    {
      ...options,
      countryCode,
    },
    ['placeName'],
  );

  return url;
}

export const useUrlState = ({
  countryCode,
  onUrlUpdate,
  syncStateWithUrl = true,
  options,
  filters,
  replaceFilters,
  replaceOptions,
  placeName,
  updatePlaceName,
}: SearchProviderProps & {
  filters: SearchModuleFilters;
  options: Required<GetPropertiesOptions>;
  replaceFilters: (filters: SearchModuleFilters) => void;
  replaceOptions: (options: Required<GetPropertiesOptions>) => void;
  placeName?: string;
  updatePlaceName: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const updateUrl = useCallback(async () => {
    const url = await createSerpUrl(filters, options, countryCode, placeName);

    if (window.location.href === url.href) return;

    if (onUrlUpdate) onUrlUpdate(url.href);

    if (syncStateWithUrl) {
      const historyState: NextHistoryState = {
        url: url.href,
        as: url.href,
        options: { shallow: true },
        __N: true, // indicates that this is a Next.js history state so the next-router will handle restoring the page
        key: createKey(),
      };
      window.history.pushState(historyState, '', url.href);
    }
  }, [countryCode, filters, onUrlUpdate, options, syncStateWithUrl]);

  const setStateFromUrl = useCallback(
    (e?: PopStateEvent) => {
      if (!syncStateWithUrl || /exposes|projects/.test(e?.state?.url ?? '')) return;

      const { filters: newFilters, options: newOptions } = parseSearchParams(window.location.href, true);
      const placeName = new URL(window.location.href).searchParams.get('placeName');
      // using replaceFilters and replaceOptions because updateFilters and updateOptions merge in refinements,
      // but here we want to replace the state with what we get from the URL.
      updatePlaceName(placeName ?? '');
      replaceFilters(mapToFiltersWithoutArrayFields(newFilters));
      replaceOptions({
        ...DEFAULT_OPTIONS,
        ...newOptions,
      });
    },
    [replaceFilters, replaceOptions, syncStateWithUrl],
  );

  return {
    updateUrl,
    setStateFromUrl,
  };
};

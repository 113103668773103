import React from 'react';

import type { LinkSizes, LinkVariant } from './Link.styled';
import { StyledLink } from './Link.styled';

export interface ILinkProps {
  className?: string;
  label: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: LinkVariant;
  size?: LinkSizes;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  as?: 'a' | 'button';
  href?: string;
  disabled?: boolean;
  target?: string;
  linkType?: 'external' | 'internal';
  rel?: string;
}

export const Link = ({
  className,
  label,
  onClick,
  variant = 'primary',
  size = 'small',
  icon,
  iconPosition = 'left',
  as,
  href,
  disabled,
  target,
  rel,
  //! added pass-through props to support aria attributes
  ...props
}: ILinkProps) => {
  if (as === 'button') {
    return (
      <StyledLink
        as={as}
        className={className}
        onClick={onClick}
        $variant={variant}
        $size={size}
        disabled={disabled}
        //! added pass-through props to support aria attributes
        {...props}
      >
        {Boolean(icon) && iconPosition === 'left' && icon}
        <span>{label}</span>
        {Boolean(icon) && iconPosition === 'right' && icon}
      </StyledLink>
    );
  }

  //! removed "external" check because what's internal for spearhead is external for us
  //! https://git.engelvoelkers.com/ev/search/frontend/-/merge_requests/1431#note_386139

  if (!href) console.error('Link Component: href is required for anchor tag');

  return (
    <StyledLink
      className={className}
      $variant={variant}
      href={href}
      $size={size}
      target={target}
      rel={rel}
      //! added pass-through props to support aria attributes
      {...props}
    >
      {Boolean(icon) && iconPosition === 'left' && icon}
      <span>{label}</span>
      {Boolean(icon) && iconPosition === 'right' && icon}
    </StyledLink>
  );
};

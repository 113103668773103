/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type CountryCode = (typeof CountryCode)[keyof typeof CountryCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountryCode = {
  AE: 'AE',
  AD: 'AD',
  AT: 'AT',
  BS: 'BS',
  BE: 'BE',
  BZ: 'BZ',
  CA: 'CA',
  CH: 'CH',
  CL: 'CL',
  CO: 'CO',
  CR: 'CR',
  CZ: 'CZ',
  DE: 'DE',
  DK: 'DK',
  ES: 'ES',
  FR: 'FR',
  GR: 'GR',
  KY: 'KY',
  HR: 'HR',
  HK: 'HK',
  HU: 'HU',
  IE: 'IE',
  IT: 'IT',
  LI: 'LI',
  LU: 'LU',
  MF: 'MF',
  MG: 'MG',
  MU: 'MU',
  MX: 'MX',
  NL: 'NL',
  NG: 'NG',
  PL: 'PL',
  PT: 'PT',
  ZA: 'ZA',
  TC: 'TC',
  GB: 'GB',
  US: 'US',
  UY: 'UY',
  DO: 'DO',
  SA: 'SA',
  LT: 'LT',
  PR: 'PR',
  NA: 'NA',
  PE: 'PE',
  DZ: 'DZ',
  SV: 'SV',
  AR: 'AR',
  GT: 'GT',
  TZ: 'TZ',
  EC: 'EC',
  MC: 'MC',
  BR: 'BR',
  BM: 'BM',
  VE: 'VE',
  PW: 'PW',
  CV: 'CV',
  BW: 'BW',
  HN: 'HN',
  KE: 'KE',
  VG: 'VG',
  PA: 'PA',
  IR: 'IR',
} as const;

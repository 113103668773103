import React from 'react';

import { StyledInlineLink } from './InlineLink.styled';
import { TrackingInfo } from '@/types/tracking';

export interface IInlineLinkProps {
  className?: string;
  label: React.ReactNode;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  as?: 'a' | 'button';
  target?: string;
  rel?: string;
  trackingInfo?: TrackingInfo;
}

export const InlineLink = ({
  className,
  label,
  href,
  as,
  onClick,
  rel,
  target,
  trackingInfo,
  //! added pass-through props to support aria attributes
  ...props
}: IInlineLinkProps) => {
  const handleLinkClick = () => {
    trackingInfo?.handleEvent?.(trackingInfo);
  };

  if (as === 'button') {
    return (
      <StyledInlineLink
        className={className}
        as={as}
        onClick={onClick}
        //! added pass-through props to support aria attributes
        {...props}
      >
        {label}
      </StyledInlineLink>
    );
  }

  return (
    <StyledInlineLink
      className={className}
      href={href}
      target={target}
      rel={rel}
      onClick={handleLinkClick}
      //! added pass-through props to support aria attributes
      {...props}
    >
      {label}
    </StyledInlineLink>
  );
};

import { create } from 'zustand';

interface HoverState {
  hoveredPropertyId?: string;
  setHoveredPropertyId: (propertyId?: string) => void;
}

export const useHoverStore = create<HoverState>((set) => ({
  hoveredPropertyId: undefined,
  setHoveredPropertyId: (propertyId) => set({ hoveredPropertyId: propertyId }),
}));

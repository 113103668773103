import { ListBox, ListBoxItem } from 'react-aria-components';

import { type AutoSuggestOption } from './AutoComplete';
import { StyledPopover } from './AutoCompletePopover.styled';
import { ListItem } from './ListItem';
type AutoCompletePopoverProps = {
  renderItem: (item: AutoSuggestOption) => JSX.Element;
  setIsFocused: (isFocused: boolean) => void;
};

export const AutoCompletePopover = ({ renderItem, ...props }: AutoCompletePopoverProps) => {
  return (
    <StyledPopover placement="bottom start" containerPadding={0} offset={2} data-test-id="search-components_autocomplete_popover">
      <ListBox>
        {(item: AutoSuggestOption) => (
          <ListBoxItem textValue={item.title} value={item} key={item.id}>
            {({ isFocused }) => (
              <ListItem {...props} isFocused={isFocused}>
                {renderItem(item)}
              </ListItem>
            )}
          </ListBoxItem>
        )}
      </ListBox>
    </StyledPopover>
  );
};

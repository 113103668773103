import {
  commonSuggest
} from "./chunk-XNHY4EZT.js";
import {
  PlaceType
} from "./chunk-VTYJUGD5.js";
import {
  getErrorResult
} from "./chunk-XNWIRXJJ.js";
import {
  createSessionToken
} from "./chunk-EYGO4I34.js";

// src/getLocationSuggestions/getLocationSuggestions.ts
async function getLocationSuggestions(query, options, apiConfig) {
  try {
    const suggestPayload = {
      ...options,
      sessionToken: options.sessionToken ?? createSessionToken(),
      input: query,
      limit: options.limit ?? 10,
      types: options.types ?? [
        PlaceType.country,
        PlaceType.neighborhood,
        PlaceType.locality,
        PlaceType.administrative_area_level_1,
        PlaceType.administrative_area_level_4,
        PlaceType.postal_code,
        PlaceType.sublocality
      ]
    };
    const results = await commonSuggest(suggestPayload, apiConfig);
    return {
      status: "success",
      data: results
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  getLocationSuggestions
};

import qs from 'qs';

// TODO: check if we can replace this function with something from the api library
export const addParamsToUrl = (url: string, queryParams?: Record<string, unknown>): string => {
  if (queryParams) {
    let queryString = qs.stringify(queryParams, {
      addQueryPrefix: !url.includes('?'),
    });
    if (url.includes('?')) {
      queryString = '&' + queryString;
    }
    return `${url}${queryString}`;
  }
  return url;
};

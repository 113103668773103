import {
  getProperties
} from "./chunk-DMRMWGUK.js";

// src/getPropertiesByLocation/getPropertiesByLocation.ts
async function getPropertiesByLocation(placeId, filters, options, apiConfig) {
  if (placeId === "") {
    console.warn("placeId is an empty string.");
  }
  return await getProperties(
    {
      ...filters,
      placeId
    },
    options,
    apiConfig
  );
}

export {
  getPropertiesByLocation
};

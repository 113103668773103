import {
  BusinessArea,
  Currency,
  Language,
  MeasurementSystem,
  PropertyKind,
  PropertyMarketingType,
  PropertyType,
  type SearchFilterDto,
} from '@generated/search-bff';
import {
  type DeepPartial,
  type GetPropertiesFilters,
  type GetPropertiesOptions,
  type Property,
  type RangeFilter,
  type SurfaceFilterKey,
} from '@pkgs/api/types';
import { isKey, isRecord } from '@pkgs/api/utils/object';
import { isEmpty, mergeWith, omitBy } from 'lodash-es';

import { safePanic } from './panic';

export const DEFAULT_FILTERS = {
  businessArea: [BusinessArea.residential],
  propertyMarketingType: [PropertyMarketingType.sale],
} as const satisfies GetPropertiesFilters;

export const DEFAULT_OPTIONS = {
  language: Language.en,
} as const satisfies GetPropertiesOptions;

export function mapPriceFilter(
  filter: RangeFilter,
  propertyMarketingType: PropertyMarketingType,
  currency: Currency,
): Partial<SearchFilterDto> {
  const priceType = propertyMarketingType === PropertyMarketingType.rent ? 'rentTotal' : 'salesPrice';

  const currencySuffix = currency[0] + currency.slice(1).toLowerCase();

  const priceDtoKey = `${priceType}${currencySuffix}` as keyof SearchFilterDto;

  return {
    [priceDtoKey]: {
      ...filter,
    },
  };
}

export function mapSurfaceFilter(
  filter: RangeFilter,
  name: SurfaceFilterKey,
  measurementSystem: MeasurementSystem,
): Partial<SearchFilterDto> {
  const unitSuffix = measurementSystem === MeasurementSystem.metric ? 'Sqmt' : 'Sqft';

  const surfaceDtoKey = `${name}${unitSuffix}`;

  return {
    [surfaceDtoKey]: {
      ...filter,
    },
  };
}

export function mapFilters(filters: GetPropertiesFilters, options: GetPropertiesOptions): SearchFilterDto {
  // remove price and/or surface filters if currency or measurement system is not provided
  const { filters: refinedFilters, options: refinedOptions } = refineFiltersAndOptions(filters, options);

  const { price, plotSurface, livingSurface, totalSurface, ...restFilters } = refinedFilters;

  // skip false values for booleans
  for (const key in restFilters) {
    if (isKey(restFilters, key) && restFilters[key] === false) {
      delete restFilters[key];
    }
  }

  return {
    ...restFilters,
    // TODO double check with BE team
    // TODO remove property kind from filters
    propertyKind: refinedFilters.propertyType?.[0] === PropertyType.group ? PropertyKind.group : PropertyKind.object,
    ...(price
      ? Object.fromEntries(
          refinedFilters.propertyMarketingType.flatMap((mt) =>
            Object.entries(
              mapPriceFilter(
                price,
                mt,
                refinedOptions.currency ?? safePanic('Currency is required when price filter is present', Currency.EUR),
              ),
            ),
          ),
        )
      : {}),
    ...(plotSurface &&
      mapSurfaceFilter(
        plotSurface,
        'plotSurface',
        refinedOptions.measurementSystem ??
          safePanic('Measurement system is required when surface filter is present', MeasurementSystem.metric),
      )),
    ...(livingSurface &&
      mapSurfaceFilter(
        livingSurface,
        'livingSurface',
        refinedOptions.measurementSystem ??
          safePanic('Measurement system is required when surface filter is present', MeasurementSystem.metric),
      )),
    ...(totalSurface &&
      mapSurfaceFilter(
        totalSurface,
        'totalSurface',
        refinedOptions.measurementSystem ??
          safePanic('Measurement system is required when surface filter is present', MeasurementSystem.metric),
      )),
  };
}

export function getSimilarPropertiesPriceRange(property: Property): RangeFilter {
  if (!property.prices) return safePanic('Property does not have a prices field', {});

  const [, priceEntry] = Object.entries(property.prices).find(([currency]) => currency === property.baseCurrency) ?? [];

  if (!priceEntry) return safePanic('Property prices field is empty', {});

  const priceValue = property.propertyMarketingType === PropertyMarketingType.rent ? priceEntry.rentTotal : priceEntry.salesPrice;

  if (!priceValue) return safePanic('Property does not have a rentTotal or salesPrice value', {});

  return { min: priceValue * 0.8, max: priceValue * 1.2 };
}
/**
 * Sets the value of the key to undefined if the value is an empty object.
 * @param o object to clean
 * @returns object with undefined as value for empty objects
 */
export const cleanEmptyObjects = (o: unknown): unknown => {
  if (!isRecord(o)) return o;

  return Object.fromEntries(
    Object.entries(o).map<[string, unknown]>(([k, v]) => [k, isRecord(v) && isEmpty(v) ? undefined : cleanEmptyObjects(v)]),
  );
};

/**
 * Deletes empty object values from the given object.
 * @param o object to clean
 * @returns object without empty objects
 */
export const deleteEmptyObjects = (o: unknown): unknown => {
  if (!isRecord(o)) return o;

  for (const key of Object.keys(o)) {
    const value = o[key];
    if (value === undefined || (isRecord(value) && isEmpty(value))) {
      delete o[key];
    }
  }

  return o;
};

/**
 * Deep merge of search state with partial refinement, removing undefined values.
 */
export function mergeState<T>(state: T, refinement: DeepPartial<T>): T {
  const _refinement = cleanEmptyObjects(refinement);
  const mergedObject = mergeWith({}, state, _refinement, (targetValue, srcValue, key, target) => {
    if (Array.isArray(srcValue)) {
      return srcValue;
    }
    if (targetValue !== srcValue && srcValue === undefined) {
      target[key] = srcValue;
    }
  });
  return omitBy<T>(mergedObject, (value) => value === undefined || value === false) as T;
}

/**
 * Refines filters and options by adding default values and removing irrelevant filters.
 * @param filters filters to refine
 * @param options options to refine
 * @returns refined filters and options
 */
export function refineFiltersAndOptions(
  filters: Partial<GetPropertiesFilters>,
  options: Partial<GetPropertiesOptions>,
): { filters: GetPropertiesFilters; options: GetPropertiesOptions } {
  const refinedFilters: GetPropertiesFilters = {
    ...DEFAULT_FILTERS,
    ...filters,
  };

  const refinedOptions: GetPropertiesOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  validateWithDefaults(refinedFilters, DEFAULT_FILTERS);
  validateWithDefaults(refinedOptions, DEFAULT_OPTIONS);

  const hasSurface = Boolean(refinedFilters.livingSurface || refinedFilters.plotSurface || refinedFilters.totalSurface);

  if (refinedFilters.price && !refinedOptions.currency) {
    console.warn('Price filter is removed because currency is not provided');
    delete refinedFilters.price;
  }

  if (hasSurface && !refinedOptions.measurementSystem) {
    console.warn('Surface filters are removed because measurement system is not provided');
    delete refinedFilters.livingSurface;
    delete refinedFilters.plotSurface;
    delete refinedFilters.totalSurface;
  }

  return {
    filters: refinedFilters,
    options: refinedOptions,
  };
}

function validateWithDefaults(data: GetPropertiesFilters, defaults: GetPropertiesFilters): void;
function validateWithDefaults(data: GetPropertiesOptions, defaults: GetPropertiesOptions): void;

function validateWithDefaults<T extends GetPropertiesFilters | GetPropertiesOptions>(data: T, defaults: T): void {
  for (const key of Object.keys(defaults)) {
    if (!isKey<T>(data, key)) continue;

    const defaultValue = defaults[key];
    const value = data[key];

    if (isEmpty(value) || (Array.isArray(value) && value.some((item: string) => isEmpty(item.trim())))) {
      data[key] = defaultValue;
    }
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export enum PageTypeEnum {
  WATCHLIST = 'watchlist-page',
  SEARCH_RESULT = 'search-result-page',
  LIPA_SEARCH_RESULT = 'lipa-search-result-page',
  FLYOUT = 'flyout',
  EXPOSE = 'expose-page',
  PROJECT = 'project-page',
  NOT_FOUND = '404-page',
}

// TODO: rename to something like NavigationTrackingContext
export type TrackingInfo = {
  elementId?: string;
  sourceScreen?: string;
  targetScreen?: string;
  selectedOption?: string;
  href?: string;
  handleEvent?: (data: TrackingInfo) => void;
};

// TODO: Check if we can use TrackingData from Property
export interface PropertyTrackingIds {
  criteoID?: string;
  facebookID?: string;
  googleAnalytics4ID?: string;
  googleAnalyticsID?: string;
  linkedInID?: string;
  masterDataLicensePartnerID?: string;
  masterDataShopID?: string;
  microsoftID?: string;
  googleAdsID?: string;
}

export type TrackingProvider =
  | 'licencePartnerCriteoID'
  | 'licencePartnerFacebookID'
  | 'licencePartnerGA4ID'
  | 'licencePartnerLinkedInID'
  | 'licencePartnerMicrosoftID'
  | 'licencePartnerGoogleAdsID'
  | 'marketCriteoID'
  | 'marketFacebookID'
  | 'marketGA4ID'
  | 'marketLinkedInID'
  | 'marketMicrosoftID'
  | 'marketGoogleAdsID'
  | 'globalCriteoID'
  | 'globalFacebookID'
  | 'globalGA4ID'
  | 'globalLinkedInID'
  | 'globalMicrosoftID'
  | 'globalGoogleAdsID';

export type TrackingIDs = {
  [key in TrackingProvider]?: string | number | null;
};

export type LocalStorageTrackingProperty = {
  [key: string]: TrackingIDs;
};

export type ABTestVariation = 'A' | 'B';

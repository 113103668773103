// generated/search-api/models/inputContactPreferredLanguage.ts
var InputContactPreferredLanguage = {
  DE: "DE",
  EN: "EN",
  ES: "ES",
  FR: "FR",
  DA: "DA",
  EL: "EL",
  PT: "PT",
  IT: "IT",
  CS: "CS",
  CA: "CA",
  RU: "RU",
  NL: "NL",
  HU: "HU"
};

// generated/search-bff/models/addressTypeDto.ts
var AddressTypeDto = {
  natural_feature: "natural_feature",
  room: "room",
  floor: "floor",
  street_number: "street_number",
  supermarket: "supermarket",
  gas_station: "gas_station",
  stadium: "stadium",
  bakery: "bakery",
  place_of_worship: "place_of_worship",
  grocery_or_supermarket: "grocery_or_supermarket",
  establishment: "establishment",
  colloquial_area: "colloquial_area",
  premise: "premise",
  subpremise: "subpremise",
  lodging: "lodging",
  finance: "finance",
  health: "health",
  meal_delivery: "meal_delivery",
  plus_code: "plus_code",
  post_box: "post_box",
  point_of_interest: "point_of_interest",
  bus_station: "bus_station",
  doctor: "doctor",
  furniture_store: "furniture_store",
  train_station: "train_station",
  subway_station: "subway_station",
  transit_station: "transit_station",
  light_rail_station: "light_rail_station",
  school: "school",
  lawyer: "lawyer",
  primary_school: "primary_school",
  secondary_school: "secondary_school",
  store: "store",
  shopping_mall: "shopping_mall",
  art_gallery: "art_gallery",
  street_address: "street_address",
  park: "park",
  parking: "parking",
  restaurant: "restaurant",
  bar: "bar",
  meal_takeaway: "meal_takeaway",
  clothing_store: "clothing_store",
  local_government_office: "local_government_office",
  moving_company: "moving_company",
  storage: "storage",
  car_repair: "car_repair",
  intersection: "intersection",
  route: "route",
  ward: "ward",
  tourist_attraction: "tourist_attraction",
  drugstore: "drugstore",
  general_contractor: "general_contractor",
  food: "food",
  real_estate_agency: "real_estate_agency",
  car_rental: "car_rental",
  travel_agency: "travel_agency",
  electronics_store: "electronics_store",
  home_goods_store: "home_goods_store",
  insurance_agency: "insurance_agency",
  painter: "painter",
  airport: "airport",
  town_square: "town_square",
  cafe: "cafe",
  museum: "museum",
  campground: "campground",
  rv_park: "rv_park",
  archipelago: "archipelago",
  accounting: "accounting",
  amusement_park: "amusement_park",
  aquarium: "aquarium",
  atm: "atm",
  bank: "bank",
  beauty_salon: "beauty_salon",
  bicycle_store: "bicycle_store",
  book_store: "book_store",
  bowling_alley: "bowling_alley",
  car_dealer: "car_dealer",
  car_wash: "car_wash",
  casino: "casino",
  cemetery: "cemetery",
  church: "church",
  city_hall: "city_hall",
  convenience_store: "convenience_store",
  courthouse: "courthouse",
  dentist: "dentist",
  department_store: "department_store",
  electrician: "electrician",
  embassy: "embassy",
  fire_station: "fire_station",
  florist: "florist",
  funeral_home: "funeral_home",
  gym: "gym",
  hair_care: "hair_care",
  hardware_store: "hardware_store",
  hindu_temple: "hindu_temple",
  hospital: "hospital",
  jewelry_store: "jewelry_store",
  laundry: "laundry",
  library: "library",
  liquor_store: "liquor_store",
  locksmith: "locksmith",
  mosque: "mosque",
  movie_rental: "movie_rental",
  movie_theater: "movie_theater",
  night_club: "night_club",
  pet_store: "pet_store",
  pharmacy: "pharmacy",
  physiotherapist: "physiotherapist",
  plumber: "plumber",
  police: "police",
  post_office: "post_office",
  roofing_contractor: "roofing_contractor",
  shoe_store: "shoe_store",
  spa: "spa",
  synagogue: "synagogue",
  taxi_stand: "taxi_stand",
  university: "university",
  veterinary_care: "veterinary_care",
  zoo: "zoo",
  landmark: "landmark",
  address: "address",
  cities: "cities",
  neighborhood: "neighborhood",
  sublocality: "sublocality",
  sublocality_level_5: "sublocality_level_5",
  sublocality_level_4: "sublocality_level_4",
  sublocality_level_3: "sublocality_level_3",
  sublocality_level_2: "sublocality_level_2",
  sublocality_level_1: "sublocality_level_1",
  locality: "locality",
  postal_town: "postal_town",
  postal_code_prefix: "postal_code_prefix",
  postal_code: "postal_code",
  postal_code_suffix: "postal_code_suffix",
  administrative_area_level_7: "administrative_area_level_7",
  administrative_area_level_6: "administrative_area_level_6",
  administrative_area_level_5: "administrative_area_level_5",
  administrative_area_level_4: "administrative_area_level_4",
  administrative_area_level_3: "administrative_area_level_3",
  administrative_area_level_2: "administrative_area_level_2",
  administrative_area_level_1: "administrative_area_level_1",
  country: "country",
  continent: "continent",
  political: "political",
  unknown: "unknown",
  geocode: "geocode"
};

// generated/search-bff/models/coordinateSystemAxisDirection.ts
var CoordinateSystemAxisDirection = {
  NORTH: "NORTH",
  EAST: "EAST",
  SOUTH: "SOUTH",
  WEST: "WEST",
  UP: "UP",
  DOWN: "DOWN",
  OTHER: "OTHER",
  UNKNOWN: "UNKNOWN",
  GeocentricX: "GeocentricX",
  GeocentricY: "GeocentricY",
  GeocentricZ: "GeocentricZ"
};

// generated/search-bff/models/energyClass.ts
var EnergyClass = {
  UNKNOWN: "UNKNOWN",
  NOT_APPLICABLE: "NOT_APPLICABLE",
  IN_PROGRESS: "IN_PROGRESS",
  I: "I",
  H: "H",
  G: "G",
  F: "F",
  E: "E",
  D: "D",
  C: "C",
  B: "B",
  A: "A",
  A2010: "A2010",
  "A+": "A+",
  A2015: "A2015",
  "A++": "A++",
  A2020: "A2020",
  "A+++": "A+++"
};

// generated/search-bff/models/imageType.ts
var ImageType = {
  image: "image",
  floor_plan: "floor_plan",
  map: "map"
};

// generated/search-bff/models/listingSortOption.ts
var ListingSortOption = {
  SALES_PRICE_DESC: "SALES_PRICE_DESC",
  SALES_PRICE_ASC: "SALES_PRICE_ASC",
  RENT_DESC: "RENT_DESC",
  RENT_ASC: "RENT_ASC",
  CREATED_AT_DESC: "CREATED_AT_DESC",
  CREATED_AT_ASC: "CREATED_AT_ASC",
  LIVING_SURFACE_DESC: "LIVING_SURFACE_DESC",
  LIVING_SURFACE_ASC: "LIVING_SURFACE_ASC",
  TOTAL_SURFACE_ASC: "TOTAL_SURFACE_ASC",
  TOTAL_SURFACE_DESC: "TOTAL_SURFACE_DESC",
  PUBLISHED_AT_DESC: "PUBLISHED_AT_DESC",
  PUBLISHED_AT_ASC: "PUBLISHED_AT_ASC",
  PROPERTY_KIND_DESC: "PROPERTY_KIND_DESC",
  PROPERTY_KIND_ASC: "PROPERTY_KIND_ASC",
  BUSINESS_AREA_DESC: "BUSINESS_AREA_DESC",
  BUSINESS_AREA_ASC: "BUSINESS_AREA_ASC",
  PROPERTY_MARKETING_TYPE_DESC: "PROPERTY_MARKETING_TYPE_DESC",
  PROPERTY_MARKETING_TYPE_ASC: "PROPERTY_MARKETING_TYPE_ASC"
};

// generated/search-bff/models/listingStatus.ts
var ListingStatus = {
  ACTIVE: "ACTIVE",
  OFFERSHOW: "OFFERSHOW",
  OFFERNOSHOW: "OFFERNOSHOW",
  SOLDBYEV: "SOLDBYEV",
  LETBYEV: "LETBYEV"
};

// generated/search-bff/models/placeType.ts
var PlaceType = {
  accounting: "accounting",
  airport: "airport",
  amusement_park: "amusement_park",
  aquarium: "aquarium",
  art_gallery: "art_gallery",
  atm: "atm",
  bakery: "bakery",
  bank: "bank",
  bar: "bar",
  beauty_salon: "beauty_salon",
  bicycle_store: "bicycle_store",
  book_store: "book_store",
  bowling_alley: "bowling_alley",
  bus_station: "bus_station",
  cafe: "cafe",
  campground: "campground",
  car_dealer: "car_dealer",
  car_rental: "car_rental",
  car_repair: "car_repair",
  car_wash: "car_wash",
  casino: "casino",
  cemetery: "cemetery",
  church: "church",
  city_hall: "city_hall",
  clothing_store: "clothing_store",
  convenience_store: "convenience_store",
  courthouse: "courthouse",
  dentist: "dentist",
  department_store: "department_store",
  doctor: "doctor",
  drugstore: "drugstore",
  electrician: "electrician",
  electronics_store: "electronics_store",
  embassy: "embassy",
  fire_station: "fire_station",
  florist: "florist",
  funeral_home: "funeral_home",
  furniture_store: "furniture_store",
  gas_station: "gas_station",
  gym: "gym",
  hair_care: "hair_care",
  hardware_store: "hardware_store",
  hindu_temple: "hindu_temple",
  home_goods_store: "home_goods_store",
  hospital: "hospital",
  insurance_agency: "insurance_agency",
  jewelry_store: "jewelry_store",
  laundry: "laundry",
  lawyer: "lawyer",
  library: "library",
  light_rail_station: "light_rail_station",
  liquor_store: "liquor_store",
  local_government_office: "local_government_office",
  locksmith: "locksmith",
  lodging: "lodging",
  meal_delivery: "meal_delivery",
  meal_takeaway: "meal_takeaway",
  mosque: "mosque",
  movie_rental: "movie_rental",
  movie_theater: "movie_theater",
  moving_company: "moving_company",
  museum: "museum",
  night_club: "night_club",
  painter: "painter",
  park: "park",
  parking: "parking",
  pet_store: "pet_store",
  pharmacy: "pharmacy",
  physiotherapist: "physiotherapist",
  plumber: "plumber",
  police: "police",
  post_office: "post_office",
  primary_school: "primary_school",
  real_estate_agency: "real_estate_agency",
  restaurant: "restaurant",
  roofing_contractor: "roofing_contractor",
  rv_park: "rv_park",
  school: "school",
  secondary_school: "secondary_school",
  shoe_store: "shoe_store",
  shopping_mall: "shopping_mall",
  spa: "spa",
  stadium: "stadium",
  storage: "storage",
  store: "store",
  subway_station: "subway_station",
  supermarket: "supermarket",
  synagogue: "synagogue",
  taxi_stand: "taxi_stand",
  tourist_attraction: "tourist_attraction",
  train_station: "train_station",
  transit_station: "transit_station",
  travel_agency: "travel_agency",
  university: "university",
  veterinary_care: "veterinary_care",
  zoo: "zoo",
  administrative_area_level_1: "administrative_area_level_1",
  administrative_area_level_2: "administrative_area_level_2",
  administrative_area_level_3: "administrative_area_level_3",
  administrative_area_level_4: "administrative_area_level_4",
  administrative_area_level_5: "administrative_area_level_5",
  administrative_area_level_6: "administrative_area_level_6",
  administrative_area_level_7: "administrative_area_level_7",
  archipelago: "archipelago",
  colloquial_area: "colloquial_area",
  continent: "continent",
  finance: "finance",
  floor: "floor",
  food: "food",
  general_contractor: "general_contractor",
  health: "health",
  intersection: "intersection",
  landmark: "landmark",
  natural_feature: "natural_feature",
  place_of_worship: "place_of_worship",
  plus_code: "plus_code",
  point_of_interest: "point_of_interest",
  political: "political",
  post_box: "post_box",
  postal_code_prefix: "postal_code_prefix",
  postal_code_suffix: "postal_code_suffix",
  postal_code: "postal_code",
  postal_town: "postal_town",
  premise: "premise",
  room: "room",
  route: "route",
  street_address: "street_address",
  street_number: "street_number",
  sublocality_level_1: "sublocality_level_1",
  sublocality_level_2: "sublocality_level_2",
  sublocality_level_3: "sublocality_level_3",
  sublocality_level_4: "sublocality_level_4",
  sublocality_level_5: "sublocality_level_5",
  subpremise: "subpremise",
  town_square: "town_square",
  country: "country",
  locality: "locality",
  sublocality: "sublocality",
  neighborhood: "neighborhood",
  geocode: "geocode",
  establishment: "establishment",
  address: "address",
  cities: "cities"
};

// generated/search-bff/models/polygonSource.ts
var PolygonSource = {
  google: "google",
  osm: "osm",
  fotocasa: "fotocasa",
  custom: "custom"
};

// generated/search-bff/models/propertySubType.ts
var PropertySubType = {
  apartment_house: "apartment_house",
  bungalow: "bungalow",
  country_house: "country_house",
  castle: "castle",
  detached_house: "detached_house",
  finca: "finca",
  investment: "investment",
  semi_detached_house: "semi_detached_house",
  terraced_house: "terraced_house",
  townhouse: "townhouse",
  villa: "villa",
  attic: "attic",
  flat: "flat",
  loft: "loft",
  maisonette: "maisonette",
  penthouse: "penthouse",
  special_property: "special_property",
  land: "land",
  site: "site",
  parking: "parking",
  developments: "developments",
  projects: "projects",
  other: "other",
  new_developments: "new_developments",
  lager_mit_freiflaeche: "lager_mit_freiflaeche",
  industriehalle: "industriehalle",
  hochregallager: "hochregallager",
  speditionslager: "speditionslager",
  lager: "lager",
  "": ""
};

// generated/search-bff/models/searchAlertState.ts
var SearchAlertState = {
  CREATED: "CREATED",
  ACTIVE: "ACTIVE"
};

// generated/search-bff/models/sortingOrder.ts
var SortingOrder = {
  ASC: "ASC",
  DESC: "DESC"
};

// generated/search-bff/models/sortingType.ts
var SortingType = {
  CREATED_AT: "CREATED_AT",
  PUBLISHED_AT: "PUBLISHED_AT",
  SALES_PRICE: "SALES_PRICE",
  LIVING_SURFACE: "LIVING_SURFACE",
  TOTAL_SURFACE: "TOTAL_SURFACE",
  RENT: "RENT"
};

export {
  AddressTypeDto,
  CoordinateSystemAxisDirection,
  EnergyClass,
  ImageType,
  ListingSortOption,
  ListingStatus,
  PlaceType,
  PolygonSource,
  PropertySubType,
  SearchAlertState,
  SortingOrder,
  SortingType,
  InputContactPreferredLanguage
};

import { type Property } from '@ev/search-modules-api';
import {
  type BusinessDivisionType,
  getConsentDocumentContext,
  getNavigationContext,
  getPageContext,
  getPropertyContext,
  getShopContext,
  trackNavigationEvent,
  trackUserInteractionEvent,
  UserActionEnum,
} from '@ev/snowplow-library';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { mapPropertyToPropertyContext, PageType } from '@pkgs/components/utils/tracking';
import { useCallback, useMemo } from 'react';

export const useTracking = () => {
  const {
    filters,
    options: { language },
    countryCode,
    snowPlowPageType,
  } = useSearch();

  const isLipaPage = filters.shopIds || filters.masterDataShopIds;

  const pageContext = useMemo(
    () =>
      getPageContext({
        type: snowPlowPageType ?? (isLipaPage ? PageType.LIPA_SEARCH_RESULT : PageType.SEARCH_RESULT),
        isHQPage: true,
        template_type: PageType.SEARCH_RESULT,
        page_language: language,
        country_code: countryCode,
      }),
    [snowPlowPageType, isLipaPage, language, countryCode],
  );

  const shopContext = useMemo(
    () =>
      getShopContext({
        shop_id: filters.masterDataShopIds?.[0],
        license_id: filters.masterDataLiPaIds?.[0],
        business_division_name: filters.businessArea as BusinessDivisionType,
      }),
    [filters.masterDataShopIds, filters.masterDataLiPaIds, filters.businessArea],
  );

  const trackWatchlistToggle = useCallback(
    (property: Property, action: 'add' | 'remove', source: 'default' | 'preview') => {
      let elementId = '';

      if (source === 'preview') elementId += 'map-module_';

      elementId += `${property.isDevelopmentProject ? 'project' : 'expose'}_`;
      elementId += `${action === 'add' ? 'add-to' : 'remove-from'}-watchlist`;

      trackUserInteractionEvent({
        action: UserActionEnum.click,
        elementId,
        contexts: [
          pageContext,
          { ...shopContext, ...getShopContext({ office_id: property.shopID }) },
          getConsentContext(),
          getPropertyContext(mapPropertyToPropertyContext(property)),
        ],
      });
    },
    [pageContext, shopContext],
  );

  const trackSearchResultCardClick = useCallback(
    (exposeUrl: string, property: Property, row: number, column: number) => {
      trackNavigationEvent({
        screenName: '',
        contexts: [
          pageContext,
          shopContext,
          getConsentContext(),
          getPropertyContext(mapPropertyToPropertyContext(property)),
          getNavigationContext({
            target_url: exposeUrl,
            element_id: `property-card_row-${row}_card-${column}`,
          }),
        ],
      });
    },
    [pageContext, shopContext],
  );

  return { pageContext, shopContext, trackWatchlistToggle, trackSearchResultCardClick };
};

export const getConsentContext = () => {
  const status = window.Didomi?.getUserStatus?.().vendors?.consent;

  return getConsentDocumentContext({
    vendors_consent: status?.enabled.join(',') ?? '',
    vendors_consent_denied: status?.disabled.join(',') ?? '',
  });
};

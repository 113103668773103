import { wrapGetServerSidePropsWithSentry } from '@sentry/nextjs';

import { propertySearchPageGetServerSideProps } from '@/components/pages/PropertySearch/getServerSideProps';
import { PropertySearchPage } from '@/components/pages/PropertySearch/page';

export const getServerSideProps = wrapGetServerSidePropsWithSentry(
  propertySearchPageGetServerSideProps,
  '[countryCodeOrLocale]/propertysearch'
);
export default PropertySearchPage;

import { type Currency, MeasurementSystem } from '@generated/search-bff';
import { type PropertyLocation, type SurfaceUnit } from '@pkgs/api/types';

import { displayIdPrefixes } from '../constants/propertyData';

export const decodeHTML = (str: string): string => {
  const htmlEntities: Record<string, string> = {
    '&#34;': '"',
    '&amp;': '&',
    '&#39;': "'",
    '&gt;': '>',
    '&lt;': '<',
  };

  const entityRegex = new RegExp(`(${Object.keys(htmlEntities).join('|')})`, 'g');

  return str.replaceAll(entityRegex, (entity) => htmlEntities[entity]!);
};

export const formatShopPhoneNumber = (num: string): string => num.replaceAll('-', ' ');

export const formatLocation = ({ subLocality, city, region, country }: Partial<PropertyLocation>): string => {
  // remove duplicate city/region like 'Hamburg, Hamburg, Germany'
  return [subLocality, ...new Set([city, region]), country].filter(Boolean).join(', ');
};

export const createSessionToken = (): string => {
  return Math.random().toString(36).slice(2);
};

export const shortenMasterDataId = async (uuid: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(uuid);
  const hash = await crypto.subtle.digest('SHA-1', data);

  return [...new Uint8Array(hash)]
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
    .slice(0, 8);
};

export const shortenMasterDataIds = async (uuids: string[]): Promise<string[]> =>
  await Promise.all(uuids.map(async (shopId) => (isUUID(shopId) ? await shortenMasterDataId(shopId) : shopId)));

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

export const hasDisplayIdPrefix = (id: string): boolean => {
  const regex = new RegExp(`^(${displayIdPrefixes.join('|')})`);
  return regex.test(id.toUpperCase());
};

export const CurrencySymbolMap: Record<Currency, string> = {
  EUR: '\u20AC',
  USD: '\u0024',
  CAD: 'CA' + '\u0024',
  GBP: '\u00A3',
  CHF: 'CHF',
  AED: 'AED',
  CLF: 'CLF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CZK: 'CZK',
  DKK: 'DKK',
  HKD: 'HKD',
  HUF: 'HUF',
  OMR: 'OMR',
  MUR: 'MUR',
  QAR: 'QAR',
  RUB: 'RUB',
  SEK: 'SEK',
  TRY: 'TRY',
  ZAR: 'ZAR',
  BSD: 'BSD',
  BZD: 'BZD',
  CRC: 'CRC',
  KYD: 'KYD',
  MXN: 'MXN',
  PAB: 'PAB',
} as const;

export const UnitSymbolMap: Record<MeasurementSystem, string> = {
  [MeasurementSystem.metric]: 'm²',
  [MeasurementSystem.imperial]: 'sq ft',
} as const;

export const SurfaceUnitMap: Record<MeasurementSystem, SurfaceUnit> = {
  [MeasurementSystem.metric]: 'sqmt',
  [MeasurementSystem.imperial]: 'sqft',
} as const;

export const isUUID = (uuid: string): boolean => {
  return /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i.test(uuid);
};

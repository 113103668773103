import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const EVIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={58}
      height={40}
      viewBox="0 0 58 40"
      fill="none"
      aria-label="E&V signet"
      style={{ width: 60, height: 40 }}
      {...props}
    >
      <path fill="#303030" d="M12.999 13.364h9.455v1.855h-7.4v3.818h6.31v1.855h-6.31v4.256h7.565V27H13V13.364Z" />
      <path
        fill="#E60000"
        d="M30.963 20.837c.254-.617.381-1.365.381-2.236h1.492c0 1.32-.27 2.431-.82 3.326.244.23.48.388.71.474.23.085.504.127.82.127h.364v1.382h-.292c-.302 0-.57-.01-.8-.038a2.63 2.63 0 0 1-.637-.144 2.204 2.204 0 0 1-.545-.291 5.275 5.275 0 0 1-.546-.457c-.847.703-1.944 1.057-3.292 1.057-1.187 0-2.11-.271-2.764-.81-.655-.538-.981-1.3-.981-2.28 0-.69.178-1.287.535-1.784.356-.497.943-.957 1.756-1.382-.364-.425-.628-.823-.793-1.2a2.837 2.837 0 0 1-.246-1.146c0-.727.247-1.314.744-1.763.497-.45 1.152-.672 1.965-.672.813 0 1.468.22 1.965.655.497.436.744 1.019.744 1.746a2.32 2.32 0 0 1-.49 1.454c-.326.425-.868.847-1.619 1.272l2.346 2.71h.003Zm-3.745-2.055c-.521.268-.898.56-1.135.875a1.796 1.796 0 0 0-.353 1.108c0 .607.22 1.08.655 1.427.435.346 1.018.517 1.746.517.775 0 1.423-.212 1.944-.637l-2.853-3.293-.004.003Zm.563-1.618c.521-.278.885-.546 1.09-.8.206-.253.309-.559.309-.908 0-.388-.1-.683-.298-.882-.2-.199-.484-.298-.848-.298-.363 0-.651.092-.864.281-.212.189-.319.453-.319.792 0 .254.069.522.21.8.14.277.377.617.716 1.018l.004-.003Z"
      />
      <path fill="#303030" d="M33.512 13.364h2.215l3.838 11.366 3.838-11.366h2.092L40.748 27h-2.472l-4.764-13.636Z" />
    </SvgIcon>
  );
};

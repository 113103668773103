import { css, styled } from 'styled-components';

import { focusStyleDark, focusStyleLight } from '../../utils/focusStyle';

const variants = {
  primary: css`
    color: var(--color-link-pimary);

    ${focusStyleDark};

    @media (hover: hover) {
      &:hover {
        color: var(--color-link-pimary-hover);
      }
    }

    &:active {
      color: var(--color-link-pimary-active);
    }
  `,
  secondary: css`
    color: var(--color-link-secondary);

    @media (hover: hover) {
      &:hover {
        color: var(--color-link-secondary-hover);
      }
    }

    ${focusStyleLight};

    &:active {
      color: var(--color-link-secondary-active);
    }
  `,
} as const;
export type LinkVariant = keyof typeof variants;

const sizes = {
  small: css`
    padding: var(--distance-static-xs) 0;
  `,
  medium: css`
    padding: var(--distance-static-s) 0;
  `,
};
export type LinkSizes = keyof typeof sizes;

export interface IStyledLinkProps {
  $variant: LinkVariant;
  $size: LinkSizes;
  as?: 'a' | 'button';
}

const StyledLink = styled.a<IStyledLinkProps>`
  box-sizing: border-box;
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  gap: var(--distance-static-xs);
  align-items: center;
  justify-content: center;
  border: 0;
  font-family: var(--font-family-text), Arial, Helvetica, sans-serif;
  font-size: var(--font-size-static-m);
  line-height: var(--line-height-static-m);
  text-decoration: none;
  cursor: pointer;

  ${({ as }) =>
    as === 'button' &&
    css`
      background: 0;
    `}

  ${({ $size }) => sizes[$size]}
    &:focus {
    outline: 0;
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      text-decoration: underline;
      text-underline-offset: var(--text-underline-offset);
    }
  }

  ${({ $variant }) => variants[$variant]}

  &:disabled {
    color: var(--color-interaction-disabled-text);
    cursor: default;
  }
`;

export { StyledLink };

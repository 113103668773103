import type { DividerProps as MuiDividerProps } from '@mui/material';
import React from 'react';

import { StyledDivider } from './Divider.styles';

type DividerProps = {
  spacing?: number;
} & MuiDividerProps;

export const Divider = React.memo(({ spacing = 1, ...props }: DividerProps): React.ReactElement => {
  return <StyledDivider spacing={spacing} {...props} />;
});

Divider.displayName = 'Divider';

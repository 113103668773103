import { type AriaPopoverProps, DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import React from 'react';
import { type OverlayTriggerState } from 'react-stately';

import { StyledPopover } from './RangeDropdown.styled';

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode;
  state: OverlayTriggerState;
}

export const Popover = ({ children, state, offset = 2, ...props }: PopoverProps) => {
  const popoverRef = React.useRef(null);
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      offset,
      popoverRef,
    },
    state,
  );

  return (
    <Overlay>
      <div {...underlayProps} className="underlay" />
      <StyledPopover {...popoverProps} ref={popoverRef} className="popover">
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </StyledPopover>
    </Overlay>
  );
};

export const ELLIPSIS = '...';

type PaginationRange = (number | typeof ELLIPSIS)[];

const getNumberRange = (start: number, end: number): number[] => {
  const result: number[] = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

export const getPaginationRange = (numPages: number, currentPage: number): PaginationRange => {
  const firstPageIndex = 1;
  const lastPageIndex = numPages;
  const lastPageIndexOfLeftRange = 4;
  const firstPageIndexOfRightRange = lastPageIndex - 3;

  if (numPages <= 0 || currentPage <= 0 || currentPage > numPages) {
    return [];
  }
  if (numPages <= 5) {
    return getNumberRange(firstPageIndex, lastPageIndex);
  }

  const leftSiblingNum = Math.max(currentPage - 1, firstPageIndex);
  const rightSiblingNum = Math.min(currentPage + 1, numPages);

  const showLeftDots = leftSiblingNum > 2;
  const showRightDots = rightSiblingNum < numPages - 1;

  if (!showLeftDots && showRightDots) {
    const leftRange = getNumberRange(firstPageIndex, lastPageIndexOfLeftRange);
    return [...leftRange, ELLIPSIS, lastPageIndex];
  } else if (showLeftDots && !showRightDots) {
    const rightRange = getNumberRange(firstPageIndexOfRightRange, lastPageIndex);
    return [firstPageIndex, ELLIPSIS, ...rightRange];
  } else if (showLeftDots && showRightDots) {
    const middleRange = getNumberRange(leftSiblingNum, rightSiblingNum);
    return [firstPageIndex, ELLIPSIS, ...middleRange, ELLIPSIS, lastPageIndex];
  } else {
    return [];
  }
};

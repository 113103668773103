import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { Headline } from '@spearhead/components/elements/Headline/Headline';

import { SearchResultCardWrapper } from '../SearchResults/SearchResultCardWrapper';
import { StyledResultsList } from '../SearchResults/SearchResults.styled';
import { StyledContainer, StyledHeadlineContainer } from './WiderAreaResults.styled';

export const WiderAreaResults = ({ isMapView }: { isMapView: boolean }) => {
  const { t } = useAppContext();
  const { results } = useSearch();

  return (
    results.widerAreaHits &&
    results.widerAreaHits.length > 0 && (
      <StyledContainer $isMapView={isMapView}>
        <StyledHeadlineContainer>
          <Headline as="h2" size={isMapView ? 'h5' : 'h4'} label={t('search.results.widerAreaResults.label.headline')} />
        </StyledHeadlineContainer>
        <StyledResultsList $isMapView={isMapView}>
          {results.widerAreaHits.map((property) => (
            <SearchResultCardWrapper key={property.id} property={property} preloadImage={false} />
          ))}
        </StyledResultsList>
      </StyledContainer>
    )
  );
};

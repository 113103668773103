import { CountryCode } from '@pkgs/api';

export const SEO_INDEXED_COUNTRIES: ReadonlySet<CountryCode> = new Set([
  CountryCode.DE,
  CountryCode.ES,
  CountryCode.PT,
  CountryCode.AE,
  CountryCode.FR,
  CountryCode.US,
  CountryCode.CA,
  CountryCode.BS,
  CountryCode.VG,
  CountryCode.TC,
  CountryCode.KY,
  CountryCode.CR,
  CountryCode.MX,
  CountryCode.MF,
  CountryCode.PA,
  CountryCode.BZ,
  CountryCode.GR,
  CountryCode.LU,
  CountryCode.MU,
  CountryCode.LI,
  CountryCode.CH,
  CountryCode.IE,
  CountryCode.HR,
  CountryCode.CO,
  CountryCode.CL,
  CountryCode.UY,
  CountryCode.ZA,
  CountryCode.CZ,
  CountryCode.AD,
  CountryCode.HU,
  CountryCode.BE,
  CountryCode.NL,
  CountryCode.DK,
  CountryCode.IT,
  CountryCode.AT,
  CountryCode.HK,
]);

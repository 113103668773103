/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type ListingSortOption = (typeof ListingSortOption)[keyof typeof ListingSortOption];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListingSortOption = {
  SALES_PRICE_DESC: 'SALES_PRICE_DESC',
  SALES_PRICE_ASC: 'SALES_PRICE_ASC',
  RENT_DESC: 'RENT_DESC',
  RENT_ASC: 'RENT_ASC',
  CREATED_AT_DESC: 'CREATED_AT_DESC',
  CREATED_AT_ASC: 'CREATED_AT_ASC',
  LIVING_SURFACE_DESC: 'LIVING_SURFACE_DESC',
  LIVING_SURFACE_ASC: 'LIVING_SURFACE_ASC',
  TOTAL_SURFACE_ASC: 'TOTAL_SURFACE_ASC',
  TOTAL_SURFACE_DESC: 'TOTAL_SURFACE_DESC',
  PUBLISHED_AT_DESC: 'PUBLISHED_AT_DESC',
  PUBLISHED_AT_ASC: 'PUBLISHED_AT_ASC',
  PROPERTY_KIND_DESC: 'PROPERTY_KIND_DESC',
  PROPERTY_KIND_ASC: 'PROPERTY_KIND_ASC',
  BUSINESS_AREA_DESC: 'BUSINESS_AREA_DESC',
  BUSINESS_AREA_ASC: 'BUSINESS_AREA_ASC',
  PROPERTY_MARKETING_TYPE_DESC: 'PROPERTY_MARKETING_TYPE_DESC',
  PROPERTY_MARKETING_TYPE_ASC: 'PROPERTY_MARKETING_TYPE_ASC',
} as const;

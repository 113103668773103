import { from, to } from '@pkgs/components/utils/mediaQueries';
import { css, styled } from 'styled-components';

const containerStyles = css`
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledHeadlineContainer = styled.div`
  ${containerStyles}
`;

export const StyledContainer = styled.section<{ $isMapView: boolean }>`
  ${({ $isMapView }) =>
    $isMapView &&
    css`
      @media screen and (${to.laptop}) {
        display: none;
      }
      @media screen and (${from.laptop}) {
        margin-left: calc(-1 * var(--distance-static-xxl));
        margin-right: calc(-1 * var(--distance-static-xs));
        padding-right: var(--distance-static-xs);
        padding-block: var(--distance-static-xxl);
        gap: var(--distance-static-l);
        margin-top: var(--distance-static-3xl);
      }
    `};
  display: flex;
  flex-direction: column;
  gap: var(--distance-dynamic-m);
  background-color: var(--color-light-colored);
  padding-top: var(--distance-dynamic-l);
  padding-bottom: var(--distance-dynamic-xl);
  padding-inline: var(--distance-static-xxl);
  margin-top: var(--distance-static-l);

  @media screen and (${to.tabletLandscape}) {
    padding-inline: var(--distance-static-m);
  }
`;

import { type AddressComponentDto, type AddressTypeDto, type PlaceResult, PlaceType } from '@generated/search-bff';
import { type PlaceLocation } from '@pkgs/api/types/location';

export const findLocalityPlaceId = (placeIds: string[]): string | undefined => {
  const localityPlaceId = placeIds.find((placeId) => placeId.includes('locality.'));
  if (localityPlaceId) {
    return localityPlaceId.split('.')[1];
  }
  // fallback to the highest administrative area in case there is no locality
  // Google Maps admin area levels go from 1 to 7, so a simple sort should do the trick to get the highest level.
  const orderedAdminAreas = placeIds.filter((placeIds) => placeIds.includes('administrative_area_level_'));

  // TODO: replace with toSorted and toReversed again, once spearhead has figured out webpack issue in storyblok plugin
  orderedAdminAreas.sort();
  orderedAdminAreas.reverse();

  return orderedAdminAreas[0]?.split('.')[1];
};

export const SupportedPlaceTypes: ReadonlySet<PlaceType> = new Set([
  PlaceType.neighborhood,
  PlaceType.sublocality,
  PlaceType.postal_code,
  PlaceType.locality,
  PlaceType.administrative_area_level_4,
  PlaceType.administrative_area_level_1,
  PlaceType.country,
  PlaceType.natural_feature,
]);

export const getFirstSupportedPlaceType = (types: PlaceType[]): PlaceType | undefined => {
  return types.find((type) => SupportedPlaceTypes.has(type));
};

function getAddressComponent(type: PlaceType, allComponents: AddressComponentDto[]) {
  // TODO check if below comment and fix is still valid and required
  // ----
  // when the address component type is not just postal_code but has other types
  // like postal_code_prefix or postal_code_suffix then we ignore
  // see https://engelvoelkers.atlassian.net/browse/NS-3777
  return allComponents.find((component) => {
    if (type === PlaceType.postal_code) {
      return component.types.length === 1 && component.types[0] === PlaceType.postal_code;
    }
    return component.types.includes(type as AddressTypeDto);
  });
}

function getPostalCode(postalCode: string, types: PlaceType[]): string | undefined {
  // only apply postcode if the selected location is a postcode, a sublocality or a neighborhood
  const featureType = getFirstSupportedPlaceType(types);

  if (featureType == PlaceType.postal_code || featureType == PlaceType.sublocality || featureType == PlaceType.neighborhood) {
    return postalCode;
  }
}

export function getLocationFromPlaceDetails(result: PlaceResult): PlaceLocation {
  const { addressComponents = [], types = [], formattedAddress, description, ...restLocationFields } = result;

  const getComponent = (type: PlaceType) => getAddressComponent(type, addressComponents)?.longName;

  const country = getComponent(PlaceType.country);
  const region = getComponent(PlaceType.administrative_area_level_1);
  const city = getComponent(PlaceType.locality);
  const subLocality = getComponent(PlaceType.sublocality);
  const neighborhood = getComponent(PlaceType.neighborhood);
  const _postalCode = getComponent(PlaceType.postal_code);
  const naturalFeature = getComponent(PlaceType.natural_feature);

  const postalCode = _postalCode ? getPostalCode(_postalCode, types as PlaceType[]) : undefined;

  const countryCode = getAddressComponent(PlaceType.country, addressComponents)?.shortName;

  return {
    ...restLocationFields,
    ...(countryCode && { countryCode }),
    ...(country && { country }),
    ...(region && { region }),
    ...(city && { city }),
    ...(subLocality && { subLocality }),
    ...(neighborhood && { neighborhood }),
    ...(_postalCode && { postalCode }),
    ...(naturalFeature && { naturalFeature }),
    name: description ?? formattedAddress,
  };
}

import React from 'react';
import { type AriaDialogProps, useDialog } from 'react-aria';

import { StyledDialog } from './RangeDropdown.styled';

interface DialogProps extends AriaDialogProps {
  children: React.ReactNode;
}

export const Dialog = ({ children, ...props }: DialogProps) => {
  const ref = React.useRef(null);
  const { dialogProps } = useDialog(props, ref);

  return (
    <StyledDialog {...dialogProps} ref={ref}>
      {children}
    </StyledDialog>
  );
};

import { ListingSortOption, PropertyMarketingType } from '@generated/search-bff';
import {
  type ApiConfig,
  type ApiResult,
  type GetPropertiesFilters,
  type GetPropertiesOptions,
  type PropertiesResponse,
  type Property,
} from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils/error';
import { DEFAULT_FILTERS, getSimilarPropertiesPriceRange } from '@pkgs/api/utils/filters';
import { findLocalityPlaceId } from '@pkgs/api/utils/location';

import { getProperties } from '../getProperties/getProperties';

/**
 * Fetch property listings that are similar to a property. The location, price +- 20%, business area, and marketing type of the property are used to find similar properties. If the property is a development project, only other development projects are returned. If it is not a development project, the search radius is set to 5 km.
 * @function
 * @param {Property} property - An object representing the property to find similar properties for.
 * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the property listings or error details.
 *
 * @summary Fetch similar properties based on a provided property listing.
 */

export async function getSimilarProperties(
  property: Property,
  options: GetPropertiesOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<PropertiesResponse>> {
  try {
    const localityPlaceId = findLocalityPlaceId(property.placeIds ?? []);

    if (!localityPlaceId) {
      throw new Error('The property does not have any locality place id');
    }

    const { businessArea, propertyMarketingType, isDevelopmentProject, priceOnRequest, id, baseCurrency } = property;

    const filters: GetPropertiesFilters = {
      excludedPropertyIds: [id],
      businessArea: businessArea ? [businessArea] : DEFAULT_FILTERS.businessArea,
      propertyMarketingType: propertyMarketingType ? [propertyMarketingType] : DEFAULT_FILTERS.propertyMarketingType,
      placeId: localityPlaceId,
      sortingOptions: [
        propertyMarketingType === PropertyMarketingType.rent ? ListingSortOption.RENT_DESC : ListingSortOption.SALES_PRICE_DESC,
      ],
    };

    if (isDevelopmentProject) {
      filters.propertyType = ['group'];
    } else {
      filters.searchRadius = 5;

      if (priceOnRequest) {
        filters.priceOnRequest = true;
      } else {
        filters.price = getSimilarPropertiesPriceRange(property);
        options.currency = baseCurrency;
      }
    }

    return await getProperties(filters, options, apiConfig);
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

import { to } from '@pkgs/components/utils/mediaQueries';
import ArrowThinDown from '@spearhead/assets/icons/small/Arrow-Thin-Down.svg';
import CheckNaked from '@spearhead/assets/icons/small/Check-Naked.svg';
import { type InputTextSizesProps } from '@spearhead/components/elements/InputText/InputText';
import { Button, ListBox, ListBoxItem, Popover, Select } from 'react-aria-components';
import { css, keyframes, styled } from 'styled-components';

import { Copy } from '../Copy/Copy';

export const StyledIcon = styled(ArrowThinDown)<{ $isOpen?: boolean }>`
  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg);'}
  transition: transform 0.3s ease-in-out;
  color: inherit;
  flex-shrink: 0;
`;

type HasError = {
  $hasError?: boolean;
};

export type SelectButtonVariants = 'basic' | 'basicLight' | 'outlined';

export const StyledCopy = styled(Copy)`
  color: var(--color-dark-subdued);
  line-height: var(--line-height-static-s);
`;

export const StyledValue = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  gap: var(--distance-static-xs);
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--distance-static-xxs);
  min-width: 0;
  width: 100%;
`;

interface ButtonProps {
  $width?: string;
  $variant: SelectButtonVariants;
  $size?: InputTextSizesProps;
}

const commonBasicButtonStyles = css`
  height: auto;
  border: none;
  padding: 0;
  font-size: var(--font-size-static-m);
  color: var(--color-cod-gray);
  background-color: transparent;
  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: var(--color-dark);
    }
  }
`;

export const selectVariant = {
  basic: css`
    ${commonBasicButtonStyles};
    line-height: var(--line-height-static-m);
    font-weight: var(--font-weight-heavy);
  `,
  basicLight: css`
    ${commonBasicButtonStyles};
    line-height: var(--line-height-static-l);
    letter-spacing: var(--letter-spacing-default);
  `,
  outlined: css`
    border: 1px solid var(--color-input-border-idle);
    border-radius: var(--border-radius-s);
    padding: var(--distance-static-xs) var(--distance-static-s);
    font-size: var(--font-size-static-m);
    line-height: var(--line-height-static-l);
    color: var(--color-tundora);
    background-color: var(--color-light);
    @media (hover: hover) {
      &:hover:not(:disabled) {
        border-color: 1px solid var('--color-input-border-hover');
        box-shadow: var(--shadow-xs);
      }
    }
  `,
} as const;

export const StyledAriaButton = styled(Button)<ButtonProps & HasError>`
  height: ${({ $size }) => ($size === 'm' ? '48px' : '40px')};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  appearance: none;
  font-family: var(--font-family-text), Arial, Helvetica, sans-serif;
  font-size: var(--font-size-static-m);
  line-height: var(--line-height-static-l);
  cursor: pointer;
  border: 1px solid var(--color-input-border-idle);
  border-radius: var(--border-radius-s);

  ${({ $hasError }) => $hasError && `border-color: var(--color-input-border-error);`}

  width: ${({ $width }) => $width || '100%'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ $variant }) => selectVariant[$variant]}
  ${({ $hasError }) => ($hasError ? `border-color: var(--color-input-border-error);` : '')}

  &:focus {
    outline: 0;
  }

  &:focus-within {
    outline: var(--distance-static-3xs) solid var(--color-focus-dark);
  }
`;

export const StyledAriaList = styled(ListBox)<{ $fixedWidth?: number }>`
  max-height: 300px;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  outline: none;
  ${({ $fixedWidth }) => ($fixedWidth ? `width: ${$fixedWidth}px;` : 'width: 100%;')}
  :hover {
    cursor: pointer;
  }

  @media screen and (${to.tabletLandscape}) {
    padding: var(--distance-static-m);
    width: auto;
  }
`;

interface StyledListItemProps {
  $isFocused?: boolean;
  $isNested?: boolean;
  $isParent?: boolean;
}

export const StyledAriaListItem = styled(ListBoxItem)<StyledListItemProps>`
  &[data-focused] {
    background: var(--color-dropdown-listBox);
  }

  :focus {
    outline: none;
  }

  padding: var(--distance-static-s);
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: center;
  gap: var(--distance-static-xs);
  justify-content: space-between;
  cursor: default;
  outline: none;

  ${({ $isNested, $isParent }) =>
    $isNested
      ? css`
          padding-left: var(--distance-static-l);
        `
      : $isParent &&
        css`
          padding: var(--distance-static-m) var(--distance-static-s);
          font-size: var(--font-size-static-xs);
          text-transform: uppercase;
          letter-spacing: 2px;
          color: var(--color-dark-subdued, red);
          &:not(:first-child) {
            border-top: 1px solid var(--color-dark-decent);
          }
        `}

  @media screen and (${to.tabletLandscape}) {
    ${({ $isParent }) =>
      $isParent &&
      `
        &:not(:first-child) {
          border-top: 1px solid var(--color-dark-decent);
        }
      `}
  }
`;

export const StyledSelectedIcon = styled(CheckNaked)`
  display: none;

  [data-selected='true'] & {
    display: block;
  }
  width: 24px;
`;

const slide = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }`;

export const StyledAriaPopover = styled(Popover)<{ $width?: string }>`
  border-radius: var(--border-radius-s);
  margin-block: var(--distance-static-xxs);
  padding: 0;
  background: var(--color-surface-light);
  box-shadow: var(--shadow-m);
  ${({ $width }) => `width: ${$width ?? 'auto'}`};

  @media screen and (${to.tabletLandscape}) {
    &&& {
      // hack to override the default position
      // and make the dropdown appear at the bottom. Sorry :(
      top: unset !important;
      left: unset !important;
      bottom: 0 !important;
      position: fixed !important;
      width: 100%;
      border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
      animation: ${slide} 300ms;
      margin-block: 0;
    }
  }

  // select the popover underlay
  :has(+ &) {
    pointer-events: none;
    @media screen and (${to.tabletLandscape}) {
      z-index: 100;
      position: fixed;
      inset: 0;
      background: var(--color-overlay-background);
    }
  }
`;

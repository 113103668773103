import { Grid, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Divider } from '@/components/common/Divider/Divider';

import { aboutLogo, charityLogo, commercialLogo, ggMagazineLogo, poloSchoolLogo, yachtingLogo } from '../footerConfig';
import FooterBrandLogo from '../FooterDesktop/FooterBrandLogo';

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '> .ggLogo': {
    width: theme.spacing(10.75),
  },
}));

export const FooterMobileBrandRows = (): React.ReactElement => {
  const { t } = useTranslation('header_footer');

  return (
    <>
      <StyledGrid container justifyContent="space-between">
        <FooterBrandLogo icon={aboutLogo.icon} url={t(aboutLogo.url)} />
        <FooterBrandLogo icon={commercialLogo.icon} url={t(commercialLogo.url)} />
      </StyledGrid>
      <Divider />
      <StyledGrid container justifyContent="space-between">
        <FooterBrandLogo icon={yachtingLogo.icon} url={t(yachtingLogo.url)} />
        <FooterBrandLogo icon={charityLogo.icon} url={t(charityLogo.url)} />
      </StyledGrid>
      <Divider />

      <StyledGrid container justifyContent="space-between">
        <FooterBrandLogo icon={poloSchoolLogo.icon} url={t(poloSchoolLogo.url)} />
        <FooterBrandLogo icon={ggMagazineLogo.icon} url={t(ggMagazineLogo.url)} className="ggLogo" />
      </StyledGrid>
    </>
  );
};

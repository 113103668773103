import { to } from '@pkgs/components';
import { keyframes, styled } from 'styled-components';

export type SkeletonProps = {
  $width?: string;
  $height?: string;
  $borderRadius?: string;
  $gridRow?: number;
  $gridColumn?: number;
};

const shimmer = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const StyledImageSkeletonContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  gap: var(--distance-static-m);

  @media screen and (${to.tabletLandscape}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledLeftColumn = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  aspect-ratio: 3 / 2;
`;

export const StyledRightColumn = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: var(--distance-static-m);
  @media screen and (${to.tabletLandscape}) {
    display: none;
  }
`;

export const StyledSkeletonDiv = styled.div<SkeletonProps>`
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || '100%'};
  border-radius: ${({ $borderRadius }) => $borderRadius || 'var(--border-radius-s)'};
  background: linear-gradient(90deg, var(--color-light-shade) 25%, #f0f0f0 50%, var(--color-light-shade) 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
`;

export const StyledActionBarSkeletonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: var(--distance-static-m);
  margin-bottom: var(--distance-static-xl);

  @media screen and (${to.tabletLandscape}) {
    margin: 0 var(--distance-static-s);
    margin-bottom: var(--distance-static-xl);
  }
`;

export const ActionButtonRightcontainer = styled.div`
  display: flex;
  gap: var(--distance-static-m);
  height: var(--distance-static-m);

  @media screen and (${to.tabletLandscape}) {
    gap: var(--distance-static-xs);
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (${to.tabletLandscape}) {
    flex-direction: column;
  }
`;

export const StyledContentContainer = styled.div<{
  $flexDirection?: string;
  $justifyContent?: string;
  $flex?: number;
  $display?: string;
}>`
  flex: ${({ $flex }) => $flex ?? 1};
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'column'};
  gap: var(--distance-static-m);
  justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-start'};

  @media screen and (${to.tabletLandscape}) {
    display: ${({ $display }) => $display};
  }
`;

import { UserActionEnum } from '@ev/snowplow-library';
import SearchIcon from '@pkgs/components/assets/icons/small/Search.svg';
import { DEFAULT_SHOP_IDS_FILTERS, SHOP_BANNER_BACKGROUND_IMAGE } from '@pkgs/components/consts/consts';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useLipaPortfolioTrackButtonClick } from '@pkgs/components/hooks/useLipaPortfolioTrackButtonClick';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { Button } from '@spearhead/components/elements/Button/Button';

import { StyledContainer, StyledHeadline, StyledWrapper } from './ShopBanner.styled';

export const ShopBanner = ({ isVisible }: { isVisible: boolean }) => {
  const { shopNames, updateFilters, isPartnerShops } = useSearch();
  const { ImageComponent, t } = useAppContext();
  const { trackButtonClick } = useLipaPortfolioTrackButtonClick({
    elementId: 'extend_search_headline',
    action: UserActionEnum.click,
  });

  const handleButtonClick = () => {
    trackButtonClick();
    updateFilters(DEFAULT_SHOP_IDS_FILTERS);
  };

  const isShopBannerVisible = Boolean(shopNames?.length) && isVisible && isPartnerShops;

  return (
    <>
      {isShopBannerVisible && (
        <StyledWrapper>
          <StyledContainer>
            <StyledHeadline
              size="h3"
              label={t('search.licencePartner.hero.title', {
                shopNames: shopNames?.join(', '),
              })}
            />
            <Button type="button" label={t('search.licencePartner.ctaCard.btn')} icon={<SearchIcon />} onClick={handleButtonClick} />
          </StyledContainer>
          <ImageComponent
            src={SHOP_BANNER_BACKGROUND_IMAGE}
            alt="A beautiful house in the background"
            fill
            priority
            style={{ objectFit: 'cover' }}
          />
        </StyledWrapper>
      )}
    </>
  );
};

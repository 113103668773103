import { css, styled } from 'styled-components';

import { skeletonAnimation } from './Skeleton.styles';

export const StyledContainer = styled.div`
  box-shadow: var(--shadow-xs);
  border-radius: var(--border-radius-s);
  background: var(--color-light);

  ${skeletonAnimation}
`;

export const StyledImageSkeleton = styled.div<{ $isPreview: boolean }>`
  width: 100%;
  background-color: var(--color-desert-storm);

  ${({ $isPreview }) => css`
    aspect-ratio: ${$isPreview ? '16 / 9' : '3 / 2'};
  `};
`;

export const StyledSkeletonContentWrapper = styled.div<{ $isPreview: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-s);
  padding: var(--distance-static-m);

  ${({ $isPreview }) =>
    $isPreview
      ? css`
          padding: var(--distance-static-s);
        `
      : css`
          & > :nth-child(3) {
            width: 40%;
            height: 2rem;
          }
        `};

  & > :nth-child(1) {
    width: 55%;
    height: 1.5rem;
  }
  & > :nth-child(2) {
    width: 90%;
    height: 2rem;
  }
`;

export const StyledSkeletonContentRow = styled.div`
  display: flex;
  gap: var(--distance-static-s);

  & > * {
    width: 30%;
    height: 1.5rem;
  }
`;

export const StyledContentSkeleton = styled.div`
  background-color: var(--color-desert-storm);
`;

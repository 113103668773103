import { type BigDecimalRangeDto } from '@ev/search-modules-api';
import { useEffect, useState } from 'react';

import { useAppContext } from '../../hooks/useAppContext';
import { validateRangeValues } from '../../utils/numberFormatters';
import { formatRangeLabel } from '../../utils/stringUtils';
import { RangeDropdown } from '../RangeDropdown/RangeDropdown';
import { LivingSurfaceInput } from '../RangeInputs/LivingSurfaceInput';
import { type SurfaceFilterDropdownProps } from './FilterDropdown.types';

export const LivingSurfaceFilterDropdown = ({
  value,
  language,
  unitSymbol,
  className,
  onChange,
  'data-test-id': dataTestId,
}: SurfaceFilterDropdownProps) => {
  const { t } = useAppContext();
  const [range, setRange] = useState<BigDecimalRangeDto | undefined>(value ? validateRangeValues(value) : undefined);

  useEffect(() => {
    setRange(value ? validateRangeValues(value) : undefined);
  }, [value]);

  return (
    <RangeDropdown
      className={className}
      label={t('search.searchbar.livingArea')}
      value={value ? formatRangeLabel(value, unitSymbol, language, true) : ''}
      onDone={() => {
        range && onChange({ livingSurface: { ...range } });
      }}
      onReset={() => {
        onChange({ livingSurface: undefined });
        setRange(undefined);
      }}
      data-test-id={dataTestId ? `${dataTestId}_dropdown` : 'search-components_living-surface-filter_dropdown'}
    >
      <LivingSurfaceInput
        unitSymbol={unitSymbol}
        hiddenLabel={true}
        defaultRange={value}
        language={language}
        onChange={setRange}
        data-test-id={dataTestId ?? 'search-components_living-surface-filter'}
      />
    </RangeDropdown>
  );
};

import { css, styled } from 'styled-components';

const propertyBadgesStyles = css`
  font-weight: var(--font-weight-heavy);
  letter-spacing: var(--letter-spacing-s);
  text-transform: uppercase;
  border-radius: var(--border-radius-s);
`;

const statusBadgesStyles = css`
  border-radius: var(--border-radius-xl);
`;

export const variants = {
  new: css`
    color: var(--color-text-light);
    background: var(--color-badge-new);
    ${propertyBadgesStyles}
  `,
  featured: css`
    color: var(--color-text-light);
    background: var(--color-badge-featured);
    ${propertyBadgesStyles}
  `,
  sold: css`
    color: var(--color-text-light);
    background: var(--color-badge-sold);
    ${propertyBadgesStyles}
  `,
  residential: css`
    color: var(--color-text-dark);
    background: var(--color-badge-residential);
    ${propertyBadgesStyles}
  `,
  open: css`
    color: var(--color-badge-open-text);
    background: var(--color-badge-open);
    ${statusBadgesStyles}
  `,
  closed: `
    color: var(--color-badge-closed-text);
    background: var(--color-badge-closed);
    ${statusBadgesStyles}
  `,
  development: css`
    --color-badge-development: var(--color-surface-dark-highlight);
    --color-badge-development-text: var(--color-text-light);
    color: var(--color-badge-development-text);
    background: var(--color-badge-development);
    ${propertyBadgesStyles}
  `,
} as const;

export type BadgeVariant = keyof typeof variants;

export interface IStyledBadgeProps {
  $variant: BadgeVariant;
}

export const StyledBadge = styled.span<IStyledBadgeProps>`
  display: inline-block;
  padding: var(--distance-static-3xs) var(--distance-static-xs);
  font-size: var(--font-size-static-xs);
  line-height: var(--line-height-static-s);

  ${({ $variant }) => variants[$variant]}
`;

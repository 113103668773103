import { type GridProps, Grid, styled } from '@mui/material';
import dynamic from 'next/dynamic';
import React from 'react';

import { HeaderDesktop } from './HeaderDesktop';
import { HeaderLogo } from './HeaderLogo';

const HeaderMobile = dynamic<HeaderProps>(() => import('./HeaderMobile').then((mod) => mod.HeaderMobile), {
  ssr: false,
});
const StyledContainer = styled(Grid)<GridProps>(({ theme }) => ({
  padding: theme.spacing(2, 5, 1, 5),
  boxSizing: 'border-box',
  width: '100%',
  height: '7rem',
  margin: '0 auto',
  maxWidth: theme.breakpoints.values.xl,
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexFlow: 'row wrap',
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    height: '5.5678rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '0',
    display: 'block',
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    height: '3.5rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const StyledRedStrip = styled('div')(({ theme }) => ({
  height: '3px',
  width: '100%',
  backgroundColor: theme.palette.red[100],
  position: 'sticky',
  top: 0,
  zIndex: 99,
}));

export type HeaderProps = {
  id?: string;
  onLanguageChange?: (locale: string) => void;
};

export const Header = ({ id = 'header', onLanguageChange }: HeaderProps): React.ReactElement => {
  return (
    <>
      <StyledContainer component="header" id={id}>
        <HeaderLogo />
        <HeaderMobile onLanguageChange={onLanguageChange} />
        <HeaderDesktop onLanguageChange={onLanguageChange} />
      </StyledContainer>
      <StyledRedStrip />
    </>
  );
};

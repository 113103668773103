import { type Currency, type Language } from '@pkgs/api';
import { type ParseKeys, type TFunction } from 'i18next';

import { FALLBACK_LOCALE } from '@/consts/settings';

export const formatNumber = (
  number: Maybe<number>,
  locale: Language = FALLBACK_LOCALE,
  opts: Intl.NumberFormatOptions & { notation?: string } = {}
): string => {
  if (!number) {
    return '';
  }

  // The formatted numbers in french language are using Narrow no break space https://www.codetable.net/decimal/8239 as thousands separator
  // and the E&V font is rendering this kind of white spaces bigger than usual white spaces like &nbsp;
  // here we are replacing all white spaces with a blank space character as a workaround
  // https://engelvoelkers.atlassian.net/browse/NS-3288?focusedCommentId=342373
  return new Intl.NumberFormat(locale, { ...opts }).format(number).replace(/\s+/g, ' ');
};

const formatPrice = (
  number: number,
  language: Language = FALLBACK_LOCALE,
  opts: Intl.NumberFormatOptions & { notation?: string } = {}
): string => {
  if (number <= 0) {
    return '';
  }
  return formatNumber(number, language, {
    style: 'currency',
    maximumFractionDigits: opts.maximumFractionDigits || 0,
    minimumFractionDigits: opts.minimumFractionDigits || 0,
    ...{ currency: 'EUR', ...opts },
  });
};

export const formatPriceWithoutDecimals = (price: number, language: Language = FALLBACK_LOCALE, currency: string | Currency) => {
  return formatPrice(price, language, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency: currency,
  });
};

export const formatPriceWithDecimals = (price: number, language: Language = FALLBACK_LOCALE, currency: string | Currency) => {
  // we add this check to avoid trailing zero e.g 12.00€ when formatting prices without decimals
  const isInteger = price % 1 === 0;
  return formatPrice(price, language, {
    maximumFractionDigits: isInteger ? 0 : 2,
    minimumFractionDigits: isInteger ? 0 : 2,
    currency: currency,
  });
};

export const getNumberUnformatted = (value: string): string => {
  // remove space, comma or period from a string
  return value.replace(/,|\.|\s/g, '');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatWithCount = (t: TFunction<any>, key: ParseKeys<any>, count: number): string => {
  return `${t(key, {
    count: count,
  })}${count > 1 ? ` (${count})` : ''}`;
};

export const joinWithSeparator = (arr: Array<string | undefined>, separator = ' \u00B7 '): string =>
  arr.filter((item) => item).join(separator);

export const formatDate = (date: string, language: Language): string => {
  return new Date(date).toLocaleString(language, {
    year: 'numeric',
    month: 'long',
  });
};

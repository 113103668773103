import {
  customInstance
} from "./chunk-PCL3B4V6.js";

// generated/search-bff/controllers/search-alert/search-alert.ts
var editSearchAlert = (searchAlertId, editSearchAlertRequest, options) => {
  return customInstance(
    {
      url: `/v3/searchalert/alert/${searchAlertId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: editSearchAlertRequest
    },
    options
  );
};
var deleteSearchAlert = (searchAlertId, options) => {
  return customInstance({ url: `/v3/searchalert/alert/${searchAlertId}`, method: "DELETE" }, options);
};
var createSearchAlert = (createSearchAlertRequestV3, options) => {
  return customInstance(
    { url: `/v3/searchalert/alert`, method: "POST", headers: { "Content-Type": "application/json" }, data: createSearchAlertRequestV3 },
    options
  );
};
var confirm = (searchAlertId, options) => {
  return customInstance({ url: `/v3/searchalert/alert/${searchAlertId}/confirm`, method: "POST" }, options);
};
var createSearchAlert1 = (createSearchAlertRequestV2, options) => {
  return customInstance(
    { url: `/v2/searchalert/alert`, method: "POST", headers: { "Content-Type": "application/json" }, data: createSearchAlertRequestV2 },
    options
  );
};
var findAllSearchAlerts = (accountId, params, options) => {
  return customInstance(
    { url: `/v3/searchalert/account/${accountId}/alert`, method: "GET", params },
    options
  );
};
var deleteAllSearchAlerts = (accountId, options) => {
  return customInstance({ url: `/v3/searchalert/account/${accountId}/alert`, method: "DELETE" }, options);
};

export {
  editSearchAlert,
  deleteSearchAlert,
  createSearchAlert,
  confirm,
  createSearchAlert1,
  findAllSearchAlerts,
  deleteAllSearchAlerts
};

import { type ReactNode, createContext, useEffect, useReducer } from 'react';

import { viewportSizes } from '@/components/ui-core-components-clone/utils/mediaQueries';

export const Breakpoints = {
  MOBILE: viewportSizes.MOBILE,
  TABLET_PORTRAIT: viewportSizes.TABLET_PORTRAIT,
  TABLET_LANDSCAPE: viewportSizes.TABLET_LANDSCAPE,
  LAPTOP: viewportSizes.LAPTOP,
  DESKTOP: viewportSizes.DESKTOP,
} as const;

export type Breakpoints = (typeof Breakpoints)[keyof typeof Breakpoints];

export type BreakpointProviderType = {
  breakpoint: Breakpoints;
  isMobile: boolean;
  isTablet: boolean;
  isTabletPortrait: boolean;
  isDesktop: boolean;
};

type MediaQuery = {
  breakpoint: Breakpoints;
  query: string;
};

const initialState: BreakpointProviderType = {
  breakpoint: Breakpoints.MOBILE,
  isMobile: true,
  isTablet: false,
  isTabletPortrait: false,
  isDesktop: false,
};

export const BreakpointContext = createContext<BreakpointProviderType>(initialState);

const mediaQueries = Object.values(Breakpoints).map<MediaQuery>((breakpoint, i, arr) => ({
  breakpoint,
  query: i === arr.length - 1 ? `(min-width: ${breakpoint}px)` : `(min-width: ${breakpoint}px) and (max-width: ${arr[i + 1] - 1}px)`,
}));

const breakpointReducer = (state: BreakpointProviderType, { breakpoint }: { breakpoint: Breakpoints }): BreakpointProviderType => ({
  breakpoint: breakpoint,
  isMobile: breakpoint === Breakpoints.MOBILE,
  isTablet: breakpoint === Breakpoints.TABLET_PORTRAIT || breakpoint === Breakpoints.TABLET_LANDSCAPE,
  isTabletPortrait: breakpoint === Breakpoints.TABLET_PORTRAIT,
  isDesktop: breakpoint >= Breakpoints.LAPTOP,
});

export const BreakpointProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(breakpointReducer, initialState);

  useEffect(() => {
    for (const mediaQuery of mediaQueries) {
      const matcher: MediaQueryList = window.matchMedia(mediaQuery.query);

      const handleChange = (event: MediaQueryListEvent) => {
        if (event.matches) {
          dispatch({ breakpoint: mediaQuery.breakpoint });
        }
      };

      if (matcher.matches) {
        dispatch({ breakpoint: mediaQuery.breakpoint });
      }

      matcher.addEventListener('change', handleChange);
    }
  }, []);

  return <BreakpointContext.Provider value={state}>{children}</BreakpointContext.Provider>;
};

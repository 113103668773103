import { type Property, CountryCode } from '@pkgs/api';
import { type ParseKeys } from 'i18next';

export const getSpecificLivingAreaLabel = (property: Property): ParseKeys<'common'> => {
  if (property.countryAlpha2 === CountryCode.HK) {
    if (property.isDevelopmentProject) {
      return 'search.expose.propertyDetails.development.label.livingArea.hongKong';
    }
    return 'search.expose.propertyDetails.label.livingArea.hongKong';
  }
  if (property.isDevelopmentProject) {
    return 'search.expose.propertyDetails.development.label.livingArea';
  }

  return 'search.expose.propertyDetails.label.livingArea';
};

import {
  type BoundingBoxDto,
  BusinessArea,
  type GetPropertiesOptions,
  ListingSortOption,
  PropertyMarketingType,
  PropertySubType,
  PropertyType,
} from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { type SelectItem } from '@pkgs/components/components/Select';

import { FilterAttributeType, SortOptionId } from '../enums/enums';
import type { Filter } from '../types/types';
import { type Features } from '../types/types';

const PROPERTY_TYPE_PREFIX = 'search.searchbar.advFilter.propertyType.';
const PROPERTY_SUBTYPE_PREFIX = 'search.searchbar.advFilter.propertySubType.';
const SORT_BY_PREFIX = 'search.searchbar.sortBy.';

export const FEATURE_ATTRIBUTES = [
  'hasAirConditioning',
  'hasBalcony',
  'hasBasement',
  'hasBuiltInKitchen',
  'hasGarage',
  'hasGarden',
  'hasPatio',
  'hasSeaOrLakeView',
  'hasSecuritySystem',
  'hasSwimmingPool',
  'hasTennisCourt',
  'hasTerrace',
  'hasWaterfront',
] as const;

export const propertyTypes: SelectItem[] = [
  {
    id: BusinessArea.residential,
    label: `${PROPERTY_TYPE_PREFIX}${BusinessArea.residential}`,
    parent: true,
  },
  {
    id: `${BusinessArea.residential}.${PropertyType.house}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.house}`,
    nested: true,
  },
  {
    id: `${BusinessArea.residential}.${PropertyType.apartment}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.apartment}`,
    nested: true,
  },
  {
    id: `${BusinessArea.residential}.${PropertyType.land}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.land}`,
    nested: true,
  },
  // TODO: is PropertyType.DEVELOPMENT really 'group' ?
  {
    id: `${BusinessArea.residential}.${PropertyType.group}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.group}`,
    parent: true,
  },
  {
    id: BusinessArea.commercial,
    label: `${PROPERTY_TYPE_PREFIX}${BusinessArea.commercial}`,
    parent: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.agriculture_and_forestry}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.agriculture_and_forestry}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.commercial_space}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.commercial_space}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.hotel}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.hotel}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.industry}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.industry}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.investment}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.investment}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.gastronomy}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.gastronomy}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.land}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.land}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.office}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.office}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.other}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.other}`,
    nested: true,
  },
  {
    id: `${BusinessArea.commercial}.${PropertyType.store}`,
    label: `${PROPERTY_TYPE_PREFIX}${PropertyType.store}`,
    nested: true,
  },
];

export const propertySubtypes: {
  // TODO: handle this "reset" option better
  id: PropertySubType | 'reset';
  label: string;
  propertyType: PropertyType[];
}[] = [
  {
    id: 'reset',
    label: `${PROPERTY_SUBTYPE_PREFIX}allPropertySubTypes`,
    propertyType: [PropertyType.apartment, PropertyType.house, PropertyType.land],
  },
  {
    id: PropertySubType.flat,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.flat}`,
    propertyType: [PropertyType.apartment],
  },
  {
    id: PropertySubType.detached_house,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.detached_house}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.villa,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.villa}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.land,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.land}`,
    propertyType: [PropertyType.land],
  },
  {
    id: PropertySubType.terraced_house,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.terraced_house}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.semi_detached_house,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.semi_detached_house}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.penthouse,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.penthouse}`,
    propertyType: [PropertyType.apartment],
  },
  {
    id: PropertySubType.country_house,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.country_house}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.maisonette,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.maisonette}`,
    propertyType: [PropertyType.apartment],
  },
  {
    id: PropertySubType.townhouse,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.townhouse}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.apartment_house,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.apartment_house}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.loft,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.loft}`,
    propertyType: [PropertyType.apartment],
  },
  {
    id: PropertySubType.special_property,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.special_property}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.castle,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.castle}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.bungalow,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.bungalow}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.investment,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.investment}`,
    propertyType: [PropertyType.house],
  },
  {
    id: PropertySubType.site,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.site}`,
    propertyType: [PropertyType.land],
  },
  {
    id: PropertySubType.parking,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.parking}`,
    propertyType: [PropertyType.land],
  },
  {
    id: PropertySubType.attic,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.attic}`,
    propertyType: [PropertyType.apartment],
  },
  {
    id: PropertySubType.other,
    label: `${PROPERTY_SUBTYPE_PREFIX}${PropertySubType.other}`,
    propertyType: [PropertyType.apartment, PropertyType.house, PropertyType.land],
  },
];

const allFeatureFilters: Filter[] = FEATURE_ATTRIBUTES.map((feature) => ({
  name: feature,
  type: FilterAttributeType.BOOLEAN,
}));

export const ALL_ADVANCED_FILTERS: readonly Filter[] = [
  {
    name: 'propertySubType',
    type: FilterAttributeType.VALUE,
  },
  {
    name: 'price',
    type: FilterAttributeType.RANGE,
  },
  {
    name: 'totalSurface',
    type: FilterAttributeType.RANGE,
  },
  {
    name: 'livingSurface',
    type: FilterAttributeType.RANGE,
  },
  {
    name: 'plotSurface',
    type: FilterAttributeType.RANGE,
  },
  {
    name: 'constructionYear',
    type: FilterAttributeType.RANGE,
  },
  {
    name: 'bedrooms',
    type: FilterAttributeType.RANGE,
  },
  {
    name: 'bathrooms',
    type: FilterAttributeType.RANGE,
  },
  {
    name: 'rooms',
    type: FilterAttributeType.RANGE,
  },
  { name: 'hasVtour', type: FilterAttributeType.BOOLEAN },
  ...allFeatureFilters,
] as const;

export const defaultFeatures = FEATURE_ATTRIBUTES.reduce<Features>(
  (acc: Features, current) => ((acc[current] = undefined), acc),
  {} as Features,
);

type SortOption = {
  id: SortOptionId;
  label: string;
  sortingOption: ListingSortOption;
  tags: string[];
};

export const sortByOptions: SortOption[] = [
  {
    id: SortOptionId.LATEST,
    label: `${SORT_BY_PREFIX}${SortOptionId.LATEST}`,
    sortingOption: ListingSortOption.PUBLISHED_AT_DESC,
    tags: [BusinessArea.commercial, BusinessArea.residential, PropertyMarketingType.sale, PropertyMarketingType.rent],
  },
  {
    id: SortOptionId.PRICE_ASC,
    label: `${SORT_BY_PREFIX}${SortOptionId.PRICE_ASC}`,
    sortingOption: ListingSortOption.SALES_PRICE_ASC,
    tags: [BusinessArea.commercial, BusinessArea.residential, PropertyMarketingType.sale],
  },
  {
    id: SortOptionId.PRICE_DESC,
    label: `${SORT_BY_PREFIX}${SortOptionId.PRICE_DESC}`,
    sortingOption: ListingSortOption.SALES_PRICE_DESC,
    tags: [BusinessArea.commercial, BusinessArea.residential, PropertyMarketingType.sale],
  },
  {
    id: SortOptionId.RENT_ASC,
    label: `${SORT_BY_PREFIX}${SortOptionId.RENT_ASC}`,
    sortingOption: ListingSortOption.RENT_ASC,
    tags: [BusinessArea.commercial, BusinessArea.residential, PropertyMarketingType.rent],
  },
  {
    id: SortOptionId.RENT_DESC,
    label: `${SORT_BY_PREFIX}${SortOptionId.RENT_DESC}`,
    sortingOption: ListingSortOption.RENT_DESC,
    tags: [BusinessArea.commercial, BusinessArea.residential, PropertyMarketingType.rent],
  },
  {
    id: SortOptionId.LIVING_SURFACE_ASC,
    label: `${SORT_BY_PREFIX}${SortOptionId.LIVING_SURFACE_ASC}`,
    sortingOption: ListingSortOption.LIVING_SURFACE_ASC,
    tags: [BusinessArea.residential, PropertyMarketingType.rent, PropertyMarketingType.sale],
  },
  {
    id: SortOptionId.LIVING_SURFACE_DESC,
    label: `${SORT_BY_PREFIX}${SortOptionId.LIVING_SURFACE_DESC}`,
    sortingOption: ListingSortOption.LIVING_SURFACE_DESC,
    tags: [BusinessArea.residential, PropertyMarketingType.rent, PropertyMarketingType.sale],
  },
  {
    id: SortOptionId.TOTAL_SURFACE_ASC,
    label: `${SORT_BY_PREFIX}${SortOptionId.TOTAL_SURFACE_ASC}`,
    sortingOption: ListingSortOption.TOTAL_SURFACE_ASC,
    tags: [BusinessArea.commercial, PropertyMarketingType.rent, PropertyMarketingType.sale],
  },
  {
    id: SortOptionId.TOTAL_SURFACE_DESC,
    label: `${SORT_BY_PREFIX}${SortOptionId.TOTAL_SURFACE_DESC}`,
    sortingOption: ListingSortOption.TOTAL_SURFACE_DESC,
    tags: [BusinessArea.commercial, PropertyMarketingType.rent, PropertyMarketingType.sale],
  },
];

export const VTOUR_VIDEO_URLS = [
  'service-dev.engelvoelkers.com',
  'service.engelvoelkers.com',
  'video.engelvoelkers.com',
  'oohembed.com',
  'vimeo.',
  'oohembed.com',
  'youtube.',
  'youtu.be',
  'youtube.com',
];

export const NON_RESETTABLE_FILTERS: readonly (keyof SearchModuleFilters)[] = [
  'placeId',
  'countryCode',
  'propertyMarketingType',
  'sortingOptions',
  'searchRadius',
  'businessArea',
] as const;

export const NON_RESETTABLE_OPTIONS: ReadonlySet<keyof GetPropertiesOptions> = new Set(['language', 'page', 'pageSize']);

export const NEW_SEARCH_URL_MAP = {
  dev: 'https://new-search.envdevel.com/api/',
  stage: 'https://new-search.envstage.com/api/',
  prod: 'https://new-search.engelvoelkers.com/api/',
} as const;

export const SHOP_BANNER_BACKGROUND_IMAGE = 'https://uploadcare.engelvoelkers.com/92d3d95d-5ddc-4fdc-b6c8-290eac425270/';

export const DEFAULT_SHOP_IDS_FILTERS = {
  shopIds: undefined,
  masterDataShopIds: undefined,
};

export const GLOBAL_NETWORK_IMAGE_URL = 'https://uploadcare.engelvoelkers.com/cb542e46-1fe6-4213-b6e6-3ad9c3430966/';

export const BLURRY_FALLBACK_IMAGE_URL = 'https://uploadcare.engelvoelkers.com/bd2a765a-d840-42d3-bc25-b9ebff4da7f4/';
// Bbox of Hamburg
export const DEFAULT_MAP_BBOX: BoundingBoxDto = {
  southWest: { lat: 52.913_042_899_445_2, lng: 9.481_201_171_875_002 },
  northEast: { lat: 53.956_085_530_987_9, lng: 10.458_984_375_000_002 },
};

export const DIDOMI_VENDOR = {
  MAPTILER: 'maptilera-KZWnDQD7',
} as const;
export type DIDOMI_VENDOR = (typeof DIDOMI_VENDOR)[keyof typeof DIDOMI_VENDOR];

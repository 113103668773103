import { type ReactNode } from 'react';
import { type AriaButtonProps } from 'react-aria';

import { type IconButtonSizes, type IconButtonVariant } from '../../../spearhead/lib/components/elements/IconButton/IconButton.styled';
import { Link } from '../../../spearhead/lib/components/elements/Link/Link';
import { StyledIconButton } from './IconButton.styled';

type IconButtonProps = {
  className?: string;
  showLabel?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  icon: ReactNode;
  size?: IconButtonSizes;
  variant?: IconButtonVariant;
};

export const IconButton = ({
  className,
  onClick,
  label,
  icon,
  size = 'small',
  showLabel = false,
  variant = 'tertiary',
  ...props
}: IconButtonProps & AriaButtonProps) => {
  const { onPress, ...rest } = props;
  return showLabel ? (
    <Link icon={icon} onClick={onClick} className={className} label={label} as="button" {...rest} />
  ) : (
    <StyledIconButton label={label} icon={icon} variant={variant} size={size} onClick={onClick} className={className} {...rest} />
  );
};

import { Typography, useTheme } from '@mui/material';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useRef, useState } from 'react';

import { HeartFilled } from '@/components/ui-core-components-clone/icons/small/HeartFilled';
import { HeartOutlined } from '@/components/ui-core-components-clone/icons/small/HeartOutlined';
import { useWatchlist } from '@/hooks/useWatchlist';
import { PageTypeEnum } from '@/types/tracking';

import { LinkWithParams } from '../LinkWithParams';
import {
  StyledLink,
  StyledMessage,
  StyledOutlinedButton,
  StyledPopover,
  StyledPropertyCard,
  StyledTitle,
  StyledWrapper,
} from './HeaderWatchlist.styles';

export const HeaderWatchlist = (): React.ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['common']);
  const theme = useTheme();
  const { watchlist, isWatchlistEmpty, lastWatchlistItems } = useWatchlist();

  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef<HTMLDivElement>(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
    popoverAnchor.current?.focus();
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
    popoverAnchor.current?.blur();
  };

  return (
    <>
      <div
        tabIndex={1}
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        <StyledWrapper>
          <StyledLink selected={openedPopover} href="/watchlist/" locale={language} prefetch={false}>
            {isWatchlistEmpty ? (
              <HeartOutlined
                style={{
                  marginRight: theme.spacing(0.5),
                }}
              />
            ) : (
              <HeartFilled
                style={{
                  marginRight: theme.spacing(0.5),
                }}
              />
            )}
            <Typography variant="caption">{t('search.watchlist.watchlist')}</Typography>
          </StyledLink>
        </StyledWrapper>
      </div>
      <StyledPopover
        id="mouse-over-popover"
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={45}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
        keepMounted={false}
      >
        <StyledTitle variant="h3" component="span">
          {t('search.watchlist.foundResults', {
            count: watchlist.length,
          })}
        </StyledTitle>
        {isWatchlistEmpty && (
          <>
            <StyledMessage variant="body2">{t('search.watchlist.flyout.message')}</StyledMessage>
            <Image
              src="https://uploadcare.engelvoelkers.com/21b7b30c-c666-4c58-bf34-766f1c348a58/"
              alt="example property card"
              width={326}
              height={300}
            />
          </>
        )}
        {lastWatchlistItems.map((property) => (
          <StyledPropertyCard key={property.id} properties={[property]} hidePlacement pageLocation={PageTypeEnum.FLYOUT} />
        ))}
        {watchlist.length > 3 && (
          <StyledOutlinedButton component={LinkWithParams} href="/watchlist" locale={language} passHref prefetch={false}>
            {t('search.watchlist.flyout.button')}
          </StyledOutlinedButton>
        )}
      </StyledPopover>
    </>
  );
};

import { type Language, PropertyMarketingType } from '@ev/search-modules-api';

import { useAppContext } from '../../hooks/useAppContext';
import { FormattedNumberInput } from '../FormattedNumberInput/FormattedNumberInput';
import { RangeInput } from './RangeInput';
import { StyledEndAdornment } from './RangeInput.styled';
import { type RangeInputProps } from './RangeInput.types';

type PriceInputProps = RangeInputProps & {
  propertyMarketingType: PropertyMarketingType;
  currencySymbol: string;
  language?: Language;
  'data-test-id'?: string;
};

export const PriceInput = ({ propertyMarketingType, language, currencySymbol, ...restProps }: PriceInputProps) => {
  const { t } = useAppContext();
  const priceLabel = propertyMarketingType === PropertyMarketingType.rent ? t('search.searchbar.rent') : t('search.searchbar.price');
  return (
    <RangeInput
      label={priceLabel}
      id="price"
      inputComponent={
        <FormattedNumberInput
          language={language ?? 'en'}
          endAdornment={<StyledEndAdornment forwardedAs="span" size="l" text={currencySymbol} />}
        />
      }
      {...restProps}
    />
  );
};

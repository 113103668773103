import React from 'react';

import type { ButtonSizes, ButtonVariant } from './Button.styled';
import { StyledButton } from './Button.styled';
import { LinkWithParams } from '../LinkWithParams';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement> {
  className?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonVariant;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right' | 'withoutIcon';
  as?: 'button' | 'a';
  href?: string;
  disabled?: boolean;
  size?: ButtonSizes;
  bypassUrl?: boolean;
  refObject?: React.RefObject<HTMLButtonElement | HTMLAnchorElement>;
  target?: string;
}

export const Button = ({
  className,
  label,
  onClick,
  variant = 'primary',
  icon,
  iconPosition = 'left',
  as,
  href,
  disabled,
  size = 'm',
  bypassUrl = true,
  refObject,
  ...props
}: ButtonProps) => {
  if (as === 'a') {
    if (!href) console.error('Button Component: href is required for anchor tag');

    return (
      <LinkWithParams
        href={href || ''}
        passHref
        bypassUrl={bypassUrl}
        prefetch={false}
        ref={refObject as React.RefObject<HTMLAnchorElement>}
      >
        <StyledButton className={className} as={as} $variant={variant} $size={size} $iconPosition={iconPosition} {...props}>
          {Boolean(icon) && iconPosition === 'left' && icon}
          <span>{label}</span>
          {Boolean(icon) && iconPosition === 'right' && icon}
        </StyledButton>
      </LinkWithParams>
    );
  }

  return (
    <StyledButton
      className={className}
      onClick={onClick}
      $variant={variant}
      disabled={disabled}
      $size={size}
      ref={refObject as React.RefObject<HTMLButtonElement>}
      $iconPosition={iconPosition}
      {...props}
    >
      {Boolean(icon) && iconPosition === 'left' && icon}
      <span>{label}</span>
      {Boolean(icon) && iconPosition === 'right' && icon}
    </StyledButton>
  );
};

import { type CountryCode, Language } from '@generated/search-bff';
import { type GetPropertiesFilters, type GetPropertiesOptions } from '@pkgs/api/types';
import qs from 'query-string';

import { refineFiltersAndOptions } from './filters';
import { isRecord } from './object';
import { safePanic } from './panic';
import { parseParams, validateCountryCode, validateLanguage } from './validation';

export type SearchUrlParts = {
  countryCode?: CountryCode;
  language?: Language;
  query?: string;
};

export const extractParamsFromUrl = (url: string | URL): SearchUrlParts => {
  const _url = typeof url === 'string' ? url : url.toString();

  if (_url.startsWith('?')) return { query: _url.slice(1) };

  const pathRx = /\/(?:(?<countryCode>[A-Za-z]{2})\/)?(?<language>[A-Za-z]{2})(?:\/[^?]*)?(?:\?(?<query>.*))?$/;

  const match = _url.match(pathRx);

  if (!match?.groups) {
    console.error(
      `Invalid url. Should be '.../<country>/<language>/...?<query>', for example: 'http://example.com/de/en/propertysearch?currency=EUR' but got: '${url}'`,
    );
    return {};
  }

  const countryCode = match.groups.countryCode?.toUpperCase();
  const language = match.groups.language?.toLowerCase();

  return {
    countryCode: validateCountryCode(countryCode) ? countryCode : undefined,
    language: validateLanguage(language) ? language : safePanic(`Invalid language: ${language}. Falling back to Language.en`, Language.en),
    query: match.groups.query,
  };
};

export function flattenNestedValues(obj: Record<string, unknown>, prefix = ''): Record<string, unknown> {
  let result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (isRecord(value)) {
      result = {
        ...result,
        ...flattenNestedValues(value, `${prefix}${key}.`),
      };
    } else {
      result[`${prefix}${key}`] = value;
    }
  }
  return result;
}

export function expandNestedValues(obj: Record<string, unknown>): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(obj)) {
    const keys = key.split('.');

    if (keys.some((k) => k === '__proto__' || k === 'constructor' || k === 'prototype')) {
      continue; // skip dangerous keys
    }
    if (keys.length > 1) {
      const lastKey = keys.pop()!;
      let nestedObj = result;
      for (const k of keys) {
        nestedObj[k] = nestedObj[k] || {};
        nestedObj = nestedObj[k] as Record<string, unknown>;
      }
      nestedObj[lastKey] = value;
    } else {
      result[key] = value;
    }
  }
  return result;
}

/**
 * Transform search state into search query params string
 * @param params object containing search state
 * @param currentParams string representing current query params
 * @returns string containing search query params
 */
export const stringifySearchParams = (params: Record<string, unknown>, currentParams?: string | URLSearchParams): string => {
  // normalize existing params (remove leading '?' if present)
  const searchParams = currentParams instanceof URLSearchParams ? currentParams : new URLSearchParams(currentParams);

  const _currentParams = searchParams.toString();

  const nestedValues = flattenNestedValues(params);

  const newParams = qs.stringify(nestedValues, {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: 'bracket-separator',
  });

  if (!_currentParams && !newParams) return '';

  return `?${newParams}${_currentParams ? `&${_currentParams}` : ''}`;
};

export const getUrlWithParams = (url: string | URL, params: Record<string, unknown>, preserveExistingParams = false) => {
  const _url = url instanceof URL ? url : new URL(url);

  _url.search = stringifySearchParams(params, preserveExistingParams ? _url.search : '');

  return _url.href;
};

/**
 * Transform search params string into options and filters objects
 * @param search query string containing search params
 * @param addFallbacksForRequiredFields boolean to add fallback values for required filters and options
 * @returns filters and options objects
 */
export function parseSearchParams(
  url: string | URL,
  addFallbacksForRequiredFields?: false,
): {
  options: Partial<GetPropertiesOptions>;
  filters: Partial<GetPropertiesFilters>;
};

export function parseSearchParams(
  url: string | URL,
  addFallbacksForRequiredFields: true,
): { options: GetPropertiesOptions; filters: GetPropertiesFilters };

export function parseSearchParams(url: string | URL, addFallbacksForRequiredFields?: boolean) {
  const { language, query } = extractParamsFromUrl(url);

  const parsedQuery: Record<string, unknown> = qs.parse(query ?? '', {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: 'bracket-separator',
    types: {
      masterDataShopIds: 'string[]',
      masterDataLiPaIds: 'string[]',
      shopIds: 'string[]',
    },
  });

  const expandedParams = expandNestedValues(parsedQuery);

  let { filters, options } = parseParams({
    ...expandedParams,
    ...(language && { language }),
  });

  if (addFallbacksForRequiredFields) {
    const { filters: refinedFilters, options: refinedOptions } = refineFiltersAndOptions(filters, options);

    options = refinedOptions;
    filters = refinedFilters;
  }

  return { options, filters };
}

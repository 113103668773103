import {
  deleteAllSearchAlerts as generatedDeleteAllSearchAlerts,
  type DeleteAllSearchAlertsResult as GeneratedDeleteAllSearchAlertsResult,
} from '@generated/search-bff/controllers/search-alert/search-alert';
import { type ApiConfig, type ApiResult } from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils';

/**
 * Delete all search alerts of an Account
 * @function
 * @param {string} accountId - The unique identifier of an account to delete all search alerts.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
 *
 * @summary Delete all search alerts of an Account
 */
export async function deleteAllSearchAlerts(accountId: string, apiConfig: ApiConfig): Promise<ApiResult<void>> {
  try {
    await generatedDeleteAllSearchAlerts(accountId, apiConfig);
    return { status: 'success', data: undefined };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export type DeleteAllSearchAlertsResult = GeneratedDeleteAllSearchAlertsResult;

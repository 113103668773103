import { Language } from '@ev/search-modules-api';
import { useRef, useState } from 'react';

import { Checkbox } from '../../../spearhead/lib/components/elements/Checkbox/Checkbox';
import { ExpandableLabel } from '../../../spearhead/lib/components/elements/ExpandableLabel/ExpandableLabel';
import { useAppContext } from '../../hooks/useAppContext';
import { useSearch } from '../../hooks/useSearch';
import { StyledAlert, StyledConsentWrapper } from './SearchAlert.styled';

const closingTagRegx = /<\/\d+>/g;

export const ConsentCheckbox = () => {
  const { t } = useAppContext();
  const {
    options: { language },
  } = useSearch();
  const [isValid, setIsValid] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const updateValidity = () => {
    if (!inputRef.current) return;
    setIsValid(inputRef.current.validity.valid);
  };

  const imprintLink =
    language === Language.de ? 'https://www.engelvoelkers.com/de/de/impressum' : 'https://www.engelvoelkers.com/de/en/imprint';

  const privacyPolicyLink =
    language === Language.de ? 'https://www.engelvoelkers.com/de/de/datenschutz' : 'https://www.engelvoelkers.com/de/en/privacy-policy';

  const imprintTag = `<a href="${imprintLink}" target="_blank" rel="noopener">`;
  const privacyPolicyTag = `<a href="${privacyPolicyLink}" target="_blank" rel="noopener">`;

  const consentText = t('search.searchAlert.create.consentText')
    .replaceAll(closingTagRegx, '</a>')
    .replace('<0>', imprintTag)
    .replace('<1>', privacyPolicyTag)
    .replace('<2>', privacyPolicyTag);

  return (
    <StyledConsentWrapper>
      {!isValid && <StyledAlert variant="error">{t('search.contact.form.validation.consent.invalid')}</StyledAlert>}
      <Checkbox
        ref={inputRef}
        isRequired
        error={!isValid}
        onInvalid={updateValidity}
        onChange={updateValidity}
        aria-label={consentText}
        data-test-id="search-components_search-alert-creation-form_consent-checkbox"
        label={
          <ExpandableLabel
            maxLines={3}
            readMoreLabel={t('search.expose.propertyDetails.label.showMore')}
            readLessLabel={t('search.expose.propertyDetails.label.showLess')}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: consentText,
              }}
            />
          </ExpandableLabel>
        }
      />
    </StyledConsentWrapper>
  );
};

import {
  customInstance
} from "./chunk-PCL3B4V6.js";

// generated/search-bff/controllers/tracking-v2/tracking-v2.ts
var getTrackingByShopId = (masterDataShopId, options) => {
  return customInstance({ url: `/v2/tracking/shop/${masterDataShopId}`, method: "GET" }, options);
};
var getTrackingByGa4Id = (ga4Id, options) => {
  return customInstance({ url: `/v2/tracking/ga4/${ga4Id}`, method: "GET" }, options);
};

export {
  getTrackingByShopId,
  getTrackingByGa4Id
};

import { to } from '@pkgs/components/utils/mediaQueries';
import { styled } from 'styled-components';

export const StyledContainer = styled.div`
  background-color: var(--color-surface-light);
  box-shadow: var(--shadow-xs);
  border-radius: var(--border-radius-s);
  color: var(--color-link-pimary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(var(--distance-dynamic-m) / 2);
  @media screen and (${to.tabletPortrait}) {
    padding: calc(var(--distance-dynamic-xl) / 2);
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-l);
`;

export const StyledTitle = styled.span`
  transition: all 0.25s ease-in;
  text-align: center;
  white-space: initial;
  display: block;
  width: 100%;
  font-size: var(--font-size-static-s);
`;

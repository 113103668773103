import {
  editSearchAlert as generatedEditSearchAlert,
  type EditSearchAlertResult as GeneratedEditSearchAlertResult,
} from '@generated/search-bff/controllers/search-alert/search-alert';
import { type EditSearchAlertRequest } from '@pkgs/api/models';
import { type ApiConfig, type ApiResult } from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils';

/**
 * Edit a search alert
 * @function
 * @param {string} searchAlertId - The unique identifier of the search alert which should be updated.
 * @param {EditSearchAlertRequest} editSearchAlertRequest - Object containing the fields of a search alert that should be updated like name or intervalSeconds.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
 *
 * @summary Edit a search alert
 */
export async function editSearchAlert(
  searchAlertId: string,
  editSearchAlertRequest: EditSearchAlertRequest,
  apiConfig: ApiConfig,
): Promise<ApiResult<void>> {
  try {
    await generatedEditSearchAlert(searchAlertId, editSearchAlertRequest, apiConfig);
    return { status: 'success', data: undefined };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export type EditSearchAlertResult = GeneratedEditSearchAlertResult;

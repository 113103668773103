import {
  getSuggestionsByDisplayId
} from "./chunk-YZ4INOXI.js";
import {
  getLocationSuggestions
} from "./chunk-SSFX3AK3.js";
import {
  hasDisplayIdPrefix
} from "./chunk-EYGO4I34.js";

// src/getSuggestions/getSuggestions.ts
async function getSuggestions(query, options, apiConfig) {
  if (query === "") {
    console.warn("Input is an empty string.");
  }
  if (hasDisplayIdPrefix(query)) {
    const response = await getSuggestionsByDisplayId(query, options, apiConfig);
    return response.status === "success" ? { status: "success", data: { properties: response.data } } : response;
  } else {
    const response = await getLocationSuggestions(query, options, apiConfig);
    return response.status === "success" ? { status: "success", data: { locations: response.data } } : response;
  }
}

export {
  getSuggestions
};

import { css, styled } from 'styled-components';

import { from } from '../../utils/mediaQueries';
import { BuyRentFilter } from '../BuyRentFilter/BuyRentFilter';
import { ExtendedSearchRadiusFilter } from '../ExtendedSearchRadiusFilter/ExtendedSearchRadiusFilter';
import { ConstructionYearFilterDropdown } from '../FilterDropdowns/ConstructionYearFilterDropdown';
import { LivingSurfaceFilterDropdown } from '../FilterDropdowns/LivingSurfaceFilterDropdown';
import { PlotSurfaceFilterDropdown } from '../FilterDropdowns/PlotSurfaceFilterDropdown';
import { PriceFilterDropdown } from '../FilterDropdowns/PriceFilterDropdown';
import { RoomsFilterDropdown } from '../FilterDropdowns/RoomsFilterDropdown';
import { TotalSurfaceFilterDropdown } from '../FilterDropdowns/TotalSurfaceFilterDropdown';
import { LocationFilter } from '../LocationFilter/LocationFilter';
import { PropertyTypeFilter } from '../PropertyTypeFilter/PropertyTypeFilter';

const mobileHiddenStyles = css`
  display: none;
  @media screen and (${from.tabletLandscape}) {
    display: flex;
  }
`;

const customHiddenStyles = css`
  display: none;
  @media screen and (min-width: 1380px) {
    display: flex;
  }
`;

const dropdownsStyles = css`
  min-width: 130px;
  ${customHiddenStyles}
`;
export const StyledFilterBarContainer = styled.div`
  display: flex;
  gap: var(--distance-static-xs);
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-light-shade);
  padding: var(--distance-static-m);
  margin: auto;

  @media screen and (${from.tabletLandscape}) {
    padding: var(--distance-static-m) var(--distance-static-xxl);
  }
`;

export const StyledBuyRentFilter = styled(BuyRentFilter)`
  width: 100px;
  max-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  &&& {
    ${mobileHiddenStyles}
  }
`;

export const StyledPropertyTypeFilter = styled(PropertyTypeFilter)`
  width: 154px;
  max-width: 154px;
  flex-grow: 0;
  flex-shrink: 0;
  &&& {
    ${mobileHiddenStyles}
  }
`;
export const StyledExtendedSearchRadiusFilter = styled(ExtendedSearchRadiusFilter)`
  width: 102px;
  max-width: 102px;
  flex-grow: 0;
  flex-shrink: 0;
  &&& {
    ${mobileHiddenStyles}
  }
`;

export const StyledPriceFilter = styled(PriceFilterDropdown)`
  &&& {
    ${dropdownsStyles}
  }
`;

export const StyledLivingSurfaceFilter = styled(LivingSurfaceFilterDropdown)`
  &&& {
    ${dropdownsStyles}
  }
`;
export const StyledRoomsFilter = styled(RoomsFilterDropdown)`
  &&& {
    ${dropdownsStyles}
  }
`;

export const StyledTotalSurfaceFilter = styled(TotalSurfaceFilterDropdown)`
  &&& {
    ${dropdownsStyles}
  }
`;

export const StyledPlotSurfaceFilter = styled(PlotSurfaceFilterDropdown)`
  &&& {
    ${dropdownsStyles}
  }
`;

export const StyledConstructionYearFilter = styled(ConstructionYearFilterDropdown)`
  width: 240px;
  flex-shrink: 0;
  flex-grow: 0;
  &&& {
    ${customHiddenStyles}
  }
`;

export const StyledLocationFilter = styled(LocationFilter)`
  flex-shrink: 0;
  flex-grow: 1;
  @media screen and (${from.tabletLandscape}) {
    min-width: 300px;
  }
`;

import { type CardProps, Box } from '@mui/material';
import { type Language, Currency, MeasurementSystem } from '@pkgs/api';
import { replaceLocaleInPath } from '@pkgs/api';
import omit from 'lodash-es/omit';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useSettings } from '@/hooks/useSettings';
import { CurrencyNameEnum } from '@/types/currency';
import { mapLanguageCode } from '@/utils/settings';

import { SelectableRoundButton } from '../Button/Button';
import { type HeaderProps } from '../Header/Header';
import { type SelectItem, type SelectProps } from '../Select/Select';
import { SelectField } from '../Select/SelectField';
import { StyledCard, StyledCardContent, StyledLabel, StyledTitle } from './GlobalSettings.styles';

const LanguageSelector = (): React.ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const router = useRouter();
  const languageCode = omit(mapLanguageCode, ['default']);

  const supportedLanguages: SelectItem[] = Object.values(languageCode).map((lang) => ({
    name: t(lang.translationKey),
    value: lang.value,
  }));

  function changeLanguage(newLanguage: Language) {
    const targetUrl = replaceLocaleInPath(location.pathname + location.search, newLanguage);
    router.push(targetUrl);
  }

  return (
    <SelectField
      SelectProps={
        {
          values: supportedLanguages,
          value: language,
          onValueChange: (newLanguage) => changeLanguage(newLanguage.value as Language),
        } as SelectProps
      }
      label={supportedLanguages.find((lang) => lang.value === language)?.name ?? ''}
      dataCy="languageSelect"
    />
  );
};

const CurrencySelector = (): React.ReactElement => {
  const { currency, setSettings } = useSettings();
  const { t } = useTranslation('common');

  const currencies: SelectItem[] = [
    {
      name: Currency.EUR,
      value: Currency.EUR,
      extraInfo: t(CurrencyNameEnum.EUR),
    },
    {
      name: Currency.USD,
      value: Currency.USD,
      extraInfo: t(CurrencyNameEnum.USD),
    },
    {
      name: Currency.CAD,
      value: Currency.CAD,
      extraInfo: t(CurrencyNameEnum.CAD),
    },
    {
      name: Currency.GBP,
      value: Currency.GBP,
      extraInfo: t(CurrencyNameEnum.GBP),
    },
    {
      name: Currency.CHF,
      value: Currency.CHF,
      extraInfo: t(CurrencyNameEnum.CHF),
    },
    {
      name: Currency.AED,
      value: Currency.AED,
      extraInfo: t(CurrencyNameEnum.AED),
    },
    {
      name: Currency.CLF,
      value: Currency.CLF,
      extraInfo: t(CurrencyNameEnum.CLF),
    },
    {
      name: Currency.COP,
      value: Currency.COP,
      extraInfo: t(CurrencyNameEnum.COP),
    },
    {
      name: Currency.CZK,
      value: Currency.CZK,
      extraInfo: t(CurrencyNameEnum.CZK),
    },
    {
      name: Currency.DKK,
      value: Currency.DKK,
      extraInfo: t(CurrencyNameEnum.DKK),
    },
    {
      name: Currency.HUF,
      value: Currency.HUF,
      extraInfo: t(CurrencyNameEnum.HUF),
    },
    {
      name: Currency.MUR,
      value: Currency.MUR,
      extraInfo: t(CurrencyNameEnum.MUR),
    },
    {
      name: Currency.ZAR,
      value: Currency.ZAR,
      extraInfo: t(CurrencyNameEnum.ZAR),
    },
  ];

  return (
    <SelectField
      SelectProps={
        {
          values: currencies,
          value: currency,
          onValueChange: (newCurrency) => setSettings({ currency: newCurrency.value as Currency }),
        } as SelectProps
      }
      label={currency}
      dataCy="currencySelect"
    />
  );
};

type GlobalSettingsType = HeaderProps & {
  selectedValues?: string[];
} & CardProps;

export const GlobalSettings = ({ onLanguageChange, ...props }: GlobalSettingsType): React.ReactElement => {
  const { t } = useTranslation();
  const { setSettings, measurementSystem } = useSettings();

  return (
    <StyledCard elevation={1} {...props}>
      <StyledCardContent>
        <StyledTitle variant="h3" component="span">
          {t('search.globalSettings.settings')}
        </StyledTitle>
        <Box
          sx={{
            mb: { xs: 0, md: 1 },
          }}
        >
          <StyledLabel variant="h6" component="label">
            {t('search.globalSettings.preferredLang')}
          </StyledLabel>
        </Box>

        <LanguageSelector />

        <Box
          sx={{
            mb: { xs: 0, md: 1 },
            mt: 3,
          }}
        >
          <StyledLabel variant="h6" component="label">
            {t('search.globalSettings.preferredCurrency')}
          </StyledLabel>
        </Box>

        <CurrencySelector />

        <Box
          sx={{
            mb: 1,
            mt: 4,
          }}
        >
          <StyledLabel variant="h6">{t('search.globalSettings.preferredUnit')}</StyledLabel>
        </Box>

        <SelectableRoundButton
          selected={measurementSystem === MeasurementSystem.metric}
          onClick={() =>
            setSettings({
              measurementSystem: MeasurementSystem.metric,
            })
          }
          size="small"
          data-cy="unitMTButton"
        >
          {t('search.globalSettings.unitM')}
        </SelectableRoundButton>
        <SelectableRoundButton
          selected={measurementSystem === MeasurementSystem.imperial}
          onClick={() =>
            setSettings({
              measurementSystem: MeasurementSystem.imperial,
            })
          }
          size="small"
          data-cy="unitFTButton"
        >
          {t('search.globalSettings.unitFt')}
        </SelectableRoundButton>
      </StyledCardContent>
    </StyledCard>
  );
};

import { css, styled } from 'styled-components';

const inlineLinkStyles = css`
  position: relative;
  font-size: inherit;
  text-decoration: underline;
  text-underline-offset: var(--text-underline-offset);
  color: currentColor;
  appearance: none;

  &:focus {
    outline: var(--distance-static-3xs) solid var(--color-focus-dark);
    outline-offset: var(--distance-static-xxs);
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const StyledInlineLink = styled.a`
  ${inlineLinkStyles}
  border: 0;
  padding: 0;
  background: none;
`;

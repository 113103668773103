import { type FallbackSearch, type GetPropertiesOptions, type PlaceLocation, type PropertiesResponse } from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { type SearchProviderProps } from '@pkgs/components/providers/SearchProvider';
import { mapToFiltersWithArrayFields } from '@pkgs/components/utils/mapFilterFields';
import { sendTrackSiteSearchEvent } from '@pkgs/components/utils/tracking';
import { useQuery } from '@tanstack/react-query';

import { useAppContext } from './useAppContext';

export const PROPERTIES_QUERY_KEY = 'properties';

const DEFAULT_FALLBACK_SEARCH: Readonly<FallbackSearch> = {
  activateUnder: 10,
  propertyCount: 21,
  newRadius: 50,
};

export const RESULTS_PLACEHOLDER: Readonly<PropertiesResponse> = {
  hits: [],
  widerAreaHits: [],
  totalHits: 0,
  totalPages: 0,
};

export const useProperties = ({
  filters,
  options,
  fallbackSearch = DEFAULT_FALLBACK_SEARCH,
  initialResults,
  placeDetails,
  shopNames,
  snowPlowPageType,
}: SearchProviderProps & {
  filters: SearchModuleFilters;
  options: Required<GetPropertiesOptions>;
  placeDetails: PlaceLocation;
}) => {
  const { page, pageSize, language } = options;
  const { api } = useAppContext();

  return useQuery({
    queryKey: [PROPERTIES_QUERY_KEY, filters, page, pageSize, language, shopNames, fallbackSearch],
    queryFn: async () => {
      const _filters = { ...filters, fallbackSearch };

      const propertiesResult = await api.getProperties(mapToFiltersWithArrayFields(_filters), options, true);

      if (propertiesResult.status === 'error') {
        throw propertiesResult.error;
      }

      sendTrackSiteSearchEvent({
        results: propertiesResult.data,
        filters: _filters,
        options,
        shopNames: shopNames ?? [],
        placeName: placeDetails.name,
        pageType: snowPlowPageType,
      });

      return propertiesResult.data;
    },
    initialData: initialResults,
    refetchOnMount: !initialResults,
  });
};

import { css, styled } from 'styled-components';

import { skeletonAnimation } from '../Skeleton/Skeleton.styles';

export const CarouselImageSkeletonStyled = styled.div<{ $isVisible: boolean }>`
  inset: 0;
  background-color: var(--color-desert-storm);
  ${skeletonAnimation}
  position: absolute;
  ${({ $isVisible }) => css`
    display: ${$isVisible ? 'block' : 'none'};
    opacity: ${$isVisible ? 1 : 0};
  `};
  transition:
    opacity 0.3s ease,
    display 0.3s ease;
  transition-behavior: allow-discrete;
`;

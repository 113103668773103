import { useContext } from 'react';

import { WatchlistContext, type WatchlistContextType } from '../providers/WatchlistProvider';

export const useWatchlist = (): WatchlistContextType => {
  const ctx = useContext(WatchlistContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (ctx === undefined) {
    throw new Error('useWatchlist used outside of ContextProvider!');
  } else {
    return ctx;
  }
};

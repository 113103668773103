import { type Currency, type Language, type Property } from '@ev/search-modules-api';

import { formatPriceWithDecimals, formatPriceWithoutDecimals } from './numberFormatters';

export const getDevelopmentPrice = (property: Partial<Property>, language: Language, currency: Currency) => {
  const projectAggregate = property.project?.aggregate;
  return {
    value: formatPriceWithoutDecimals(projectAggregate?.prices?.[currency]?.minSalesPrice || 0, language, currency),
    labelTranslationKey: 'search.price.label.development.price',
  };
};

export const getSalePrice = (property: Partial<Property>, language: Language, currency: Currency) => {
  const prices = property.prices?.[currency];
  return {
    value: formatPriceWithoutDecimals(prices?.salesPrice || 0, language, currency),
  };
};

export const getRentPrice = (property: Partial<Property>, language: Language, currency: Currency) => {
  const prices = property.prices?.[currency];
  const rentTotal = formatPriceWithoutDecimals(prices?.rentTotal || 0, language, currency);
  const rentNetPerSquareMeter = formatPriceWithDecimals(prices?.rentNetPerSquareMeter || 0, language, currency);
  const rentNet = formatPriceWithoutDecimals(prices?.rentNet || 0, language, currency);

  const utilitiesTotal = formatPriceWithDecimals(prices?.rentUtilities || 0, language, currency);

  const rentalPropertyProps = {
    value: rentTotal,
    rentNetValue: rentNet,
    utilitiesValue: utilitiesTotal,
    labelTranslationKey: 'search.price.label.rentTotal',
  };

  if (property.countryAlpha2 === 'DE' && rentTotal && rentNet && utilitiesTotal) {
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rentTotal';
  }

  if (property.countryAlpha2 === 'DE' && !utilitiesTotal && !rentTotal) {
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rentNet';
  }

  // other countries, this was originally here before the Germany-specific work was started but these translations need to be checked again
  if (!rentTotal && rentNetPerSquareMeter) {
    rentalPropertyProps.value = rentNetPerSquareMeter;
    rentalPropertyProps.rentNetValue = '';
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rentNetPerSquareMeter';
  }

  if (!rentTotal && !rentNetPerSquareMeter && rentNet) {
    rentalPropertyProps.value = rentNet;
    rentalPropertyProps.rentNetValue = '';
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rentNet';
  }

  if (property.priceOnRequest || (!rentNet && !rentTotal && !rentNetPerSquareMeter)) {
    rentalPropertyProps.value = '';
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rent';
  }

  return rentalPropertyProps;
};

export const getParsedShopName = (shopName?: string): string => {
  if (!shopName) {
    return '';
  }
  const regexName = /Engel & Völkers/g;
  const replacedShopName = shopName.replaceAll(regexName, '');

  return replacedShopName;
};

import { type ReactElement, type SVGProps } from 'react';

export const CrossNaked = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.9996 12.7055L18.2961 18.9988L19.0032 18.292L12.7068 11.9988L19.0032 5.70553L18.2961 4.99878L11.9996 11.292L5.7032 4.99878L4.99609 5.70553L11.2925 11.9988L4.99609 18.292L5.7032 18.9988L11.9996 12.7055Z"
        fill="currentColor"
      />
    </svg>
  );
};

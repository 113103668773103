import { type ExposePath } from '@pkgs/api';
import { type SearchModuleFilters } from '@pkgs/components';
import type { TFunction } from 'i18next';
import HeadNext from 'next/head';
import { useTranslation } from 'next-i18next';
import React from 'react';

export enum IndexFollowEnum {
  INDEXFOLLOW = 'index,follow',
  NOINDEXNOFOLLOW = 'noindex,nofollow',
  NOINDEXFOLLOW = 'noindex,follow',
}

type HeadType = {
  title: string;
  description?: string;
  index: IndexFollowEnum;
  imageUrl?: string;
  paths?: ExposePath[];
  canonicalPath?: string;
};

// TODO: this functionaity should be moved inside the component directly
export const metaDescriptionsIndex = (
  t: TFunction<'common'>,
  filters: SearchModuleFilters,
  placeName?: string
): Record<string, string> & { defaultValue: string } => {
  const propertyType = filters.propertyType;
  const propertyMarketingType = filters.propertyMarketingType;

  return {
    city: placeName ? `${placeName}, ` : '',
    propertyType: propertyType
      ? `<span class='ev-headline-search-filter'>${t(`search.searchbar.advFilter.propertyType.${propertyType}`)}</span>, `
      : '',
    marketingType: `<span class='ev-headline-search-filter'>${t(`search.searchbar.${propertyMarketingType}`)}</span>`,
    defaultValue: '',
  };
};

export const Head = ({ title, description, index, imageUrl, paths, canonicalPath }: HeadType): React.ReactElement => {
  // TODO: check if we can replace this with our useSettings hook
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <HeadNext>
      <title>{title}</title>
      {Boolean(description) && <meta name="description" content={description} />}
      <meta property="og:title" content={title} />
      <meta property="og:locale" content={language} />
      <meta property="og:site_name" content="Engel &amp; Völkers" />
      {Boolean(description) && <meta property="og:description" content={description} />}
      {Boolean(imageUrl) && <meta property="og:image" content={imageUrl} />}
      <meta name="robots" content={index} />
      {!!canonicalPath && <link rel="canonical" href={`https://www.engelvoelkers.com${canonicalPath}`} />}
      {paths?.map((path) => {
        return <link key={path.path} rel="alternate" hrefLang={path.language} href={`https://www.engelvoelkers.com${path.path}`} />;
      })}
    </HeadNext>
  );
};

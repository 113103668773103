/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { ClickAwayListener, Typography, useMediaQuery, useTheme } from '@mui/material';
import { type ParseKeys } from 'i18next';
import { Trans, useTranslation } from 'next-i18next';
import { type ReactElement, useState } from 'react';

import { type MenuItem } from './headerConfig';
import {
  StyledContainer,
  StyledHeaderNavigationLink,
  StyledHeaderNavigationTitle,
  StyledLink,
  StyledMenu,
  StyledMenuItem,
  StyledNavigationItemContainer,
} from './HeaderNavigation.styles';

type HeaderNavigationItemType = {
  id: number;
  title: ReactElement | string;
  url?: string;
  content?: ReactElement;
};

export const HeaderNavigationItemOptions = ({ items }: { items: MenuItem[] }): ReactElement => {
  const { t } = useTranslation('header_footer');

  return (
    <>
      {items.map((item) => {
        if (!t(item.labelKey) || !t(item.urlKey as ParseKeys<'header_footer'>)) {
          return null;
        }
        return (
          <StyledMenuItem key={item.id}>
            <StyledLink href={t(item.urlKey as ParseKeys<'header_footer'>)}>
              <Trans
                i18nKey={item.labelKey}
                components={[
                  <Typography
                    key={1}
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      marginLeft: 0.5,
                      display: 'inline',
                    }}
                  />,
                ]}
                ns="header_footer"
              />
            </StyledLink>
          </StyledMenuItem>
        );
      })}
    </>
  );
};

const HeaderNavigationItem = ({ title, url, content }: HeaderNavigationItemType) => {
  const theme = useTheme();
  const isMobileTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {title && url ? (
        <StyledNavigationItemContainer>
          <StyledHeaderNavigationLink href={url}>{title}</StyledHeaderNavigationLink>
        </StyledNavigationItemContainer>
      ) : (
        <ClickAwayListener onClickAway={handleClose}>
          <StyledNavigationItemContainer>
            <StyledHeaderNavigationTitle isToggled={open} onClick={(e: React.MouseEvent<HTMLButtonElement>) => content && handleClick(e)}>
              {title}
            </StyledHeaderNavigationTitle>
            {!isMobileTabletScreen ? (
              <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {content}
              </StyledMenu>
            ) : (
              <>{open && <ul>{content}</ul>}</>
            )}
          </StyledNavigationItemContainer>
        </ClickAwayListener>
      )}
    </>
  );
};

export const HeaderNavigation = ({ items }: { items: HeaderNavigationItemType[] }): ReactElement => {
  return (
    <StyledContainer>
      {items.map((item) => (
        <HeaderNavigationItem key={item.id} {...item} />
      ))}
    </StyledContainer>
  );
};

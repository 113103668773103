import { type ApiConfig, type Environment, SearchApi } from '@pkgs/api';
import * as Sentry from '@sentry/nextjs';

export const SEARCH_API_CONFIG: ApiConfig = {
  applicationKey: process.env.NEXT_PUBLIC_APPLICATION_KEY_FRONTEND!,
  environment: (process.env.NEXT_PUBLIC_BFF_ENV ?? 'prod') as Environment,
  axiosConfig: {
    ...(process.env.NEXT_PUBLIC_USE_BFF_PROXY === 'true' && {
      baseURL: 'http://localhost:3000/api/bff-proxy',
    }),
  },
  onError: Sentry.captureException,
};

export const SearchApiClient = new SearchApi(SEARCH_API_CONFIG);

import { css, styled } from 'styled-components';

import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';
import { IconButton } from '../../../spearhead/lib/components/elements/IconButton/IconButton';
import { from, to } from '../../utils/mediaQueries';

type IPaginationButtonProps = {
  $isSelected?: boolean;
  $length?: number;
};

const notSelectedStyles = css<IPaginationButtonProps>`
  color: var(--color-dark);
  background-color: transparent;
  border-radius: var(--border-radius-m);
  border: none;
  &:hover {
    background-color: var(--color-light-shade);
  }
  &:focus {
    outline: var(--distance-static-3xs) solid var(--color-dark-subdued);
  }
  &:active {
    background-color: var(--color-dark-decent);
    border: none;
    outline: none;
  }
`;

const selectedStyles = css<IPaginationButtonProps>`
  color: var(--color-light);
  background-color: var(--color-dark);
  border: var(--color-dark);
  outline: none;
  border-radius: var(--border-radius-m);
`;

const largePaginationNumberStyles = css`
  padding-left: var(--distance-static-xxs);
  padding-right: var(--distance-static-xxs);
`;

export const StyledPaginationWrapper = styled.ul`
  display: flex;
  align-items: center;
  gap: var(--distance-static-xxs);
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledEllipsis = styled(Copy)`
  @media screen and (${to.tabletLandscape}) {
    width: var(--distance-static-l);
  }
  @media screen and (${from.tabletLandscape}) {
    width: 28px;
  }
  text-align: center;
  padding-bottom: var(--distance-static-xxs);
`;

export const StyledPaginationNumberButton = styled.button<IPaginationButtonProps>`
  font-family: var(--font-family-text), Arial, Helvetica, sans-serif;
  cursor: pointer;
  @media screen and (${to.tabletLandscape}) {
    ${({ $length }) =>
      $length && $length <= 2
        ? css`
            width: var(--distance-static-l);
            padding: 0;
          `
        : largePaginationNumberStyles}
    height: var(--distance-static-l);
  }
  @media screen and (${from.tabletLandscape}) {
    ${({ $length }) =>
      $length && $length <= 2
        ? css`
            width: 28px;
            padding: 0;
          `
        : largePaginationNumberStyles}
    height: 28px;
  }
  ${({ $isSelected }) => ($isSelected ? selectedStyles : notSelectedStyles)}
`;

export const StyledPaginationArrowButton = styled(IconButton)`
  &:disabled {
    background-color: transparent;
    cursor: auto;
    color: var(--color-dark-lighter);
    &:hover {
      background-color: transparent;
    }
  }
`;

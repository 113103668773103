import {
  type CountryCode,
  type CreateSearchAlertRequestV3,
  type Currency,
  getErrorResult,
  type Language,
  type MeasurementSystem,
} from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { mapToFiltersWithArrayFields } from '@pkgs/components/utils/mapFilterFields';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { getSecondsByFrequency } from '../utils/searchAlertUtils';

export enum SearchAlertFrequencyEnum {
  INSTANTLY = 'instantly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

type CreateAlertParams = {
  email: string;
  name: string;
  frequency: SearchAlertFrequencyEnum;
  newsletterConsent: boolean;
  filterDescriptionText: string;
  language: Language;
  filters: SearchModuleFilters;
  friendlyCaptchaSolution: string;
  countryCode?: CountryCode;
  placeName: string;
  currency: Currency;
  measurementSystem: MeasurementSystem;
};

type CreateSearchAlertResponse = {
  emailId?: string | null;
  searchAlertId?: string | null;
};

type CreateSearchAlertSuccess = {
  status: 'success';
  data: CreateSearchAlertResponse;
};

type CreateSearchAlertResultFailure = {
  status: 'error';
  message?: string;
};

type CreateSearchAlertResult = CreateSearchAlertSuccess | CreateSearchAlertResultFailure;

const createSearchAlert = async (
  {
    email,
    name,
    frequency,
    newsletterConsent,
    filterDescriptionText,
    language,
    filters,
    friendlyCaptchaSolution,
    countryCode,
    placeName,
    currency,
    measurementSystem,
  }: CreateAlertParams,
  newSearchUrl: string,
  vercelProtectionBypassToken?: string,
): Promise<CreateSearchAlertResult> => {
  const body: {
    searchAlert: Omit<CreateSearchAlertRequestV3['searchAlert'], 'consentText'>;
    wantsNewsLetter: CreateSearchAlertRequestV3['wantsNewsLetter'];
  } = {
    searchAlert: {
      searchFilter: mapToFiltersWithArrayFields(filters),
      email,
      language,
      currency,
      name,
      intervalSeconds: getSecondsByFrequency(frequency),
      filterDescriptionText,
      measurementSystem,
      placeName,
      marketCountryCode: countryCode,
    },
    wantsNewsLetter: newsletterConsent,
  };

  const response = await axios.post(`${newSearchUrl}search-alert/create`, body, {
    headers: {
      Authorization: `Bearer ${friendlyCaptchaSolution}`,
      'x-vercel-protection-bypass': vercelProtectionBypassToken ?? '',
      tokentype: 'friendlycaptcha',
    },
  });

  return response.data;
};

export const useSearchAlert = () => {
  const { newSearchUrl, vercelProtectionBypassToken } = useAppContext();

  return useMutation<CreateSearchAlertResult, Error, CreateAlertParams>({
    mutationFn: async (createAlertParams: CreateAlertParams) =>
      createSearchAlert(createAlertParams, newSearchUrl, vercelProtectionBypassToken),
    onError: (error) => getErrorResult(error),
  });
};

import { styled } from 'styled-components';

export const StyledFrequencyPicker = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-xs);
`;

export const StyledRadioGroup = styled.div`
  display: flex;
  gap: var(--distance-static-xs);
`;

export const StyledInput = styled.input`
  display: none;
`;

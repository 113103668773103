import { type Property } from '@ev/search-modules-api';
import { createContext, type PropsWithChildren, useCallback, useMemo } from 'react';

export interface WatchlistItem {
  id: string;
  addedOn: number;
}

export type WatchlistContextType = {
  watchlist: WatchlistItem[];
  checkIsInWatchlist: (propertyId: string) => boolean;
  onWatchlistClick: (property: Property) => void;
};

export const WatchlistContext = createContext<WatchlistContextType>({
  watchlist: [],
  checkIsInWatchlist: () => false,
  onWatchlistClick: () => {},
});

type WatchlistProviderProps = PropsWithChildren<{
  watchlist?: WatchlistItem[];
  onWatchlistClick?: (property: Property) => void;
}>;

export const WatchlistProvider = ({ children, onWatchlistClick = () => {}, watchlist = [] }: WatchlistProviderProps) => {
  const checkIsInWatchlist = useCallback((propertyId: string) => watchlist.some(({ id }) => id === propertyId), [watchlist]);

  const value = useMemo(
    () => ({
      watchlist,
      checkIsInWatchlist,
      onWatchlistClick,
    }),
    [watchlist, checkIsInWatchlist, onWatchlistClick],
  );
  return <WatchlistContext.Provider value={value}>{children}</WatchlistContext.Provider>;
};

import { Currency, Language, MeasurementSystem } from '@pkgs/api';
import { type ParseKeys } from 'i18next';

export const mapLanguageCode: Record<
  Language | 'cimode',
  {
    code: Language;
    translationKey: ParseKeys<'common'>;
    value: Language;
  }
> = {
  [Language.de]: {
    code: Language.de,
    translationKey: 'search.globalSettings.languages.german',
    value: Language.de,
  },
  [Language.en]: {
    code: Language.en,
    translationKey: 'search.globalSettings.languages.english',
    value: Language.en,
  },
  [Language.fr]: {
    code: Language.fr,
    translationKey: 'search.globalSettings.languages.french',
    value: Language.fr,
  },
  [Language.it]: {
    code: Language.it,
    translationKey: 'search.globalSettings.languages.italian',
    value: Language.it,
  },
  [Language.es]: {
    code: Language.es,
    translationKey: 'search.globalSettings.languages.spanish',
    value: Language.es,
  },
  [Language.el]: {
    code: Language.el,
    translationKey: 'search.globalSettings.languages.greek',
    value: Language.el,
  },
  [Language.da]: {
    code: Language.da,
    translationKey: 'search.globalSettings.languages.danish',
    value: Language.da,
  },
  [Language.pt]: {
    code: Language.pt,
    translationKey: 'search.globalSettings.languages.portuguese',
    value: Language.pt,
  },
  [Language.ca]: {
    code: Language.ca,
    translationKey: 'search.globalSettings.languages.catalan',
    value: Language.ca,
  },
  [Language.ru]: {
    code: Language.ru,
    translationKey: 'search.globalSettings.languages.russian',
    value: Language.ru,
  },
  [Language.cs]: {
    code: Language.cs,
    translationKey: 'search.globalSettings.languages.czech',
    value: Language.cs,
  },
  [Language.nl]: {
    code: Language.nl,
    translationKey: 'search.globalSettings.languages.dutch',
    value: Language.nl,
  },
  [Language.hu]: {
    code: Language.hu,
    translationKey: 'search.globalSettings.languages.hungarian',
    value: Language.hu,
  },
  ['cimode']: {
    code: Language.en,
    translationKey: 'search.globalSettings.languages.english',
    value: Language.en,
  },
};

enum UnitSymbolEnum {
  MT = 'm²',
  FT = 'sq ft',
}

export const mapMeasurementSymbol: Record<MeasurementSystem, UnitSymbolEnum> = {
  [MeasurementSystem.metric]: UnitSymbolEnum.MT,
  [MeasurementSystem.imperial]: UnitSymbolEnum.FT,
};

export const mapLanguageTranslation: Record<Language, string> = {
  [Language.ca]: 'language.catalan',
  [Language.cs]: 'language.czech',
  [Language.da]: 'language.danish',
  [Language.de]: 'language.german',
  [Language.en]: 'language.english',
  [Language.el]: 'language.greek',
  [Language.es]: 'language.spanish',
  [Language.fr]: 'language.french',
  [Language.it]: 'language.italian',
  [Language.pt]: 'language.portuguese',
  [Language.ru]: 'language.russian',
  [Language.nl]: 'language.dutch',
  [Language.hu]: 'language.hungarian',
};

export const defaultSettingsByLang: Record<Language, { currency: Currency; measurementSystem: MeasurementSystem }> = {
  [Language.de]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.en]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.fr]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.it]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.es]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.el]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.da]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.pt]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.ca]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.cs]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.ru]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.nl]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
  [Language.hu]: {
    currency: Currency.EUR,
    measurementSystem: MeasurementSystem.metric,
  },
};

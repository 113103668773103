/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type PropertyType = (typeof PropertyType)[keyof typeof PropertyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyType = {
  apartment: 'apartment',
  agriculture_and_forestry: 'agriculture_and_forestry',
  commercial_space: 'commercial_space',
  group: 'group',
  gastronomy: 'gastronomy',
  house: 'house',
  hotel: 'hotel',
  industry: 'industry',
  investment: 'investment',
  land: 'land',
  motor: 'motor',
  office: 'office',
  other: 'other',
  retail_services: 'retail services',
  sail: 'sail',
  store: 'store',
  '': '',
} as const;

import { css, keyframes, styled } from 'styled-components';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const skeletonAnimation = css`
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.4) 20%,
      rgba(255, 255, 255, 0.8) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${shimmer} 2.5s infinite;
  }
`;

export const SkeletonContainer = styled.span`
  display: block;
  height: auto;
  width: fit-content;
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--color-desert-storm);

  > * {
    visibility: hidden;
  }

  ${skeletonAnimation}
`;

import { listPropertiesForMap, type SearchGeoPointResponse } from '@generated/search-bff';
import { type ApiConfig, type BaseOptions } from '@pkgs/api/types';
import { type ApiResult, type GetPropertiesFilters } from '@pkgs/api/types';
import { mapFilters } from '@pkgs/api/utils';
import { getErrorResult } from '@pkgs/api/utils/error';

/**
 * Fetch properties coordinates based on provided filters.
 * @function
 * @param {GetPropertiesFilters} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
 * @param {BaseOptions} options - Options for price & surface filters: language, currency, measurement system.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<SearchGeoPointResponse[]>>} A promise that resolves to an array of geopoint objects.
 *
 * @summary Fetch properties coordinates based on provided filters.
 */

export async function getPropertiesCoordinates(
  filters: GetPropertiesFilters,
  options: BaseOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<SearchGeoPointResponse>> {
  try {
    const data = await listPropertiesForMap(mapFilters(filters, options), apiConfig);

    return {
      status: 'success',
      data,
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

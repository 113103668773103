import { Grid, styled, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { getBusinessTypeColumnOneLinks, getBusinessTypeColumnTwoLinks, getEvLinks, getSellerLinks } from '../common';

type columnTitle = string;

const StyledTitle = styled(Typography)({
  fontWeight: 700,
});

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const FooterMobileNavLinks = (): React.ReactElement => {
  const { t } = useTranslation('header_footer');
  const evLinks: Array<React.ReactNode> = getEvLinks();

  const businessColumnTitle: columnTitle = t('business_type_column.title');
  const businessColumnOneLinks: Array<React.ReactNode> = getBusinessTypeColumnOneLinks();
  const businessColumnTwoLinks: Array<React.ReactNode> = getBusinessTypeColumnTwoLinks();
  const businessColumnTabletLinks = businessColumnOneLinks.concat(businessColumnTwoLinks);
  const sellerColumnTitle: columnTitle = t('seller_column.title');
  const sellerColumnLinks: Array<React.ReactNode> = getSellerLinks();
  const evColumnTitle: columnTitle = t('ev_column.title');

  return (
    <Grid direction="column" container>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <StyledTitle variant="body2">{evColumnTitle}</StyledTitle>
        {evLinks}
      </Grid>

      <StyledGrid container direction="column" justifyContent="center" alignItems="center">
        <StyledTitle variant="body2">{businessColumnTitle}</StyledTitle>
        {businessColumnTabletLinks}
      </StyledGrid>
      <StyledGrid container direction="column" justifyContent="center" alignItems="center">
        <StyledTitle variant="body2">{sellerColumnTitle}</StyledTitle>
        {sellerColumnLinks}
      </StyledGrid>
    </Grid>
  );
};

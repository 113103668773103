import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ArrowRightIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path fill="currentColor" fillRule="evenodd" d="M15.549 12 8 4.714 8.711 4 17 12l-8.289 8L8 19.285 15.549 12Z" clipRule="evenodd" />
    </SvgIcon>
  );
};

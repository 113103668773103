import { css, styled } from 'styled-components';

import { IconButton } from '../../../spearhead/lib/components/elements/IconButton/IconButton';
import { from, to } from '../../utils/mediaQueries';

export const StyledModal = styled.div<{
  $fullScreen?: boolean;
}>`
  ${({ $fullScreen }) =>
    $fullScreen
      ? css`
          position: fixed;
          inset: 0;
          max-width: unset;
        `
      : css`
          position: relative;
          width: 100%;

          @media screen and (${from.tabletLandscape}) {
            width: calc(100% - var(--distance-static-m) * 2);
            border-radius: var(--border-radius-s);
            max-height: calc(100% - var(--distance-static-l) * 2);
            box-shadow: var(--shadow-l);
          }
        `}
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background: var(--color-surface-light);

  @media screen and (${to.tabletLandscape}) {
    position: fixed;
    inset: 0;
  }
`;

export const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const StyledTopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: var(--distance-static-xs);
  box-sizing: border-box;
`;

export const StyledCloseButton = styled(IconButton)`
  cursor: pointer;
`;

export const StyledContent = styled.div<{
  $fullScreen?: boolean;
}>`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

import styled, { css } from 'styled-components';
import { focusStyleDark, focusStyleLight } from '../utils/focusStyle';

export const baseStyles = css`
  box-sizing: border-box;
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  gap: var(--distance-static-xs);
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-s);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-static-m);
  line-height: var(--line-height-static-l);
  text-align: center;
  cursor: pointer;
`;

const variants = {
  primary: css`
    background: var(--color-cta-bg);
    color: var(--color-cta-text);

    ${focusStyleDark};

    &:hover {
      background: var(--color-cta-hover);
      box-shadow: var(--shadow-xs);
    }

    &:active {
      background: var(--color-cta-active);
    }
  `,
  secondary: css`
    background: var(--color-interaction-bg);
    color: var(--color-interaction-text);

    ${focusStyleDark};

    &:hover {
      background: var(--color-interaction-hover);
      box-shadow: var(--shadow-xs);
    }

    &:active {
      background: var(--color-interaction-active);
    }
  `,
  secondaryAlt: css`
    background: var(--color-interaction-inverted-bg);
    color: var(--color-interaction-inverted-text);

    ${focusStyleLight};

    &:hover {
      background: var(--color-interaction-inverted-hover);
      box-shadow: var(--shadow-xs);
    }

    &:active {
      background: var(--color-interaction-inverted-active);
    }
  `,
  tertiary: css`
    border: 1px solid var(--color-outlined-button-border);
    background: var(--color-outlined-button-bg);
    color: var(--color-outlined-button-text);

    ${focusStyleDark};

    &:hover {
      border-color: var(--color-outlined-button-hover);
      background: var(--color-outlined-button-hover);
      color: var(--color-outlined-button-text-hover);
    }

    &:active {
      border-color: var(--color-outlined-button-active);
      background: var(--color-outlined-button-active);
      color: var(--color-outlined-button-text-active);
    }
  `,
  tertiaryAlt: css`
    border: 1px solid var(--color-outlined-button-inverted-border);
    background: var(--color-outlined-button-inverted-bg);
    color: var(--color-outlined-button-inverted-text);

    ${focusStyleLight};

    &:hover {
      border-color: var(--color-outlined-button-inverted-hover);
      background: var(--color-outlined-button-inverted-hover);
      color: var(--color-outlined-button-inverted-text-hover);
    }

    &:active {
      border-color: var(--color-outlined-button-inverted-active);
      background: var(--color-outlined-button-inverted-active);
      color: var(--color-outlined-button-inverted-text-active);
    }
  `,
} as const;

export type ButtonVariant = keyof typeof variants;

// different left / right padding, depending on where the icon is positioned
export const sizes = {
  s: {
    // fixed values to get 40px height
    left: css`
      padding: 5px var(--distance-static-l) 5px var(--distance-static-m);
    `,
    right: css`
      padding: 5px var(--distance-static-m) 5px var(--distance-static-l);
    `,
    withoutIcon: css`
      padding: 5px var(--distance-static-l);
    `,
  },
  m: {
    // fixed values to get 48px height
    left: css`
      padding: 9px var(--distance-static-l) 9px var(--distance-static-m);
    `,
    right: css`
      padding: 9px var(--distance-static-m) 9px var(--distance-static-l);
    `,
    withoutIcon: css`
      padding: 9px var(--distance-static-l);
    `,
  },
};

export type ButtonSizes = keyof typeof sizes;

type ButtonAs = 'button' | 'a';

interface IStyledButtonProps {
  $variant: ButtonVariant;
  $size: ButtonSizes;
  as?: ButtonAs;
  $iconPosition?: 'left' | 'right' | 'withoutIcon';
}

export const StyledButton = styled.button<IStyledButtonProps>`
  box-sizing: border-box;
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  gap: var(--distance-static-xs);
  align-items: center;
  justify-content: center;
  border: 0;
  width: auto;
  border-radius: var(--border-radius-s);
  font-family: var(--font-family-text), Arial, Helvetica, sans-serif;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-static-m);
  line-height: var(--line-height-static-l);
  text-align: center;
  cursor: pointer;

  ${({ $size, $iconPosition = 'withoutIcon' }) => sizes[$size][$iconPosition]}

  ${({ as }) =>
    as === 'a' &&
    css`
      text-decoration: none;
    `}

  ${({ $variant }) => variants[$variant]}

  &:disabled {
    background: var(--color-interaction-disabled-bg);
    color: var(--color-interaction-disabled-text);
    box-shadow: none;
    border-color: transparent;
  }
`;

import { css, styled } from 'styled-components';

export type OverlineTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'div' | 'span' | 'address' | 'li' | 'cite' | 'dt' | 'dd';

interface StyledOverlineProps {
  $light?: boolean;
  $stroke?: boolean;
}

const StyledOverline = styled.p<StyledOverlineProps>`
  margin: 0;
  font-size: var(--font-size-static-xs);
  line-height: var(--line-height-static-s);
  letter-spacing: var(--letter-spacing-s);
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;

  ${({ $light }) =>
    $light
      ? css`
          color: var(--color-text-light);
        `
      : css`
          color: var(--color-text-dark-subdued);
        `}

  ${({ $stroke }) =>
    $stroke &&
    css`
      display: grid;
      gap: var(--distance-static-l);
      grid-template-columns: 1fr auto 1fr;
      align-items: center;

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--color-dark-decent);
      }
    `}
`;

export interface OverlineProps {
  className?: string;
  text: string;
  as?: OverlineTagName;
  light?: boolean;
  stroke?: boolean;
}

export const Overline = ({ className, as = 'p', text, light, stroke }: OverlineProps) => (
  <StyledOverline className={className} forwardedAs={as} $light={light} $stroke={stroke}>
    <span>{text}</span>
  </StyledOverline>
);

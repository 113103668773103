import { useContext } from 'react';

import { AppContext, type AppContextProviderType } from '../providers/AppContextProvider';

export const useAppContext = (): AppContextProviderType => {
  const ctx = useContext(AppContext);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (ctx === undefined) {
    throw new Error('useAppContext used outside of ContextProvider!');
  } else {
    return ctx;
  }
};

import { type Property } from '@ev/search-modules-api';
import React, { useCallback, useEffect, useState } from 'react';

import { type IconButtonSizes, type IconButtonVariant } from '../../../spearhead/lib/components/elements/IconButton/IconButton.styled';
import { useAppContext } from '../../hooks/useAppContext';
import { StyledHeartIcon, StyledIconButton } from './FavoriteButton.styled';

type FavoriteButtonProps = {
  className?: string;
  property: Property;
  showLabel?: boolean;
  size?: IconButtonSizes;
  iconButtonVariant?: IconButtonVariant;
  variant?: 'outlined' | 'filled';
  isInWatchlist: boolean;
  onWatchlistClick?: (property: Property) => void;
};

export const FavoriteButton = ({
  className,
  property,
  showLabel,
  size = 'small',
  iconButtonVariant = 'tertiary',
  variant = 'outlined',
  isInWatchlist,
  onWatchlistClick,
}: FavoriteButtonProps) => {
  const { t } = useAppContext();
  const [_isInWatchlist, setIsInWatchlist] = useState(false);

  // set watchlist state on mount to prevent hydration mismatch
  useEffect(() => {
    setIsInWatchlist(isInWatchlist);
  }, [isInWatchlist]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      // prevent the click event from bubbling up to the parent element
      event.preventDefault();
      event.stopPropagation();
      onWatchlistClick?.(property);
    },
    [onWatchlistClick, property],
  );

  return (
    <StyledIconButton
      className={className}
      showLabel={showLabel}
      icon={<StyledHeartIcon $isInWatchlist={_isInWatchlist} $variant={variant} />}
      label={_isInWatchlist ? t('search.watchlist.remove') : t('search.watchlist.add')}
      onClick={handleClick}
      size={size}
      variant={iconButtonVariant}
    />
  );
};

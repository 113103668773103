import { css, styled } from 'styled-components';

export const StyledLabel = styled.label<{ $required?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: var(--distance-static-xxs);
  color: var(--color-input-label);
  font-size: var(--font-size-static-s);
  line-height: var(--line-height-static-s);

  ${({ $required }) =>
    $required &&
    css`
      &::before {
        align-self: flex-start;
        content: '*';
        color: var(--color-input-text-error);
      }
    `};
`;

import { Headline } from '@pkgs/components/components/Headline/Headline';
import { to } from '@pkgs/components/utils/mediaQueries';
import { styled } from 'styled-components';

export const StyledHeadline = styled(Headline)`
  color: var(--color-text-light);
  text-align: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  padding: var(--distance-dynamic-xl);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  gap: var(--distance-dynamic-xxs);
  @media screen and (${to.tabletLandscape}) {
    padding: var(--distance-dynamic-s);
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 90%);
  }
`;

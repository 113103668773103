import { memo, useEffect } from 'react';

import { StyledListItem } from './AutoCompletePopover.styled';

type ListItemProps = {
  isFocused: boolean;
  children: JSX.Element;
  setIsFocused: (isFocused: boolean) => void;
};

export const ListItem = memo(({ isFocused, children, setIsFocused }: ListItemProps) => {
  useEffect(() => {
    setIsFocused(isFocused);
  }, [isFocused, setIsFocused]);

  return <StyledListItem $isFocused={isFocused}>{children}</StyledListItem>;
});

ListItem.displayName = 'ListItem';

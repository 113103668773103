import { type CountryCode, type Property, Language, PropertyMarketingType, PropertyType } from '@pkgs/api';
import { type ParseKeys, type TFunction } from 'i18next';

import { EU_COUNTRIES } from '@/consts/energyClasses';
import { FINANCING_ENABLED_COUNTRIES } from '@/consts/financing';
import { SEO_INDEXED_COUNTRIES } from '@/consts/seo';
import { Country } from '@/enums/country';
import { type TransformedSurfaceFieldsType } from '@/hooks/useConvertedSurfaceFields';

import { mapLanguageTranslation } from './settings';
import { formatIntlList } from './stringUtils';

const PLACEID_SEPARATOR = '.';

export const getLocalityPlaceId = (placeIds: string[]) => {
  const localityPlaceId = placeIds.find((placeId) => placeId.includes('locality.'));
  if (!localityPlaceId) {
    // fallback to the highest administrative area in case there is no locality
    // Google Maps admin area levels go from 1 to 7, so a simple sort should do the trick to get the highest level
    const orderedAdminAreas = placeIds
      .filter((placeIds) => placeIds.includes('administrative_area_level_'))
      .sort()
      .reverse();
    const highestAdminArea = orderedAdminAreas[0];
    return highestAdminArea ? highestAdminArea.split(PLACEID_SEPARATOR)[1] : '';
  }
  return localityPlaceId.split(PLACEID_SEPARATOR)[1];
};

export const getOfficeNameForLabels = (officeName?: string, shopName?: string) => {
  return officeName ? officeName : shopName ? getParsedShopName(shopName) : 'Engel & Völkers';
};

export const getCourtagePassusTitleTranslationKey = (property: Property) =>
  property.countryAlpha2 === Country.DE && property.propertyMarketingType === PropertyMarketingType.rent
    ? 'search.expose.propertyDetails.label.courtagePassusRentalGermanyProperty'
    : 'search.expose.propertyDetails.label.courtagePassus';

export const getParsedShopName = (shopName: string): string => {
  const regexName = /Engel & Völkers/g;
  const replacedShopName = shopName.replace(regexName, '');

  return replacedShopName;
};

export const getPlaceIdForLocationContext = (placeIds: string[]) => {
  const neighborhoodPlaceId = placeIds.find((placeId) => placeId.includes('neighborhood'));
  if (neighborhoodPlaceId) {
    return neighborhoodPlaceId.split(PLACEID_SEPARATOR)[1];
  }
  // Google Maps sublocality levels go from 1 to 5, so a simple sort should do the trick to get the highest level
  const orderedSublocalities = placeIds
    .filter((placeIds) => placeIds.includes('sublocality_level'))
    .sort()
    .reverse();
  if (orderedSublocalities.length > 0) {
    return orderedSublocalities[0].split(PLACEID_SEPARATOR)[1];
  }
  return getLocalityPlaceId(placeIds);
};

export const getEnergyCertificateTranslationKey = (
  objectType: Property['objectType'],
  country: Property['countryAlpha2'],
  energyCertificateAvailable?: Property['energyCertAvailable']
) => {
  const showEnergyCertAvailability = Boolean(objectType !== PropertyType.land && country && EU_COUNTRIES.includes(country));
  if (energyCertificateAvailable === undefined) {
    return '';
  }
  if (energyCertificateAvailable === 'yes') {
    return 'search.general.label.yes';
  }
  if (energyCertificateAvailable === 'no' && showEnergyCertAvailability) {
    return 'search.general.label.no';
  }
  if (energyCertificateAvailable === 'unknown' && showEnergyCertAvailability) {
    return 'search.expose.propertyDetails.energy.value.energyCertificateAvailable.unknown';
  }
  return '';
};

export const getPropertyAvailableLanguageMessage = (
  t: TFunction<'property_expose'>,
  language: Language,
  availableLanguages: Array<string>
): string => {
  const availableLanguagesLabel = availableLanguages
    .filter((lang) => lang in Language)
    .map((lang) => {
      return t(`${mapLanguageTranslation[lang.toLowerCase() as Language]}`);
    });
  const languages = formatIntlList(availableLanguagesLabel, language);

  return t('search.expose.label.availableLanguagesBanner', {
    languages,
  });
};

export const shouldShowAutogenButton = (property: Property) =>
  property.autogenPageUrl &&
  [PropertyType.house, PropertyType.apartment, PropertyType.land].some((propertyType) => propertyType === property.objectType);

export const isIndexedProperty = (property: Property, language: Language, pageLanguages: string[], countryCode?: CountryCode) =>
  Boolean(
    property.countryAlpha2 &&
      countryCode === property.countryAlpha2 &&
      SEO_INDEXED_COUNTRIES.has(property.countryAlpha2) &&
      property.language?.includes(language) &&
      language !== Language.ca &&
      pageLanguages.includes(language)
  ); // https://engelvoelkers.atlassian.net/browse/NS-5401

export const hasFinancingSection = (property: Property) =>
  !!property.countryAlpha2 &&
  FINANCING_ENABLED_COUNTRIES.has(property.countryAlpha2) &&
  property.propertyMarketingType === PropertyMarketingType.sale;

export const hasExtraInfo = (propertyData: Property): boolean => {
  return Boolean(
    propertyData.rooms ||
      propertyData.bedrooms ||
      propertyData.bathrooms ||
      propertyData.areas?.['sqmt']?.livingSurface ||
      propertyData.areas?.['sqmt']?.totalSurface ||
      propertyData.areas?.['sqmt']?.officeSurface ||
      propertyData.areas?.['sqmt']?.salesSurface ||
      propertyData.areas?.['sqmt']?.storageSurface ||
      propertyData.areas?.['sqmt']?.terraceSurface ||
      propertyData.areas?.['sqmt']?.dividableFrom ||
      propertyData.areas?.['sqmt']?.plotSurface ||
      propertyData.parkingBays ||
      propertyData.condition ||
      propertyData.parkingBays ||
      propertyData.objectSubType ||
      propertyData.constructionYear ||
      propertyData.objectType ||
      propertyData.garages ||
      propertyData.flooring ||
      propertyData.floors
  );
};

export const hasFeatures = (propertyData: Property): boolean => {
  return Boolean(
    propertyData.hasAirConditioning ||
      propertyData.hasBalcony ||
      propertyData.hasBasement ||
      propertyData.hasBuiltInKitchen ||
      propertyData.hasGarage ||
      propertyData.hasGarden ||
      propertyData.hasPatio ||
      propertyData.hasSeaOrLakeView ||
      propertyData.hasSecuritySystem ||
      propertyData.hasSwimmingPool ||
      propertyData.hasTennisCourt ||
      propertyData.hasTerrace ||
      propertyData.hasWaterfront ||
      (Array.isArray(propertyData.elevator) && propertyData.elevator.length) ||
      propertyData.elevator ||
      propertyData.petsAllowed ||
      propertyData.heatProtectionClass
  );
};

export const hasEnergyInfo = (propertyData: Property): boolean => {
  return Boolean(
    getEnergyCertificateTranslationKey(propertyData.objectType, propertyData.countryAlpha2, propertyData.energyCertAvailable) ||
      (propertyData.epCode && propertyData.countryAlpha2 === Country.BE) ||
      (propertyData.co2Emission && propertyData.countryAlpha2 === Country.BE) ||
      propertyData.energyCertType ||
      propertyData.energyClassNormalized ||
      propertyData.energyEfficiencyFactor ||
      propertyData.finalEnergyConsumption ||
      propertyData.energyFinalDemand ||
      propertyData.heatingType ||
      propertyData.heatingMaterial ||
      propertyData.heatingConsumption ||
      propertyData.heatingFinalConsumption ||
      propertyData.co2EmissionRate ||
      propertyData.powerFinalConsumption
  );
};

export const getPropertyDetails = (
  property: Property,
  transformedSurfaceFields: TransformedSurfaceFieldsType,
  livingAreaLabel: ParseKeys<'property_expose'>,
  showConstructionYear: boolean = false,
  t: TFunction<'property_expose'>
) => {
  const propertyDetails = [
    {
      label: t(property.objectSubType ? 'search.expose.propertyDetails.label.propertySubtype' : 'search.searchbar.propertyType'),
      content:
        property.objectSubType || property.objectType
          ? t(
              property.objectSubType
                ? `search.searchbar.advFilter.propertySubType.${property.objectSubType}`
                : `search.searchbar.advFilter.propertyType.${property.objectType}`
            )
          : '',
    },
    {
      label: t('search.expose.propertyDetails.label.condition'),
      content: property.condition && t(`search.expose.propertyDetails.${property.condition}`),
    },
    {
      label: t('search.expose.propertyDetails.label.rooms', {
        count: 2, // hardcode labels to plural
      }),
      content: property.rooms,
    },
    {
      label: t('search.expose.propertyDetails.label.bedrooms', {
        count: 2, // hardcode labels to plural
      }),
      content: property.bedrooms,
    },
    {
      label: t('search.expose.propertyDetails.label.bathrooms', {
        count: 2, // hardcode labels to plural
      }),
      content: property.bathrooms,
    },
    {
      label: t('search.expose.propertyDetails.label.floor'),
      content: property.floor,
    },
    {
      label: t('search.expose.propertyDetails.label.numberOfFloors'),
      content: property.floors,
    },
    {
      label: t('search.expose.propertyDetails.label.garage', {
        count: 2, // hardcode labels to plural
      }),
      content: property.garages,
    },
    {
      label: t('search.expose.propertyDetails.label.parkingBays'),
      content: property.parkingBays,
    },
    {
      label: t('search.expose.propertyDetails.label.totalSurface'),
      content: transformedSurfaceFields.totalSurface,
    },
    {
      label: t(livingAreaLabel),
      content: transformedSurfaceFields.livingSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.plotSurface'),
      content: transformedSurfaceFields.plotSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.shopSurface'),
      content: transformedSurfaceFields.shopSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.terraceSurface'),
      content: transformedSurfaceFields.terraceSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.officeSurface'),
      content: transformedSurfaceFields.officeSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.storageSurface'),
      content: transformedSurfaceFields.storageSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.salesSurface'),
      content: transformedSurfaceFields.salesSurface,
    },
    {
      label: t('search.expose.propertyDetails.label.dividableFrom'),
      content: transformedSurfaceFields.dividableFrom,
    },
    {
      label: t('search.expose.propertyDetails.label.roiCurrent'),
      content: property.roiCurrent ? `${property.roiCurrent} %` : '',
    },
    {
      label: t('search.expose.propertyDetails.label.roiTarget'),
      content: property.roiTarget ? `${property.roiTarget} %` : '',
    },
    {
      label: t('search.expose.propertyDetails.label.flooring'),
      content: property.flooring?.map((floor) => t(`search.expose.propertyDetails.label.flooring.${floor}`)).join(', '),
    },
    {
      label: t('search.expose.propertyDetails.label.constructionYear'),
      content: showConstructionYear ? property.constructionYear : null,
    },
  ];

  return propertyDetails;
};

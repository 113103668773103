import {
  customInstance
} from "./chunk-PCL3B4V6.js";

// generated/search-bff/controllers/auto-suggestion/auto-suggestion.ts
var commonSuggest = (params, options) => {
  return customInstance({ url: `/v1/autosuggestion/suggest`, method: "GET", params }, options);
};
var commonPropertySuggest = (params, options) => {
  return customInstance({ url: `/v1/autosuggestion/listing/suggest`, method: "GET", params }, options);
};
var getPlaceDetailsV2 = (params, options) => {
  return customInstance({ url: `/v1/autosuggestion/details`, method: "GET", params }, options);
};

export {
  commonSuggest,
  commonPropertySuggest,
  getPlaceDetailsV2
};

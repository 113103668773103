import {
  panic
} from "./chunk-3W4JOJMB.js";

// generated/search-bff/custom-instance.ts
import Axios from "axios";
import { merge } from "lodash-es";
var baseUrlMap = {
  dev: "https://search-bff.envdevel.com/api/",
  stage: "https://search-bff.envstage.com/api/",
  prod: "https://search-bff.engelvoelkers.com/api/"
};
var createAxiosInstance = (apiConfig) => {
  const { environment = "prod", basicAuth, applicationKey, axiosConfig } = apiConfig || {};
  return Axios.create(
    merge(
      {},
      {
        baseURL: baseUrlMap[environment],
        paramsSerializer: {
          indexes: null
        },
        ...basicAuth && {
          auth: {
            username: basicAuth.username,
            password: basicAuth.password
          }
        },
        headers: {
          "x-application-key": applicationKey
        }
      },
      axiosConfig
    )
  );
};
var customInstance = (config, apiConfig) => {
  const axiosInstance = createAxiosInstance(apiConfig);
  if (!axiosInstance.defaults.headers["x-application-key"]) {
    panic("x-application-key is required");
  }
  return axiosInstance({
    ...config
  }).then(({ data }) => data);
};

export {
  customInstance
};

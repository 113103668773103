import { styled } from 'styled-components';

const carouselThumbSpacing = 'var(--distance-static-xxs)';
const carouselThumbSize = 'calc(var(--distance-static-s) / 2)';

export const CarouselThumbViewportStyled = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const CarouselThumbContainerStyled = styled.div<{
  $scrollSnaps: number;
  $maxThumbs: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $maxThumbs, $scrollSnaps }) => ($maxThumbs > $scrollSnaps ? 'center' : 'flex-start')};
  margin-left: ${({ $maxThumbs, $scrollSnaps }) => ($scrollSnaps > $maxThumbs ? `calc(${carouselThumbSpacing} * -1)` : '0')};
`;

export const CarouselThumbSlideStyled = styled.div`
  min-width: ${carouselThumbSize};
  margin: 0 ${carouselThumbSpacing};
`;

export const DotButtonStyled = styled.button<{ $active: boolean; $activeColor?: string; $inActiveColor?: string }>`
  width: ${carouselThumbSize};
  height: ${carouselThumbSize};
  border-radius: var(--border-radius-circle);
  background-color: ${({ $active, $activeColor, $inActiveColor }) =>
    $active ? ($activeColor ?? 'var(--color-white)') : ($inActiveColor ?? 'rgba(255, 255, 255, 0.6)')};
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: background-color 0.3s ease;
`;

export const CarouselThumbStyled = styled.div<{ $maxThumbs: number }>`
  width: ${({ $maxThumbs }) => `calc((var(--distance-static-xs) + ${carouselThumbSize}) * ${$maxThumbs})`};
  position: absolute;
  bottom: var(--distance-static-s);
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
`;

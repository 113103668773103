import { type Theme, Box, Grid, Link, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Divider } from '@/components/common/Divider/Divider';
import { FacebookIcon } from '@/components/common/Icons/FacebookIcon';
import { InstagramIcon } from '@/components/common/Icons/InstagramIcon';
import { LinkedInIcon } from '@/components/common/Icons/LinkedInIcon';
import { TelephoneIcon } from '@/components/common/Icons/TelephoneIcon';
import { YoutubeIcon } from '@/components/common/Icons/YoutubeIcon';
import { PrintIcon } from '@/components/ui-core-components-clone/icons/small/PrintIcon';

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '40px',
  height: '1px',
  borderColor: theme.palette.primary.main,
}));

const StyledSocialIconsGroup = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(0),
  },
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(4),
}));

const StyledTextContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
  },
}));

const socialMediaIconStyles = (theme: Theme) => ({
  color: theme.palette.grey[600],
  '&:hover': {
    color: theme.palette.red[100],
  },
});

export const FooterContactColumn = (): React.ReactElement => {
  const { t } = useTranslation('header_footer');
  const theme = useTheme();

  const columnTitle = t('contact_column.title');
  const tel = t('contact_column.tel');
  const fax = t('contact_column.fax');
  const address = t('contact_column.street');
  const city = t('contact_column.zip_country_city');

  const facebookUrl = 'https://www.facebook.com/engelvoelkershq/';
  const linkedinUrl = 'https://www.linkedin.com/company/engel-&-voelkers';
  const instagramUrl = 'https://instagram.com/engelvoelkershq/';
  const youtubeUrl = 'https://www.youtube.com/EngelVoelkersAG';

  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {columnTitle}
      </Typography>
      <StyledDivider />
      <StyledIconContainer>
        <Link
          underline="none"
          color="textSecondary"
          href={`tel:${tel}`}
          sx={{
            '&:hover': {
              color: theme.palette.red[100],
            },
          }}
        >
          <Typography variant="body2">
            <TelephoneIcon sx={{ position: 'absolute', left: theme.spacing(0) }} />
            {tel}
          </Typography>
        </Link>
      </StyledIconContainer>
      <StyledIconContainer>
        <Typography variant="body2" color="textSecondary">
          <PrintIcon sx={{ position: 'absolute', left: theme.spacing(0) }} />
          {fax}
        </Typography>
      </StyledIconContainer>
      <StyledTextContainer>
        <Typography variant="body2" color="textSecondary">
          {address}
        </Typography>
      </StyledTextContainer>
      <StyledTextContainer>
        <Typography variant="body2" color="textSecondary">
          {city}
        </Typography>
      </StyledTextContainer>
      <StyledSocialIconsGroup container>
        <Box sx={{ mr: 3 }}>
          <Link href={facebookUrl}>
            <FacebookIcon sx={socialMediaIconStyles(theme)} />
          </Link>
        </Box>
        <Box sx={{ mr: 3 }}>
          <Link href={linkedinUrl}>
            <LinkedInIcon sx={socialMediaIconStyles(theme)} />
          </Link>
        </Box>
        <Box sx={{ mr: 3 }}>
          <Link href={instagramUrl}>
            <InstagramIcon sx={socialMediaIconStyles(theme)} />
          </Link>
        </Box>
        <Box>
          <Link href={youtubeUrl}>
            <YoutubeIcon sx={socialMediaIconStyles(theme)} />
          </Link>
        </Box>
      </StyledSocialIconsGroup>
    </>
  );
};

import { styled } from 'styled-components';

import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';

export const StyledFieldsSeparator = styled(Copy)`
  color: var(--color-dark-lighter);
  text-align: center;
  letter-spacing: 0.48px;
`;

export const StyledFieldset = styled.fieldset`
  display: flex;
  gap: var(--distance-static-s);
  margin: 0;
  padding: 0;
  border: none;
  align-items: center;
`;

export const StyledCopy = styled(Copy)`
  color: var(--color-dark-subdued);
`;

export const StyledEndAdornment = styled(Copy)`
  color: var(--color-dark-lighter);
`;

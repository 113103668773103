import { forwardRef } from 'react';
import { StyledBackground, StyledWrapper, LaptopSize, TabletLandscapeSize } from './Wrapper.styled';
import { BackgroundColorVariants } from '../backgroundColor';

type WrapperTagName = 'div' | 'section' | 'main';

export interface WrapperProps {
  className?: string;
  as?: WrapperTagName;
  children: React.ReactNode;
  width: {
    laptop: LaptopSize;
    tabletLandscape: TabletLandscapeSize;
  };
  id?: string;
  smallerBottomSpace?: boolean;
  backgroundVariant?: BackgroundColorVariants;
  tabIndex?: number;
}

export const Wrapper = forwardRef<HTMLElement, WrapperProps>(
  ({ className, children, as, width, id, smallerBottomSpace, backgroundVariant, ...restProps }, ref) => {
    if (backgroundVariant) {
      return (
        <StyledBackground $backgroundVariant={backgroundVariant} as={as} ref={ref} id={id} {...restProps}>
          <StyledWrapper as="div" $tabletLandscapeWidth={width.tabletLandscape} $laptopWidth={width.laptop} className={className}>
            {children}
          </StyledWrapper>
        </StyledBackground>
      );
    }

    return (
      <StyledWrapper
        as={as}
        className={className}
        $tabletLandscapeWidth={width.tabletLandscape}
        $laptopWidth={width.laptop}
        $smallerBottomSpace={smallerBottomSpace}
        id={id}
        ref={ref}
        {...restProps}
      >
        {children}
      </StyledWrapper>
    );
  },
);

Wrapper.displayName = 'Wrapper';

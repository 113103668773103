import {
  getProperties
} from "./chunk-DMRMWGUK.js";
import {
  findLocalityPlaceId
} from "./chunk-FGZHCMS7.js";
import {
  ListingSortOption
} from "./chunk-VTYJUGD5.js";
import {
  getErrorResult
} from "./chunk-XNWIRXJJ.js";
import {
  DEFAULT_FILTERS,
  getSimilarPropertiesPriceRange
} from "./chunk-EYGO4I34.js";
import {
  PropertyMarketingType
} from "./chunk-VQQW7DNQ.js";

// src/getSimilarProperties/getSimilarProperties.ts
async function getSimilarProperties(property, options, apiConfig) {
  try {
    const localityPlaceId = findLocalityPlaceId(property.placeIds ?? []);
    if (!localityPlaceId) {
      throw new Error("The property does not have any locality place id");
    }
    const { businessArea, propertyMarketingType, isDevelopmentProject, priceOnRequest, id, baseCurrency } = property;
    const filters = {
      excludedPropertyIds: [id],
      businessArea: businessArea ? [businessArea] : DEFAULT_FILTERS.businessArea,
      propertyMarketingType: propertyMarketingType ? [propertyMarketingType] : DEFAULT_FILTERS.propertyMarketingType,
      placeId: localityPlaceId,
      sortingOptions: [
        propertyMarketingType === PropertyMarketingType.rent ? ListingSortOption.RENT_DESC : ListingSortOption.SALES_PRICE_DESC
      ]
    };
    if (isDevelopmentProject) {
      filters.propertyType = ["group"];
    } else {
      filters.searchRadius = 5;
      if (priceOnRequest) {
        filters.priceOnRequest = true;
      } else {
        filters.price = getSimilarPropertiesPriceRange(property);
        options.currency = baseCurrency;
      }
    }
    return await getProperties(filters, options, apiConfig);
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  getSimilarProperties
};

import { css, styled } from 'styled-components';

import HeartFilled from '../../../spearhead/lib/assets/icons/small/Heart-Filled.svg';
import { IconButton } from '../IconButton/IconButton';

export const StyledIconButton = styled(IconButton)`
  :hover {
    background: none;
    > svg {
      transform: scale(1.1);
    }
  }
`;

export const StyledHeartIcon = styled(HeartFilled)<{
  $isInWatchlist: boolean;
  $variant: 'filled' | 'outlined';
}>`
  & path {
    transition: fill 0.2s ease;
  }

  ${({ $variant, $isInWatchlist }) => {
    return $variant === 'filled'
      ? css`
          stroke: var(--color-light);
          fill-opacity: 0.6;
          ${$isInWatchlist &&
          css`
            & path {
              fill: var(--color-highlight);
            }
            fill-opacity: 1;
          `}
        `
      : css`
          stroke: var(--color-dark-intense);
          & path {
            fill: transparent;
          }

          ${$isInWatchlist &&
          css`
            & path {
              fill: var(--color-highlight);
            }
            stroke: var(--color-highlight);
          `}
        `;
  }}
`;

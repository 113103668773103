import { getTrackingQueryParams } from '@pkgs/components/utils/stringUtils';
import { useEffect, useState } from 'react';

export const useQueryParams = () => {
  const [trackingQueryParams, setTrackingQueryParams] = useState<Record<string, unknown> | undefined>();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const queryParams = getTrackingQueryParams(currentUrl);
    setTrackingQueryParams(queryParams);
  }, []);

  return trackingQueryParams;
};

import { type ApiConfig, type GetPropertiesOptions } from '@pkgs/api/types';
import { type ApiResult, type GetPropertiesByShopIdsFilter, type PropertiesResponse } from '@pkgs/api/types';

import { getProperties } from '../getProperties/getProperties';

/**
 * Fetch property listings listed by an array of shop IDs.
 * @function
 * @param {string[]} shopIds - The ID of the shop whose properties are being requested.
 * @param {GetPropertiesByShopIdsFilter} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
 * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the the properties data or error details.
 *
 * @summary Fetch property listings listed by an array of shop IDs.
 */

export async function getPropertiesByShopIds(
  shopIds: string[],
  filters: GetPropertiesByShopIdsFilter,
  options: GetPropertiesOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<PropertiesResponse>> {
  if (shopIds.length === 0) {
    console.warn('shopIds is an empty array.');
  }
  return await getProperties(
    {
      ...filters,
      masterDataShopIds: shopIds,
    },
    options,
    apiConfig,
  );
}

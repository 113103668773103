/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

import { customInstance } from '../../custom-instance';
import type {
  CreateSearchAlertRequestV2,
  CreateSearchAlertRequestV3,
  CreateSearchAlertResponse,
  EditSearchAlertRequest,
  FindAllSearchAlertsBy1Params,
  FindAllSearchAlertsParams,
  FindAllSearchAlertsResponseV3,
  SearchAlertEntity,
} from '../../models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Edit a search alert
 */
export const editSearchAlert = (
  searchAlertId: string,
  editSearchAlertRequest: EditSearchAlertRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/v3/searchalert/alert/${searchAlertId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: editSearchAlertRequest,
    },
    options,
  );
};
/**
 * @summary Delete a search alert
 */
export const deleteSearchAlert = (searchAlertId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/v3/searchalert/alert/${searchAlertId}`, method: 'DELETE' }, options);
};
/**
 * @deprecated
 * @summary Edit a search alert
 */
export const editSearchAlert1 = (
  searchAlertId: string,
  editSearchAlertRequest: EditSearchAlertRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/v2/searchalert/alert/${searchAlertId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: editSearchAlertRequest,
    },
    options,
  );
};
/**
 * @deprecated
 * @summary Delete a search alert
 */
export const deleteSearchAlert1 = (searchAlertId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/v2/searchalert/alert/${searchAlertId}`, method: 'DELETE' }, options);
};
/**
 * @summary Create a search alert
 */
export const createSearchAlert = (
  createSearchAlertRequestV3: CreateSearchAlertRequestV3,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CreateSearchAlertResponse>(
    { url: `/v3/searchalert/alert`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createSearchAlertRequestV3 },
    options,
  );
};
/**
 * @summary Confirm a search alert
 */
export const confirm = (searchAlertId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/v3/searchalert/alert/${searchAlertId}/confirm`, method: 'POST' }, options);
};
/**
 * @deprecated
 * @summary Create a search alert
 */
export const createSearchAlert1 = (
  createSearchAlertRequestV2: CreateSearchAlertRequestV2,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CreateSearchAlertResponse>(
    { url: `/v2/searchalert/alert`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createSearchAlertRequestV2 },
    options,
  );
};
/**
 * @deprecated
 * @summary Confirm a search alert
 */
export const confirm1 = (searchAlertId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/v2/searchalert/alert/${searchAlertId}/confirm`, method: 'POST' }, options);
};
/**
 * @summary Find all search alerts of an Account
 */
export const findAllSearchAlerts = (
  accountId: string,
  params?: FindAllSearchAlertsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FindAllSearchAlertsResponseV3>(
    { url: `/v3/searchalert/account/${accountId}/alert`, method: 'GET', params },
    options,
  );
};
/**
 * @summary Delete all search alerts of an Account
 */
export const deleteAllSearchAlerts = (accountId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/v3/searchalert/account/${accountId}/alert`, method: 'DELETE' }, options);
};
/**
 * @deprecated
 * @summary Find all search alerts of an Account
 */
export const findAllSearchAlertsBy1 = (params: FindAllSearchAlertsBy1Params, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<SearchAlertEntity[]>({ url: `/v2/admin/searchalert/alert`, method: 'GET', params }, options);
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type EditSearchAlertResult = NonNullable<Awaited<ReturnType<typeof editSearchAlert>>>;
export type DeleteSearchAlertResult = NonNullable<Awaited<ReturnType<typeof deleteSearchAlert>>>;
export type EditSearchAlert1Result = NonNullable<Awaited<ReturnType<typeof editSearchAlert1>>>;
export type DeleteSearchAlert1Result = NonNullable<Awaited<ReturnType<typeof deleteSearchAlert1>>>;
export type CreateSearchAlertResult = NonNullable<Awaited<ReturnType<typeof createSearchAlert>>>;
export type ConfirmResult = NonNullable<Awaited<ReturnType<typeof confirm>>>;
export type CreateSearchAlert1Result = NonNullable<Awaited<ReturnType<typeof createSearchAlert1>>>;
export type Confirm1Result = NonNullable<Awaited<ReturnType<typeof confirm1>>>;
export type FindAllSearchAlertsResult = NonNullable<Awaited<ReturnType<typeof findAllSearchAlerts>>>;
export type DeleteAllSearchAlertsResult = NonNullable<Awaited<ReturnType<typeof deleteAllSearchAlerts>>>;
export type FindAllSearchAlertsBy1Result = NonNullable<Awaited<ReturnType<typeof findAllSearchAlertsBy1>>>;

import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const EVLogoGG = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={42} height={23} viewBox="0 0 42 23" fill="none" sx={{ width: '100%' }} {...props}>
      <path
        fill="red"
        fillRule="evenodd"
        d="M29.5.302c2.615.001 4.976.874 6.355 1.407.11.055.449.115.576.115.468 0 .919-.406.944-.921h.807v6.864h-.747c-.703-3.716-3.639-6.398-7.01-6.398-4.574 0-8.253 4.669-8.253 10.265h-4.634V11.6c0-2.072.575-4.035 1.604-5.726.27.6.475 1.244.604 1.926l.026.14h1.068l-.001-.172h-.732c-.703-3.716-3.638-6.4-7.009-6.4-4.575 0-8.253 4.67-8.253 10.267 0 5.593 3.308 9.896 7.685 9.896 1.252 0 2.441-.37 3.495-1.032.59-.384 1.081-1.058 1.409-1.785 1.045-2.32.062-5.257-1.39-5.779-.29-.104-.582-.213-.882-.21l-.021-.01h-1.26v-.909h10.58v.91h-1.165c-.803.074-1.36.56-1.64 1.23.14-.307.343-.564.605-.75.552 4.814 3.637 8.335 7.596 8.335 1.253 0 2.442-.37 3.496-1.032.59-.384 1.081-1.058 1.409-1.785 1.045-2.32.062-5.256-1.39-5.779-.29-.104-.582-.213-.882-.21l-1.282-.01v-.909h10.583v.91h-1.168c-1.197.11-1.847 1.136-1.847 2.32l-.002.016v5.247h-.607c-2.142 1.501-5.123 2.4-8.07 2.4-3.353 0-6.398-.764-8.651-2.438v.038h-.607c-2.142 1.501-5.123 2.4-8.071 2.4C5.833 22.698.21 19.43.21 11.598.21 5.472 5.235.3 12.17.303c2.617.001 4.977.874 6.358 1.407.11.054.448.115.575.115.468 0 .92-.406.944-.921h.807v2.811l.217-.208C23.184 1.533 26.113.301 29.5.302Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

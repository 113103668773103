import { styled } from '@mui/material';
import { type Property } from '@pkgs/api';
import { type SearchModuleFilters } from '@pkgs/components';
import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { type ReactElement, useEffect, useMemo, useState } from 'react';

import { type HeaderProps } from '@/components/common/Header/Header';
import { ExposeSkeletonLoading } from '@/components/property-expose/ExposeSkeletonLoading/ExposeSkeletonLoading';
import { type AppPageProps } from '@/pages/_app';

const HeaderBlokDynamic = dynamic(() => import('@/components/ui-core-components-wrapper/Header/Header').then((mod) => mod.HeaderBlock));
const Header = dynamic(() => import('@/components/common/Header/Header').then((mod) => mod.Header));

const FooterBlokDynamic = dynamic(() => import('@/components/ui-core-components-wrapper/Footer/Footer').then((mod) => mod.FooterBlock));
const Footer = dynamic(() => import('@/components/common/Footer/Footer').then((mod) => mod.Footer));

const StickyContainer = styled('div')<{ $isLegacyHeader: boolean }>(({ $isLegacyHeader }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 3,
  background: 'var(--color-surface-light)',
  paddingBottom: $isLegacyHeader ? '0.75rem' : '0',
}));

type Props = HeaderProps & {
  children: React.ReactNode;
  stickyHeader?: boolean;
  withContactButton?: boolean;
  property?: Property;
  filters?: SearchModuleFilters;
  isPropertySearchPage?: boolean;
};

export const Layout = ({
  children,
  spearHeadNavigation,
  onLanguageChange,
  pageLanguages,
  stickyHeader,
  withContactButton,
  externalLinkDomains,
  property,
  filters,
  isPropertySearchPage,
}: Props & AppPageProps): ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();
  const router = useRouter();

  const [loadingPage, setLoadingPage] = useState('');

  const evLogoUrl = `https://www.engelvoelkers.com/${router.query.countryCodeOrLocale}/${language}`;

  const ConditionalStickyWrapper = ({ children, $isLegacyHeader = false }: { children: React.ReactNode; $isLegacyHeader?: boolean }) =>
    isPropertySearchPage ? <StickyContainer $isLegacyHeader={$isLegacyHeader}>{children}</StickyContainer> : children;

  useEffect(() => {
    const handleRouteChangeStart = (route: string) => {
      if (route) {
        const routeInLowerCase = route.toLowerCase();
        const routes = ['exposes', 'projects'];

        if (routes.some((route) => routeInLowerCase.includes(route))) {
          window.scrollTo({ top: 0, behavior: 'auto' });
          setLoadingPage('exposes');
        }
      }
    };

    const handleRouteChangeComplete = () => setLoadingPage('');

    const handleRouteChangeError = (url: string, err: Record<string, string>) => {
      Sentry.captureException(err, { extra: { message: `routing to ${url} threw an error` } });
      handleRouteChangeComplete();
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [router]);

  const loadingComponent = useMemo(() => {
    switch (loadingPage) {
      case 'exposes':
        return <ExposeSkeletonLoading />;
      default:
        return null;
    }
  }, [loadingPage]);

  return (
    <>
      {spearHeadNavigation?.headerData ? (
        <ConditionalStickyWrapper>
          <HeaderBlokDynamic
            blok={spearHeadNavigation.headerData}
            className="sp-header"
            baseSlug={evLogoUrl}
            pageLanguages={pageLanguages}
            onLanguageChange={onLanguageChange}
            sticky={stickyHeader}
            withContactButton={withContactButton}
            externalLinkDomains={externalLinkDomains}
            property={property}
            filters={filters}
          />
        </ConditionalStickyWrapper>
      ) : (
        <ConditionalStickyWrapper $isLegacyHeader>
          <Header onLanguageChange={onLanguageChange} />
        </ConditionalStickyWrapper>
      )}
      {loadingComponent ?? children}
      {spearHeadNavigation?.footerData ? (
        <FooterBlokDynamic blok={spearHeadNavigation.footerData} externalLinkDomains={externalLinkDomains} />
      ) : (
        <Footer />
      )}
    </>
  );
};

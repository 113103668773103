import ArrowLeft from '../../../spearhead/lib/assets/icons/small/Arrow-Thin-Left.svg';
import ArrowRight from '../../../spearhead/lib/assets/icons/small/Arrow-Thin-Right.svg';
import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';
import { ELLIPSIS, getPaginationRange } from './getPaginationRange';
import { StyledEllipsis, StyledPaginationArrowButton, StyledPaginationNumberButton, StyledPaginationWrapper } from './Pagination.styles';

type IPaginationProps = {
  numPages: number;
  currentPage: number;
  onPageChange: (currentPage: number) => void;
};

export const Pagination = ({ numPages, currentPage, onPageChange }: IPaginationProps) => {
  const paginationRange = getPaginationRange(numPages, currentPage);

  const goToPage = (page: number) => {
    onPageChange(page);
  };
  const goToNextPage = () => {
    onPageChange(currentPage + 1);
  };

  const goToPrevPage = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <nav aria-label="Pagination Navigation">
      <StyledPaginationWrapper>
        {numPages > 1 && (
          <li>
            <StyledPaginationArrowButton
              aria-label="Goto next page"
              variant="transparent"
              icon={<ArrowLeft />}
              disabled={currentPage === 1}
              onClick={goToPrevPage}
            />
          </li>
        )}

        {paginationRange.map((pageItem, index) => (
          <li key={index}>
            {pageItem === ELLIPSIS ? (
              <StyledEllipsis text={ELLIPSIS} size="l" />
            ) : (
              <StyledPaginationNumberButton
                aria-label={pageItem === currentPage ? `Current Page, Page ${pageItem}` : `Goto Page ${pageItem}`}
                aria-current={pageItem === currentPage}
                $isSelected={pageItem === currentPage}
                $length={String(pageItem).length}
                onClick={() => goToPage(pageItem)}
              >
                <Copy size="m" text={String(pageItem)} />
              </StyledPaginationNumberButton>
            )}
          </li>
        ))}

        {numPages > 1 && (
          <li>
            <StyledPaginationArrowButton
              aria-label="Goto previous page"
              variant="transparent"
              icon={<ArrowRight />}
              disabled={currentPage === numPages}
              onClick={goToNextPage}
              size="medium"
            />
          </li>
        )}
      </StyledPaginationWrapper>
    </nav>
  );
};

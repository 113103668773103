import {
  type BusinessDivisionType,
  getABTestContext,
  getConsentDocumentContext,
  getDivisionContext,
  getLeadContext,
  getNavigationContext,
  getPageContext,
  getPropertyContext,
  getShopContext,
  getSortContext,
  trackNavigationEvent,
  trackUserInteractionEvent,
  UserActionEnum,
} from '@ev/snowplow-library';
import {
  type Currency,
  type InputContact,
  type MeasurementSystem,
  type PropertiesResponse,
  type Property,
  type TrackingV2Response,
  Language,
} from '@pkgs/api';
import { type SearchModuleFilters } from '@pkgs/components';
import { getSortValues } from '@pkgs/components/utils/stringUtils';
import { mapPropertyMarketingTypeToLeadContext, mapSearchStateToFilterContext } from '@pkgs/components/utils/tracking';
import { trackSiteSearch } from '@snowplow/browser-plugin-site-tracking';

import { AB_TESTS } from '@/consts/ab-test';
import { SearchApiClient } from '@/consts/search-api';
import { type PropertyTrackingIds } from '@/types/property.types';
import type { ABTestVariation, LocalStorageTrackingProperty, TrackingIDs, TrackingProvider } from '@/types/tracking';
import { PageTypeEnum } from '@/types/tracking';

import { getCookie } from './cookieUtils';
import { getCurrentPriceOrRentProps } from './getCurrentPriceOrRentProps';
import { isLocalStorageAvailable } from './localStorage';
import { getPlaceIdForLocationContext } from './propertyDataUtils';
import { extractWithSuffixes } from './searchFilterMappingUtils';

const ga4IDRegex = /^G-[A-Z0-9]{10}$/;

export const isGA4ID = (googleAnalytics4ID: string | undefined | null): boolean => {
  if (!googleAnalytics4ID) {
    return false;
  }
  return ga4IDRegex.test(googleAnalytics4ID);
};

export const mapPropertyDataToPropertyContext = (property: Property): Parameters<typeof getPropertyContext>[0] => {
  return {
    propertyId: property.displayID,
    profileId: property.evProfileID,
    bffObjectId: property.id,
    type: property.objectType || '',
    subType: property.objectSubType,
    constructionYear: property.constructionYear,
    currency: property.baseCurrency,
    price: getCurrentPriceOrRentProps(property, Language.en).value,
    measurementUnit: property.baseUnit,
    ...('areas' in property && property.areas && extractWithSuffixes(property.areas)),
    rooms: property.rooms,
    bathrooms: property.bathrooms,
    bedrooms: property.bedrooms,
    propertyCondition: property.condition || '',
    hasKitchen: property.hasBuiltInKitchen,
    hasAlarmSystem: property.hasSecuritySystem,
    hasGarden: property.hasGarden,
    hasTerrace: property.hasTerrace,
    hasBalcony: property.hasBalcony,
    hasCellar: property.hasBasement,
    hasAirconditioning: property.hasAirConditioning,
    hasPatio: property.hasPatio,
    hasSeaview: property.hasSeaOrLakeView,
    hasTennisYard: property.hasTennisCourt,
    hasParking: property.hasGarage,
    hasPool: property.hasSwimmingPool,
    isWaterfront: property.hasWaterfront,
    hasSquashCourt: property.hasSquashCourt,
    hasClubHouse: property.hasClubhouse,
    hasGolfCourse: property.hasGolfCourse,
    hasPlayground: property.hasPlayground,
    isWheelchairAccessible: property.isWheelchairAccessible,
    hasBuiltinWardrobe: property.hasBuiltInWardrobe,
    isFurnished: property.isFurnished,
    hasGuestToilet: property.hasGuestToilet,
    isSeniorOriented: property.isSeniorOriented,
    hasOpenView: property.hasOpenView,
    hasGreenView: property.hasGreenView,
    hasMountainView: property.hasMountainView,
    hasJacuzzi: property.hasJacuzzi,
    hasSauna: property.hasSauna,
    hasGym: property.hasGym,
    hasAttic: property.hasAttic,
  };
};

export const mapDevelopmentDataToPropertyContext = (property: Property) => {
  return {
    propertyId: property.displayID,
    profileId: property.evProfileID,
    bffObjectId: property.id,
    type: property.objectType || '',
    subType: property.objectSubType,
    constructionYear: property.constructionYear,
    currency: property.baseCurrency,
    price: getCurrentPriceOrRentProps(property, Language.en).value,
    measurementUnit: property.baseUnit,
    livingArea: property.baseUnit ? property.project?.aggregate?.areas?.[property.baseUnit]?.minLivingSurface : undefined,
    plotArea: property.baseUnit ? property.project?.aggregate?.areas?.[property.baseUnit]?.minPlotSurface : undefined,
    rooms: property.project?.aggregate?.minRooms,
    bathrooms: property.project?.aggregate?.minBathRooms,
    bedrooms: property.project?.aggregate?.minBedRooms,
  };
};

/* when navigating from landing pages to search app, we add ev_tracking cookie with the Google Analytics account of
/ the licence partner to be able to track the user
/ this can be overwritten by passing a Google Analytics account as a query param ga=UA-XXXXX */
export const getLicencePartnerGAAccount = (): string => {
  let licencePartnerGAAccount = getCookie('ev_tracking');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const gaQueryParam = urlSearchParams.get('ga');
  if (gaQueryParam) {
    licencePartnerGAAccount = gaQueryParam;
  }
  return licencePartnerGAAccount;
};

const pushTrackingObjectToDataLayer = (trackingIDs: TrackingIDs) => {
  Object.keys(trackingIDs).forEach((trackingProperty) => {
    if (!trackingIDs[trackingProperty as TrackingProvider]) {
      return;
    }

    switch (trackingProperty) {
      case 'licencePartnerCriteoID':
        window.dataLayer?.push({
          event: 'licencePartnerCriteoAccount',
          licencePartnerCriteoID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'licencePartnerFacebookID':
        window.dataLayer?.push({
          event: 'licencePartnerFacebookAccount',
          licencePartnerFacebookID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'licencePartnerGA4ID':
        window.dataLayer?.push({
          event: 'licencePartnerGA4Account',
          licencePartnerGA4ID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'licencePartnerLinkedInID':
        window.dataLayer?.push({
          event: 'licencePartnerLinkedInAccount',
          licencePartnerLinkedInID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'licencePartnerMicrosoftID':
        window.dataLayer?.push({
          event: 'licencePartnerMicrosoftAccount',
          licencePartnerMicrosoftID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'licencePartnerGoogleAdsID':
        window.dataLayer?.push({
          event: 'licencePartnerGoogleAdsAccount',
          licencePartnerGoogleAdsID: trackingIDs[trackingProperty] as string,
        });
        break;
      // Market Values from Spearhead project
      case 'marketCriteoID':
        window.dataLayer?.push({
          event: 'marketCriteoAccount',
          marketCriteoID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'marketFacebookID':
        window.dataLayer?.push({
          event: 'marketFacebookAccount',
          marketFacebookID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'marketGA4ID':
        window.dataLayer?.push({
          event: 'marketGA4Account',
          marketGA4ID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'marketLinkedInID':
        window.dataLayer?.push({
          event: 'marketLinkedInAccount',
          marketLinkedInID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'marketMicrosoftID':
        window.dataLayer?.push({
          event: 'marketMicrosoftAccount',
          marketMicrosoftID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'marketGoogleAdsID':
        window.dataLayer?.push({
          event: 'marketGoogleAdsAccount',
          marketGoogleAdsID: trackingIDs[trackingProperty] as string,
        });
        break;
      // Global Values from Spearhead project
      case 'globalCriteoID':
        window.dataLayer?.push({
          event: 'globalCriteoAccount',
          globalCriteoID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'globalFacebookID':
        window.dataLayer?.push({
          event: 'globalFacebookAccount',
          globalFacebookID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'globalGA4ID':
        window.dataLayer?.push({
          event: 'globalGA4Account',
          globalGA4ID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'globalLinkedInID':
        window.dataLayer?.push({
          event: 'globalLinkedInAccount',
          globalLinkedInID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'globalMicrosoftID':
        window.dataLayer?.push({
          event: 'globalMicrosoftAccount',
          globalMicrosoftID: trackingIDs[trackingProperty] as string,
        });
        break;
      case 'globalGoogleAdsID':
        window.dataLayer?.push({
          event: 'globalGoogleAdsAccount',
          globalGoogleAdsID: trackingIDs[trackingProperty] as string,
        });
        break;
      default:
        break;
    }
  });
};

export const mapPropertyTrackingIdsToDataLayerTrackingIds = (trackingIds: PropertyTrackingIds): TrackingIDs | null => {
  const allTrackingIds = {
    ...(trackingIds.googleAnalytics4ID && {
      licencePartnerGA4ID: trackingIds.googleAnalytics4ID,
    }),
    ...(trackingIds.googleAdsID && {
      licencePartnerGoogleAdsID: trackingIds.googleAdsID,
    }),
    ...(trackingIds.microsoftID && {
      licencePartnerMicrosoftID: trackingIds.microsoftID,
    }),
    ...(trackingIds.linkedInID && {
      licencePartnerLinkedInID: trackingIds.linkedInID,
    }),
    ...(trackingIds.criteoID && {
      licencePartnerCriteoID: trackingIds.criteoID,
    }),
    ...(trackingIds.facebookID && {
      licencePartnerFacebookID: trackingIds.facebookID,
    }),
  };
  return Object.keys(allTrackingIds).length ? allTrackingIds : null;
};

// priority when pushing tracking ids to GTM datalayer
// 1. tracking ids fetched based on the ga4Id url parameter
// 2. tracking ids coming from local storage
// 3. tracking ids coming from property data (relevant only for expose page)
export const pushReferrerTrackingToDataLayer = ({
  propertyTrackingIds,
  urlTrackingIds,
}: {
  propertyTrackingIds?: TrackingIDs | null;
  urlTrackingIds?: TrackingIDs | null;
}): void => {
  if (typeof window === 'undefined') {
    return;
  }

  // push tracking ids fetched based on ga4Id url param
  if (urlTrackingIds) {
    pushTrackingObjectToDataLayer(urlTrackingIds);
    return;
  }

  if (isLocalStorageAvailable()) {
    const storedTrackingProperties = localStorage.getItem('ev_dl_tracking');
    const trackingProperties: LocalStorageTrackingProperty = storedTrackingProperties ? JSON.parse(storedTrackingProperties) : {};

    const referrerTracking = document.referrer ? trackingProperties[document.referrer] : trackingProperties['https://ev-tracking.test/en']; // custom url to test manually in stage

    // push tracking ids coming from local storage
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (referrerTracking) {
      pushTrackingObjectToDataLayer(referrerTracking);
      return;
    }
  }

  // push tracking ids coming from the property data
  if (propertyTrackingIds) {
    pushTrackingObjectToDataLayer(propertyTrackingIds);
    return;
  }
};

export const mapBFFTrackingResponseToPropertyTrackingIds = (trackingIds: TrackingV2Response): PropertyTrackingIds => {
  const { criteoId, facebookId, shopGa4Id, linkedInId, licensePartnerId, shopId, microsoftId, adwordsId } = trackingIds;
  return {
    ...(criteoId && { criteoID: criteoId }),
    ...(facebookId && { facebookID: facebookId }),
    ...(shopGa4Id && { googleAnalytics4ID: shopGa4Id }),
    ...(linkedInId && { linkedInID: linkedInId }),
    ...(licensePartnerId && { masterDataLicensePartnerID: licensePartnerId }),
    ...(shopId && { masterDataShopID: shopId }),
    ...(microsoftId && { microsoftID: microsoftId }),
    ...(adwordsId && { googleAdsID: adwordsId }),
  };
};

export const fetchTrackingIDs = async (googleAnalytics4ID: string): Promise<PropertyTrackingIds | null> => {
  try {
    const trackingIds = await SearchApiClient.getTrackingByGa4Id(googleAnalytics4ID);
    return mapBFFTrackingResponseToPropertyTrackingIds(trackingIds);
  } catch {
    return null;
  }
};

export const sendTrackSiteSearchEvent = ({
  results,
  filters,
  currency,
  measurementSystem,
  shopNames,
  placeName,
}: {
  results: PropertiesResponse;
  filters: SearchModuleFilters;
  currency: Currency;
  measurementSystem: MeasurementSystem;
  shopNames: string[];
  placeName?: string;
}) => {
  const isLipaPortfolioPage = filters.shopIds || filters.masterDataShopIds;
  const { sortOrder, sortField } = getSortValues(filters.sortingOptions?.[0]);

  const siteSearchPayload: Parameters<typeof trackSiteSearch>[0] = {
    terms: isLipaPortfolioPage ? (shopNames.length > 0 ? [...shopNames] : ['']) : [placeName || ''],
    filters: {
      ...mapSearchStateToFilterContext(filters, currency, measurementSystem),
    },
    totalResults: results.totalHits,
    context: [
      getSortContext({
        sortOrder,
        sortField,
      }),
      getLeadContext(mapPropertyMarketingTypeToLeadContext(filters.propertyMarketingType)),
      getPageContext({
        isHQPage: true,
        type: isLipaPortfolioPage ? PageTypeEnum.LIPA_SEARCH_RESULT : PageTypeEnum.SEARCH_RESULT,
      }),
      getDivisionContext({
        name: filters.businessArea[0] as BusinessDivisionType,
      }),
      getShopContext({ id: filters.shopIds?.[0] }),
    ],
  };

  trackSiteSearch(siteSearchPayload);
};

export const getShopContextCommonValues = (
  { shopID, masterDataLiPaID, masterDataShopID, businessArea }: Property,
  tracking?: PropertyTrackingIds
): Parameters<typeof getShopContext>[0] => ({
  office_id: shopID,
  ...((masterDataShopID || tracking?.masterDataShopID) && {
    shop_id: masterDataShopID || tracking?.masterDataShopID,
  }),
  ...((masterDataLiPaID || tracking?.masterDataLicensePartnerID) && {
    license_id: masterDataLiPaID || tracking?.masterDataLicensePartnerID,
  }),
  business_division_name: businessArea,
});

export const getCookiesConsentContext = () => {
  if (typeof window === 'undefined') {
    return getConsentDocumentContext({
      vendors_consent: '',
      vendors_consent_denied: '',
    });
  }
  return getConsentDocumentContext({
    vendors_consent: window.didomiState?.didomiVendorsRawConsent,
    vendors_consent_denied: window.didomiState?.didomiVendorsRawConsentDenied,
  });
};

export const getContactFormABTestContext = (abTestVariation: ABTestVariation) => {
  return getABTestContext({
    id: AB_TESTS.contactForm.id,
    name: AB_TESTS.contactForm.name,
    variant: abTestVariation,
    variant_description: Object.keys(AB_TESTS.contactForm.variants).includes(abTestVariation)
      ? AB_TESTS.contactForm.variants[abTestVariation]
      : '',
  });
};

export const trackRequestExposeEvents = ({
  property,
  elementId,
  language,
  navigationTarget,
  countryCode,
  abTestVariation,
}: {
  property: Property;
  elementId: string;
  language: string;
  navigationTarget: 'source' | 'target';
  countryCode?: string;
  abTestVariation?: ABTestVariation;
}) => {
  trackNavigationEvent({
    screenName: '',
    contexts: [
      getPageContext({
        type: property.isDevelopmentProject ? 'project-page' : 'expose-page',
        ...(countryCode && { country_code: countryCode }),
        page_language: language,
        template_type: 'expose-page',
        isHQPage: true,
      }),
      getNavigationContext({
        ...(navigationTarget === 'target' && {
          target_screen: 'request-expose-modal-form',
        }),
        ...(navigationTarget === 'source' && {
          source_screen: 'request-expose-modal-form',
        }),
        element_id: elementId,
      }),
      getShopContext(getShopContextCommonValues(property)),
      getCookiesConsentContext(),
      ...(abTestVariation ? [getContactFormABTestContext(abTestVariation)] : []),
    ],
  });
};

export const trackShareExposeEvent = (elementId: string, property: Property, language: string, countryCode?: string) => {
  trackUserInteractionEvent({
    elementId: elementId,
    action: UserActionEnum.click,
    contexts: [
      getPageContext({
        isHQPage: true,
        template_type: PageTypeEnum.EXPOSE,
        type: property.isDevelopmentProject ? PageTypeEnum.PROJECT : PageTypeEnum.EXPOSE,
        ...(countryCode && { country_code: countryCode }),
        page_language: language,
      }),
      getShopContext(getShopContextCommonValues(property)),
      getPropertyContext(mapPropertyDataToPropertyContext(property)),
    ],
  });
};

export const mapFormDataToUserContext = (form: InputContact) => {
  return {
    email: form.email,
    phoneNumber: form.phoneNumber,
    contactPreferences: form.callBack ? Object.keys(form.callBack) : [],
    viewingPreferences: form.viewing ? Object.keys(form.viewing) : [],
    contactSelected: form.callBack ? Boolean(Object.keys(form.callBack).length) : false,
    viewingSelected: form.viewing ? Boolean(Object.keys(form.viewing).length) : false,
    expose: form.expose,
  };
};

export const mapPropertyToLocationContext = (property: Property) => {
  return {
    country: property.country,
    countryCode: property.countryAlpha2,
    region: property.region,
    city: property.city,
    district: property.subLocality,
    zipCode: property.postalCode,
    placeId: getPlaceIdForLocationContext(property.placeIds ?? []),
  };
};

export const trackExposePhoneEvents = (
  trigger: string,
  isDevelopment: boolean,
  countryCode: string | undefined,
  language: string,
  property: Property
) => {
  trackUserInteractionEvent({
    elementId: trigger,
    action: UserActionEnum.call,
    contexts: [
      getPageContext({
        isHQPage: true,
        template_type: PageTypeEnum.EXPOSE,
        type: isDevelopment ? PageTypeEnum.PROJECT : PageTypeEnum.EXPOSE,
        ...(countryCode && { country_code: countryCode }),
        page_language: language,
      }),
      getShopContext(getShopContextCommonValues(property)),
      getPropertyContext(mapPropertyDataToPropertyContext(property)),
    ],
  });
};

export const getPhoneLinkTrackingEventName = (trigger: string, index: number, phoneNumberCount: number) => {
  if (phoneNumberCount > 1) {
    return `${trigger}_link-${index + 1}_call`;
  } else {
    return `${trigger}_link_call`;
  }
};

import { type Currency, type Language, type Property } from '@ev/search-modules-api';
import { type TFunction } from '@pkgs/components/providers/AppContextProvider';
import { getCardPrice } from '@pkgs/components/utils/propertyCardUtils';
import { useEffect, useState } from 'react';

type UsePriceProps = {
  property: Property;
  language: Language;
  currency: Currency;
  translationResolver: TFunction;
};

export const usePrice = ({ property, language, currency, translationResolver: t }: UsePriceProps) => {
  const [convertedPrice, setConvertedPrice] = useState<string | null>(null);
  const { value, labelTranslationKey } = getCardPrice(property, language, property.baseCurrency);

  useEffect(() => {
    if (property.baseCurrency === currency) {
      setConvertedPrice(null);
    } else {
      const priceBasedOnUserCurrency = getCardPrice(property, language, currency);
      setConvertedPrice(priceBasedOnUserCurrency.value);
    }
  }, [currency, property, language]);

  const priceOnRequest = property.priceOnRequest || !value;

  return {
    convertedPrice,
    price: priceOnRequest ? t('search.map.priceOnRequest') : value,
    priceLabel: t(labelTranslationKey),
  };
};

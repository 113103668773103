import { searchListings, searchListingsMinified } from '@generated/search-bff';
import {
  type ApiConfig,
  type ApiResult,
  type GetPropertiesFilters,
  type GetPropertiesOptions,
  type PropertiesResponse,
  type RawListing,
} from '@pkgs/api/types';
import { mapFilters } from '@pkgs/api/utils';
import { getDefaultSearchListingsParams } from '@pkgs/api/utils/defaultOptions';
import { getErrorResult } from '@pkgs/api/utils/error';
import { refineProperty } from '@pkgs/api/utils/refineProperty';

/**
 * Fetch property listings based on provided filters and options.
 * @function
 * @param {GetPropertiesFilters} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
 * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @param {boolean} [useCompactData=false] - Whether to use compact data for the search module. This will return a smaller set of data for each property listing.
 * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the property listings or error details.
 *
 * @summary Fetch property listings based on provided filters and options.
 */

export async function getProperties(
  filters: GetPropertiesFilters,
  options: GetPropertiesOptions,
  apiConfig: ApiConfig,
  useCompactData: boolean = false,
): Promise<ApiResult<PropertiesResponse>> {
  try {
    const params = getDefaultSearchListingsParams(options);

    const { listing, listingTotal, widerArea } = useCompactData
      ? await searchListingsMinified(mapFilters(filters, options), params, apiConfig)
      : await searchListings(mapFilters(filters, options), params, apiConfig);

    const data: PropertiesResponse = {
      hits: listing?.map((i) => refineProperty(i as RawListing, options.language)) ?? [],
      totalHits: listingTotal ?? 0,
      totalPages: listingTotal ? Math.ceil(listingTotal / params.limit) : 0,
      widerAreaHits: widerArea?.map((i) => refineProperty(i as RawListing, options.language)) ?? [],
    };

    return {
      status: 'success',
      data,
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

import { css, styled } from 'styled-components';

import { focusStyleDark } from '../../utils/focusStyle';
import { baseStyles as buttonBaseStyles } from '../Button/Button.styled';

type StyledLabelProps = {
  $required?: boolean;
  $lines?: number;
  $isExpanded?: boolean;
  $height?: number;
  $size: LabelSizes;
};

export const labelSizes = {
  s: css`
    font-size: var(--font-size-static-xs);
    line-height: var(--line-height-relative-s);
  `,
  m: css`
    font-size: var(--font-size-static-s);
    line-height: var(--line-height-relative-default);
  `,
} as const;

export type LabelSizes = keyof typeof labelSizes;

export const StyledLabel = styled.div<StyledLabelProps>`
  height: ${({ $height, $isExpanded }) => ($isExpanded ? 'auto' : `${$height}px`)};

  white-space: pre-line;
  ${({ $size }) => labelSizes[$size]}

  a {
    color: currentColor;
  }

  ${({ $required }) =>
    $required &&
    css`
      position: relative;
      padding-left: var(--distance-static-xs);

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '*';
        color: var(--color-checkbox-required);
      }
    `}

  ${({ $isExpanded, $lines }) =>
    $lines &&
    !$isExpanded &&
    css`
      display: -webkit-box;
      max-height: calc(var(--line-height-static-m) * ${$lines});
      -webkit-line-clamp: ${$lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const StyledButton = styled.button<{
  $required?: boolean;
  $size: LabelSizes;
}>`
  ${buttonBaseStyles}
  ${({ $size }) => labelSizes[$size]}
  grid-area: button;
  justify-self: start;
  padding: 0;
  border: 0;
  background: 0;
  appearance: none;
  text-decoration: underline;
  text-underline-offset: var(--text-underline-offset);
  color: currentColor;

  ${focusStyleDark}
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }

  ${({ $required }) =>
    $required &&
    css`
      margin-left: var(--distance-static-xs);
    `}
`;

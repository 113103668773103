/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

import { customInstance } from '../../custom-instance';
import type {
  AdminSearchGeoPointResponse,
  GetListingByIdParams,
  GetListingsByIdsParams,
  GetListingsRequest,
  ListingDto,
  MinifiedSearchListingResponse,
  MinifiedSearchListingsResponse,
  PostListingsByIdsParams,
  SearchFilterDto,
  SearchGeoPointResponse,
  SearchListings1Params,
  SearchListingsMinifiedParams,
  SearchListingsParams,
  SearchListingsResponse,
} from '../../models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of listings based on given displayIds and listingIds
 */
export const getListingsByIds = (params: GetListingsByIdsParams, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<ListingDto[]>({ url: `/v1/listing`, method: 'GET', params }, options);
};
/**
 * @summary Returns a list of listings based on given displayIds and listingIds
 */
export const postListingsByIds = (
  getListingsRequest: GetListingsRequest,
  params: PostListingsByIdsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<MinifiedSearchListingResponse[]>(
    { url: `/v1/listing`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: getListingsRequest, params },
    options,
  );
};
/**
 * @summary Search the database for properties matching the search filter
 */
export const searchListings = (
  searchFilterDto: SearchFilterDto,
  params: SearchListingsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SearchListingsResponse>(
    { url: `/v1/listing/search`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: searchFilterDto, params },
    options,
  );
};
/**
 * @summary Search the database for properties matching the search filter and return a minified response
 */
export const searchListingsMinified = (
  searchFilterDto: SearchFilterDto,
  params: SearchListingsMinifiedParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<MinifiedSearchListingsResponse>(
    { url: `/v1/listing/search/minified`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: searchFilterDto, params },
    options,
  );
};
/**
 * @summary Search the database for geopoints matching the search filter in allowed countries
 */
export const listPropertiesForMap = (searchFilterDto: SearchFilterDto, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<SearchGeoPointResponse>(
    { url: `/v1/listing/search/geopoints`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: searchFilterDto },
    options,
  );
};
/**
 * @summary Search the database for properties matching the search filter
 */
export const searchListings1 = (
  searchFilterDto: SearchFilterDto,
  params: SearchListings1Params,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SearchListingsResponse>(
    { url: `/v1/admin/listing/search`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: searchFilterDto, params },
    options,
  );
};
/**
 * @summary Search the database for geopoints matching the search filter in allowed countries
 */
export const listPropertiesForMap1 = (searchFilterDto: SearchFilterDto, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<AdminSearchGeoPointResponse>(
    { url: `/v1/admin/listing/search/geopoints`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: searchFilterDto },
    options,
  );
};
/**
 * @summary Returns a listing(property/project) based by listingId or displayId
 */
export const getListingById = (
  listingIdOrDisplayId: string,
  params: GetListingByIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListingDto>({ url: `/v1/listing/${listingIdOrDisplayId}`, method: 'GET', params }, options);
};
/**
 * @summary Returns a property/listing based by listingId
 */
export const adminListing = (listingIdOrDisplayId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/v1/admin/listing/${listingIdOrDisplayId}`, method: 'GET' }, options);
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type GetListingsByIdsResult = NonNullable<Awaited<ReturnType<typeof getListingsByIds>>>;
export type PostListingsByIdsResult = NonNullable<Awaited<ReturnType<typeof postListingsByIds>>>;
export type SearchListingsResult = NonNullable<Awaited<ReturnType<typeof searchListings>>>;
export type SearchListingsMinifiedResult = NonNullable<Awaited<ReturnType<typeof searchListingsMinified>>>;
export type ListPropertiesForMapResult = NonNullable<Awaited<ReturnType<typeof listPropertiesForMap>>>;
export type SearchListings1Result = NonNullable<Awaited<ReturnType<typeof searchListings1>>>;
export type ListPropertiesForMap1Result = NonNullable<Awaited<ReturnType<typeof listPropertiesForMap1>>>;
export type GetListingByIdResult = NonNullable<Awaited<ReturnType<typeof getListingById>>>;
export type AdminListingResult = NonNullable<Awaited<ReturnType<typeof adminListing>>>;

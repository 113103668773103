import { DEFAULT_SHOP_IDS_FILTERS, GLOBAL_NETWORK_IMAGE_URL } from '@pkgs/components/consts/consts';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { Button } from '@spearhead/components/elements/Button/Button';

import { StyledContainer, StyledContent, StyledTitle } from './ActionCard.styled';

export type ActionCardProps = { onclick: () => void };

export const ActionCard = () => {
  const { t, ImageComponent } = useAppContext();
  const { updateFilters } = useSearch();

  const handleButtonClick = () => {
    updateFilters(DEFAULT_SHOP_IDS_FILTERS);
  };

  return (
    <StyledContainer>
      <ImageComponent
        src={GLOBAL_NETWORK_IMAGE_URL}
        alt="display showing engel and voelkers global network"
        height={240}
        width={360}
        style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
      />
      <StyledContent>
        <StyledTitle>{t('search.licencePartner.ctaCard.text')}</StyledTitle>
        <Button type="button" label={t('search.licencePartner.ctaCard.btn')} onClick={handleButtonClick} />
      </StyledContent>
    </StyledContainer>
  );
};

import {
  PlaceType
} from "./chunk-VTYJUGD5.js";
import {
  deleteEmptyObjects
} from "./chunk-GXCAL3EM.js";
import {
  MeasurementSystem
} from "./chunk-VQQW7DNQ.js";

// src/utils/location.ts
var findLocalityPlaceId = (placeIds) => {
  const localityPlaceId = placeIds.find((placeId) => placeId.includes("locality."));
  if (localityPlaceId) {
    return localityPlaceId.split(".")[1];
  }
  const orderedAdminAreas = placeIds.filter((placeIds2) => placeIds2.includes("administrative_area_level_"));
  orderedAdminAreas.sort();
  orderedAdminAreas.reverse();
  return orderedAdminAreas[0]?.split(".")[1];
};
var SupportedPlaceTypes = /* @__PURE__ */ new Set([
  PlaceType.neighborhood,
  PlaceType.sublocality,
  PlaceType.postal_code,
  PlaceType.locality,
  PlaceType.administrative_area_level_4,
  PlaceType.administrative_area_level_1,
  PlaceType.country,
  PlaceType.natural_feature
]);
var getFirstSupportedPlaceType = (types) => {
  return types.find((type) => SupportedPlaceTypes.has(type));
};
function getAddressComponent(type, allComponents) {
  return allComponents.find((component) => {
    if (type === PlaceType.postal_code) {
      return component.types.length === 1 && component.types[0] === PlaceType.postal_code;
    }
    return component.types.includes(type);
  });
}
function getPostalCode(postalCode, types) {
  const featureType = getFirstSupportedPlaceType(types);
  if (featureType == PlaceType.postal_code || featureType == PlaceType.sublocality || featureType == PlaceType.neighborhood) {
    return postalCode;
  }
}
function getLocationFromPlaceDetails(result) {
  const { addressComponents = [], types = [], formattedAddress, description, ...restLocationFields } = result;
  const getComponent = (type) => getAddressComponent(type, addressComponents)?.longName;
  const country = getComponent(PlaceType.country);
  const region = getComponent(PlaceType.administrative_area_level_1);
  const city = getComponent(PlaceType.locality);
  const subLocality = getComponent(PlaceType.sublocality);
  const neighborhood = getComponent(PlaceType.neighborhood);
  const _postalCode = getComponent(PlaceType.postal_code);
  const naturalFeature = getComponent(PlaceType.natural_feature);
  const postalCode = _postalCode ? getPostalCode(_postalCode, types) : void 0;
  const countryCode = getAddressComponent(PlaceType.country, addressComponents)?.shortName;
  return {
    ...restLocationFields,
    ...countryCode && { countryCode },
    ...country && { country },
    ...region && { region },
    ...city && { city },
    ...subLocality && { subLocality },
    ...neighborhood && { neighborhood },
    ..._postalCode && { postalCode },
    ...naturalFeature && { naturalFeature },
    name: description ?? formattedAddress
  };
}

// src/utils/mapFiltersDtoToGetPropertiesFiltersAndOptions.ts
var mappedFilterPrefixes = [
  "livingSurface",
  "plotSurface",
  "totalSurface",
  "rentTotal",
  "salesPrice"
];
var mapFiltersDtoToGetPropertiesFiltersAndOptions = (filtersDto) => {
  deleteEmptyObjects(filtersDto);
  const options = getOptionsFromFilters(filtersDto);
  const { propertyKind, sortBy, sortOrder, ...filteredDto } = filtersDto;
  const filters = {
    ...removeMappedFilterPrefixes(filteredDto),
    ...getSurfaceFilters(filtersDto),
    ...getPriceFilterField(filtersDto)
  };
  return { filters, options };
};
var removeMappedFilterPrefixes = (filtersDto) => {
  const result = filtersDto;
  for (const key of Object.keys(filtersDto)) {
    if (mappedFilterPrefixes.some((prefix) => key.startsWith(prefix))) {
      delete result[key];
    }
  }
  return result;
};
var getPriceFilterFieldFromFilterDto = (filtersDto) => {
  const propertyMarketingType = filtersDto.propertyMarketingType[0];
  const priceFilterField = Object.keys(filtersDto).find((key) => key.startsWith(propertyMarketingType || ""));
  return priceFilterField ? priceFilterField : void 0;
};
var getPriceFilterField = (filtersDto) => {
  const priceFilterField = getPriceFilterFieldFromFilterDto(filtersDto);
  return priceFilterField ? {
    price: filtersDto[priceFilterField]
  } : void 0;
};
var getSurfaceFilters = (filtersDto) => {
  const surfaceFilterKeys = ["livingSurface", "plotSurface", "totalSurface"];
  let surfaceFields = {};
  for (const key of Object.keys(filtersDto)) {
    const surfaceKey = surfaceFilterKeys.find((prefix) => key.startsWith(prefix));
    if (surfaceKey) {
      surfaceFields = {
        ...surfaceFields,
        [surfaceKey]: filtersDto[key]
      };
    }
  }
  return surfaceFields;
};
var getOptionsFromFilters = (filtersDto) => {
  const priceFilterField = getPriceFilterFieldFromFilterDto(filtersDto);
  const currency = priceFilterField?.slice(-3).toUpperCase();
  const surfaceFilterField = Object.keys(filtersDto).find((key) => /(?:sqmt|sqft)$/i.test(key));
  const measurementSystem = surfaceFilterField ? surfaceFilterField.endsWith("Sqft") ? MeasurementSystem.imperial : MeasurementSystem.metric : void 0;
  return {
    currency,
    measurementSystem
  };
};

export {
  findLocalityPlaceId,
  SupportedPlaceTypes,
  getFirstSupportedPlaceType,
  getLocationFromPlaceDetails,
  mapFiltersDtoToGetPropertiesFiltersAndOptions
};

import { type PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useResize } from '../../utils/useResize';
import type { LabelSizes } from './ExpandableLabel.styled';
import { StyledButton, StyledLabel, StyledWrapper } from './ExpandableLabel.styled';

interface ExpandableLabelProps {
  className?: string;
  readLessLabel?: string;
  readMoreLabel?: string;
  maxLines?: number;
  isRequired?: boolean;
  size?: LabelSizes;
}

export const ExpandableLabel = ({
  className,
  readLessLabel,
  readMoreLabel,
  maxLines,
  isRequired,
  children,
  size = 's',
}: PropsWithChildren<ExpandableLabelProps>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [closedHeight, setClosedHeight] = useState<number | null>(null);
  const [readMoreVisible, setReadMoreVisible] = useState(true);
  const width = useResize();

  function handleClick() {
    setIsExpanded(!isExpanded);
  }

  useEffect(() => {
    if (!contentRef.current) return;
    if (!maxLines) return;

    const lineHeight = Math.round(Number.parseFloat(getComputedStyle(contentRef.current).getPropertyValue('line-height')));
    const contentChildren = contentRef.current;

    setClosedHeight(lineHeight * maxLines);

    const marginBottom = Number.parseFloat(getComputedStyle(contentChildren).getPropertyValue('margin-bottom'));

    const contentScrollHeight = contentChildren.scrollHeight + marginBottom;

    if (!closedHeight) return;

    if (closedHeight > contentScrollHeight) {
      setClosedHeight((state) => (state || 0) + marginBottom);
    }

    if (contentScrollHeight <= closedHeight) {
      setReadMoreVisible(false);
    } else if (!readMoreVisible) {
      setReadMoreVisible(true);
      setIsExpanded(true);
    }
  }, [contentRef, width, closedHeight, readMoreVisible, maxLines]);

  const label = (
    <StyledLabel
      className={maxLines ? undefined : className}
      $size={size}
      $required={isRequired}
      $isExpanded={isExpanded}
      $lines={maxLines}
      $height={closedHeight || undefined}
      ref={contentRef}
    >
      {children}
    </StyledLabel>
  );

  return maxLines ? (
    <StyledWrapper className={className}>
      {label}
      {readMoreVisible && (
        <StyledButton $size={size} onClick={handleClick} $required={isRequired} type="button">
          {isExpanded ? readLessLabel : readMoreLabel}
        </StyledButton>
      )}
    </StyledWrapper>
  ) : (
    label
  );
};

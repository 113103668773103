import { css, styled } from 'styled-components';

import Check from '../../../assets/icons/xsmall/Check.svg';
import { focusStyleDark } from '../../utils/focusStyle';
import { copySizes } from '../Copy/Copy';

export const StyledIcon = styled(Check)`
  position: absolute;
  top: var(--distance-static-3xs);
  left: 0;
  pointer-events: none;
  color: var(--color-text-light);
  z-index: var(--zindex-m);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
`;

export const StyledInput = styled.input<{ $error?: boolean }>`
  appearance: none;
  margin: var(--distance-static-3xs) 0 0;
  border: 1px solid currentColor;
  padding: 0;
  height: 20px;
  width: 20px;
  border-radius: var(--border-radius-m);
  cursor: pointer;

  background: var(--color-checkbox-bg);

  ${focusStyleDark};

  &:active {
    background: var(--color-checkbox-bg);
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--color-checkbox-hover);
    }
  }

  &:checked {
    background: var(--color-checkbox-checked);

    @media (hover: hover) {
      &:hover {
        background: var(--color-checkbox-hover);
      }
    }

    ~ ${StyledIcon} {
      transform: scale(1);
    }
  }

  &:disabled,
  &:hover:disabled {
    background: var(--color-checkbox-bg);
    border-color: var(--color-checkbox-disabled);

    ~ ${StyledIcon} {
      color: var(--color-checkbox-disabled);
    }
  }

  ${({ $error }) =>
    $error &&
    css`
      &:not(:checked) {
        border-color: var(--color-input-border-error);
      }
    `}
`;

export const StyledContainer = styled.label<{ $disabled?: boolean }>`
  position: relative;
  display: grid;
  gap: var(--distance-static-xs);
  grid-template-columns: 20px 1fr;
  color: var(--color-text-dark);
  ${copySizes['m']}
`;

import { css, styled } from 'styled-components';

import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';
import { from } from '../../utils/mediaQueries';

export const StyledDisclaimerText = styled(Copy)<{ $isMapView: boolean }>`
  text-align: center;
  padding: 0 var(--distance-static-m);

  && {
    margin-bottom: var(--distance-dynamic-m);
    margin-top: var(--distance-static-l);
  }

  @media screen and (${from.tabletLandscape}) {
    padding: 0 var(--distance-static-xxl);
  }

  ${({ $isMapView }) =>
    $isMapView &&
    css`
      @media screen and (${from.laptop}) {
        padding-inline: 0;
      }
    `};
`;

/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type PropertyMarketingType = (typeof PropertyMarketingType)[keyof typeof PropertyMarketingType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMarketingType = {
  sale: 'sale',
  rent: 'rent',
} as const;

import { forwardRef } from 'react';

import { Label } from '../Label/Label';
import {
  type InputTextSizes,
  StyledCopy,
  StyledEndAdornment,
  StyledInputText,
  StyledRootInputText,
  StyledStartAdornment,
} from './InputText.styled';

export type InputTextSizesProps = keyof typeof InputTextSizes;

export interface InputTextProps {
  label?: React.ReactNode;
  //! added to handle aria attributes
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  onEnter?: () => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  size?: InputTextSizesProps;
  helperText?: string;
  error?: boolean;
  inputMode?: 'numeric' | 'text' | 'tel' | 'email';
  isRequired?: boolean;
  preventOnWheelChange?: boolean;
  id?: string;
  name?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  //! added to handle invalid event
  onInvalid?: (event: React.InvalidEvent<HTMLInputElement>) => void;
  value?: string;
  defaultValue?: string;
  className?: string;
  type?: 'text' | 'email' | 'number';
  min?: number | string;
  max?: number | string;
  readOnly?: boolean;
  pattern?: string;
  autoComplete?:
    | 'given-name'
    | 'family-name'
    | 'email'
    | 'street-address'
    | 'address-level2'
    | 'address-level1'
    | 'postal-code'
    | 'country'
    | 'tel'
    | 'off';
  placeholder?: string;
  'aria-label'?: string;
  'data-test-id'?: string;
  'data-cy'?: string;
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      label,
      //! added to handle aria attributes
      labelProps,
      endAdornment,
      startAdornment,
      size = 's',
      error,
      helperText,
      className,
      type = 'text',
      inputMode,
      isRequired,
      onEnter,
      onKeyDown,
      name,
      pattern,
      id = name,
      preventOnWheelChange,
      ...inputProps
    },
    ref,
  ) => (
    <div className={className}>
      {Boolean(label) && (
        <Label
          htmlFor={id}
          as="label"
          label={label}
          required={isRequired}
          //! added to handle aria attributes
          {...labelProps}
        />
      )}
      <StyledRootInputText>
        {Boolean(startAdornment) && <StyledStartAdornment $disabled={inputProps.disabled}>{startAdornment}</StyledStartAdornment>}
        <StyledInputText
          {...inputProps}
          name={name}
          id={id}
          ref={ref}
          type={type}
          inputMode={inputMode}
          $endAdornment={Boolean(endAdornment)}
          $startAdornment={Boolean(startAdornment)}
          $size={size}
          $error={error}
          required={isRequired}
          pattern={pattern}
          onWheel={
            preventOnWheelChange
              ? (e) => {
                  e.currentTarget.blur();
                }
              : undefined
          }
          onKeyDown={(e) => {
            onKeyDown?.(e);
            if (e.key === 'Enter' && !e.shiftKey) {
              onEnter?.();
              e.preventDefault();
            }
          }}
        />
        {Boolean(endAdornment) && <StyledEndAdornment $disabled={inputProps.disabled}>{endAdornment}</StyledEndAdornment>}
      </StyledRootInputText>
      {Boolean(helperText) && <StyledCopy text={helperText} size="s" $error={error} />}
    </div>
  ),
);

InputText.displayName = 'InputText';

import {
  getTrackingByGa4Id as generatedGetTrackingByGa4Id,
  type GetTrackingByGa4IdResult as GeneratedGetTrackingByGa4IdResult,
} from '@generated/search-bff/index';
import { type ApiConfig } from '@pkgs/api/types';

/**
 * @summary Returns all tracking ids based on a given google GA4 id
 */
export function getTrackingByGa4Id(ga4Id: string, apiConfig: ApiConfig) {
  return generatedGetTrackingByGa4Id(ga4Id, apiConfig);
}

export type GetTrackingByGa4IdResult = GeneratedGetTrackingByGa4IdResult;

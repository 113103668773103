import { useContext } from 'react';

import { BreakpointContext, type BreakpointProviderType } from '../providers/BreakpointProvider';

export const useBreakpoint = (): BreakpointProviderType => {
  const ctx = useContext(BreakpointContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (ctx === undefined) {
    throw new Error('useBreakpoint used outside of ContextProvider!');
  } else {
    return ctx;
  }
};

import { useRef, useState } from 'react';
import { type AriaTextFieldProps, useTextField } from 'react-aria';

import { type InputTextProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { useAppContext } from '../../hooks/useAppContext';
import { InputText } from '../InputText/InputText';
import { StyledInputWrapper } from './SearchAlert.styled';

export const EmailInput = (props: AriaTextFieldProps) => {
  const { t } = useAppContext();
  const [validity, setValidity] = useState<Partial<ValidityState>>({
    valid: true,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const label = t('search.contact.form.label.email');

  const updateValidity = () => {
    if (!inputRef.current) return;
    const { valid, typeMismatch, valueMissing } = inputRef.current.validity;
    setValidity({ valid, typeMismatch, valueMissing });
  };

  const errorMessage = () => {
    if (validity.typeMismatch) return t('search.contact.form.validation.email.invalid');
    if (validity.valueMissing) return t('search.contact.form.validation.email.required');
    return '';
  };

  const { inputProps, labelProps } = useTextField(
    {
      ...props,
      onBlur: updateValidity,
      label,
    },
    inputRef,
  );

  return (
    <StyledInputWrapper data-test-id="search-components_search-alert-creation-form_email-input-container">
      <InputText
        {...(inputProps as InputTextProps)}
        label={label}
        labelProps={labelProps}
        name="email"
        type="email"
        autoComplete="email"
        isRequired
        size="m"
        ref={inputRef}
        error={!validity.valid}
        onInvalid={updateValidity}
        helperText={errorMessage()}
        data-test-id="search-components_search-alert-creation-form_email-input"
      />
    </StyledInputWrapper>
  );
};

import { type Language } from '@ev/search-modules-api';
import { useRef } from 'react';
import { type AriaNumberFieldProps, useButton, useNumberField } from 'react-aria';
import { useNumberFieldState } from 'react-stately';

import Minus from '../../../spearhead/lib/assets/icons/small/Minus-Naked.svg';
import Plus from '../../../spearhead/lib/assets/icons/small/Plus-Naked.svg';
import { type IconButtonProps } from '../../../spearhead/lib/components/elements/IconButton/IconButton';
import { Label } from '../../../spearhead/lib/components/elements/Label/Label';
import { validateNumber } from '../../utils/numberFormatters';
import { StyledIconButton, StyledInput, StyledRow } from './Stepper.styled';

interface IStepperProps extends AriaNumberFieldProps {
  className?: string;
  label?: string;
  id?: string;
  step?: number;
  name?: string;
  placeholder?: string;
  size?: 'm' | 's';
  language: Language;
  'data-test-id'?: string;
}

const inputSizeToButtonSizeMatch = {
  s: 'small' as const,
  m: 'medium' as const,
};

const Button = ({ icon, shape, variant, size, disabled, ...props }: IconButtonProps) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  return <StyledIconButton {...buttonProps} ref={ref} icon={icon} size={size} shape={shape} variant={variant} disabled={disabled} />;
};
export const Stepper = ({ language, placeholder, isDisabled, ...props }: IStepperProps) => {
  const state = useNumberFieldState({ ...props, locale: language });
  const inputRef = useRef(null);
  const { labelProps, groupProps, inputProps, incrementButtonProps, decrementButtonProps } = useNumberField(props, state, inputRef);
  const { type, value, inputMode, autoComplete, min, max, onChange, ...restInputProps } = inputProps;
  const {
    isDisabled: decrementIsDisabled,
    excludeFromTabOrder: decrementExcludeFromTabOrder,
    ...restDecrementButtonProps
  } = decrementButtonProps;
  const {
    isDisabled: incrementIsDisabled,
    excludeFromTabOrder: incrementExcludeFromTabOrder,
    ...restIncrementButtonProps
  } = incrementButtonProps;
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    const isValidInput = validateNumber(targetValue) || targetValue === '';
    if (!isValidInput) {
      event.preventDefault();
      return;
    }
    onChange &&
      onChange({
        ...event,
        target: {
          ...event.target,
          value: targetValue,
        },
      });
  };

  return (
    <div className={props.className}>
      {Boolean(props.label) && <Label as="label" {...labelProps} label={props.label} id={props.id} />}
      <StyledRow {...groupProps}>
        <Button
          {...restDecrementButtonProps}
          variant="secondary"
          shape="square"
          size={props.size ? inputSizeToButtonSizeMatch[props.size] : 'medium'}
          icon={<Minus />}
          disabled={Boolean(decrementIsDisabled || !value)}
          data-test-id={`${props['data-test-id']}_decrement-button`}
        />
        <StyledInput
          {...restInputProps}
          type="number"
          value={value?.toString()}
          ref={inputRef}
          size={props.size}
          disabled={isDisabled}
          placeholder={placeholder}
          aria-label={props['aria-label']}
          onChange={handleOnChange}
          defaultValue={props.defaultValue?.toString()}
          data-test-id={props['data-test-id']}
        />
        <Button
          {...restIncrementButtonProps}
          variant="secondary"
          shape="square"
          size={props.size ? inputSizeToButtonSizeMatch[props.size] : 'medium'}
          icon={<Plus />}
          disabled={Boolean(incrementIsDisabled)}
          data-test-id={`${props['data-test-id']}_increment-button`}
        />
      </StyledRow>
    </div>
  );
};

import { CountryCode, Language } from '@generated/search-bff';
import { type GetPropertiesFilters, type GetPropertiesOptions } from '@pkgs/api/types';

// using Record instead of Set to make sure all keys are present
const validFilters: Readonly<Record<keyof GetPropertiesFilters, undefined>> = {
  price: undefined,
  livingSurface: undefined,
  plotSurface: undefined,
  totalSurface: undefined,
  agentId: undefined,
  bathrooms: undefined,
  bedrooms: undefined,
  boundingBox: undefined,
  businessArea: undefined,
  constructionYear: undefined,
  countryCode: undefined,
  excludedPropertyIds: undefined,
  fallbackSearch: undefined,
  hasAirConditioning: undefined,
  hasBalcony: undefined,
  hasBasement: undefined,
  hasBuiltInKitchen: undefined,
  hasElevator: undefined,
  hasGarage: undefined,
  hasGarden: undefined,
  hasPatio: undefined,
  hasSeaOrLakeView: undefined,
  hasSecuritySystem: undefined,
  hasSwimmingPool: undefined,
  hasTennisCourt: undefined,
  hasTerrace: undefined,
  hasVtour: undefined,
  hasWaterfront: undefined,
  propertyMarketingType: undefined,
  masterDataLiPaIds: undefined,
  masterDataShopIds: undefined,
  parentId: undefined,
  placeId: undefined,
  postalCode: undefined,
  priceOnRequest: undefined,
  propertySubType: undefined,
  propertyType: undefined,
  rooms: undefined,
  searchRadius: undefined,
  shopIds: undefined,
  since: undefined,
  sortByDistanceToPoint: undefined,
  sortingOptions: undefined,
  statuses: undefined,
};

const validOptions: Readonly<Record<keyof GetPropertiesOptions, undefined>> = {
  currency: undefined,
  language: undefined,
  measurementSystem: undefined,
  page: undefined,
  pageSize: undefined,
};

const DEPRECATED_FIELDS: { deprecatedKey: string; newKey: string }[] = [
  { deprecatedKey: 'marketingType', newKey: 'propertyMarketingType' }, // NS-6759
];

/**
 * Ensures that only the new keys are present in the filters and old keys are still supported
 * @param params - filters and options
 * @returns filters and options with deprecated keys replaced
 */
const replaceDeprecatedFilters = (params: Record<string, unknown>) => {
  const refinedFilters = { ...params };

  for (const { deprecatedKey, newKey } of DEPRECATED_FIELDS) {
    if (deprecatedKey in refinedFilters && newKey in refinedFilters) {
      throw new Error(`Both ${deprecatedKey} and ${newKey} are present in the filters. Please use only ${newKey}.`);
    }

    if (deprecatedKey in refinedFilters) {
      refinedFilters[newKey] = refinedFilters[deprecatedKey];
      delete refinedFilters[deprecatedKey];
    }
  }

  return refinedFilters;
};

export function parseParams(params: Record<string, unknown>): {
  filters: Partial<GetPropertiesFilters>;
  options: Partial<GetPropertiesOptions>;
} {
  const refinedFilters = replaceDeprecatedFilters(params);
  const filters = Object.fromEntries(Object.entries(refinedFilters).filter(([key]) => key in validFilters));
  const options = Object.fromEntries(Object.entries(refinedFilters).filter(([key]) => key in validOptions));

  return { filters, options };
}

export function validateLanguage(language: unknown): language is Language {
  return typeof language === 'string' && Object.values(Language).includes(language as Language);
}

export function validateCountryCode(countryCode: unknown): countryCode is CountryCode {
  return typeof countryCode === 'string' && Object.values(CountryCode).includes(countryCode as CountryCode);
}

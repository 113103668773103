import { useRef } from 'react';
import { type AriaButtonProps, useButton } from 'react-aria';

import { type ButtonProps, Button as SphButton } from '@/components/ui-core-components-clone/Button';

export const Button = ({ refObject, ...props }: ButtonProps & AriaButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, refObject ?? buttonRef);
  const { onPress, ...rest } = props;
  return <SphButton {...rest} {...buttonProps} refObject={refObject ?? buttonRef} />;
};

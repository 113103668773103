import {
  BusinessArea,
  Currency,
  type DeepPartial,
  type GetPropertiesOptions,
  Language,
  MeasurementSystem,
  mergeState,
  PropertyMarketingType,
} from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { type SearchProviderProps } from '@pkgs/components/providers/SearchProvider';
import { getNewSortingOptions } from '@pkgs/components/utils/searchStateUtils';
import { isEqual, merge } from 'lodash-es';
import { useMemo, useState } from 'react';

export const DEFAULT_FILTERS: SearchModuleFilters = {
  businessArea: BusinessArea.residential,
  propertyMarketingType: PropertyMarketingType.sale,
} as const;

export const DEFAULT_OPTIONS: Required<GetPropertiesOptions> = {
  page: 1,
  pageSize: 23,
  language: Language.en,
  currency: Currency.EUR,
  measurementSystem: MeasurementSystem.metric,
} as const;

export const useSearchState = ({ initialFilters, initialOptions, currency, language, measurementSystem }: SearchProviderProps) => {
  // In order to react to option changes from the outside, we need to keep track of the previous options
  const [previousOptions, updatePreviousOptions] = useState<Partial<GetPropertiesOptions>>({
    currency,
    measurementSystem,
  });
  const [filters, setFilters] = useState<SearchModuleFilters>(mergeState(DEFAULT_FILTERS, initialFilters ?? {}));

  const [internalOptions, setOptions] = useState<Required<GetPropertiesOptions>>({ ...DEFAULT_OPTIONS, ...initialOptions });

  // override initialOptions with controlled options
  const options: Required<GetPropertiesOptions> = merge({}, internalOptions, {
    currency,
    measurementSystem,
    language,
  });

  const { updateFilters, updateOptions } = useMemo(() => {
    const updateFilters = (refinement: DeepPartial<SearchModuleFilters>) => {
      const newFilters = mergeState(filters, refinement);

      // if propertyMarketingType changes, reset sortingOptions to default value
      if (newFilters.propertyMarketingType !== filters.propertyMarketingType) {
        newFilters.sortingOptions = getNewSortingOptions(newFilters.propertyMarketingType, initialFilters?.sortingOptions?.[0]);
      }

      // if the filters already include a placeId, the boundingBox is not needed
      if (newFilters.placeId) {
        delete newFilters.boundingBox;
      }

      if (!isEqual(filters, newFilters)) {
        updateOptions({ page: 1 });
        setFilters(newFilters);
      }
    };

    const updateOptions = (refinement: Partial<GetPropertiesOptions>) => {
      const newOptions = mergeState(options, refinement);
      if (!isEqual(options, newOptions)) {
        setOptions(newOptions);
      }
    };

    return {
      updateFilters,
      updateOptions,
    };
  }, [filters, initialFilters?.sortingOptions, options]);

  if (previousOptions.currency !== options.currency) {
    updateFilters({ price: undefined });
    updatePreviousOptions({ ...previousOptions, currency: options.currency });
  }
  if (previousOptions.measurementSystem !== options.measurementSystem) {
    updateFilters({
      livingSurface: undefined,
      totalSurface: undefined,
      plotSurface: undefined,
    });
    updatePreviousOptions({
      ...previousOptions,
      measurementSystem: options.measurementSystem,
    });
  }

  return {
    filters,
    options,
    updateFilters,
    updateOptions,
    replaceFilters: setFilters,
    replaceOptions: setOptions,
  };
};

import { type EmblaCarouselType, type EmblaOptionsType, type EmblaPluginType } from 'embla-carousel';
import useEmblaCarousel, { type UseEmblaCarouselType } from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';

export type UseCarouselActions = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
  slidesInView: number[];
  emblaCarouselUtils: UseEmblaCarouselType;
};

export const useCarouselActions = (options?: EmblaOptionsType, plugins?: EmblaPluginType[]): UseCarouselActions => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [slidesInView, setSlidesInView] = useState<number[]>([0]);

  const emblaCarouselUtils = useEmblaCarousel({ loop: true, ...options }, plugins);

  const [, emblaApi] = emblaCarouselUtils;

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    const scrollSnapList = emblaApi.scrollSnapList();
    setScrollSnaps(scrollSnapList);

    if (scrollSnapList.length === 1) {
      emblaApi.reInit({ active: false });
    }
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  const onNextClick = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  const onPreviousClick = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
    setSlidesInView((prevSlidesInView) => {
      const inView = emblaApi.slidesInView().filter((index: number) => !prevSlidesInView.includes(index));

      // hack to force client side update so the first image is loaded
      if (inView.length === 0 && prevSlidesInView.length === 0) return [0];

      if (prevSlidesInView.length === emblaApi.slideNodes().length) {
        emblaApi.off('slidesInView', updateSlidesInView);
      }

      return [...prevSlidesInView, ...inView];
    });
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    // Initialize and update slides in view
    updateSlidesInView(emblaApi);
    onInit(emblaApi);

    // Attach event listeners
    emblaApi.on('slidesInView', updateSlidesInView);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);

    // Cleanup listeners when emblaApi changes or component unmounts
    return () => {
      emblaApi.off('slidesInView', updateSlidesInView);
      emblaApi.off('reInit', onSelect);
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, updateSlidesInView, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
    onNextClick,
    onPreviousClick,
    slidesInView,
    emblaCarouselUtils,
  };
};

import { type AutoSuggestionPlaceResult } from '@generated/search-bff';
import { type ApiConfig } from '@pkgs/api/types';
import { type ApiResult, type GetSuggestionsOptions, type Property } from '@pkgs/api/types';
import { hasDisplayIdPrefix } from '@pkgs/api/utils/strings';

import { getLocationSuggestions } from '../getLocationSuggestions/getLocationSuggestions';
import { getSuggestionsByDisplayId } from '../getSuggestionsByDisplayId/getSuggestionsByDisplayId';

export type PropertySuggestions = {
  properties: Property[];
};

export type LocationSuggestions = {
  locations: AutoSuggestionPlaceResult[];
};

export type SuggestionResult = PropertySuggestions | LocationSuggestions;

/**
 * Fetch either property listing suggestions or location suggestions based on the provided query.
 * @function
 * @summary Fetches suggestions for properties or locations based on query.
 * @param {string} query - The search query, which can be a display ID or a partial or complete location search term.
 * @param {GetSuggestionsOptions} options - Options to customize the suggestions, such as the number of suggestions and language of the information about the suggestions.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<SuggestionResult>>} A promise that resolves to an object containing the status of the request and the suggestions data (either properties or locations), or error details.
 *
 * @summary Fetch either property listing suggestions or location suggestions based on the provided query.
 */

export async function getSuggestions(
  query: string,
  options: GetSuggestionsOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<SuggestionResult>> {
  if (query === '') {
    console.warn('Input is an empty string.');
  }

  if (hasDisplayIdPrefix(query)) {
    const response = await getSuggestionsByDisplayId(query, options, apiConfig);
    return response.status === 'success' ? { status: 'success', data: { properties: response.data } } : response;
  } else {
    const response = await getLocationSuggestions(query, options, apiConfig);
    return response.status === 'success' ? { status: 'success', data: { locations: response.data } } : response;
  }
}

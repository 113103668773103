import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';
import { useAppContext } from '../../hooks/useAppContext';
import { useSearch } from '../../hooks/useSearch';
import { formatNumber } from '../../utils/numberFormatters';
import { Skeleton } from '../Skeleton/Skeleton';

export const TotalResultsCount = () => {
  const { t } = useAppContext();
  const {
    results,
    isFetching,
    options: { language },
  } = useSearch();
  const formattedTotalResults = formatNumber(results.totalHits, language) || 0;

  return (
    <Skeleton loading={isFetching}>
      <Copy
        size="l"
        as="span"
        text={`${formattedTotalResults} ${t('search.searchbar.foundResults', {
          count: results.totalHits || 0,
        })}`}
      />
    </Skeleton>
  );
};

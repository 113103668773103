/* eslint-disable jsx-expressions/strict-logical-expressions */
import ErrorSVG from '../../../assets/icons/large/Error.svg';
import SuccessSVG from '../../../assets/icons/large/Success.svg';
import {
  StyledButton,
  StyledContent,
  StyledCopy,
  StyledHeadline,
  StyledOverline,
  StyledProcessingSVG,
  StyledStatusScreen,
  StyledSubHeadline,
  StyledSvgWrapper,
} from './StatusScreen.styled';

export const status = {
  error: 'error',
  processing: 'processing',
  success: 'success',
  successValuate: 'successValuate',
} as const;
export type Status = (typeof status)[keyof typeof status];

const statusSVGs = {
  [status.error]: <ErrorSVG />,
  [status.processing]: <StyledProcessingSVG />,
  [status.success]: <SuccessSVG />,
  [status.successValuate]: <SuccessSVG />,
};

export interface StatusScreenProps {
  overline?: string;
  headline?: string;
  status: Status;
  subheadline?: string;
  copy?: string;
  children?: React.ReactNode;
  button?: {
    label: string;
    onClick: (id?: string) => void;
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
  };
  withMargin?: boolean;
  icon?: React.ReactNode;
  'data-test-id'?: string;
}

export const StatusScreen = ({
  overline,
  headline,
  status,
  subheadline = '',
  copy,
  children,
  button,
  withMargin = true,
  icon,
  'data-test-id': dataTestId,
}: StatusScreenProps) => {
  return (
    <StyledStatusScreen $withMargin={withMargin} data-test-id={dataTestId}>
      {overline && <StyledOverline text={overline} />}
      {headline && <StyledHeadline label={headline} forwardedAs="h3" size="h4" />}
      <StyledSvgWrapper>{icon || statusSVGs[status]}</StyledSvgWrapper>
      {subheadline && <StyledSubHeadline forwardedAs="h4" text={subheadline} bold />}
      {copy && <StyledCopy text={copy} size="xl" />}
      {children && <StyledContent>{children}</StyledContent>}
      {button && (
        <StyledButton
          variant="secondary"
          onClick={() => button.onClick()}
          label={button.label}
          icon={button.icon}
          iconPosition={button.iconPosition}
        />
      )}
    </StyledStatusScreen>
  );
};

import { forwardRef } from 'react';
import { styled } from 'styled-components';

import { InputText as SpInputText, type InputTextProps } from '../../../spearhead/lib/components/elements/InputText/InputText';

// each time the spearhead code is copied we lose the overwrites that we did before to adapt their components
const StyledInput = styled(SpInputText)`
  &&& {
    width: 100%;
  }
`;

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(({ ...props }, ref) => {
  return <StyledInput ref={ref} {...props} />;
});

InputText.displayName = 'InputText';

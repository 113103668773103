import { useContext } from 'react';

import { SearchContext, type SearchProviderType } from '../providers/SearchProvider';

export const useSearch = (): SearchProviderType => {
  const ctx = useContext(SearchContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (ctx === undefined) {
    throw new Error('useSearch used outside of ContextProvider!');
  } else {
    return ctx;
  }
};

import { css } from 'styled-components';

export const focusStyle = (light?: boolean) => css`
  content: '';
  position: absolute;
  top: calc(var(--distance-static-xxs) * -1);
  right: calc(var(--distance-static-xxs) * -1);
  bottom: calc(var(--distance-static-xxs) * -1);
  left: calc(var(--distance-static-xxs) * -1);
  border: var(--distance-static-3xs) solid var(${light ? '--color-focus-light' : '--color-focus-dark'});
  border-radius: var(--border-radius-m);
`;

const focusVisible = (light?: boolean) => css`
  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 0;

    &::after {
      ${focusStyle(light)}
    }
  }
`;

const focusWithin = (light?: boolean) => css`
  &:focus {
    outline: 0;
  }

  &:focus-within {
    outline: 0;

    &::after {
      ${focusStyle(light)}
    }
  }
`;

export const focusStyleDark = focusVisible();
export const focusStyleLight = focusVisible(true);

export const focusWithinStyleDark = focusWithin();
export const focusWithinStyleLight = focusWithin(true);

import {
  type Currency,
  Language,
  MeasurementSystem,
  type PropertiesResponse,
  type Property,
  PropertyMarketingType,
  PropertyType,
  type StrictGetPropertiesOptions,
} from '@ev/search-modules-api';
import {
  type BusinessDivisionType,
  FlowEnum,
  getDivisionContext,
  getLeadContext,
  getPageContext,
  type getPropertyContext,
  getShopContext,
  getSortContext,
  type LeadIntentionsType,
  PropertyTypesEnum,
} from '@ev/snowplow-library';
import { type SearchModuleFilters } from '@pkgs/components/components/SearchModule/SearchModule';
import { trackSiteSearch } from '@snowplow/browser-plugin-site-tracking';
import { capitalize } from 'lodash-es';

import { getCardPrice } from './propertyCardUtils';
import { getSortValues } from './stringUtils';

export const PageType = {
  WATCHLIST: 'watchlist-page',
  SEARCH_RESULT: 'search-result-page',
  LIPA_SEARCH_RESULT: 'lipa-search-result-page',
  FLYOUT: 'flyout',
  EXPOSE: 'expose-page',
  PROJECT: 'project-page',
  DEVELOPMENT_EXPOSE: 'development-expose-page',
  NOT_FOUND: '404-page',
  DEVELOPMENT_EXPOSE_UNITS_GRID: 'development-expose-page-units-grid',
  DEVELOPMENT_EXPOSE_UNITS_LIST: 'development-expose-page-units-list',
  BUYER: 'buyer-page',
  SELLER: 'seller-page',
  AGENT: 'agent-page',
  VALUATION: 'valuation-page',
  PROPERTY_PRICE: 'property-price-page',
} as const;

export type PageType = (typeof PageType)[keyof typeof PageType];

export const sendTrackSiteSearchEvent = ({
  results,
  filters,
  options,
  shopNames,
  placeName,
  pageType,
}: {
  results: PropertiesResponse;
  filters: SearchModuleFilters;
  options: StrictGetPropertiesOptions;
  shopNames: string[];
  placeName?: string;
  pageType?: PageType;
}) => {
  const isLipaPortfolioPage = filters.shopIds || filters.masterDataShopIds;
  const { currency, measurementSystem } = options;
  const { sortOrder, sortField } = getSortValues(filters.sortingOptions?.[0]);

  const siteSearchPayload: Parameters<typeof trackSiteSearch>[0] = {
    terms: isLipaPortfolioPage ? (shopNames.length > 0 ? [...shopNames] : ['']) : [placeName || ''],
    filters: {
      ...mapSearchStateToFilterContext(filters, currency, measurementSystem),
    },
    totalResults: results.totalHits,
    context: [
      getSortContext({
        sortOrder,
        sortField,
      }),
      getLeadContext(mapPropertyMarketingTypeToLeadContext(filters.propertyMarketingType)),
      getPageContext({
        isHQPage: true,
        type: (pageType ?? isLipaPortfolioPage) ? PageType.LIPA_SEARCH_RESULT : PageType.SEARCH_RESULT,
      }),
      getDivisionContext({
        name: filters.businessArea as BusinessDivisionType,
      }),
      getShopContext({ id: filters.shopIds?.[0] }),
    ],
  };

  trackSiteSearch(siteSearchPayload);
};

export const mapPropertyMarketingTypeToLeadContext = (
  propertyMarketingType?: PropertyMarketingType,
): { flow: FlowEnum; intention: LeadIntentionsType } => {
  return {
    flow: propertyMarketingType === PropertyMarketingType.sale ? FlowEnum.buyer : FlowEnum.rent,
    intention: propertyMarketingType === PropertyMarketingType.sale ? 'buy' : 'rent',
  };
};

export function mapSearchStateToFilterContext(filters: SearchModuleFilters, currency: Currency, measurementSystem: MeasurementSystem) {
  let propertyType: string | undefined = filters.propertyType;
  if (propertyType === PropertyType.land) {
    propertyType = PropertyTypesEnum.plot;
  }

  const unitSuffix = measurementSystem === MeasurementSystem.metric ? 'Sqmt' : 'Sqft';

  const currencySuffix = capitalize(currency.toLowerCase());
  const context: Record<string, string | boolean | undefined> = {
    type: propertyType || '',
    subType: filters.propertySubType,
    constructionYearMin: filters.constructionYear?.min?.toString(),
    constructionYearMax: filters.constructionYear?.max?.toString(),
    ...(filters.propertyMarketingType === PropertyMarketingType.sale && {
      priceMin: filters.price?.min?.toString(),
      priceMax: filters.price?.max?.toString(),
    }),
    ...(filters.propertyMarketingType === PropertyMarketingType.rent && {
      rentPriceMin: filters.price?.min?.toString(),
      rentPriceMax: filters.price?.max?.toString(),
    }),
    currency: currencySuffix,
    measurementUnit: unitSuffix.toUpperCase(),
    livingAreaMin: filters.livingSurface?.min?.toString(),
    livingAreaMax: filters.livingSurface?.max?.toString(),
    plotAreaMin: filters.plotSurface?.min?.toString(),
    plotAreaMax: filters.plotSurface?.max?.toString(),
    roomsMin: filters.rooms?.min?.toString(),
    roomsMax: filters.rooms?.max?.toString(),
    bathroomsMin: filters.bathrooms?.min?.toString(),
    bathroomsMax: filters.bathrooms?.max?.toString(),
    bedroomsMin: filters.bedrooms?.min?.toString(),
    bedroomsMax: filters.bedrooms?.max?.toString(),
    hasGarden: filters.hasGarden,
    hasTerrace: filters.hasTerrace,
    hasBalcony: filters.hasBalcony,
    hasBasement: filters.hasBasement,
    hasSeaView: filters.hasSeaOrLakeView,
    hasPatio: filters.hasPatio,
    hasTennisYard: filters.hasTennisCourt,
    hasPool: filters.hasSwimmingPool,
    isWaterfront: filters.hasWaterfront,
    postalCode: filters.postalCode,
    placeId: filters.placeId,
    businessArea: filters.businessArea,
    searchRadius: filters.searchRadius?.toString() || '0',
  };
  // remove empty values
  const ctx = Object.entries(context).reduce((acc, currentValue) => {
    const [key, value] = currentValue;
    if (value) {
      acc = {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, {});
  return ctx;
}

export function hasUserAcceptedTracking(): boolean {
  if (!window.Didomi) {
    return false;
  }

  const userCookieStatus = window.Didomi.getUserStatus?.();
  const legitimateInterestConsents = (userCookieStatus?.vendors?.consent.enabled ?? []) as string[];

  return legitimateInterestConsents.includes('c:engeland-UPm6kfxh');
}

export const trackSearchAlertCreatedInDataLayer = (placeName: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'search_alert_created',
    searchAlertLocation: placeName,
    templateType: 'search-result-page',
    pageType: 'autogen-search-result-page',
  });
};

export const mapPropertyToPropertyContext = (property: Property): Parameters<typeof getPropertyContext>[0] => {
  const {
    areas,
    prices,
    baseCurrency,
    displayID,
    id,
    evProfileID,
    objectType,
    objectSubType,
    baseUnit,
    condition,
    hasBuiltInKitchen,
    hasSecuritySystem,
    hasBasement,
    hasSeaOrLakeView,
    hasTennisCourt,
    hasGarage,
    hasSwimmingPool,
    hasWaterfront,
    hasAirConditioning,
    ...rest
  } = property;

  const livingArea = areas?.['sqmt']?.livingSurface;
  const plotArea = areas?.['sqmt']?.plotSurface;

  return {
    propertyId: displayID,
    profileId: evProfileID,
    bffObjectId: id,
    type: objectType || '',
    subType: objectSubType,
    currency: baseCurrency,
    price: getCardPrice(property, Language.en).value,
    measurementUnit: baseUnit,
    ...(livingArea && { livingArea }),
    ...(plotArea && { plotArea }),
    propertyCondition: condition || '',
    hasKitchen: hasBuiltInKitchen,
    hasAlarmSystem: hasSecuritySystem,
    hasCellar: hasBasement,
    hasAirconditioning: hasAirConditioning,
    hasSeaview: hasSeaOrLakeView,
    hasTennisYard: hasTennisCourt,
    hasParking: hasGarage,
    hasPool: hasSwimmingPool,
    isWaterfront: hasWaterfront,
    ...rest,
  };
};

import { Popover } from 'react-aria-components';
import { styled } from 'styled-components';

export const StyledListItem = styled.li<{ $isFocused: boolean }>`
  list-style: none;
  background: var(--color-light);
  ${({ $isFocused }) => ($isFocused ? 'background-color: var(--color-searchinput-result-hover);' : '')}
  &:hover {
    background-color: var(--color-searchinput-result-hover);
  }
  cursor: pointer;
`;

export const StyledPopover = styled(Popover)`
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  background-color: var(--color-light);
  border-radius: var(--border-radius-s);
  box-shadow: var(--shadow-m);
  max-height: 350px;
  overflow-y: scroll;
  width: var(--trigger-width);
  z-index: var(--zindex-m);
`;

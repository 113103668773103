import { type CountryCode, Currency, extractParamsFromUrl, Language, MeasurementSystem, parseSearchParams } from '@pkgs/api';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_SETTINGS } from '@/consts/settings';
import { readSettingsFromStorage, writeSettingsToStorage } from '@/utils/localStorage';

export type Settings = {
  currency: Currency;
  measurementSystem: MeasurementSystem;
  countryCode?: CountryCode;
};

export type SettingsContextType = {
  headerLogoUrl: string;
  currency: Currency;
  measurementSystem: MeasurementSystem;
  countryCode?: CountryCode;
  language: Language;
  setSettings: (settings: Partial<Settings>) => void;
};

const initialStore = {
  headerLogoUrl: '',
  setSettings: () => null,
  currency: Currency.EUR,
  measurementSystem: MeasurementSystem.metric,
  countryCode: undefined,
  language: Language.en,
};

export const SettingsContext = React.createContext<SettingsContextType>(initialStore);

function getInitialSettingsState(language: Language, path: string): Settings {
  // Fallback to English if the language is not supported. Will be removed with next prod deployment
  if (!Object.keys(Language).includes(language)) {
    language = Language.en;
  }

  let { currency, measurementSystem } = DEFAULT_SETTINGS;

  try {
    // In a client-side environment, override with settings from local storage
    if (typeof window !== 'undefined') {
      const localSettings = readSettingsFromStorage();
      currency = localSettings?.currency ?? currency;
      measurementSystem = localSettings?.measurementSystem ?? measurementSystem;
    }

    // Further override with settings from URL if available
    const { options: urlSettings } = parseSearchParams(path);
    currency = urlSettings.currency ?? currency;
    measurementSystem = urlSettings.measurementSystem ?? measurementSystem;
  } catch (error) {
    console.error('Error processing settings:', error);
  }

  return {
    currency,
    measurementSystem,
  };
}

export const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { countryCode, language = Language.en } = extractParamsFromUrl(router.asPath);

  const [currentSettings, setCurrentSettings] = useState<Settings>({
    ...getInitialSettingsState(language, router.asPath),
    countryCode,
  });

  const [headerLogoUrl, setHeaderLogoUrl] = useState('');

  const setSettings = useCallback(
    (newSettings: Partial<Settings>) => {
      const updatedSettings: Settings = {
        ...currentSettings,
        ...newSettings,
      };

      setCurrentSettings(updatedSettings);

      writeSettingsToStorage(updatedSettings, language);
    },
    [currentSettings, language]
  );

  useEffect(() => {
    //replacing the referrer language with the preference of the user
    const logoUrl = document.referrer.includes('engelvoelkers.com')
      ? document.referrer.replace(/\.com\/../, '.com/' + language)
      : 'https://www.engelvoelkers.com/' + language;

    setHeaderLogoUrl(logoUrl);

    writeSettingsToStorage(currentSettings, language);
  }, []);

  const value = useMemo(
    () => ({
      headerLogoUrl,
      language,
      currency: currentSettings.currency,
      measurementSystem: currentSettings.measurementSystem,
      countryCode: currentSettings.countryCode,
      setSettings,
    }),
    [currentSettings.countryCode, currentSettings.currency, currentSettings.measurementSystem, headerLogoUrl, language, setSettings]
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

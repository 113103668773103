import { type TFunction } from 'i18next';
import type { ReactElement } from 'react';

import { navigationMenu } from './headerConfig';
import { HeaderNavigationItemOptions } from './HeaderNavigation';

export const getHeaderNavigationItems = (
  t: TFunction<'header_footer'>
): { id: number; title: string; content: ReactElement; url?: string }[] => {
  return [
    navigationMenu.main.sell,
    navigationMenu.main.buy,
    navigationMenu.main.rent,
    navigationMenu.main.propertyPrices,
    navigationMenu.main.career,
    navigationMenu.main.company,
  ]
    .filter((navigationMenuItem) => t(navigationMenuItem.labelKey))
    .map((navigationMenuItem) => {
      return {
        id: navigationMenuItem.id,
        title: t(navigationMenuItem.labelKey),
        url: navigationMenuItem.urlKey ? t(navigationMenuItem.urlKey) : '',
        content: <HeaderNavigationItemOptions items={navigationMenuItem.menuItems} />,
      };
    });
};

import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const InstagramIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <rect width={24} height={24} fill="currentColor" rx={2} />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.291 12.004c0-2.04 1.66-3.699 3.7-3.699s3.699 1.659 3.699 3.699-1.66 3.7-3.7 3.7c-2.039 0-3.699-1.66-3.699-3.7Zm1.563.001a2.136 2.136 0 1 0 4.272 0 2.136 2.136 0 0 0-4.272 0Z"
        clipRule="evenodd"
      />
      <path fill="#fff" d="M16.684 8.195a.875.875 0 1 1-1.75 0 .875.875 0 0 1 1.75 0Z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.44 4.828h7.133a3.626 3.626 0 0 1 3.632 3.618v7.117c0 1.998-1.629 3.609-3.632 3.609H8.441c-2.003 0-3.636-1.611-3.636-3.61V8.447c0-1.998 1.633-3.618 3.636-3.618Zm7.109 12.797a2.058 2.058 0 0 0 2.053-2.063V8.447c0-1.136-.917-2.071-2.053-2.071H8.432c-1.136 0-2.08.935-2.08 2.07v7.117c0 1.137.944 2.063 2.08 2.063h7.117Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

import { useObjectRef } from '@react-aria/utils';
import { useToggleState } from '@react-stately/toggle';
import { forwardRef, type ReactNode } from 'react';
import { type AriaCheckboxProps, useCheckbox } from 'react-aria';

import { StyledContainer, StyledIcon, StyledInput } from './Checkbox.styled';

export interface CheckboxProps extends AriaCheckboxProps {
  className?: string;
  error?: boolean;
  label: ReactNode;
  children?: never;
  isRequired?: boolean;
  'aria-invalid'?: 'true' | 'false' | undefined;
  //! added to handle invalid event
  onInvalid?: (event: React.InvalidEvent<HTMLInputElement>) => void;
  'aria-label'?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, isDisabled, isRequired, error, name, label, onInvalid, ...props }, forwardedRef) => {
    const state = useToggleState(props);
    const ref = useObjectRef(forwardedRef);
    const { inputProps } = useCheckbox({ 'aria-label': props['aria-label'], ...props, isDisabled }, state, ref);

    return (
      <StyledContainer className={className} $disabled={isDisabled}>
        <StyledInput
          {...inputProps}
          type="checkbox"
          ref={ref}
          $error={error}
          name={name}
          required={isRequired}
          aria-invalid={props['aria-invalid']}
          //! added to handle invalid event
          onInvalid={onInvalid}
        />
        <StyledIcon />
        {label}
      </StyledContainer>
    );
  },
);

Checkbox.displayName = 'Checkbox';

import { type TypographyProps, Box, Popover, styled, Typography } from '@mui/material';

import { OutlinedButton } from '../Button/Button';
import { LinkWithParams } from '../LinkWithParams';
import { PropertyCard } from '../PropertyCard/PropertyCard';

export const StyledLink = styled(LinkWithParams, {
  shouldForwardProp: (props) => props !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  marginRight: 50,
  color: selected ? theme.palette.primary.main : theme.palette.grey[900],
  textDecoration: 'none',
  alignItems: 'center',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: 0,
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  marginRight: theme.spacing(3),
  display: 'flex',
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  marginTop: theme.spacing(1.25),
  '& .MuiPopover-paper': {
    '&:before': {
      content: '""',
      height: 2,
      width: 90,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      left: 115,
    },
    pointerEvents: 'auto',
    padding: theme.spacing(2.5, 4, 3),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.32)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 390,
  },
}));

export const StyledPropertyCard = styled(PropertyCard)(({ theme }) => ({
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(1),
}));

export const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.grey[900],
  marginBottom: theme.spacing(2),
}));

export const StyledMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginBottom: theme.spacing(3.75),
}));

export const StyledOutlinedButton = styled(OutlinedButton)(({ theme }) => ({
  marginTop: theme.spacing(3.5),
  width: 224,
  margin: 'auto',
}));

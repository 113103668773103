import { createContext, type ReactNode, useEffect, useMemo, useState } from 'react';

import { ViewportSize } from '../utils/mediaQueries';

export const Breakpoints = {
  MOBILE: ViewportSize.MOBILE,
  TABLET_PORTRAIT: ViewportSize.TABLET_PORTRAIT,
  TABLET_LANDSCAPE: ViewportSize.TABLET_LANDSCAPE,
  LAPTOP: ViewportSize.LAPTOP,
  DESKTOP: ViewportSize.DESKTOP,
} as const;

export type Breakpoints = (typeof Breakpoints)[keyof typeof Breakpoints];

export type BreakpointProviderType = {
  breakpoint?: Breakpoints;
  isMobile: boolean;
  isTabletPortrait: boolean;
  isTabletLandscape: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
};

type MediaQuery = {
  breakpoint: Breakpoints;
  query: string;
};

const initialState: BreakpointProviderType = {
  isMobile: false,
  isTabletPortrait: false,
  isTabletLandscape: false,
  isLaptop: false,
  isDesktop: false,
};

export const BreakpointContext = createContext<BreakpointProviderType>(initialState);

const mediaQueries = Object.values(Breakpoints).map<MediaQuery>((breakpoint, i, arr) => ({
  breakpoint,
  query: i === arr.length - 1 ? `(min-width: ${breakpoint}px)` : `(min-width: ${breakpoint}px) and (max-width: ${arr[i + 1]! - 1}px)`,
}));

export const BreakpointProvider = ({ children }: { children: ReactNode }) => {
  const [breakpoint, setBreakpoint] = useState<Breakpoints>();

  const isMobile = breakpoint === Breakpoints.MOBILE;
  const isTabletPortrait = breakpoint === Breakpoints.TABLET_PORTRAIT;
  const isTabletLandscape = breakpoint === Breakpoints.TABLET_LANDSCAPE;
  const isLaptop = breakpoint === Breakpoints.LAPTOP;
  const isDesktop = breakpoint === Breakpoints.DESKTOP;

  useEffect(() => {
    for (const mediaQuery of mediaQueries) {
      const matcher: MediaQueryList = window.matchMedia(mediaQuery.query);

      const handleChange = (event: MediaQueryListEvent) => {
        if (event.matches) {
          setBreakpoint(mediaQuery.breakpoint);
        }
      };

      if (matcher.matches) {
        setBreakpoint(mediaQuery.breakpoint);
      }

      matcher.addEventListener('change', handleChange);
    }
  }, []);

  const state = useMemo(
    () => ({
      breakpoint,
      isMobile,
      isTabletPortrait,
      isTabletLandscape,
      isLaptop,
      isDesktop,
    }),
    [breakpoint],
  );

  return <BreakpointContext.Provider value={state}>{children}</BreakpointContext.Provider>;
};

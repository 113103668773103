import { css, styled } from 'styled-components';

import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';

type ButtonProps = {
  $isSelected: boolean;
  $isFocusVisible: boolean;
  $isPressed: boolean;
};

const buttonStyles = css`
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  max-height: 32px;
  padding: var(--distance-static-xxs) var(--distance-static-xs);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius-xl);
  user-select: none;
`;

const selectedStyles = css<ButtonProps>`
  background-color: ${({ $isPressed }) => ($isPressed ? 'var(--color-light)' : 'var(--color-dark-subdued)')};
  > * {
    color: ${({ $isPressed }) => ($isPressed ? 'var(--color-dark-intense)' : 'var(--color-light)')};
  }
`;

const notSelectedStyles = css<ButtonProps>`
  background-color: ${({ $isPressed }) => ($isPressed ? 'var(--color-dark-subdued)' : 'var(--color-light)')};
  > * {
    color: ${({ $isPressed }) => ($isPressed ? 'var(--color-light)' : 'var(--color-dark-intense)')};
  }
`;

export const StyledWrapper = styled.span`
  ${buttonStyles};
  border: 1px solid var(--color-light-shade);
  background-color: var(--color-light-shade);
  color: var(--color-dark);
`;
export const StyledButton = styled.button<ButtonProps>`
  ${buttonStyles};
  cursor: pointer;
  border: 1px solid ${({ $isSelected }) => ($isSelected ? 'var(--color-dark-subdued)' : 'var(--color-dark-intense)')};
  outline-offset: 1px;
  outline: ${({ $isFocusVisible }) => ($isFocusVisible ? '2px solid var(--color-dark-subdued)' : 'none')};
  ${({ $isSelected }) => ($isSelected ? selectedStyles : notSelectedStyles)};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ $isPressed }) => ($isPressed ? 'var(--color-dark-subdued)' : 'var(--color-dark)')};
      > * {
        color: var(--color-light);
      }
    }
  }
  &:disabled {
    cursor: auto;
    background: var(--color-light-shade);
    border: 1px solid var(--color-light-shade);
    > * {
      color: var(--color-dark-lighter);
    }
  }
`;

export const StyledLabel = styled(Copy)`
  &&& {
    text-align: center;
    letter-spacing: 0.42px;
    margin: 0 var(--distance-static-xxs);
  }
`;

export const StyledLabelContainer = styled.span`
  display: flex;
  text-align: center;
  align-items: center;
  > svg {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

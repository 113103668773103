/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

import { customInstance } from '../../custom-instance';
import type { TrackingV2Response } from '../../models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns all tracking ids based on a given master data shop id
 */
export const getTrackingByShopId = (masterDataShopId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<TrackingV2Response>({ url: `/v2/tracking/shop/${masterDataShopId}`, method: 'GET' }, options);
};
/**
 * @summary Returns all tracking ids based on a given google GA4 id
 */
export const getTrackingByGa4Id = (ga4Id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<TrackingV2Response>({ url: `/v2/tracking/ga4/${ga4Id}`, method: 'GET' }, options);
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type GetTrackingByShopIdResult = NonNullable<Awaited<ReturnType<typeof getTrackingByShopId>>>;
export type GetTrackingByGa4IdResult = NonNullable<Awaited<ReturnType<typeof getTrackingByGa4Id>>>;

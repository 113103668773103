import { type Property } from '@pkgs/api';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { GA4ID_URL_PARAM } from '@/consts/tracking';
import { type PropertyTrackingIds } from '@/types/tracking';
import { fetchTrackingIDs, isGA4ID, mapPropertyTrackingIdsToDataLayerTrackingIds, pushReferrerTrackingToDataLayer } from '@/utils/tracking';

const pushGTMEvent = (eventName: DataLayerEventType['event'], eventData?: Record<string, string>): void => {
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(() =>
    window.dataLayer?.push({
      event: eventName,
      ...eventData,
    })
  );
};

export const useGAEventOnce = (eventName: DataLayerEventType['event'], eventMetaData?: Record<string, string>): void => {
  useEffectOnce(() => {
    pushGTMEvent(eventName, eventMetaData);
  });
};

export const useGAEvent = (eventName: DataLayerEventType['event'], hasLicencePartnerGAAccount: boolean): void => {
  useEffect(() => {
    if (hasLicencePartnerGAAccount) {
      pushGTMEvent(eventName);
    }
  }, [hasLicencePartnerGAAccount]);
};

const pushTrackingIdsToDataLayer = (propertyTracking: PropertyTrackingIds | undefined) => {
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(() => {
    pushReferrerTrackingToDataLayer({
      propertyTrackingIds: mapPropertyTrackingIdsToDataLayerTrackingIds(propertyTracking ?? {}),
    });
  });
};

export const useGTMTackingIdsEvents = (property?: Property) => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const ga4IdParam = url.searchParams.get(GA4ID_URL_PARAM);
    const googleAnalytics4ID = isGA4ID(ga4IdParam) ? ga4IdParam : '';
    if (googleAnalytics4ID) {
      fetchTrackingIDs(googleAnalytics4ID)
        .then((trackingIDs) => {
          if (trackingIDs) {
            window.didomiOnReady = window.didomiOnReady || [];
            window.didomiOnReady.push(() =>
              pushReferrerTrackingToDataLayer({
                urlTrackingIds: mapPropertyTrackingIdsToDataLayerTrackingIds(trackingIDs),
              })
            );
          } else {
            pushTrackingIdsToDataLayer(property?.trackingData);
          }
        })
        .catch(() => {
          console.error('error while fetching tracking ids');
        });
    } else {
      pushTrackingIdsToDataLayer(property?.trackingData);
    }
  }, []);
};

import { commonPropertySuggest } from '@generated/search-bff';
import { type ApiConfig, type RawListing } from '@pkgs/api/types';
import { type ApiResult, type GetDisplayIdSuggestionOptions, type Property } from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils/error';
import { refineProperty } from '@pkgs/api/utils/refineProperty';

/**
 * Fetch suggestions based on a partial or full display ID.
 * @function
 * @param {string} displayId - The display ID of the property to be fetched.
 * @param {GetDisplayIdSuggestionOptions} options - Options to customize the search: language and number of suggestions to return.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<Property[]>>} A promise that resolves to the result of the API call, containing either the detailed property information or error details.
 *
 * @summary Fetch suggestions based on a partial or full display ID.
 */

export async function getSuggestionsByDisplayId(
  query: string,
  options: GetDisplayIdSuggestionOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<Property[]>> {
  if (query === '') {
    console.warn('Input is an empty string.');
  }
  if (options.limit < 1) {
    console.warn('Limit is an less than 1.');
  }
  try {
    const response = await commonPropertySuggest(
      {
        input: query,
        limit: options.limit,
        languages: [options.language],
      },
      apiConfig,
    );
    return {
      status: 'success',
      data: response.map((i) => refineProperty(i as RawListing, options.language)),
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

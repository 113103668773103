/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { VisuallyHidden } from 'react-aria';

import type { IconButtonShapes, IconButtonSizes, IconButtonVariant } from './IconButton.styled';
import { StyledIconButton } from './IconButton.styled';

export interface IIconButtonProps {
  className?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: IconButtonVariant;
  size?: IconButtonSizes;
  shape?: IconButtonShapes;
  icon: React.ReactNode;
  as?: 'button' | 'a';
  href?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  target?: string;
}

export const IconButton = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IIconButtonProps>(
  (
    {
      className,
      label,
      onClick,
      variant = 'primary',
      size = 'small',
      shape = 'circle',
      icon,
      as,
      href,
      disabled,
      type = 'button',
      target = '_blank',
      //! added pass-through props to support aria attributes
      ...props
    },
    ref,
  ) => {
    if (as === 'a') {
      if (!href) console.error('IconButton Component: href is required for anchor tag');

      return (
        <StyledIconButton
          href={href}
          ref={ref as React.RefObject<HTMLAnchorElement>}
          className={className}
          as="a"
          $variant={variant}
          $size={size}
          $shape={shape}
          target={target}
          //! added pass-through props to support aria attributes
          {...props}
        >
          <VisuallyHidden>{label}</VisuallyHidden>
          {icon}
        </StyledIconButton>
      );
    }

    return (
      <StyledIconButton
        className={className}
        as="button"
        ref={ref as any}
        onClick={onClick as any}
        $variant={variant}
        $size={size}
        $shape={shape}
        disabled={disabled}
        type={type}
        //! added pass-through props to support aria attributes
        {...props}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {icon}
      </StyledIconButton>
    );
  },
);

IconButton.displayName = 'IconButton';

import { type BigDecimalRangeDto } from '@ev/search-modules-api';
import { useState } from 'react';

import { useAppContext } from '../../hooks/useAppContext';
import { validateRangeValues } from '../../utils/numberFormatters';
import { formatRangeLabel } from '../../utils/stringUtils';
import { RangeDropdown } from '../RangeDropdown/RangeDropdown';
import { ConstructionYearInput } from '../RangeInputs/ConstructionYearInput';
import { type FilterDropdownProps } from './FilterDropdown.types';

export const ConstructionYearFilterDropdown = ({ value, className, onChange, 'data-test-id': dataTestId }: FilterDropdownProps) => {
  const { t } = useAppContext();
  const [range, setRange] = useState<BigDecimalRangeDto | undefined>(value ? validateRangeValues(value) : undefined);

  return (
    <RangeDropdown
      className={className}
      label={t('search.searchbar.advFilter.constructionYear')}
      value={value ? formatRangeLabel(value) : ''}
      onDone={() => {
        onChange({ constructionYear: range ?? undefined });
      }}
      onReset={() => {
        onChange({ constructionYear: undefined });
        setRange(undefined);
      }}
      data-test-id={dataTestId ? `${dataTestId}_dropdown` : 'search-components_construction-year-filter_dropdown'}
    >
      <ConstructionYearInput hiddenLabel={true} defaultRange={value} onChange={setRange} />
    </RangeDropdown>
  );
};

import { type ImageProps } from 'next/image';
import { forwardRef, useEffect, useRef } from 'react';

export type ImageComponentWithoutRef = (props: ImageProps) => JSX.Element;

export type ImageComponent = ImageComponentWithoutRef | React.ForwardRefExoticComponent<ImageProps & React.RefAttributes<HTMLImageElement>>;

export const DefaultImageComponent: ImageComponent = forwardRef<HTMLImageElement, ImageProps>(
  ({ width, height, src, priority, ...props }, ref) => {
    const internalRef = useRef<HTMLImageElement>(null);
    const imgRef = ref || internalRef; // Use forwarded ref if provided, else use internal ref

    // Fix React issue for DOM property not being recognized
    useEffect(() => {
      if ('current' in imgRef && imgRef.current && priority) {
        imgRef.current.setAttribute('fetchpriority', 'high');
      }
    }, [priority, imgRef]);

    return <img {...props} ref={imgRef as React.RefObject<HTMLImageElement>} width={width} height={height} src={src as string} />;
  },
);

DefaultImageComponent.displayName = 'DefaultImageComponent';

import styled, { css } from 'styled-components';
import { laptop, tabletLandscape, tabletPortrait } from '../utils/mediaQueries';
import { backgroundColor, BackgroundColorVariants } from '../backgroundColor';

const tabletLandscapeSizes = {
  l: css`
    max-width: 1160px;
    min-width: 825px;
  `,
  m: css`
    max-width: 685px;
  `,
  s: css`
    max-width: 525px;
  `,
} as const;

const laptopSizes = {
  xl: css`
    max-width: 1360px;
    min-width: 1160px;
  `,
  l: css`
    max-width: 1128px;
    min-width: 928px;
  `,
  m: css`
    max-width: 896px;
    min-width: 696px;
  `,
  s: css`
    max-width: 664px;
    min-width: 464px;
  `,
} as const;

export type TabletLandscapeSize = keyof typeof tabletLandscapeSizes;
export type LaptopSize = keyof typeof laptopSizes;

interface StyledWrapperProps {
  $tabletLandscapeWidth: TabletLandscapeSize;
  $laptopWidth: LaptopSize;
  $smallerBottomSpace?: boolean;
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  //* spearhead expects this to have box-sizing: content-box but doesn't set it on the component on their side.
  box-sizing: content-box;
  margin: 0 auto;
  padding: var(--distance-dynamic-s) var(--distance-static-m) var(--distance-dynamic-xl);

  ${({ $smallerBottomSpace }) =>
    $smallerBottomSpace &&
    css`
      padding-bottom: var(--distance-dynamic-m);
    `}

  @media screen and (${tabletPortrait}) {
    padding-left: var(--distance-static-xxl);
    padding-right: var(--distance-static-xxl);
  }

  @media screen and (${tabletLandscape}) {
    ${({ $tabletLandscapeWidth }) => tabletLandscapeSizes[$tabletLandscapeWidth]}
  }

  @media screen and (${laptop}) {
    ${({ $laptopWidth }) => laptopSizes[$laptopWidth]}
  }
`;

export const StyledBackground = styled.div<{
  $backgroundVariant?: BackgroundColorVariants;
}>`
  > ${StyledWrapper} {
    padding-top: 0;
  }
  ${({ $backgroundVariant }) =>
    backgroundColor({
      backgroundColor: $backgroundVariant,
      coastToCoast: true,
    })}
`;

import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { SearchAlertFrequencyEnum } from '@pkgs/components/hooks/useSearchAlert';
import { Label } from '@spearhead/components/elements/Label/Label';
import { type AriaRadioGroupProps, useRadioGroup } from 'react-aria';
import { useRadioGroupState } from 'react-stately';

import { FrequencyInput } from './FrequencyInput';
import { StyledFrequencyPicker, StyledRadioGroup } from './FrequencyPicker.styled';

export const FrequencyPicker = (props: AriaRadioGroupProps) => {
  const { t } = useAppContext();
  const label = t('search.searchAlert.create.frequency');

  const state = useRadioGroupState({
    defaultValue: SearchAlertFrequencyEnum.INSTANTLY,
  });

  const { radioGroupProps, labelProps } = useRadioGroup(
    {
      ...props,
      orientation: 'horizontal',
      name: 'frequency',
      label,
    },
    state,
  );

  return (
    <StyledFrequencyPicker>
      <Label {...labelProps} label={label} as="label" />
      <StyledRadioGroup {...radioGroupProps}>
        {Object.values(SearchAlertFrequencyEnum).map((value) => (
          <FrequencyInput label={t(`search.searchAlert.form.frequency.${value}`)} value={value} state={state} key={value} />
        ))}
      </StyledRadioGroup>
    </StyledFrequencyPicker>
  );
};

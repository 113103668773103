import { type SwitchProps as AriaSwitchProps } from 'react-aria-components';

import { Copy } from '../Copy/Copy';
import { StyledIndicator, StyledSwitch } from './Switch.styled';

export type SwitchProps = AriaSwitchProps & {
  label: string;
};

export const Switch = (props: SwitchProps) => {
  return (
    <StyledSwitch {...props}>
      <Copy size="l" text={props.label} />
      <StyledIndicator />
    </StyledSwitch>
  );
};

import { type LabelHTMLAttributes } from 'react';

import { StyledLabel } from './Label.styled';

export type LabelTagName = 'div' | 'label';

//! added to handle aria attributes
export type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  id?: string;
  label?: React.ReactNode;
  required?: boolean;
  as: LabelTagName;
  htmlFor?: string;
};

export const Label = ({
  id,
  htmlFor,
  label,
  required,
  as = 'label',
  //! added to handle aria attributes
  ...props
}: LabelProps) => (
  //! added props to handle aria attributes
  <StyledLabel {...props} as={as} id={id} htmlFor={htmlFor} $required={required}>
    {label}
  </StyledLabel>
);

import { postListingsByIds } from '@generated/search-bff';
import { type ApiConfig, type RawListing } from '@pkgs/api/types';
import { type ApiResult, type BaseOptions, type Property } from '@pkgs/api/types';
import { getDefaultParams } from '@pkgs/api/utils/defaultOptions';
import { getErrorResult } from '@pkgs/api/utils/error';
import { refineProperty } from '@pkgs/api/utils/refineProperty';

/**
 * Fetch property listings based on an array of display IDs.
 * @function
 * @param {string[]} displayIds - An array of display IDs of the properties to be fetched.
 * @param {BaseOptions} options - Options to customize the search: language, currency, and measurement unit.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<Property[]>>} A promise that resolves to the property listings or error details.
 *
 * @summary Fetch property listings based on an array of display IDs.
 */

export async function getPropertiesByDisplayIds(
  displayIds: string[],
  options: BaseOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<Property[]>> {
  if (displayIds.length === 0) {
    console.warn('displayIds is an empty array.');
  }
  try {
    const params = getDefaultParams(options);

    const listingsDtos = await postListingsByIds({ displayIds }, params, apiConfig);

    return {
      status: 'success',
      data: listingsDtos.map((i) => refineProperty(i as RawListing, options.language)),
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

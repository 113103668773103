import { type ErrorResult } from '@pkgs/api/types';
import { type AxiosError, isAxiosError } from 'axios';

import { isRecord } from './object';

type ErrorDetails = {
  traceId: string;
  details: string;
};

const isErrorDetails = (data: unknown): data is ErrorDetails => {
  return isRecord(data) && 'traceId' in data && 'details' in data;
};

export const createError = (error: unknown): AxiosError | Error => {
  if (isAxiosError(error) || error instanceof Error) {
    return error;
  } else if (isRecord(error) && 'message' in error) {
    return new Error(error.message as string);
  } else if (typeof error === 'string') {
    return new Error(error);
  } else {
    return new Error('An unknown error occurred.');
  }
};

export const getErrorMessage = (error: AxiosError | Error): string => {
  if (isAxiosError(error) && error.isAxiosError && error.response) {
    const { data, status } = error.response;
    return `AxiosError: ${status} - ${isErrorDetails(data) ? data.details : JSON.stringify(data)}`;
  } else {
    return error.message;
  }
};

export const getErrorResult = (error: unknown): ErrorResult => {
  const parsedError = createError(error);
  return {
    status: 'error',
    error: parsedError,
    errorMessage: getErrorMessage(parsedError),
  };
};

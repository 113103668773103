import { styled } from 'styled-components';

import { useAppContext } from '../../hooks/useAppContext';
import { type Features } from '../../types/types';
import { StyledCopy, StyledFieldset } from '../AdvancedFilters/AdvancedFilters.styled';
import { Chip } from '../Chip/Chip';

const StyledContainer = styled(StyledFieldset)`
  &&& {
    flex-wrap: wrap;
    gap: var(--distance-static-xs);
  }
`;

type FeaturesFilterProps = {
  features: Features;
  onChange: (arg: Partial<Features>) => void;
};
export const FeaturesFilter = ({ features, onChange }: FeaturesFilterProps) => {
  const { t } = useAppContext();
  return (
    <StyledContainer>
      <StyledCopy forwardedAs="legend" size="m" text={t('search.searchbar.advFilter.features')} />
      {Object.keys(features).map((feature) => (
        <Chip
          key={feature}
          label={t(`search.searchbar.advFilter.features.${feature}`)}
          isSelected={Boolean(features[feature as keyof Features])}
          data-cy={`${feature}_featureButton`}
          onChange={(isSelected) => {
            onChange({ [feature as keyof Features]: isSelected });
          }}
          data-test-id={`search-components_features-filter_${feature}_chip`}
        />
      ))}
    </StyledContainer>
  );
};

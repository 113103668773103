import { type BigDecimalRangeDto } from '@generated/search-bff';
import { type Entries } from '@pkgs/api/types';
import { isPlainObject } from 'lodash-es';

export const isRecord = (value: unknown): value is Record<string, unknown> => isPlainObject(value);

export const isRangeFilter = (value: unknown): value is BigDecimalRangeDto => {
  if (!isRecord(value)) return false;

  return 'min' in value || 'max' in value;
};

export const isKey = <T extends object>(x: T, k: PropertyKey): k is keyof T => {
  return k in x;
};

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

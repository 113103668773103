// src/constants/propertyData.ts
var displayIdPrefixes = [
  "AER-S",
  "AER-R",
  "AM-",
  "D-0",
  "DNK-",
  "ENV\\d",
  "EVHK",
  "EVO-",
  "GES-",
  "PRA-",
  "T-",
  "W-0",
  "BCN-",
  "AEC-",
  "LIS-",
  "MAD-",
  "VLC-",
  "MIL-",
  "OI-",
  "CL\\d",
  // the prefix starts with CL and the next char is a number
  "RL\\d",
  "G-0",
  "EAVCHCOM"
];

export {
  displayIdPrefixes
};

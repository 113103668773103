import { css, keyframes, styled } from 'styled-components';

import { Button } from '../../../spearhead/lib/components/elements/Button/Button';
import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';
import { IconButton } from '../../../spearhead/lib/components/elements/IconButton/IconButton';
import { from } from '../../utils/mediaQueries';

const slide = keyframes`  
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }`;

export const StyledOverlay = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-dark-transparent);
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const StyledModal = styled.div`
  background: var(--color-light);
  outline: none;
  height: 100%;
  max-height: 100%;
  animation: ${slide} 500ms;
`;

export const StyledDivider = styled.hr`
  all: unset;
  display: flex;
  flex-shrink: 0;
  height: 1px;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-top: var(--distance-static-s);
  border-radius: 20px;
  background-color: #f0f0f0;
`;

const desktopHiddenStyle = css`
  @media screen and (${from.tabletLandscape}) {
    display: none;
  }
`;

export const StyledBuyRentFilterDivider = styled(StyledDivider)`
  ${desktopHiddenStyle}
`;

export const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-m);
`;

export const StyledBuyRentFilterRowContainer = styled(StyledRowContainer)`
  ${desktopHiddenStyle}
`;

export const StyledRowContainerHorizontal = styled(StyledRowContainer)`
  @media screen and (${from.tabletLandscape}) {
    flex-direction: row;
    gap: var(--distance-static-l);
  }
`;

export const StyledFieldset = styled.fieldset`
  display: flex;
  gap: var(--distance-static-s);
  margin: 0;
  padding: 0;
  border: none;
  align-items: center;
`;

export const StyledCopy = styled(Copy)`
  color: var(--color-dark-subdued);
`;

export const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100vw;
  height: 100%;
  max-height: 100%;

  @media screen and (${from.tabletLandscape}) {
    width: 560px;
    max-width: 560px;
  }

  box-sizing: border-box;

  & *,
  & *::before,
  & *::after {
    box-sizing: inherit;
  }
`;
export const StyledDialogHeader = styled.div`
  display: flex;
  justify-content: end;
  padding: var(--distance-static-xs) var(--distance-static-xs) var(--distance-static-xs) var(--distance-static-m);
  @media screen and (${from.tabletLandscape}) {
    padding: var(--distance-static-s) var(--distance-static-m) var(--distance-static-s) var(--distance-static-l);
  }
`;

export const StyledDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  gap: var(--distance-static-m);
  padding: 0 var(--distance-static-m) var(--distance-static-l) var(--distance-static-m);
  @media screen and (${from.tabletLandscape}) {
    padding: 0 var(--distance-static-xxl) var(--distance-static-l) var(--distance-static-xxl);
  }
`;

export const StyledDialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--distance-static-m);
  border-top: 1px solid var(--color-dark-decent);
  @media screen and (${from.tabletLandscape}) {
    padding: var(--distance-static-m) var(--distance-static-xxl);
  }
`;

export const StyledDesktopButton = styled(Button)`
  display: none;
  padding-left: var(--distance-static-m);
  @media screen and (${from.tabletLandscape}) {
    display: flex;
  }
`;

export const StyledMobileButton = styled(IconButton)<{ $isMapView: boolean }>`
  &&& {
    display: flex;
    flex-shrink: 0;
  }
  ${({ $isMapView }) =>
    !$isMapView &&
    css`
      @media screen and (${from.tabletLandscape}) {
        &&& {
          display: none;
        }
      }
    `}
`;

import {
  getPlaceDetailsV2
} from "./chunk-XNHY4EZT.js";
import {
  getLocationFromPlaceDetails
} from "./chunk-FGZHCMS7.js";
import {
  getErrorResult
} from "./chunk-XNWIRXJJ.js";
import {
  createSessionToken
} from "./chunk-EYGO4I34.js";

// src/getPlaceLocationDetails/getPlaceLocationDetails.ts
async function getPlaceLocationDetails(placeId, { language, sessionToken }, apiConfig) {
  try {
    const result = await getPlaceDetailsV2(
      {
        placeId,
        language,
        sessionToken: sessionToken ?? createSessionToken()
      },
      apiConfig
    );
    return {
      status: "success",
      data: getLocationFromPlaceDetails(result)
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  getPlaceLocationDetails
};

import {
  confirm
} from "./chunk-YYWHXEGH.js";
import {
  getErrorResult
} from "./chunk-52TBLELU.js";

// src/confirmSearchAlert/confirmSearchAlert.ts
async function confirmSearchAlert(searchAlertId, apiConfig) {
  try {
    await confirm(searchAlertId, apiConfig);
    return { status: "success", data: void 0 };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  confirmSearchAlert
};

export const FilterAttributeType = {
  RANGE: 'range',
  VALUE: 'value',
  BOOLEAN: 'boolean',
  SORTBY: 'sortBy',
  ARRAY: 'array',
} as const;

export type FilterAttributeType = (typeof FilterAttributeType)[keyof typeof FilterAttributeType];

export const SortOptionId = {
  LATEST: 'newestFirst',
  PRICE_ASC: 'priceAsc',
  PRICE_DESC: 'priceDesc',
  RENT_ASC: 'rentAsc',
  RENT_DESC: 'rentDesc',
  LIVING_SURFACE_ASC: 'livingSurfaceAsc',
  LIVING_SURFACE_DESC: 'livingSurfaceDesc',
  TOTAL_SURFACE_ASC: 'totalSurfaceAsc',
  TOTAL_SURFACE_DESC: 'totalSurfaceDesc',
} as const;

export type SortOptionId = (typeof SortOptionId)[keyof typeof SortOptionId];

import { type ReactElement, type SVGProps } from 'react';

export const HeartFilled = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.91358 12.0795C3.68876 11.7141 3 10.5652 3 9C3 6.23858 5.23858 4 8 4C9.6356 4 11.0878 4.78534 12 5.99951C12.9122 4.78534 14.3644 4 16 4C18.7614 4 21 6.23858 21 9C21 10.5652 20.3112 11.7141 20.0864 12.0795C18.2038 15.1387 14.8022 17.8983 12 20C9.19776 17.8983 5.79621 15.1387 3.91358 12.0795Z"
        fill="#C80000"
      />
    </svg>
  );
};

import {
  commonPropertySuggest
} from "./chunk-XNHY4EZT.js";
import {
  getErrorResult,
  refineProperty
} from "./chunk-52TBLELU.js";

// src/getSuggestionsByDisplayId/getSuggestionsByDisplayId.ts
async function getSuggestionsByDisplayId(query, options, apiConfig) {
  if (query === "") {
    console.warn("Input is an empty string.");
  }
  if (options.limit < 1) {
    console.warn("Limit is an less than 1.");
  }
  try {
    const response = await commonPropertySuggest(
      {
        input: query,
        limit: options.limit,
        languages: [options.language]
      },
      apiConfig
    );
    return {
      status: "success",
      data: response.map((i) => refineProperty(i, options.language))
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  getSuggestionsByDisplayId
};

import {
  deleteSearchAlert as generatedDeleteSearchAlert,
  type DeleteSearchAlertResult as GeneratedDeleteSearchAlertResult,
} from '@generated/search-bff/controllers/search-alert/search-alert';
import { type ApiConfig, type ApiResult } from '@pkgs/api/types';
import { getErrorResult } from '@pkgs/api/utils';

/**
 * Delete a search alert
 * @function
 * @param {string} searchAlertId - The unique identifier of the search alert which should be deleted.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<void>>} A promise that resolves to an api result object without any data.
 *
 * @summary Delete a search alert
 */
export async function deleteSearchAlert(searchAlertId: string, apiConfig: ApiConfig): Promise<ApiResult<void>> {
  try {
    await generatedDeleteSearchAlert(searchAlertId, apiConfig);
    return { status: 'success', data: undefined };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export type DeleteSearchAlertResult = GeneratedDeleteSearchAlertResult;

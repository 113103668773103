import { SearchAlertTooltip, Tooltip } from '@pkgs/components/components/SearchAlert/SearchAlertTooltip';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { type IconButtonSizes, type IconButtonVariant } from '@spearhead/components/elements/IconButton/IconButton.styled';
import React, { useRef } from 'react';
import { type AriaButtonOptions, mergeProps, useButton } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';

import BellPlus from '../../../spearhead/lib/assets/icons/small/BellPlus.svg';
import { StyledButton } from './SearchAlert.styled';

export const SearchAlertButton = ({
  variant = 'secondary',
  size = 'medium',
  className,
  ...props
}: AriaButtonOptions<'button'> & { variant?: IconButtonVariant; size?: IconButtonSizes; className?: string }) => {
  const { noLocationOrShopFilter } = useSearch();
  const { t } = useAppContext();
  const buttonRef = useRef(null);
  const triggerProps: AriaButtonOptions<'button'> = mergeProps(props, {
    isDisabled: !!noLocationOrShopFilter,
  });
  const { buttonProps } = useButton(triggerProps, buttonRef);

  const state = useTooltipTriggerState(props);

  return (
    <SearchAlertTooltip state={state}>
      <StyledButton
        {...buttonProps}
        className={className}
        ref={buttonRef}
        icon={<BellPlus />}
        variant={variant}
        shape="square"
        size={size}
        label={t('search.searchAlert.create.label')}
        data-test-id="search-components_search-alert-button"
      />
      {state.isOpen && <Tooltip props={props} state={state} isCentered={false} />}
    </SearchAlertTooltip>
  );
};

import { type BigDecimalRangeDto, type Currency, Language } from '@ev/search-modules-api';

export const validateNumber = (value: string): boolean => {
  const numberRegex = /^[1-9]\d*$/;
  return numberRegex.test(value.toString());
};
export const validateRangeValues = (filter: BigDecimalRangeDto): BigDecimalRangeDto => {
  if (!filter.min || !filter.max) {
    return filter;
  }

  return {
    min: Math.min(filter.min, filter.max),
    max: Math.max(filter.min, filter.max),
  };
};

export const formatNumber = (
  number?: number,
  language: Language = Language.en,
  opts: Intl.NumberFormatOptions & { notation?: string } = {},
): string => {
  if (!number) {
    return '';
  }
  // The formatted numbers in french language are using Narrow no break space https://www.codetable.net/decimal/8239 as thousands separator
  // and the E&V font is rendering this kind of white spaces bigger than usual white spaces like &nbsp;
  // here we are replacing all white spaces with a blank space character as a workaround
  // https://engelvoelkers.atlassian.net/browse/NS-3288?focusedCommentId=342373
  return new Intl.NumberFormat(language, { ...opts }).format(number).replaceAll(/\s+/g, ' ');
};

export const parseFormattedNumber = (value: string): string => {
  // remove space, comma or period from a string
  return value.replaceAll(/[\s,.]/g, '');
};

export const formatBigNumber = (number: number, language: Language = Language.en, maxDigits = 3): string => {
  if (Number.isNaN(number)) {
    return '';
  }
  let unit = '';
  let transformedNumber = number;

  if (number >= 1000) {
    unit = 'K';
    transformedNumber = number / 1000;
  }
  if (number >= 1_000_000) {
    unit = 'M';
    transformedNumber = number / 1_000_000;
  }

  const formattedNumber = formatNumber(
    transformedNumber,
    language,
    number >= 1000
      ? {
          maximumSignificantDigits: maxDigits,
        }
      : {},
  );
  return `${formattedNumber}${unit}`;
};

export const formatPrice = (
  number: number,
  language: Language = Language.en,
  opts: Intl.NumberFormatOptions & { notation?: string } = {},
): string => {
  if (number <= 0) {
    return '';
  }
  return formatNumber(number, language, {
    style: 'currency',
    maximumFractionDigits: opts.maximumFractionDigits || 0,
    minimumFractionDigits: opts.minimumFractionDigits || 0,
    currency: 'EUR',
    ...opts,
  });
};

export const formatPriceWithoutDecimals = (price: number, language: Language = Language.en, currency: Currency) => {
  return formatPrice(price, language, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency: currency,
  });
};

export const formatPriceWithDecimals = (price: number, language: Language = Language.en, currency: Currency) => {
  // we add this check to avoid trailing zero e.g 12.00€ when formatting prices without decimals
  const isInteger = price % 1 === 0;
  return formatPrice(price, language, {
    maximumFractionDigits: isInteger ? 0 : 2,
    minimumFractionDigits: isInteger ? 0 : 2,
    currency: currency,
  });
};

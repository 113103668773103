import { BusinessArea, type PropertyType } from '@ev/search-modules-api';

import { type InputTextSizesProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { propertyTypes } from '../../consts/consts';
import { useAppContext } from '../../hooks/useAppContext';
import { type FilterRefinement } from '../../types/types';
import { resetSearchStateOnObjectTypeChange } from '../../utils/searchStateUtils';
import { Select } from '../Select/Select';

type PropertyTypeFilterProps = {
  selectedPropertyType?: PropertyType;
  selectedBusinessArea: BusinessArea;
  hiddenLabel?: boolean;
  size?: InputTextSizesProps;
  className?: string;
  onChange: (arg: FilterRefinement) => void;
  isInModal?: boolean;
  'data-test-id'?: string;
};

export const PropertyTypeFilter = ({
  selectedPropertyType,
  selectedBusinessArea,
  hiddenLabel,
  size,
  className,
  onChange,
  isInModal,
  'data-test-id': dataTestId,
}: PropertyTypeFilterProps) => {
  const { t } = useAppContext();
  const selectedKey = [selectedBusinessArea, selectedPropertyType].filter(Boolean).join('.');
  const handleSelectChange = (value: string) => {
    const [businessArea, objType] = value.split('.');
    onChange({
      ...resetSearchStateOnObjectTypeChange({}, objType as PropertyType, businessArea as BusinessArea),
      businessArea: businessArea ? (businessArea as BusinessArea) : BusinessArea.residential,

      propertyType: objType ? (objType as PropertyType) : undefined,
      propertySubType: undefined,
    });
  };

  const translatedItems = propertyTypes.map((item) => ({
    ...item,
    label: t(item.label),
  }));

  return (
    <Select
      className={className}
      items={translatedItems}
      onSelectionChange={(value) => handleSelectChange(value as string)}
      label={t('search.searchbar.propertyType')}
      placeholder={t('search.searchbar.propertyType')}
      selectedKey={selectedKey}
      hiddenLabel={hiddenLabel}
      size={size}
      isInModal={isInModal}
      data-test-id={dataTestId ?? 'search-components_property-type-filter'}
    />
  );
};

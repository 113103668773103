import { defaultFeatures, FEATURE_ATTRIBUTES } from '../../consts/consts';
import { type Features, type FilterRefinement } from '../../types/types';

export const parseFeaturesFromFilters = (filters: FilterRefinement): Features => {
  const features = { ...defaultFeatures };

  for (const feature of FEATURE_ATTRIBUTES) {
    if (feature in filters) {
      features[feature] = Boolean(filters[feature]);
    }
  }

  return features;
};

import { hiddenScrollbar } from '@pkgs/components/utils/hiddenScrollbar';
import { from, to } from '@pkgs/components/utils/mediaQueries';
import { css, styled } from 'styled-components';

import { Switch } from '../Switch/Switch';

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: var(--distance-static-xs);
  column-gap: var(--distance-static-l);
  padding: var(--distance-static-s) 0;

  @media screen and (${to.laptop}) {
    justify-content: space-between;
  }

  @media screen and (${from.laptop}) {
    & > :last-child:not(:first-child) {
      margin-left: auto;
    }
  }
`;

export const StyledMapSwitch = styled(Switch)`
  @media screen and (${to.laptop}) {
    display: none;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-m);
  align-items: flex-start;
  @media screen and (${from.tabletLandscape}) {
    gap: var(--distance-static-xl);
    flex-direction: row;
    align-items: flex-end;
    justify-content: end;
  }
`;

export const StyledLinksContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  font-size: var(--font-size-static-s);
  line-height: var(--line-height-static-m);
  row-gap: var(--distance-static-xxs);
  column-gap: var(--distance-static-s);
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  ${hiddenScrollbar};
  @media screen and (${from.tabletLandscape}) {
    flex-wrap: wrap;
  }
  > * {
    flex-shrink: 0;
  }
`;

export const StyledAutogenInfoRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--distance-static-s);
  padding: var(--distance-static-xxl) 0 var(--distance-static-s) 0;
  @media screen and (${from.tabletLandscape}) {
    padding-bottom: var(--distance-static-l);
  }
`;

export const StyledGradient = styled.div<{ $direction: 'left' | 'right' }>`
  width: 40px;
  height: 100%;
  position: absolute;
  z-index: var(--zindex-m);
  bottom: 0;
  ${({ $direction }) =>
    $direction === 'left'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};
  pointer-events: none;

  background: ${({ $direction }) => css`
    linear-gradient(
      ${$direction === 'left' ? '270deg' : '90deg'},
      #fff 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0.1) 100%
    )
  `};

  @media screen and (${from.tabletLandscape}) {
    display: none;
  }
`;

export const StyledScrollingContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

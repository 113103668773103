// eslint-disable-next-line @typescript-eslint/no-var-requires
import { resolve } from 'path';

/** @type {import('next-i18next').UserConfig} */
export default {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'de', 'fr', 'it', 'es', 'el', 'da', 'pt', 'cs', 'ca', 'ru', 'nl', 'hu'],
  },
  fallbackLng: false,
  localePath: resolve('./public/locales'),
  react: {
    useSuspense: false,
  },
  debug: false,
};

import { css, styled } from 'styled-components';

export const StyledImageButton = styled.button`
  cursor: pointer;
  &:focus-visible {
    outline: 2px solid var(--color-dark-subdued);
    outline-offset: 1px;
  }
`;

export const ImageButtonHoverStyles = css`
  &:before {
    content: '';
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease;
    position: absolute;
    inset: 0;
    background: var(--color-dark-transparent);
  }

  &:hover:before {
    opacity: 1;
  }
`;

import { css } from 'styled-components';

export const fullsizePseudo = css`
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
`;

import { Currency, type GetListingByIdParams, MeasurementSystem, type SearchListingsParams } from '@generated/search-bff';
import { type GetPropertiesOptions, type GetPropertyOptions, type WithRequired } from '@pkgs/api/types';

export function getDefaultParams(options: GetPropertyOptions): GetListingByIdParams {
  return {
    language: [options.language],
    measurementSystem: Object.values(MeasurementSystem),
    currency: Object.values(Currency),
  };
}

export function getDefaultSearchListingsParams(options: GetPropertiesOptions): WithRequired<SearchListingsParams, 'offset' | 'limit'> {
  const pageSize = options.pageSize ?? 20;
  return {
    ...getDefaultParams(options),
    offset: options.page ? (options.page - 1) * pageSize : 0,
    limit: pageSize,
  };
}

import { type MergeWithOptional, type Property } from '@pkgs/api';

import { type WatchlistItem } from '@/providers/WatchlistProvider';
import { type WatchlistSortingOption } from '@/types/watchlist';

const getPropertyOrderByField = (
  property: MergeWithOptional<Property, WatchlistItem>,
  sortingOption: WatchlistSortingOption
): number | undefined => {
  switch (sortingOption) {
    case 'ADDED_ON_ASC':
    case 'ADDED_ON_DESC':
      return (property as WatchlistItem).addedOn;
    case 'BATHROOMS_ASC':
    case 'BATHROOMS_DESC':
      return (property as Property).bathrooms;
    case 'BEDROOMS_ASC':
    case 'BEDROOMS_DESC':
      return (property as Property).bedrooms;
    case 'ROOMS_ASC':
    case 'ROOMS_DESC':
      return (property as Property).rooms;
    case 'RENT_ASC':
    case 'RENT_DESC':
      return (property as Property).baseCurrency
        ? (property as Property).prices?.[(property as Property).baseCurrency!].rentTotal
        : undefined;
    case 'SALES_PRICE_ASC':
    case 'SALES_PRICE_DESC':
      return (property as Property).baseCurrency
        ? (property as Property).prices?.[(property as Property).baseCurrency!].salesPrice
        : undefined;
    case 'CREATED_AT_ASC':
    case 'CREATED_AT_DESC':
    case 'PUBLISHED_AT_ASC':
    case 'PUBLISHED_AT_DESC':
      return (property as Property).publishedAt ? new Date((property as Property).publishedAt!).getTime() : undefined;
    case 'LIVING_SURFACE_ASC':
    case 'LIVING_SURFACE_DESC':
      return (property as Property).areas?.['sqft'].livingSurface ?? (property as Property).areas?.['sqmt'].livingSurface;
    case 'TOTAL_SURFACE_ASC':
    case 'TOTAL_SURFACE_DESC':
      return (property as Property).areas?.['sqft'].totalSurface ?? (property as Property).areas?.['sqmt'].totalSurface;
  }
};

export const orderByField =
  (sortingOption: WatchlistSortingOption) =>
  (a: MergeWithOptional<Property, WatchlistItem>, b: MergeWithOptional<Property, WatchlistItem>) => {
    const valueA = getPropertyOrderByField(a, sortingOption) ?? 0;
    const valueB = getPropertyOrderByField(b, sortingOption) ?? 0;

    return (sortingOption.includes('DESC') ? -1 : 1) * (valueA - valueB);
  };

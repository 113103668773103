import type { HeadlineSize } from './Headline.styled';
import { StyledHeadline } from './Headline.styled';

export type HeadlineTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'div' | 'span' | 'summary' | 'legend' | 'q';

export interface HeadlineProps {
  className?: string;
  size?: HeadlineSize;
  as?: HeadlineTagName;
  label: string;
}

export const Headline = ({ className, size = 'h2', as = 'h2', label }: HeadlineProps) => {
  return (
    <StyledHeadline className={className} $size={size} as={as}>
      {label}
    </StyledHeadline>
  );
};

import {
  type BusinessArea,
  CountryCode,
  Currency,
  type Language,
  type MeasurementSystem,
  type Property,
  PropertyMarketingType,
  SurfaceUnitMap,
  UnitSymbolMap,
} from '@ev/search-modules-api';
import { type Attribute } from '@pkgs/components/components/SearchResultCard/SearchResultCard';

import { VTOUR_VIDEO_URLS } from '../consts/consts';
import { type TFunction } from '../providers/AppContextProvider';
import { formatNumber } from './numberFormatters';
import { getDevelopmentPrice, getRentPrice, getSalePrice } from './propertyDataUtils';

export const getSpecificLivingAreaLabel = (isDevelopment: boolean, countryCode?: CountryCode): string => {
  if (countryCode === CountryCode.HK) {
    if (isDevelopment) {
      return 'search.expose.propertyDetails.development.label.livingArea.hongKong';
    }
    return 'search.expose.propertyDetails.label.livingArea.hongKong';
  }
  if (isDevelopment) {
    return 'search.expose.propertyDetails.development.label.livingArea';
  }

  return 'search.expose.propertyDetails.label.livingArea';
};

const stringifyCardAttribute = (
  label: string,
  value: string | number,
  language: Language,
  reverse = false,
  measurementSystem?: string,
): string => {
  let _value = String(value);
  if (measurementSystem) {
    _value = `${formatNumber(Number.parseFloat(_value), language, {
      maximumFractionDigits: 0,
    })} ${measurementSystem}`;
  }

  return reverse ? `${label} ${_value}` : `${_value} ${label}`;
};

type PropertyAttribute = {
  id: string;
  label: string;
  value?: number | string;
  unitSymbol?: string;
  reverse?: boolean;
};

function refineAttributeList(attributes: PropertyAttribute[], language: Language, isDevelopment = false) {
  return attributes.reduce((prev, curr) => {
    if (!curr.value) {
      return [...prev];
    }
    const label = isDevelopment
      ? ` ${curr.label}`
      : stringifyCardAttribute(curr.label, curr.value, language, curr.reverse, curr.unitSymbol);

    return [
      ...prev,
      {
        id: curr.id,
        label,
      },
    ];
  }, [] as Attribute[]);
}

export function getDevelopmentCardAttributes(property: Property, t: TFunction, language: Language, measurementSystem: MeasurementSystem) {
  const projectAggregate = property.project?.aggregate;
  if (!projectAggregate) {
    return [];
  }
  const surfaceUnit = SurfaceUnitMap[measurementSystem];
  const areas = projectAggregate.areas?.[surfaceUnit];

  const livingAreaLabel = getSpecificLivingAreaLabel(property.isDevelopmentProject, property.countryAlpha2);
  const livingAreaDevelopmentValue = formatNumber(areas?.minLivingSurface, language, {
    maximumFractionDigits: 0,
  });
  const plotSurfaceDevelopmentValue = formatNumber(areas?.minPlotSurface, language, {
    maximumFractionDigits: 0,
  });
  const totalSurfaceDevelopmentValue = formatNumber(areas?.minTotalSurface, language, {
    maximumFractionDigits: 0,
  });
  const unitSymbol = UnitSymbolMap[measurementSystem];
  const attributes: PropertyAttribute[] = [
    {
      id: `${property.id}-bedrooms`,
      label: t('search.expose.propertyDetails.development.label.bedrooms', {
        count: projectAggregate.minBedRooms,
        bedrooms: projectAggregate.minBedRooms,
      }),
      value: projectAggregate.minBedRooms,
    },
    {
      id: `${property.id}-bathrooms`,
      label: t('search.expose.propertyDetails.development.label.bathrooms', {
        count: projectAggregate.minBathRooms,
        bathrooms: projectAggregate.minBathRooms,
      }),
      value: projectAggregate.minBathRooms || 0,
    },
    {
      id: `${property.id}-livingArea`,
      label: t(livingAreaLabel, {
        livingArea: `${livingAreaDevelopmentValue} ${unitSymbol}`,
      }),
      value: livingAreaDevelopmentValue || 0,
    },
    {
      id: `${property.id}-plotSurface`,
      label: t('search.expose.propertyDetails.development.label.plotSurface', {
        plotSurface: `${plotSurfaceDevelopmentValue} ${unitSymbol}`,
      }),
      value: plotSurfaceDevelopmentValue || 0,
    },
    {
      id: `${property.id}-totalSurface`,
      label: t('search.expose.propertyDetails.development.label.totalSurface', {
        totalSurface: `${totalSurfaceDevelopmentValue} ${unitSymbol}`,
      }),
      value: totalSurfaceDevelopmentValue || 0,
    },
    {
      id: `${property.id}-units`,
      label: t('search.expose.propertyDetails.development.label.units', {
        units: property.project?.totalUnits || 0,
      }),
      value: property.project?.totalUnits || 0,
    },
  ];

  if (!projectAggregate.minBedRooms) {
    attributes.unshift({
      id: `${property.id}-rooms`,
      label: t('search.expose.propertyDetails.development.label.rooms', {
        count: projectAggregate.minRooms,
        rooms: projectAggregate.minRooms,
      }),
      value: projectAggregate.minRooms || 0,
    });
  }

  return refineAttributeList(attributes, language, true);
}

export function getCommercialCardAttributes(property: Property, t: TFunction, language: Language, measurementSystem: MeasurementSystem) {
  const surfaceUnit = SurfaceUnitMap[measurementSystem];
  const areas = property.areas?.[surfaceUnit];
  if (!areas) {
    return [];
  }

  const livingAreaLabel = getSpecificLivingAreaLabel(property.isDevelopmentProject, property.countryAlpha2);

  const unitSymbol = UnitSymbolMap[measurementSystem];

  const { livingSurface, plotSurface, totalSurface, dividableFrom } = areas;

  const attributes: PropertyAttribute[] = [
    {
      id: `${property.id}-livingArea`,
      label: livingSurface ? t(livingAreaLabel) : t('search.expose.propertyDetails.label.totalSurface'),
      value: livingSurface ?? totalSurface,
      unitSymbol,
    },
    {
      id: `${property.id}-plotSurface`,
      label: t('search.expose.propertyDetails.label.plotSurface'),
      value: plotSurface,
      unitSymbol,
    },
    {
      id: `${property.id}-dividableForm`,
      label: t('search.expose.propertyDetails.label.dividableFrom'),
      value: dividableFrom,
      unitSymbol,
      reverse: true,
    },
  ];
  return refineAttributeList(attributes, language);
}

// TODO: refactor to not duplicate code with getCommercialCardAttributes
export function getResidentialCardAttributes(property: Property, t: TFunction, language: Language, measurementSystem: MeasurementSystem) {
  const surfaceUnit = SurfaceUnitMap[measurementSystem];
  const areas = property.areas?.[surfaceUnit];

  const canShowTerraceSurface = (property.countryAlpha2 === 'ES' || property.countryAlpha2 === 'PT') && property.objectType === 'apartment';

  const unitSymbol = UnitSymbolMap[measurementSystem];

  const livingAreaLabel = getSpecificLivingAreaLabel(property.isDevelopmentProject, property.countryAlpha2);
  const { livingSurface, plotSurface, totalSurface, terraceSurface } = areas ?? {};
  const attributes: PropertyAttribute[] = [
    {
      id: `${property.id}-bedrooms`,
      label: t('search.expose.propertyDetails.label.bedrooms', {
        count: property.bedrooms,
      }),
      value: property.bedrooms,
    },
    {
      id: `${property.id}-bathrooms`,
      label: t('search.expose.propertyDetails.label.bathrooms', {
        count: property.bathrooms,
      }),
      value: property.bathrooms,
    },
    {
      id: `${property.id}-livingArea`,
      label: livingSurface ? t(livingAreaLabel) : t('search.expose.propertyDetails.label.totalSurface'),
      value: livingSurface ?? totalSurface,
      unitSymbol,
    },
  ];
  if (!property.bedrooms) {
    attributes.unshift({
      id: `${property.id}-rooms`,
      label: t('search.expose.propertyDetails.label.rooms', {
        count: property.rooms,
      }),
      value: property.rooms,
    });
  }
  if (canShowTerraceSurface) {
    attributes.push({
      id: `${property.id}-terraceSurface`,
      label: t('search.expose.propertyDetails.label.terraceSurface'),
      value: terraceSurface,
      unitSymbol,
    });
  }
  if (!terraceSurface || !canShowTerraceSurface) {
    attributes.push({
      id: `${property.id}-plotSurface`,
      label: t('search.expose.propertyDetails.label.plotSurface'),
      value: plotSurface,
      unitSymbol,
    });
  }
  return refineAttributeList(attributes, language);
}

export const getCardAttributes = (
  type: BusinessArea | 'development',
  property: Property,
  t: TFunction,
  language: Language,
  measurementSystem: MeasurementSystem,
): Attribute[] => {
  switch (type) {
    case 'commercial': {
      return getCommercialCardAttributes(property, t, language, measurementSystem);
    }
    case 'residential': {
      return getResidentialCardAttributes(property, t, language, measurementSystem);
    }
    case 'development': {
      return getDevelopmentCardAttributes(property, t, language, measurementSystem);
    }
  }
};

export const getCardPrice = (
  property: Property,
  language: Language,
  currency?: Currency,
): { value: string; labelTranslationKey?: string } => {
  const _currency = currency ?? property.baseCurrency ?? Currency.EUR;

  if (property.isDevelopmentProject) {
    return getDevelopmentPrice(property, language, _currency);
  }
  if (property.propertyMarketingType === PropertyMarketingType.sale) {
    return getSalePrice(property, language, _currency);
  }

  return getRentPrice(property, language, _currency);
};

export const getVirtualTourType = (vtourUrl: string): 'video' | 'interactive' => {
  return vtourUrl && VTOUR_VIDEO_URLS.some((u) => vtourUrl.includes(u)) ? 'video' : 'interactive';
};

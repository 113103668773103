import { Language } from '@ev/search-modules-api';
import { forwardRef } from 'react';

import { type InputTextProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { formatNumber, parseFormattedNumber, validateNumber } from '../../utils/numberFormatters';
import { InputText } from '../InputText/InputText';

export const FormattedNumberInput = forwardRef<HTMLInputElement, InputTextProps & { language?: Language }>(
  ({ language, onChange, onBlur, value, ...props }, ref) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const targetValue = parseFormattedNumber(event.target.value);
      const isValidInput = validateNumber(targetValue) || targetValue === '';
      if (!isValidInput) {
        event.preventDefault();
        return;
      }
      onChange &&
        onChange({
          ...event,
          target: {
            ...event.target,
            value: targetValue,
          },
        });
    };
    return (
      <InputText
        {...props}
        ref={ref}
        type="text"
        inputMode="numeric"
        onChange={handleOnChange}
        value={formatNumber(Number.parseFloat(value || ''), language ?? Language.en)}
      />
    );
  },
);

FormattedNumberInput.displayName = 'FormattedNumberInput';

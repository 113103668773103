import { useEffect, useRef, useState } from 'react';
import { type AriaTextFieldProps, useTextField } from 'react-aria';

import { type InputTextProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { useAppContext } from '../../hooks/useAppContext';
import { InputText } from '../InputText/InputText';
import { StyledInputCounter, StyledInputWrapper } from './SearchAlert.styled';

const DISALLOWED_CHARS = ['<', ';', '>', '/', '*'];
const disallowedCharsPattern = `[^${DISALLOWED_CHARS.join('\\')}]*`;

export const NameInput = (props: AriaTextFieldProps) => {
  const { t } = useAppContext();
  const [counter, setCounter] = useState(0);
  const [validity, setValidity] = useState<Partial<ValidityState>>({
    valid: true,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const maxLength = 250;
  const label = t('search.searchAlert.create.name.label');

  const counterText = `${counter}/${maxLength}`;

  const handleChange = (value: string) => {
    setCounter(value.length);
    updateValidity();
  };

  useEffect(() => {
    if (props.defaultValue) {
      setCounter(props.defaultValue.length);
    }
  }, [props.defaultValue]);

  const updateValidity = () => {
    if (!inputRef.current) return;
    const { valid, patternMismatch, valueMissing } = inputRef.current.validity;
    setValidity({ valid, patternMismatch, valueMissing });
  };

  const errorMessage = () => {
    if (validity.patternMismatch)
      return t('search.searchAlert.create.name.notAllowed', {
        characters: DISALLOWED_CHARS.join(' '),
      });
    if (validity.valueMissing) return t('search.searchAlert.create.name.required');
    return '';
  };

  const { inputProps, labelProps } = useTextField(
    {
      ...props,
      onChange: handleChange,
      maxLength,
      label,
      defaultValue: props.defaultValue,
    },
    inputRef,
  );

  return (
    <StyledInputWrapper>
      <InputText
        {...(inputProps as InputTextProps)}
        label={label}
        labelProps={labelProps}
        name="name"
        isRequired
        size="m"
        pattern={disallowedCharsPattern}
        ref={inputRef}
        error={!validity.valid}
        helperText={errorMessage()}
        data-test-id="search-components_search-alert-creation-form_name-input"
        endAdornment={
          <StyledInputCounter size="l" text={counterText} data-test-id="search-components_search-alert-creation-form_name-input_count" />
        }
        onInvalid={updateValidity}
      />
    </StyledInputWrapper>
  );
};

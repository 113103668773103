import { type BigDecimalRangeDto, type Language, type ListingSortOption } from '@ev/search-modules-api';

import { formatBigNumber, validateRangeValues } from './numberFormatters';

export const formatRangeLabel = (value: BigDecimalRangeDto, unit?: string, language?: Language, formatNumbers?: boolean): string => {
  const range = validateRangeValues(value);

  if (!range.min && !range.max) {
    return '';
  }
  const { min, max } = range;

  const unitLabel = unit ? ` ${unit}` : '';
  let formattedMin: number | string = min ?? 0;
  let formattedMax: number | string = max ?? 0;
  if (formatNumbers) {
    formattedMin = min ? formatBigNumber(min, language) : 0;
    formattedMax = max ? formatBigNumber(max, language) : 0;
  }
  if (min && !max) {
    return `min ${formattedMin}${unitLabel}`;
  }
  if (max && !min) {
    return `max ${formattedMax}${unitLabel}`;
  }
  return `${[formattedMin, formattedMax].filter(Boolean).join(' - ')}${unitLabel}`;
};

export const getTrackingQueryParams = (url: URL): Record<string, unknown> | undefined => {
  const trackingQueryParams = [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
    'gclid',
    'msclkid',
    'fbclid',
    'sph', // spearhead
  ];
  const trackingParamsInUrl: Record<string, unknown> = {};
  for (const param of trackingQueryParams) {
    const value = url.searchParams.get(param.toLowerCase());
    if (value !== null) {
      trackingParamsInUrl[param] = value;
    }
  }
  return Object.keys(trackingParamsInUrl).length > 0 ? trackingParamsInUrl : undefined;
};

export const getSortValues = (sortingOption?: ListingSortOption) => {
  if (!sortingOption) return {};

  const sortArr = sortingOption.split('_');
  const sortOrder = sortArr.pop() as 'ASC' | 'DESC';
  const sortField = sortArr.join('_');

  return { sortOrder, sortField };
};

import { Copy } from '../../elements/Copy/Copy';

export interface HighlightedTextProps {
  text: string;
  highlightedText: string | undefined;
}
export const HighlightedText = ({ text, highlightedText = '' }: HighlightedTextProps) => {
  const regex = new RegExp(`(${highlightedText})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlightedText.toLowerCase() ? (
          <Copy key={index} text={part} bold={true} as="span" />
        ) : (
          <Copy key={index} text={part} as="span" />
        ),
      )}
    </>
  );
};

import { AddressTypeDto, type PlaceType, SupportedPlaceTypes } from '@ev/search-modules-api';
import React from 'react';

import CityIcon from '../../../spearhead/lib/assets/icons/small/City.svg';
import LocationIcon from '../../../spearhead/lib/assets/icons/small/Pin.svg';
import DistrictIcon from '../../../spearhead/lib/assets/icons/small/Property-Multiple.svg';
import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';
import { HighlightedText } from '../../../spearhead/lib/components/modules/SearchForm/HighlightedText';
import { useAppContext } from '../../hooks/useAppContext';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { type AutoSuggestOption } from './AutoComplete';
import { StyledAutoCompleteOptionContainer, StyledLink, StyledTextOverflowWrapper, StyledTextWrapper } from './AutoComplete.styles';

const getIconPerType = (type?: AddressTypeDto) => {
  switch (type) {
    case AddressTypeDto.country:
    case AddressTypeDto.natural_feature:
    case AddressTypeDto.sublocality:
    case AddressTypeDto.administrative_area_level_1:
    case AddressTypeDto.administrative_area_level_4:
      return LocationIcon;
    case AddressTypeDto.neighborhood:
    case AddressTypeDto.postal_code:
      return DistrictIcon;
    case AddressTypeDto.locality:
      return CityIcon;
    default:
      return LocationIcon;
  }
};

export type AutocompleteProps = {
  inputValue: string;
  option: AutoSuggestOption;
  'data-test-id'?: string;
};

export const AutoCompleteOption = ({ inputValue, option, 'data-test-id': dataTestId }: AutocompleteProps): React.ReactElement => {
  const { t } = useAppContext();
  const validPlaceTypes = option.isProperty ? [] : option.types.filter((type) => SupportedPlaceTypes.has(type as PlaceType));

  const Icon = getIconPerType(validPlaceTypes[0]);

  return (
    <ConditionalWrapper
      condition={option.isProperty}
      wrapper={(children) => <StyledLink href={option.isProperty ? option.link : ''}>{children}</StyledLink>}
    >
      <StyledAutoCompleteOptionContainer data-test-id={dataTestId}>
        <div>
          <Icon />
        </div>
        <StyledTextOverflowWrapper>
          <HighlightedText text={option.title} highlightedText={inputValue} />
          <StyledTextWrapper>
            <Copy
              text={option.isProperty ? option.description : t(`search.searchbar.placeType.${validPlaceTypes[0] ?? ''}`)}
              as="p"
              size="m"
            />
          </StyledTextWrapper>
        </StyledTextOverflowWrapper>
      </StyledAutoCompleteOptionContainer>
    </ConditionalWrapper>
  );
};

AutoCompleteOption.displayName = 'AutoCompleteOption';

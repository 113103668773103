import { useEffect, useState } from 'react';

export const getWidth = () => {
  if (typeof window === 'undefined') return 0;

  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

export const useResize = () => {
  const [width, setWidth] = useState<number>(getWidth());

  useEffect(() => {
    if (typeof window === 'undefined') return;

    let timeoutId: NodeJS.Timeout | null = null;
    const resizeListener = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return width;
};

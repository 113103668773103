// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"new-search"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  maxValueLength: 500,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  integrations: [Sentry.browserTracingIntegration()],

  beforeSend(event) {
    const frames = event.exception?.values?.[0].stacktrace?.frames;
    const lastFrame = frames ? frames[frames.length - 1] : null;
    const isLastFrameInNodeModules = lastFrame?.filename?.includes('./node_modules');

    if (isLastFrameInNodeModules) {
      return null;
    }

    return event;
  },

  tracePropagationTargets: [/^search-bff\.(engelvoelkers\.com|envdevel\.com|envstage\.com)$/],

  ignoreErrors: [
    /.*\(evaluating '[A-Za-z]\.[A-Za-z]'.*\).*/,
    /Non-Error promise rejection captured with value: Timeout/,
    /Can't find variable: fbq/,
    /.*ui-gdpr.*/,
    /.*@sentry.*/,
    /TypeError: Failed to fetch/,
    /null is not an object \(evaluating 'e\.contentWindow\.postMessage'\)/,
    /No identifiers allowed directly after numeric literal/,
    /Unexpected token 'else'/,
  ],
  allowUrls: [/^https:\/\/www\.engelvoelkers\.com/, /^https:\/\/www\.envstage\.com\/de\/$/],
});

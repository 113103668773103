import { styled } from 'styled-components';

import { IconButton } from '../../../spearhead/lib/components/elements/IconButton/IconButton';

export const StyledAutoCompleteContainer = styled.div`
  position: relative;
  flex: 1;
`;

export const StyledAutoCompleteOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--distance-static-m);
  padding: var(--distance-static-xs) var(--distance-static-s);
`;

export const StyledTextOverflowWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTextWrapper = styled.div`
  color: var(--color-dark-subdued);
`;

export const StyledIconButton = styled(IconButton)`
  &:focus {
    outline: 0;
  }

  &:focus-within {
    outline: 2px solid var(--color-focus-dark);
    outline-style: auto;
  }
`;

export const StyledLink = styled.a`
  all: unset;
`;

import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useRef, useState } from 'react';

import { GlobalSettings } from '../GlobalSettings/GlobalSettings';
import { GlobeIcon } from '../Icons/GlobeIcon';
import { type HeaderProps } from './Header';
import { StyledButton, StyledPopover } from './HeaderSettingsSelector.styles';

export const HeaderSettingsSelector = ({ onLanguageChange }: HeaderProps): React.ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef<HTMLButtonElement>(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
    popoverAnchor.current?.focus();
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
    popoverAnchor.current?.blur();
  };

  return (
    <>
      <StyledButton
        ref={popoverAnchor}
        aria-owns="mouse-over-popover-global-settings"
        aria-haspopup="true"
        data-cy="globalSettingsButton"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        onClick={() => setOpenedPopover(!openedPopover)}
      >
        <GlobeIcon sx={{ marginRight: theme.spacing(0.5) }} />
        <Typography variant="caption">{t('search.globalSettings.langAndCurrency')}</Typography>
      </StyledButton>
      <StyledPopover
        id="mouse-over-popover-global-settings"
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={45}
        slotProps={{
          paper: {
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          },
        }}
      >
        <GlobalSettings onLanguageChange={onLanguageChange} />
      </StyledPopover>
    </>
  );
};

import { useTheme } from '@mui/material/styles';
import React, { type ReactElement } from 'react';

import { HeartFilled } from '@/components/ui-core-components-clone/icons/small/HeartFilled';
import { HeartOutlined } from '@/components/ui-core-components-clone/icons/small/HeartOutlined';

import { StyledButton, StyledCircledDiv, StyledTextButton } from './FavoriteButton.styles';

type FavoriteButtonProps = {
  filled?: boolean;
  circled?: boolean;
  label?: string;
  onClick?: () => void;
  className?: string;
};

export const FavoriteButton = ({ filled, circled, label, onClick, className }: FavoriteButtonProps): ReactElement => {
  const theme = useTheme();

  const iconSize = {
    width: '19px',
    height: '19px',
  };

  const buttonIconStyles = {
    width: theme.spacing(2),
    height: theme.spacing(2),
    animation: filled ? 'iconAnimation 500ms ease' : 'none',
    '@keyframes iconAnimation': {
      '50%': {
        transform: 'scale(1.2,1.2)',
      },
    },
  };

  const handleClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    onClick && onClick();
  };

  const HeartIcon = filled ? HeartFilled : HeartOutlined;

  if (label) {
    return (
      <StyledTextButton onClick={handleClick} className={className} startIcon={<HeartIcon style={iconSize} />}>
        {label}
      </StyledTextButton>
    );
  }

  return (
    <StyledButton onClick={handleClick}>
      <StyledCircledDiv circled={circled}>
        <HeartIcon style={buttonIconStyles} />
      </StyledCircledDiv>
    </StyledButton>
  );
};

FavoriteButton.displayName = 'FavoriteButton';
export default React.memo(FavoriteButton);

import { type ComboBoxProps } from '@react-types/combobox';
import { type KeyboardEvent, useCallback, useState } from 'react';
import { ComboBox } from 'react-aria-components';

import { type InputTextSizesProps } from '../../../spearhead/lib/components/elements/InputText/InputText';
import { type AutoSuggestOption } from './AutoComplete';
import { StyledAutoCompleteContainer } from './AutoComplete.styles';
import { AutoCompleteInput } from './AutoCompleteInput';
import { AutoCompletePopover } from './AutoCompletePopover';

type AutoCompleteComboBoxProps = ComboBoxProps<AutoSuggestOption> & {
  inputRef: React.RefObject<HTMLInputElement>;
  size?: InputTextSizesProps;
  className?: string;
  'data-test-id'?: string;
  children: (item: AutoSuggestOption) => JSX.Element;
};

export const AutoCompleteComboBox = ({
  size,
  label,
  className,
  'data-test-id': dataTestId,
  inputRef,
  children: renderItem,
  ...props
}: AutoCompleteComboBoxProps) => {
  const [itemIsFocused, setItemIsFocused] = useState(false);

  /* 
    Allows selection of first item on Enter key press when no item is focused
    Note: If item is already focused then the default combobox behaviour is to select the focused item 
  */
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !itemIsFocused && props.items) {
        props.onSelectionChange?.([...props.items][0]?.id ?? null);
        inputRef.current?.blur();
      }
    },
    [inputRef, itemIsFocused, props],
  );

  return (
    <StyledAutoCompleteContainer className={className}>
      <ComboBox
        allowsEmptyCollection
        onSelectionChange={props.onSelectionChange}
        items={props.items}
        onKeyDown={onKeyDown}
        menuTrigger="focus"
        aria-label="Places search suggestions input"
      >
        <AutoCompleteInput
          value={props.inputValue}
          ref={inputRef}
          name="search"
          onChange={(e) => props.onInputChange?.(e.target.value)}
          onClearInput={() => props.onInputChange?.('')}
          size={size}
          label={label}
          data-test-id={dataTestId ?? 'search-components_autocomplete_input'}
        />
        <AutoCompletePopover setIsFocused={setItemIsFocused} renderItem={renderItem} />
      </ComboBox>
    </StyledAutoCompleteContainer>
  );
};

import { type Language } from '@pkgs/api';

export const isStringValueMapped = (element?: string | null): boolean => {
  // check if the value matches the mapped translation keys like "heatingMaterial.electric" or "heatingMaterial.gas"
  return Boolean(element?.match(/.*\./));
};

/**
 * Takes an array of strings and tranlates them with given language
 * @param strings array of strings to be translated
 * @param language selected langauge from settings
 * @returns translated array of given strings
 */
export const formatIntlList = (strings: string[], language: Language): string => {
  if (strings.length === 0) {
    return '';
  }

  try {
    const formatter = new Intl.ListFormat(language, {
      style: 'long',
      type: 'conjunction',
    });
    return formatter.format(strings);
  } catch {
    return strings.join(',');
  }
};

import {
  deleteSearchAlert
} from "./chunk-YYWHXEGH.js";
import {
  getErrorResult
} from "./chunk-XNWIRXJJ.js";

// src/deleteSearchAlert/deleteSearchAlert.ts
async function deleteSearchAlert2(searchAlertId, apiConfig) {
  try {
    await deleteSearchAlert(searchAlertId, apiConfig);
    return { status: "success", data: void 0 };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  deleteSearchAlert2 as deleteSearchAlert
};

import { to } from '@pkgs/components/utils/mediaQueries';
import { css, styled } from 'styled-components';

import { IconButton } from '../IconButton/IconButton';

export const SliderWrapperStyled = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const SliderContainerStyled = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;

  margin-left: auto;
  margin-right: auto;
`;

export const carouselButtonStyles = css`
  opacity: var(--coursel-button-opacity, 0);
  transition: all 0.3s ease;
  margin: 0 var(--distance-static-s);
  position: absolute;
  top: 50%;
  background-color: var(--color-white);
  transform: translateY(-50%);
  height: var(--distance-dynamic-s);
  width: var(--distance-dynamic-s);
  z-index: var(--zindex-m);
  box-shadow: var(--shadow-m);

  &:focus-visible {
    opacity: 1;
  }

  @media screen and (${to.tabletLandscape}) {
    display: none;
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-interaction-inverted-hover);
    }
  }
`;

export const LeftCarouselButtonStyled = styled(IconButton)`
  left: 0;
  // IconButton styles were still applied so we needed to increase the specificity here NS-5730
  && {
    ${carouselButtonStyles}
  }
`;

export const RightCarouselButtonStyled = styled(IconButton)`
  right: 0;
  // IconButton styles were still applied so we needed to increase the specificity here NS-5730
  && {
    ${carouselButtonStyles}
  }
`;

export const CarouselContainerStyled = styled.div`
  position: relative;
  z-index: var(--zindex-m);
`;

export const SliderSlideStyled = styled.div<{ $aspectRatio?: string }>`
  flex: 0 0 100%;
  min-width: 0;
  position: relative;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '3 / 2'};
`;

import { BusinessArea, CurrencySymbolMap, PropertyMarketingType, PropertyType, UnitSymbolMap } from '@ev/search-modules-api';
import { useRef, useState } from 'react';
import { type AriaDialogProps, useDialog, VisuallyHidden } from 'react-aria';

import Cross from '../../../spearhead/lib/assets/icons/small/Cross-Naked.svg';
import { Button } from '../../../spearhead/lib/components/elements/Button/Button';
import { Checkbox } from '../../../spearhead/lib/components/elements/Checkbox/Checkbox';
import { IconButton } from '../../../spearhead/lib/components/elements/IconButton/IconButton';
import { Link } from '../../../spearhead/lib/components/elements/Link/Link';
import { useAppContext } from '../../hooks/useAppContext';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useResetKey } from '../../hooks/useResetKey';
import { useSearch } from '../../hooks/useSearch';
import { type FilterRefinement } from '../../types/types';
import { hasResetableFilters, resetAdvancedFilters, resetSearchStateOnObjectTypeChange } from '../../utils/searchStateUtils';
import { BuyRentFilter } from '../BuyRentFilter/BuyRentFilter';
import { ExtendedSearchRadiusFilter } from '../ExtendedSearchRadiusFilter/ExtendedSearchRadiusFilter';
import { FeaturesFilter } from '../FeaturesFilter/FeaturesFilter';
import { parseFeaturesFromFilters } from '../FeaturesFilter/utils';
import { LocationFilter } from '../LocationFilter/LocationFilter';
import { PropertySubTypeFilter } from '../PropertySubTypeFilter/PropertySubTypeFilter';
import { PropertyTypeFilter } from '../PropertyTypeFilter/PropertyTypeFilter';
import { BathroomsInput } from '../RangeInputs/BathroomsInput';
import { BedroomsInput } from '../RangeInputs/BedroomsInput';
import { ConstructionYearInput } from '../RangeInputs/ConstructionYearInput';
import { LivingSurfaceInput } from '../RangeInputs/LivingSurfaceInput';
import { PlotSurfaceInput } from '../RangeInputs/PlotSurfaceInput';
import { PriceInput } from '../RangeInputs/PriceInput';
import { RoomsInput } from '../RangeInputs/RommsInput';
import { TotalSurfaceInput } from '../RangeInputs/TotalSurfaceInput';
import {
  StyledBuyRentFilterDivider,
  StyledBuyRentFilterRowContainer,
  StyledDialog,
  StyledDialogBody,
  StyledDialogFooter,
  StyledDialogHeader,
  StyledDivider,
  StyledRowContainer,
  StyledRowContainerHorizontal,
} from './AdvancedFilters.styled';

interface DialogProps extends AriaDialogProps {
  onClose: () => void;
}

export const AdvancedFiltersDialog = ({ onClose, ...props }: DialogProps) => {
  const { t } = useAppContext();
  const ref = useRef(null);
  const {
    filters,
    updateFilters,
    options: { currency, measurementSystem },
    placeDetails,
    isMapSearch,
  } = useSearch();
  const { dialogProps, titleProps } = useDialog(props, ref);
  const [initialAdvancedFilters, setInitialAdvancedFilters] = useState<FilterRefinement>({
    ...filters,
  });
  const [advancedFilters, setAdvancedFilters] = useState<FilterRefinement>({
    ...filters,
  });
  const { resetKeys, resetAllKeys } = useResetKey([
    'propertySubtype',
    'price',
    'livingSurface',
    'plotSurface',
    'totalSurface',
    'rooms',
    'bedrooms',
    'bathrooms',
    'constructionYear',
    'features',
    'virtualTour',
  ]);

  const currencySymbol = CurrencySymbolMap[currency];
  const unitSymbol = UnitSymbolMap[measurementSystem];

  const features = parseFeaturesFromFilters(advancedFilters);
  const { isMobile } = useBreakpoint();
  const isDevelopmentSelected = advancedFilters.propertyType === PropertyType.group;
  const isResidentialSelected = advancedFilters.businessArea === BusinessArea.residential;

  const handleDoneClick = () => {
    updateFilters({ ...advancedFilters });
    onClose();
  };
  const handleResetClick = () => {
    const nextSearchState = resetAdvancedFilters(advancedFilters);
    setInitialAdvancedFilters({ ...nextSearchState });
    setAdvancedFilters({ ...nextSearchState });
    resetAllKeys();
    updateFilters({ ...nextSearchState });
  };

  const handleMarketingTypeChange = (value: FilterRefinement) => {
    setInitialAdvancedFilters({
      ...initialAdvancedFilters,
      ...value,
    });
    setAdvancedFilters({
      ...advancedFilters,
      ...value,
    });
  };

  const handlePropertyTypeChange = (filterRefinement: FilterRefinement) => {
    setInitialAdvancedFilters({
      ...resetSearchStateOnObjectTypeChange(initialAdvancedFilters, filterRefinement.propertyType, filterRefinement.businessArea),
    });
    setAdvancedFilters({
      ...advancedFilters,
      ...filterRefinement,
    });
  };

  return (
    <StyledDialog {...dialogProps} ref={ref}>
      <StyledDialogHeader>
        <VisuallyHidden>
          <h3 {...titleProps}>{t('search.searchbar.filters')}</h3>
        </VisuallyHidden>
        <IconButton variant="tertiary" icon={<Cross />} onClick={onClose} data-test-id="search-components_advanced-filters_close-button" />
      </StyledDialogHeader>
      <StyledDialogBody id="advancedFiltersForm">
        <StyledBuyRentFilterRowContainer>
          <BuyRentFilter
            propertyMarketingType={advancedFilters.propertyMarketingType ?? PropertyMarketingType.sale}
            size="s"
            onChange={handleMarketingTypeChange}
            isInModal={true}
            data-test-id="search-components_advanced-filters_buy-rent-filter"
          />
        </StyledBuyRentFilterRowContainer>
        <StyledBuyRentFilterDivider />
        {isMobile && (
          <>
            <StyledRowContainer>
              <LocationFilter
                size="s"
                hiddenLabel={false}
                initialLocation={isMapSearch ? t('search.searchbar.mapSearch') : (placeDetails.name ?? '')}
                onChange={(refinement) => {
                  setAdvancedFilters({ ...advancedFilters, ...refinement });
                }}
                data-test-id="search-components_advanced-filters_location-filter"
              />
              <ExtendedSearchRadiusFilter
                searchRadius={advancedFilters.searchRadius ?? 0}
                size="s"
                isInModal={true}
                onChange={(refinement) =>
                  setAdvancedFilters({
                    ...advancedFilters,
                    ...refinement,
                  })
                }
                data-test-id="search-components_advanced-filters_search-radius-filter"
              />
            </StyledRowContainer>
            <StyledDivider />
          </>
        )}
        <StyledRowContainerHorizontal>
          <PropertyTypeFilter
            selectedPropertyType={advancedFilters.propertyType ?? undefined}
            selectedBusinessArea={advancedFilters.businessArea ?? BusinessArea.residential}
            size="s"
            isInModal={true}
            onChange={handlePropertyTypeChange}
            data-test-id="search-components_advanced-filters_property-type-filter"
          />
          {isResidentialSelected && (
            <PropertySubTypeFilter
              key={`PropertySubTypeFilter_${resetKeys['propertySubtype']}`}
              selectedSubType={advancedFilters.propertySubType ?? undefined}
              propertyType={advancedFilters.propertyType ?? undefined}
              isInModal={true}
              onChange={(refinement) => {
                setAdvancedFilters({
                  ...advancedFilters,
                  ...refinement,
                });
              }}
              data-test-id="search-components_advanced-filters_property-sub-type-filter"
            />
          )}
        </StyledRowContainerHorizontal>
        <StyledDivider />
        {!isDevelopmentSelected && (
          <>
            <StyledRowContainer>
              <PriceInput
                key={`PriceInput_${resetKeys['price']}`}
                propertyMarketingType={advancedFilters.propertyMarketingType ?? PropertyMarketingType.sale}
                currencySymbol={currencySymbol}
                hiddenLabel={false}
                defaultRange={initialAdvancedFilters.price}
                onChange={(range) => {
                  setAdvancedFilters({
                    ...advancedFilters,
                    price: { ...range },
                  });
                }}
                data-test-id="search-components_advanced-filters_price-filter"
              />
            </StyledRowContainer>
            <StyledDivider />
          </>
        )}
        {!isDevelopmentSelected && (
          <>
            <StyledRowContainer>
              {isResidentialSelected && (
                <LivingSurfaceInput
                  key={`LivingSurfaceInput_${resetKeys['livingSurface']}`}
                  hiddenLabel={false}
                  unitSymbol={unitSymbol}
                  defaultRange={initialAdvancedFilters.livingSurface}
                  onChange={(range) => {
                    setAdvancedFilters({
                      ...advancedFilters,
                      livingSurface: { ...range },
                    });
                  }}
                  data-test-id="search-components_advanced-filters_living-surface-filter"
                />
              )}
              <PlotSurfaceInput
                key={`PlotSurfaceInput_${resetKeys['plotSurface']}`}
                hiddenLabel={false}
                unitSymbol={unitSymbol}
                defaultRange={initialAdvancedFilters.plotSurface}
                onChange={(range) => {
                  setAdvancedFilters({
                    ...advancedFilters,
                    plotSurface: { ...range },
                  });
                }}
                data-test-id="search-components_advanced-filters_plot-surface-filter"
              />
              <TotalSurfaceInput
                key={`TotalSurfaceInput_${resetKeys['totalSurface']}`}
                hiddenLabel={false}
                unitSymbol={unitSymbol}
                defaultRange={initialAdvancedFilters.totalSurface}
                onChange={(range) => {
                  setAdvancedFilters({
                    ...advancedFilters,
                    totalSurface: { ...range },
                  });
                }}
                data-test-id="search-components_advanced-filters_total-surface-filter"
              />
            </StyledRowContainer>
            <StyledDivider />
          </>
        )}
        {isResidentialSelected && !isDevelopmentSelected && (
          <>
            <StyledRowContainer>
              <RoomsInput
                key={`RoomsInput_${resetKeys['rooms']}`}
                hiddenLabel={false}
                defaultRange={initialAdvancedFilters.rooms}
                onChange={(range) => {
                  setAdvancedFilters({
                    ...advancedFilters,
                    rooms: range ?? undefined,
                  });
                }}
                data-test-id="search-components_advanced-filters_rooms-filter"
              />
              <BedroomsInput
                key={`BedroomsInput_${resetKeys['bedrooms']}`}
                hiddenLabel={false}
                defaultRange={initialAdvancedFilters.bedrooms}
                onChange={(range) => {
                  setAdvancedFilters({
                    ...advancedFilters,
                    bedrooms: range ?? undefined,
                  });
                }}
                data-test-id="search-components_advanced-filters_bedrooms-filter"
              />
              <BathroomsInput
                key={`BathroomsInput_${resetKeys['bathrooms']}`}
                hiddenLabel={false}
                defaultRange={initialAdvancedFilters.bathrooms}
                onChange={(range) => {
                  setAdvancedFilters({
                    ...advancedFilters,
                    bathrooms: range ?? undefined,
                  });
                }}
                data-test-id="search-components_advanced-filters_bathrooms-filter"
              />
            </StyledRowContainer>
            <StyledDivider />
          </>
        )}
        <StyledRowContainer>
          <ConstructionYearInput
            key={`ConstructionYearInput_${resetKeys['constructionYear']}`}
            hiddenLabel={false}
            defaultRange={initialAdvancedFilters.constructionYear}
            onChange={(range) => {
              setAdvancedFilters({
                ...advancedFilters,
                constructionYear: range ?? undefined,
              });
            }}
            data-test-id="search-components_advanced-filters_construction-year-filter"
          />
        </StyledRowContainer>
        <StyledDivider />
        <StyledRowContainer>
          {!isDevelopmentSelected && (
            <FeaturesFilter
              key={`Features${resetKeys['features']}`}
              features={features}
              onChange={(feature) => {
                setAdvancedFilters({
                  ...advancedFilters,
                  ...feature,
                });
              }}
            />
          )}
          <Checkbox
            key={`VirtualTour_${resetKeys['virtualTour']}`}
            value="vtour"
            label={t('search.searchbar.advFilter.vtour')}
            aria-label={t('search.searchbar.advFilter.vtour')}
            isSelected={Boolean(advancedFilters.hasVtour)}
            data-test-id="search-components_advanced-filters_virtual-tour-checkbox"
            onChange={(isSelected) => {
              setAdvancedFilters({
                ...advancedFilters,
                hasVtour: isSelected,
              });
            }}
          />
        </StyledRowContainer>
      </StyledDialogBody>
      <StyledDialogFooter>
        <Link
          as="button"
          label={t('search.searchbar.reset')}
          size="medium"
          variant="primary"
          disabled={!hasResetableFilters(filters)}
          onClick={handleResetClick}
          data-test-id="search-components_advanced-filters_reset-button"
        />
        <Button
          variant="primary"
          label={t('search.searchbar.done')}
          size="m"
          onClick={handleDoneClick}
          data-test-id="search-components_advanced-filters_submit-button"
          iconPosition="withoutIcon"
        />
      </StyledDialogFooter>
    </StyledDialog>
  );
};

import { type BigDecimalRangeDto } from '@ev/search-modules-api';
import { type ChangeEvent, cloneElement, type ReactElement, useState } from 'react';
import { VisuallyHidden } from 'react-aria';

import { useAppContext } from '../../hooks/useAppContext';
import { validateRangeValues } from '../../utils/numberFormatters';
import { StyledCopy, StyledFieldset, StyledFieldsSeparator } from './RangeInput.styled';
import { type RangeInputProps } from './RangeInput.types';

export const RangeInput = ({
  defaultRange,
  onChange,
  label,
  id,
  hiddenLabel = false,
  inputComponent,
  'data-test-id': dataTestId,
}: RangeInputProps & {
  label: string;
  id: string;
  inputComponent: ReactElement;
  'data-test-id'?: string;
}) => {
  const { t } = useAppContext();
  const [range, setRange] = useState<BigDecimalRangeDto | undefined>(defaultRange ?? undefined);
  const handleOnChange = (range: BigDecimalRangeDto) => {
    setRange({ ...range });
    onChange(validateRangeValues({ ...range }));
  };

  const minInput = cloneElement(inputComponent, {
    placeholder: t('search.searchbar.minLabel'),
    id: `${id}Min`,
    name: `${id}Min`,
    'aria-label': `${label} ${t('search.searchbar.minLabel')}`,
    value: range ? range.min?.toString() : '',
    onChange: ({ target }: ChangeEvent<HTMLInputElement>) => {
      const value = Number.parseInt(target.value);
      handleOnChange({
        ...range,
        min: Number.isNaN(value) ? undefined : value,
      });
    },
    'data-test-id': `${dataTestId}_input-min`,
  });
  const maxInput = cloneElement(inputComponent, {
    placeholder: t('search.searchbar.maxLabel'),
    id: `${id}Max`,
    name: `${id}Max`,
    'aria-label': `${label} ${t('search.searchbar.maxLabel')}`,
    value: range ? range.max?.toString() : '',
    onChange: ({ target }: ChangeEvent<HTMLInputElement>) => {
      const value = Number.parseInt(target.value);
      handleOnChange({
        ...range,
        max: Number.isNaN(value) ? undefined : value,
      });
    },
    'data-test-id': `${dataTestId}_input-max`,
  });
  return (
    <StyledFieldset data-test-id={dataTestId}>
      {hiddenLabel ? (
        <VisuallyHidden>
          <StyledCopy forwardedAs="legend" text={label} size="m" />
        </VisuallyHidden>
      ) : (
        <StyledCopy forwardedAs="legend" text={label} size="m" />
      )}
      {minInput}
      <StyledFieldsSeparator size="l" forwardedAs="span" text="–" />
      {maxInput}
    </StyledFieldset>
  );
};

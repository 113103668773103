import { styled } from 'styled-components';

import ArrowDown from '../../../spearhead/lib/assets/icons/small/Arrow-Thin-Down.svg';
import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';

interface ButtonProps {
  $isOpen?: boolean;
  $isFocusVisible?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  position: relative;
  appearance: none;
  font-family: var(--font-family-text), Arial, Helvetica, sans-serif;
  font-size: var(--font-size-static-m);
  line-height: var(--line-height-static-l);
  padding: var(--distance-static-xs);
  background-color: var(--color-light);
  cursor: pointer;
  border: 1px solid var(--color-input-border-idle);
  border-radius: var(--border-radius-s);

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus,
  &:focus-visible {
    outline: 0;
  }

  &:focus-within {
    outline: 2px solid var(--color-focus-dark);
    outline-offset: -1px;
  }
  @media (hover: hover) {
    &:hover:not(:disabled) {
      border-color: 1px solid var('--color-input-border-hover');
      box-shadow: var(--shadow-xs);
    }
  }
`;

export const StyledLabel = styled(Copy)`
  color: var(--color-dark-lighter);
  letter-spacing: 0.48px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledArrowDown = styled(ArrowDown)<{ $isOpen?: boolean }>`
  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg);'}
  transition: transform 0.3s ease-in-out;
  color: var(--color-dark);
`;

export const StyledDialog = styled.div`
  display: flex;
  max-width: 340px;
  padding: var(--distance-static-m);
  flex-direction: column;
  gap: var(--distance-static-m);
  border-radius: var(--distance-static-3xs);
  background: var(--color-white);
  box-shadow: var(--shadow-m);
  outline: none;
`;

export const StyledDivider = styled.hr`
  height: 1px;
  width: 100%;
  background: var(--color-dark-decent);
  margin: 0;
  border: none;
`;

export const StyledText = styled(Copy)`
  overflow: hidden;
  color: var(--color-dark);
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0.48px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDialogBody = styled.div`
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--distance-static-s);
`;

export const StyledPopover = styled.div`
  box-sizing: border-box;

  & * {
    box-sizing: inherit;
  }
`;

import { type BoundingBoxDto, type Property } from '@ev/search-modules-api';
import {
  getNavigationContext,
  getPropertyContext,
  trackNavigationEvent,
  trackUserInteractionEvent,
  UserActionEnum,
} from '@ev/snowplow-library';
import { getConsentContext, useTracking } from '@pkgs/components/hooks/useTracking';
import { mapPropertyToPropertyContext } from '@pkgs/components/utils/tracking';
import { useCallback } from 'react';

import { useSearch } from './useSearch';
import { createSerpUrl } from './useUrlState';

export const useMapTracking = () => {
  const { pageContext, shopContext } = useTracking();
  const { placeDetails, filters, options, countryCode } = useSearch();

  const trackPropertyMarkerClick = useCallback(
    (propertyId: string, state: 'open' | 'close') => {
      trackUserInteractionEvent({
        elementId: `map-module_property-pin_${state}`,
        action: UserActionEnum.click,
        contexts: [
          pageContext,
          shopContext,
          getConsentContext(),
          getPropertyContext({
            bffObjectId: propertyId,
          }),
        ],
      });
    },
    [pageContext, shopContext],
  );

  const trackClusterClick = useCallback(
    (state: 'open' | 'close' | 'click', depthIndex?: number) => {
      trackUserInteractionEvent({
        elementId: `map-module_property-cluster${depthIndex ? `-${depthIndex}` : ''}_${state}`,
        action: UserActionEnum.click,
        contexts: [pageContext, shopContext, getConsentContext()],
      });
    },
    [pageContext, shopContext],
  );

  const trackMapToggleClick = useCallback(
    (state: 'on' | 'off') => {
      trackUserInteractionEvent({
        elementId: `map-module_${state}`,
        action: UserActionEnum.click,
        contexts: [pageContext, shopContext, getConsentContext()],
      });
    },
    [pageContext, shopContext],
  );

  const trackSearchThisAreaClick = useCallback(
    async (boundingBox: BoundingBoxDto) => {
      const { href } = await createSerpUrl({ ...filters, boundingBox, placeId: undefined }, options, countryCode, placeDetails.name);
      trackNavigationEvent({
        screenName: '',
        contexts: [
          pageContext,
          shopContext,
          getConsentContext(),
          getNavigationContext({
            target_url: href,
            element_id: 'map-module_area-site-search',
          }),
        ],
      });
    },
    [pageContext, shopContext, filters, options, countryCode, placeDetails],
  );

  const trackPreviewCardClick = useCallback(
    (exposeUrl: string, property: Property) => {
      trackNavigationEvent({
        screenName: '',
        contexts: [
          pageContext,
          shopContext,
          getConsentContext(),
          getPropertyContext(mapPropertyToPropertyContext(property)),
          getNavigationContext({
            target_url: exposeUrl,
            element_id: 'map-module_property-preview_link',
          }),
        ],
      });
    },
    [pageContext, shopContext],
  );

  return {
    trackPropertyMarkerClick,
    trackClusterClick,
    trackMapToggleClick,
    trackSearchThisAreaClick,
    trackPreviewCardClick,
  };
};

import { from } from '@pkgs/components/utils/mediaQueries';
import { Alert } from '@spearhead/components/elements/Alert/Alert';
import { Button } from '@spearhead/components/elements/Button/Button';
import { Copy } from '@spearhead/components/elements/Copy/Copy';
import { IconButton } from '@spearhead/components/elements/IconButton/IconButton';
import { StatusScreen } from '@spearhead/components/modules/StatusScreen/StatusScreen';
import { css, styled } from 'styled-components';

import { Modal } from '../Modal/Modal';

export const SearchAlertTooltipStyles = css`
  position: absolute;
  padding: var(--distance-static-xs) var(--distance-static-s);
  background-color: var(--color-dark);
  color: var(--color-light);
  white-space: nowrap;
  border-radius: var(--border-radius-s);
  font-size: var(--font-size-static-s);
  line-height: var(--line-height-relative-default);
`;

export const StyledButton = styled(IconButton)`
  flex-shrink: 0;
  cursor: pointer;

  &:disabled {
    border-color: var(--color-dark-20);
    cursor: default;
  }
`;

export const StyledModal = styled(Modal)`
  padding: 0;
  width: 100%;

  @media screen and (${from.tabletPortrait}) {
    overflow-y: hidden;
  }

  @media screen and (${from.tabletLandscape}) {
    max-width: var(--max-width-m);
  }
`;

export const StyledModalContent = styled.div<{ $hasMargin: boolean }>`
  padding-inline: var(--distance-static-m);
  padding-bottom: var(--distance-static-xxl);
  overflow-x: hidden;

  @media screen and (${from.tabletPortrait}) {
    padding-inline: var(--distance-static-xxl);
  }
  position: relative;

  ${({ $hasMargin }) =>
    $hasMargin &&
    css`
      margin-block: var(--distance-static-xxl);
    `}

  @media screen and (${from.tabletPortrait}) {
    overflow-y: auto;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-s);
`;

export const StyledForm = styled.form<{ $hidden: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-l);

  ${({ $hidden }) =>
    $hidden &&
    css`
      display: none;
    `}

  &:focus {
    outline: none;
  }
`;

export const StyledFilterSummary = styled(Copy)`
  padding-left: var(--distance-static-m);
  border-left: 1px solid var(--color-brand);
`;

export const StyledModalFooter = styled.div`
  padding: var(--distance-static-m) var(--distance-static-l);
  display: flex;
  flex-direction: column-reverse;
  gap: var(--distance-static-m);

  @media screen and (${from.tabletPortrait}) {
    border-top: 1px solid var(--color-dark-decent);
  }

  @media screen and (${from.tabletLandscape}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const StyledInputCounter = styled(Copy)`
  color: var(--color-dark-lighter);
`;

export const StyledInputWrapper = styled.div`
  position: relative;

  & :has(> ${StyledInputCounter}) {
    right: var(--distance-static-s);
    width: unset;
  }

  & input {
    padding-right: 80px;
  }

  & label {
    &::before {
      display: none;
    }
  }
`;

export const StyledAlert = styled(Alert)`
  & #error-alert-description {
    align-items: center;
  }
`;

export const StyledConsentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--distance-static-s);
`;

export const StyledSubmitButton = styled(Button)`
  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledStatusScreen = styled(StatusScreen)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: unset;
  width: 100%;
  padding-inline: var(--distance-static-xxl);
  box-sizing: border-box;
`;

export const StyledCaptchaContainer = styled.div`
  padding-bottom: var(--distance-static-xxl);
`;

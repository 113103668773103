export const ViewportSize = {
  MOBILE: 0,
  TABLET_PORTRAIT: 600,
  TABLET_LANDSCAPE: 905,
  LAPTOP: 1240,
  DESKTOP: 1440,
} as const;

export type ViewportSize = (typeof ViewportSize)[keyof typeof ViewportSize];

export const from = {
  tabletPortrait: `min-width: ${ViewportSize.TABLET_PORTRAIT}px`,
  tabletLandscape: `min-width: ${ViewportSize.TABLET_LANDSCAPE}px`,
  laptop: `min-width: ${ViewportSize.LAPTOP}px`,
  desktop: `min-width: ${ViewportSize.DESKTOP}px`,
};

export const to = {
  tabletPortrait: `max-width: ${ViewportSize.TABLET_PORTRAIT - 1}px`,
  tabletLandscape: `max-width: ${ViewportSize.TABLET_LANDSCAPE - 1}px`,
  laptop: `max-width: ${ViewportSize.LAPTOP - 1}px`,
  desktop: `max-width: ${ViewportSize.DESKTOP - 1}px`,
};

export const getPrefersReducedMotion = () => {
  const motionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
  return motionQuery.matches;
};

import { type Property } from '@ev/search-modules-api';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useHoverStore } from '@pkgs/components/hooks/useHoverStore';
import { useMapTracking } from '@pkgs/components/hooks/useMapTracking';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { useTracking } from '@pkgs/components/hooks/useTracking';
import { useWatchlist } from '@pkgs/components/hooks/useWatchlist';
import { getCssGridColumnCount } from '@pkgs/components/utils/gridColumns';
import { useCallback, useRef } from 'react';

import { SearchResultCard, type SearchResultsCardVariant } from '../SearchResultCard/SearchResultCard';

type Props = {
  property: Property;
  preloadImage: boolean;
  variant?: SearchResultsCardVariant;
  className?: string;
  index?: number;
};

export const SearchResultCardWrapper = ({ property, preloadImage, variant, className, index }: Props) => {
  const { t } = useAppContext();
  const { checkIsInWatchlist, onWatchlistClick } = useWatchlist();
  const { trackPreviewCardClick } = useMapTracking();
  const { trackWatchlistToggle, trackSearchResultCardClick } = useTracking();
  const {
    countryCode,
    options: { language, currency, measurementSystem },
  } = useSearch();
  const cardElementRef = useRef<HTMLDivElement | null>(null);

  const { setHoveredPropertyId } = useHoverStore();

  const isInWatchlist = checkIsInWatchlist(property.id);

  const trackCardClick = useCallback(
    (exposeUrl: string) => {
      if (variant === 'preview') {
        trackPreviewCardClick(exposeUrl, property);
      } else {
        const parentContainer = cardElementRef.current?.parentElement;
        if (!parentContainer || index === undefined) return;
        const columnCount = getCssGridColumnCount(parentContainer);

        const row = Math.floor(index / columnCount) + 1;

        const cardIndex = (index % columnCount) + 1;

        trackSearchResultCardClick(exposeUrl, property, row, cardIndex);
      }
    },
    [index, property, trackPreviewCardClick, trackSearchResultCardClick, variant],
  );

  return (
    <SearchResultCard
      className={className}
      key={property.id}
      ref={cardElementRef}
      property={property}
      preloadImage={preloadImage}
      isInWatchlist={isInWatchlist}
      onWatchlistClick={(property) => {
        trackWatchlistToggle(property, isInWatchlist ? 'remove' : 'add', variant ?? 'default');
        onWatchlistClick(property);
      }}
      currency={currency}
      measurementSystem={measurementSystem}
      language={language}
      translationResolver={t}
      variant={variant}
      countryCode={countryCode}
      onClickPropertyCardLink={trackCardClick}
      onMouseEnter={() => {
        if (variant !== 'preview') setHoveredPropertyId(property.id);
      }}
      onMouseLeave={() => {
        if (variant !== 'preview') setHoveredPropertyId(undefined);
      }}
    />
  );
};

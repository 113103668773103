import { BusinessArea, CurrencySymbolMap, PropertyType, UnitSymbolMap } from '@ev/search-modules-api';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useCreateSearchAlertButtonClick } from '@pkgs/components/hooks/useCreateSearchAlertButtonClick';
import { lazy, Suspense } from 'react';
import { type AriaButtonProps, mergeProps, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { useSearch } from '../../hooks/useSearch';
import { AdvancedFilters } from '../AdvancedFilters/AdvancedFilters';
import { SearchAlertButton } from '../SearchAlert/SearchAlertButton';
import {
  StyledBuyRentFilter,
  StyledConstructionYearFilter,
  StyledExtendedSearchRadiusFilter,
  StyledFilterBarContainer,
  StyledLivingSurfaceFilter,
  StyledLocationFilter,
  StyledPlotSurfaceFilter,
  StyledPriceFilter,
  StyledPropertyTypeFilter,
  StyledRoomsFilter,
  StyledTotalSurfaceFilter,
} from './FilterBar.styled';

const SearchAlertModal = lazy(() => import('../SearchAlert/SearchAlertModal'));

export const FilterBar = () => {
  const {
    filters,
    options: { currency, measurementSystem, language },
    updateFilters,
    placeDetails,
    isPartnerShops,
    isMapSearch,
  } = useSearch();
  const { t } = useAppContext();
  const isDevelopmentSelected = filters.propertyType === PropertyType.group;
  const { trackButtonClick } = useCreateSearchAlertButtonClick();
  const isResidentialSelected = filters.businessArea === BusinessArea.residential;
  const isCommercialSelected = filters.businessArea === BusinessArea.commercial;
  const unitSymbol = UnitSymbolMap[measurementSystem];
  const currencySymbol = CurrencySymbolMap[currency];

  const state = useOverlayTriggerState({});

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

  const searchAlertButtonTriggerProps: AriaButtonProps = mergeProps(triggerProps, {
    onPress: () => trackButtonClick('filter-bar_tertiary_search-alert'),
  });

  return (
    <StyledFilterBarContainer>
      <StyledBuyRentFilter
        propertyMarketingType={filters.propertyMarketingType}
        hiddenLabel
        size="m"
        onChange={(refinement) => updateFilters(refinement)}
        data-test-id="search-components_filter-bar_buy-rent-filter"
      />
      <StyledPropertyTypeFilter
        selectedPropertyType={filters.propertyType ?? undefined}
        selectedBusinessArea={filters.businessArea}
        hiddenLabel
        size="m"
        onChange={(refinement) => updateFilters(refinement)}
        data-test-id="search-components_filter-bar_property-type-filter"
      />
      <StyledLocationFilter
        size="m"
        hiddenLabel
        initialLocation={isMapSearch ? t('search.searchbar.mapSearch') : (placeDetails.name ?? '')}
        onChange={(refinement) => {
          updateFilters(refinement);
        }}
        data-test-id="search-components_filter-bar_location-filter"
      />
      <StyledExtendedSearchRadiusFilter
        hiddenLabel
        size="m"
        searchRadius={filters.searchRadius ?? 0}
        onChange={(refinement) => updateFilters(refinement)}
        data-test-id="search-components_filter-bar_search-radius-filter"
      />
      {!isDevelopmentSelected && (
        <StyledPriceFilter
          propertyMarketingType={filters.propertyMarketingType}
          value={filters.price}
          currencySymbol={currencySymbol}
          language={language}
          onChange={(refinement) => {
            updateFilters(refinement);
          }}
          data-test-id="search-components_filter-bar_price-filter"
        />
      )}
      {isResidentialSelected && !isDevelopmentSelected && (
        <>
          <StyledLivingSurfaceFilter
            value={filters.livingSurface}
            unitSymbol={unitSymbol}
            unit={measurementSystem}
            language={language}
            onChange={(refinement) => {
              updateFilters(refinement);
            }}
            data-test-id="search-components_filter-bar_living-surface-filter"
          />
          <StyledRoomsFilter
            value={filters.rooms}
            language={language}
            onChange={(refinement) => {
              updateFilters(refinement);
            }}
            data-test-id="search-components_filter-bar_rooms-filter"
          />
        </>
      )}
      {isCommercialSelected && (
        <>
          <StyledTotalSurfaceFilter
            value={filters.totalSurface}
            unitSymbol={unitSymbol}
            unit={measurementSystem}
            language={language}
            onChange={(refinement) => {
              updateFilters(refinement);
            }}
            data-test-id="search-components_filter-bar_total-surface-filter"
          />
          <StyledPlotSurfaceFilter
            value={filters.plotSurface}
            unitSymbol={unitSymbol}
            unit={measurementSystem}
            language={language}
            onChange={(refinement) => {
              updateFilters(refinement);
            }}
            data-test-id="search-components_filter-bar_plot-surface-filter"
          />
        </>
      )}
      {isDevelopmentSelected && (
        <StyledConstructionYearFilter
          value={filters.constructionYear}
          onChange={(refinement) => {
            updateFilters(refinement);
          }}
          data-test-id="search-components_filter-bar_construction-year-filter"
        />
      )}
      <AdvancedFilters />
      {!isPartnerShops && (
        <>
          <SearchAlertButton {...searchAlertButtonTriggerProps} />
          {state.isOpen && (
            <Suspense>
              <SearchAlertModal state={state} overlayProps={overlayProps} />
            </Suspense>
          )}
        </>
      )}
    </StyledFilterBarContainer>
  );
};

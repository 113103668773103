import { css, styled } from 'styled-components';

export const headlineSizes = {
  h1: css`
    font-size: var(--font-size-dynamic-xl);
    line-height: var(--line-height-dynamic-xl);
  `,
  h2: css`
    font-size: var(--font-size-dynamic-l);
    line-height: var(--line-height-dynamic-l);
  `,
  h3: css`
    font-size: var(--font-size-dynamic-m);
    line-height: var(--line-height-dynamic-m);
  `,
  h4: css`
    font-size: var(--font-size-dynamic-s);
    line-height: var(--line-height-dynamic-s);
  `,
  h5: css`
    font-size: var(--font-size-static-l);
    line-height: var(--line-height-static-l);
  `,
  h6: css`
    font-size: var(--font-size-static-m);
    line-height: var(--line-height-static-m);
  `,
} as const;
export type HeadlineSize = keyof typeof headlineSizes;

export const headlineBaseStyles = css`
  margin: 0;
  font-family: var(--font-family-heading), Georgia, 'Times New Roman', Times, serif;
  font-weight: var(--font-weight-heavy);
  letter-spacing: normal;
  color: var(--color-text-dark-highlight);
`;

export interface StyledHeadlineProps {
  $size: HeadlineSize;
}

export const StyledHeadline = styled.div<StyledHeadlineProps>`
  ${headlineBaseStyles};
  ${({ $size }) => headlineSizes[$size]}
`;

import { SearchAlertTooltip, Tooltip } from '@pkgs/components/components/SearchAlert/SearchAlertTooltip';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useCreateSearchAlertButtonClick } from '@pkgs/components/hooks/useCreateSearchAlertButtonClick';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { Copy } from '@spearhead/components/elements/Copy/Copy';
import { useRef } from 'react';
import { type AriaButtonProps, mergeProps, useButton } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';

import BellPlus from '../../../spearhead/lib/assets/icons/medium/Bell-Plus.svg';
import { StyledArticle, StyledButton, StyledTextContainer, StyledTitle } from './SearchAlertCtaCard.styles';

export const SearchAlertCtaCard = ({ className, ...props }: AriaButtonProps<'button'> & { className?: string }) => {
  const { t } = useAppContext();
  const { trackButtonClick } = useCreateSearchAlertButtonClick();

  const ref = useRef(null);
  const { noLocationOrShopFilter } = useSearch();

  const state = useTooltipTriggerState(props);

  const triggerPropsWithTracking = mergeProps(props, {
    onPress: () => trackButtonClick('create-search-alert-button'),
    isDisabled: noLocationOrShopFilter,
  });
  const { buttonProps } = useButton(triggerPropsWithTracking, ref);

  return (
    <StyledArticle className={className} data-test-id="search-components_cta-card_search-alert">
      <BellPlus />
      <StyledTextContainer>
        <StyledTitle label={t('search.searchAlert.ctaCard.title')} size="h5" />
        <Copy text={t('search.searchAlert.ctaCard.text')} />
      </StyledTextContainer>

      <SearchAlertTooltip state={state}>
        <StyledButton
          {...buttonProps}
          refObject={ref}
          variant="secondary"
          label={t('search.searchAlert.ctaCard.button')}
          data-test-id="search-components_cta-card_search-alert_button"
        />
        {state.isOpen && <Tooltip props={props} state={state} isCentered />}
      </SearchAlertTooltip>
    </StyledArticle>
  );
};

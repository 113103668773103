import { useAppContext } from '../../hooks/useAppContext';
import { FormattedNumberInput } from '../FormattedNumberInput/FormattedNumberInput';
import { RangeInput } from './RangeInput';
import { StyledEndAdornment } from './RangeInput.styled';
import type { SurfaceRangeInputProps } from './RangeInput.types';

export const PlotSurfaceInput = ({ language, unitSymbol, ...restProps }: SurfaceRangeInputProps) => {
  const { t } = useAppContext();
  return (
    <RangeInput
      label={t('search.searchbar.advFilter.plotSurface')}
      id="plotSurface"
      inputComponent={
        <FormattedNumberInput
          language={language ?? 'en'}
          endAdornment={<StyledEndAdornment forwardedAs="span" size="l" text={unitSymbol} />}
        />
      }
      {...restProps}
    />
  );
};

import { useScrollLock } from '@pkgs/components/hooks/useScrollLock';
import CheckNaked from '@spearhead/assets/icons/small/Check-Naked.svg';
import { type InputTextSizesProps } from '@spearhead/components/elements/InputText/InputText';
import { cloneElement, forwardRef, type ReactElement, type ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Label, type PopoverProps, type SelectProps, SelectStateContext } from 'react-aria-components';
import { type SelectState } from 'react-stately';

import {
  type SelectButtonVariants,
  StyledAriaButton,
  StyledAriaList,
  StyledAriaListItem,
  StyledAriaPopover,
  StyledCopy,
  StyledIcon,
  StyledSelect,
  StyledSelectedIcon,
  StyledValue,
} from './Select.styled';

export type SelectItem = {
  id: string;
  label: string | ReactNode;
  // text to add to the aria-label for the item in-case the label is not a string
  description?: string;
  selectedLabel?: string | ReactNode;
  nested?: boolean;
  parent?: boolean;
};

type SelectButtonProps = {
  buttonIcon?: React.ReactNode;
  placeholder?: string;
  size?: InputTextSizesProps;
  isInModal?: boolean;
  arrowIcon?: React.ReactElement;
  variant?: SelectButtonVariants;
  'data-test-id'?: string;
};

const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ buttonIcon, placeholder, size, isInModal = false, arrowIcon, variant = 'outlined', 'data-test-id': dataTestId }, buttonRef) => {
    // cannot be destructured, because the context value is undefined initially
    const state = useContext(SelectStateContext) as SelectState<SelectItem> | undefined;

    const scrollLockRef = useRef<HTMLButtonElement>(null);

    useScrollLock(scrollLockRef, !isInModal, !state?.isOpen);

    const label =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      state?.selectedItem?.value?.selectedLabel ?? state?.selectedItem?.rendered ?? placeholder;

    return (
      <StyledAriaButton $variant={variant} $hasError={false} $size={size} ref={buttonRef} data-test-id={dataTestId}>
        <StyledValue>
          {buttonIcon}
          {label}
        </StyledValue>
        {arrowIcon ? cloneElement(arrowIcon, { $isOpen: state?.isOpen }) : <StyledIcon $isOpen={state?.isOpen} aria-hidden="true" />}
      </StyledAriaButton>
    );
  },
);

SelectButton.displayName = 'SelectButton';

export const Select = ({
  buttonIcon,
  placeholder,
  className,
  label,
  items,
  hiddenLabel,
  size,
  isInModal,
  arrowIcon,
  overlayProps,
  variant,
  itemWithCheckmark,
  useButtonWidth = false,
  'data-test-id': dataTestId,
  ...props
}: SelectProps<SelectItem> & {
  buttonIcon?: ReactNode;
  placeholder?: string;
  label?: string;
  className?: string;
  items: SelectItem[];
  hiddenLabel?: boolean;
  size?: InputTextSizesProps;
  isInModal?: boolean;
  arrowIcon?: ReactElement;
  overlayProps?: PopoverProps;
  variant?: SelectButtonVariants;
  itemWithCheckmark?: SelectItem;
  useButtonWidth?: boolean;
  'data-test-id'?: string;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  useEffect(() => {
    if (useButtonWidth && buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [useButtonWidth, buttonRef.current]);
  return (
    <StyledSelect {...props} className={className} data-test-id={dataTestId}>
      <Label hidden={hiddenLabel}>
        <StyledCopy forwardedAs="legend" size="m" text={label} />
      </Label>
      <SelectButton
        ref={useButtonWidth ? buttonRef : null}
        buttonIcon={buttonIcon}
        placeholder={placeholder}
        size={size}
        arrowIcon={arrowIcon}
        isInModal={isInModal}
        variant={variant}
        data-test-id={dataTestId ? `${dataTestId}_button` : 'search-components_select_button'}
      />
      <StyledAriaPopover {...overlayProps}>
        <StyledAriaList
          $fixedWidth={useButtonWidth ? buttonWidth : undefined}
          data-test-id={dataTestId ? `${dataTestId}_listbox` : 'search-components_select_listbox'}
        >
          {items.map((item) => (
            <StyledAriaListItem
              key={item.id}
              id={item.id}
              $isNested={item.nested}
              $isParent={item.parent}
              value={item}
              textValue={typeof item.label === 'string' ? item.label : item.description}
            >
              <StyledValue>{item.label}</StyledValue>
              {itemWithCheckmark === item ? (
                <>
                  <CheckNaked />
                  <StyledSelectedIcon aria-hidden="true" />
                </>
              ) : (
                <StyledSelectedIcon aria-hidden="true" />
              )}
            </StyledAriaListItem>
          ))}
        </StyledAriaList>
      </StyledAriaPopover>
    </StyledSelect>
  );
};

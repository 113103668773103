import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';

import { type HeaderProps } from './Header';
import { StyledNavWrapper, StyledWrapper } from './HeaderDesktop.styles';
import { HeaderNavigation } from './HeaderNavigation';
import { HeaderSettingsSelector } from './HeaderSettingsSelector';
import { HeaderWatchlist } from './HeaderWatchlist';
import { getHeaderNavigationItems } from './utils';

export const HeaderDesktop = ({ onLanguageChange }: HeaderProps): React.ReactElement => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('header_footer');

  return (
    <StyledNavWrapper data-test-id="main-header" ref={headerRef}>
      <StyledWrapper>
        <HeaderWatchlist />
        <HeaderSettingsSelector onLanguageChange={onLanguageChange} />
      </StyledWrapper>
      <HeaderNavigation items={getHeaderNavigationItems(t)} />
    </StyledNavWrapper>
  );
};

import { useRef } from 'react';
import { type AriaButtonProps, useButton } from 'react-aria';

import CloseIcon from '../../../spearhead/lib/assets/icons/small/Cross-Naked.svg';
import { StyledIconButton } from './AutoComplete.styles';

interface Props extends AriaButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ClearInputButton = (props: Props) => {
  const clearButtonRef = useRef(null);
  const { buttonProps } = useButton(props, clearButtonRef);
  const { onKeyDown, ...restButtonProps } = buttonProps;

  return (
    <StyledIconButton
      ref={clearButtonRef}
      icon={<CloseIcon />}
      variant="primaryAlt"
      shape="square"
      aria-label="clear-button"
      size="xsmall"
      {...restButtonProps}
    />
  );
};

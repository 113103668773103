import { MeasurementSystem } from '@pkgs/api';

import { type UnitEnum } from '@/types/currency';

export const unitToMeasurementSystem = (unit: UnitEnum): MeasurementSystem => {
  switch (unit.toUpperCase()) {
    case 'SQMT':
      return MeasurementSystem.metric;
    case 'SQFT':
      return MeasurementSystem.imperial;
    default:
      console.warn('Invalid measurement system. Must be "SQMT" or "SQFT".');
      return MeasurementSystem.metric;
  }
};

/**
 * Example:
 * {
 *  ha: { totalSurface: 100 },
 *  sqmt: { totalSurface: 10 }
 * }
 *  => { totalSurface_HA: 100, totalSurface_SQMT: 10 }
 */
type InputObject<T extends string, U extends string, V> = Record<T, Partial<Record<U, V>>>;
type OutputObject<T extends string, U extends string, V> = Record<`${U}_${Uppercase<T>}`, V>;
export function extractWithSuffixes<T extends string, U extends string, V>(inputObject: InputObject<T, U, V>): OutputObject<T, U, V> {
  return Object.entries(inputObject).reduce((acc, [suffix, innerObj]) => {
    return {
      ...acc,
      ...Object.entries(innerObj || {}).reduce((innerAcc, [prefix, value]) => {
        return {
          ...innerAcc,
          ...(value !== null && {
            [`${prefix}_${suffix.toUpperCase()}`]: value,
          }),
        };
      }, {}),
    };
  }, {} as OutputObject<T, U, V>);
}

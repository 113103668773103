import React from 'react';

import type { BadgeVariant } from './Badge.styled';
import { StyledBadge } from './Badge.styled';

export interface IBadgeProps {
  className?: string;
  label?: string;
  variant?: BadgeVariant;
  'data-test-id'?: string;
}

export const Badge = ({ className, label, variant = 'new', 'data-test-id': dataTestId }: IBadgeProps) => {
  return (
    <StyledBadge className={className} $variant={variant} data-test-id={dataTestId}>
      {label}
    </StyledBadge>
  );
};

import { getScrollParent } from '@react-aria/utils';
import { type RefObject, useCallback, useEffect, useRef } from 'react';

export const useScrollLock = (elementRef: RefObject<HTMLElement>, isDisabled: boolean, isScrollable: boolean) => {
  const initialValues = useRef({
    overflowY: '',
    paddingRight: '',
  });

  const toggleParentScroll = useCallback((element: HTMLElement, isScrollable: boolean) => {
    const parent = getScrollParent(element) as HTMLElement;

    const parentStyles = 'computedStyleMap' in parent ? parent.computedStyleMap() : window.getComputedStyle(parent);

    if (!initialValues.current.overflowY) {
      const overflowY =
        'get' in parentStyles ? parentStyles.get('overflow-y')?.toString() : parentStyles.getPropertyValue('overflow-y').toString();

      const paddingRight =
        'get' in parentStyles ? parentStyles.get('padding-right')?.toString() : parentStyles.getPropertyValue('padding-right').toString();

      initialValues.current.overflowY = overflowY ?? '';
      initialValues.current.paddingRight = paddingRight ?? '';
    }

    const scrollBarWidth = parent.offsetWidth - parent.clientWidth;

    parent.style.overflowY = isScrollable ? initialValues.current.overflowY : 'hidden';
    parent.style.paddingRight = isScrollable
      ? initialValues.current.paddingRight
      : `${Number.parseInt(initialValues.current.paddingRight) + scrollBarWidth}px`;
  }, []);

  useEffect(() => {
    if (!elementRef.current || isDisabled) {
      return;
    }
    const element = elementRef.current;

    toggleParentScroll(element, isScrollable);
  }, [isScrollable, elementRef, toggleParentScroll, isDisabled]);
};

import { getListingById, type ProjectUnit } from '@generated/search-bff';
import {
  type ApiConfig,
  type ApiResult,
  type GetPropertyOptions,
  type Property,
  type PropertyResponse,
  type RawListing,
} from '@pkgs/api/types';
import { getDefaultParams } from '@pkgs/api/utils/defaultOptions';
import { getErrorResult } from '@pkgs/api/utils/error';
import { refineProperty } from '@pkgs/api/utils/refineProperty';

/**
 * Fetch detailed information about a property by its listing ID (UUID).
 * @function
 * @param {string} listingId - The UUID for the listing.
 * @param {GetPropertyOptions} options - Options to customize the search: language, currency, measurement unit, and optionally (if it exists), if information about the parent (development project) property should also be returned.
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<PropertyResponse>>} A promise that resolves to the result of the API call, containing either the detailed property information or an error message.
 *
 * @summary Fetch property details using its listing ID.
 */

export async function getPropertyByListingId(
  listingId: string,
  options: GetPropertyOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<PropertyResponse>> {
  let property: Property | undefined;
  let parentProject: Property | undefined;
  let projectUnits: ProjectUnit[] | undefined;
  const params = getDefaultParams(options);

  try {
    const listingDto = await getListingById(listingId, params, apiConfig);
    property = refineProperty(listingDto, options.language);
    projectUnits = property.project?.units;
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }

  if (options.includeParentProject && property.parentID) {
    try {
      const parentProjectDto = await getListingById(property.parentID, params, apiConfig);
      parentProject = refineProperty(parentProjectDto, options.language);

      projectUnits = parentProject.project?.units?.filter((unit) => unit.id !== property.id);
    } catch (error) {
      apiConfig.onError?.(error);
      console.error('Error fetching parent project:', error, 'Returning only the child property data.');
    }
  }

  return {
    status: 'success',
    data: {
      property,
      ...(parentProject && { parentProject }),
      ...(projectUnits && {
        projectUnits: projectUnits.map((unit) => refineProperty(unit as RawListing, options.language)),
      }),
    },
  };
}

import type { AriaModalOverlayProps } from '@react-aria/overlays';
import { Overlay, useModalOverlay } from '@react-aria/overlays';
import type { OverlayTriggerState } from '@react-stately/overlays';
import { type DOMProps } from '@react-types/shared';
import { type ReactNode, useEffect, useRef } from 'react';
import { FocusScope, useFocusManager } from 'react-aria';
import { useKeyPressEvent } from 'react-use';

import CrossNaked from '../../../spearhead/lib/assets/icons/small/Cross-Naked.svg';
import { StyledBackdrop, StyledCloseButton, StyledContent, StyledModal, StyledTopBar } from './Modal.styled';

export interface ModalProps extends AriaModalOverlayProps {
  className?: string;
  children: ReactNode;
  state: OverlayTriggerState;
  fullScreen?: boolean;
  overlayProps: DOMProps;
  hasCloseButton?: boolean;
  'data-test-id'?: string;
}

export const Modal = (props: ModalProps) => {
  const { children, state, className, fullScreen, overlayProps, hasCloseButton } = props;
  useKeyPressEvent('Escape', state.close);

  const modalRef = useRef(null);

  const { modalProps, underlayProps } = useModalOverlay({ ...props, isDismissable: true }, state, modalRef);

  if (!state.isOpen) {
    return null;
  }

  // useFocusManager must be called within the FocusScope
  const ModalChildren = () => {
    const focusManager = useFocusManager();

    useEffect(() => {
      if (focusManager) {
        // focus form on mount (instead of the close button)
        focusManager.focusNext({
          accept: (node) => node instanceof HTMLFormElement,
        });
      }
    }, [focusManager]);

    return children;
  };

  return (
    <Overlay portalContainer={document.body}>
      <FocusScope contain restoreFocus autoFocus>
        <StyledBackdrop {...underlayProps}>
          <StyledModal
            {...modalProps}
            {...overlayProps}
            ref={modalRef}
            className={className}
            $fullScreen={fullScreen}
            data-test-id={props['data-test-id'] ?? 'search-components_modal'}
          >
            {hasCloseButton && (
              <StyledTopBar>
                <StyledCloseButton
                  icon={<CrossNaked />}
                  variant="tertiaryAlt"
                  onClick={state.close}
                  data-test-id={props['data-test-id'] ? props['data-test-id'] + '_close-button' : 'search-components_modal_close-button'}
                />
              </StyledTopBar>
            )}
            <StyledContent $fullScreen={fullScreen}>
              <ModalChildren />
            </StyledContent>
          </StyledModal>
        </StyledBackdrop>
      </FocusScope>
    </Overlay>
  );
};

import { useState } from 'react';

type ResetKeys<T extends readonly string[]> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [Key in T[number]]: number;
};
export const useResetKey = <T extends readonly string[]>(fields: [...T]) => {
  const initialKeysObject = Object.fromEntries(fields.map((key) => [key, 0])) as ResetKeys<T>;

  const [resetKeys, setResetKeys] = useState(initialKeysObject);

  const resetKey = (fields: Array<T[number]>) => {
    const newKeys: ResetKeys<T> = resetKeys;
    for (const key in resetKeys) {
      if (fields.includes(key)) {
        newKeys[key as T[number]] = resetKeys[key as T[number]] + 1;
      }
    }
    setResetKeys({ ...newKeys });
  };

  const resetAllKeys = () => {
    const newKeys: ResetKeys<T> = resetKeys;
    for (const key in resetKeys) {
      newKeys[key as T[number]] = resetKeys[key as T[number]] + 1;
    }
    setResetKeys({ ...newKeys });
  };

  return {
    resetKeys,
    resetKey,
    resetAllKeys,
  };
};

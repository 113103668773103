/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type PropertyKind = (typeof PropertyKind)[keyof typeof PropertyKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyKind = {
  object: 'object',
  group: 'group',
} as const;

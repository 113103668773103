import ArrowThinDown from '@spearhead/assets/icons/xsmall/Arrow-Thin-Down.svg';
import { styled } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  gap: var(--distance-static-xxs);
  align-items: center;
`;

export const StyledArrow = styled(ArrowThinDown)<{
  $isOpen?: boolean;
}>`
  ${({ $isOpen }) => $isOpen && 'transform: rotate(-180deg);'}
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: var(--distance-static-xxs);
`;

import { Link } from '@mui/material';
import React, { type ReactNode } from 'react';

type BrandLogoProps = {
  url: string;
  className?: string;
  icon: ReactNode;
};

const FooterBrandLogo = ({ icon, url, className }: BrandLogoProps): React.ReactElement => {
  return (
    <Link href={url} className={className} sx={{ lineHeight: 0 }}>
      {icon}
    </Link>
  );
};

export default React.memo(FooterBrandLogo);

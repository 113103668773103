import { useAppContext } from '../../hooks/useAppContext';
import { InputText } from '../InputText/InputText';
import { RangeInput } from './RangeInput';
import { type RangeInputProps } from './RangeInput.types';

export const ConstructionYearInput = (props: RangeInputProps) => {
  const { t } = useAppContext();
  return (
    <RangeInput
      label={t('search.searchbar.advFilter.constructionYear')}
      id="constructionYear"
      inputComponent={<InputText type="number" inputMode="numeric" />}
      {...props}
    />
  );
};

import { useAppContext } from '@pkgs/components/hooks/useAppContext';

import { SortBySelect } from '../SortBySelect/SortBySelect';
import { StyledContainer, StyledMapSwitch } from './SearchInfoRow.styled';
import { TotalResultsCount } from './TotalResultsCount';

export const SearchInfoRow = ({
  isMapView,
  updateIsMapView,
  hasResults,
}: {
  isMapView: boolean;
  updateIsMapView: (isMapView: boolean) => void;
  hasResults: boolean;
}) => {
  const { isMapEnabled, t } = useAppContext();
  return (
    <StyledContainer>
      <TotalResultsCount />
      {hasResults && <SortBySelect />}
      {isMapEnabled && (
        <StyledMapSwitch label={t('search.searchbar.mapViewToggle.label')} isSelected={isMapView} onChange={updateIsMapView} />
      )}
    </StyledContainer>
  );
};

/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type MeasurementSystem = (typeof MeasurementSystem)[keyof typeof MeasurementSystem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeasurementSystem = {
  metric: 'metric',
  imperial: 'imperial',
} as const;

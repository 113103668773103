import { panic } from '@ev/search-modules-api';
import { type SearchProviderProps } from '@pkgs/components/providers/SearchProvider';
import { useQuery } from '@tanstack/react-query';

import { useAppContext } from './useAppContext';

export const LOCATION_DETAILS_QUERY_KEY = 'location-details';

export const usePlaceDetails = ({ placeId, language, initialPlaceDetails }: SearchProviderProps & { placeId?: string }) => {
  const { api } = useAppContext();

  const { data = {}, ...query } = useQuery({
    queryKey: [LOCATION_DETAILS_QUERY_KEY, placeId, language],
    queryFn: async () => {
      const result = await api.getPlaceLocationDetails(placeId || panic('placeId is empty'), {
        language: language,
      });
      if (result.status === 'error') {
        throw result.error;
      }
      return result.data;
    },
    initialData: initialPlaceDetails,
    refetchOnMount: !initialPlaceDetails,
    enabled: Boolean(placeId),
  });

  return {
    placeDetails: data,
    ...query,
  };
};

import {
  getTrackingByShopId
} from "./chunk-THP32KMZ.js";

// src/getTrackingByShopId/getTrackingByShopId.ts
var getTrackingByShopId2 = (shopId, apiConfig) => {
  return getTrackingByShopId(shopId, apiConfig);
};

export {
  getTrackingByShopId2 as getTrackingByShopId
};

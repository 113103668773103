import { useRef } from 'react';
import { type AriaRadioProps, useRadio } from 'react-aria';
import { type RadioGroupState } from 'react-stately';

import { Chip } from '../Chip/Chip';
import { StyledInput } from './FrequencyPicker.styled';

export const FrequencyInput = (
  props: AriaRadioProps & {
    state: RadioGroupState;
    label: string;
  },
) => {
  const { state, label, value } = props;
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected } = useRadio({ ...props, children: label }, state, ref);

  const handleChange = (isSelected: boolean) => {
    if (isSelected) state.setSelectedValue(value);
  };

  return (
    <label>
      <StyledInput {...inputProps} ref={ref} />
      <Chip
        label={label}
        isSelected={isSelected}
        onChange={handleChange}
        data-test-id={`search-components_search-alert-creation-form_frequency-picker_${value}_chip`}
      />
    </label>
  );
};

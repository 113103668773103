import {
  getTrackingByShopId as generatedGetTrackingByShopId,
  type GetTrackingByShopIdResult as GeneratedGetTrackingByShopIdResult,
} from '@generated/search-bff/controllers/tracking-v2/tracking-v2';
import { type ApiConfig } from '@pkgs/api/types';

/**
 * @summary Returns all tracking ids based on a given master data shop id
 */
export const getTrackingByShopId = (shopId: string, apiConfig: ApiConfig) => {
  return generatedGetTrackingByShopId(shopId, apiConfig);
};

export type GetTrackingByShopIdResult = GeneratedGetTrackingByShopIdResult;

/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type PlaceType = (typeof PlaceType)[keyof typeof PlaceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlaceType = {
  accounting: 'accounting',
  airport: 'airport',
  amusement_park: 'amusement_park',
  aquarium: 'aquarium',
  art_gallery: 'art_gallery',
  atm: 'atm',
  bakery: 'bakery',
  bank: 'bank',
  bar: 'bar',
  beauty_salon: 'beauty_salon',
  bicycle_store: 'bicycle_store',
  book_store: 'book_store',
  bowling_alley: 'bowling_alley',
  bus_station: 'bus_station',
  cafe: 'cafe',
  campground: 'campground',
  car_dealer: 'car_dealer',
  car_rental: 'car_rental',
  car_repair: 'car_repair',
  car_wash: 'car_wash',
  casino: 'casino',
  cemetery: 'cemetery',
  church: 'church',
  city_hall: 'city_hall',
  clothing_store: 'clothing_store',
  convenience_store: 'convenience_store',
  courthouse: 'courthouse',
  dentist: 'dentist',
  department_store: 'department_store',
  doctor: 'doctor',
  drugstore: 'drugstore',
  electrician: 'electrician',
  electronics_store: 'electronics_store',
  embassy: 'embassy',
  fire_station: 'fire_station',
  florist: 'florist',
  funeral_home: 'funeral_home',
  furniture_store: 'furniture_store',
  gas_station: 'gas_station',
  gym: 'gym',
  hair_care: 'hair_care',
  hardware_store: 'hardware_store',
  hindu_temple: 'hindu_temple',
  home_goods_store: 'home_goods_store',
  hospital: 'hospital',
  insurance_agency: 'insurance_agency',
  jewelry_store: 'jewelry_store',
  laundry: 'laundry',
  lawyer: 'lawyer',
  library: 'library',
  light_rail_station: 'light_rail_station',
  liquor_store: 'liquor_store',
  local_government_office: 'local_government_office',
  locksmith: 'locksmith',
  lodging: 'lodging',
  meal_delivery: 'meal_delivery',
  meal_takeaway: 'meal_takeaway',
  mosque: 'mosque',
  movie_rental: 'movie_rental',
  movie_theater: 'movie_theater',
  moving_company: 'moving_company',
  museum: 'museum',
  night_club: 'night_club',
  painter: 'painter',
  park: 'park',
  parking: 'parking',
  pet_store: 'pet_store',
  pharmacy: 'pharmacy',
  physiotherapist: 'physiotherapist',
  plumber: 'plumber',
  police: 'police',
  post_office: 'post_office',
  primary_school: 'primary_school',
  real_estate_agency: 'real_estate_agency',
  restaurant: 'restaurant',
  roofing_contractor: 'roofing_contractor',
  rv_park: 'rv_park',
  school: 'school',
  secondary_school: 'secondary_school',
  shoe_store: 'shoe_store',
  shopping_mall: 'shopping_mall',
  spa: 'spa',
  stadium: 'stadium',
  storage: 'storage',
  store: 'store',
  subway_station: 'subway_station',
  supermarket: 'supermarket',
  synagogue: 'synagogue',
  taxi_stand: 'taxi_stand',
  tourist_attraction: 'tourist_attraction',
  train_station: 'train_station',
  transit_station: 'transit_station',
  travel_agency: 'travel_agency',
  university: 'university',
  veterinary_care: 'veterinary_care',
  zoo: 'zoo',
  administrative_area_level_1: 'administrative_area_level_1',
  administrative_area_level_2: 'administrative_area_level_2',
  administrative_area_level_3: 'administrative_area_level_3',
  administrative_area_level_4: 'administrative_area_level_4',
  administrative_area_level_5: 'administrative_area_level_5',
  administrative_area_level_6: 'administrative_area_level_6',
  administrative_area_level_7: 'administrative_area_level_7',
  archipelago: 'archipelago',
  colloquial_area: 'colloquial_area',
  continent: 'continent',
  finance: 'finance',
  floor: 'floor',
  food: 'food',
  general_contractor: 'general_contractor',
  health: 'health',
  intersection: 'intersection',
  landmark: 'landmark',
  natural_feature: 'natural_feature',
  place_of_worship: 'place_of_worship',
  plus_code: 'plus_code',
  point_of_interest: 'point_of_interest',
  political: 'political',
  post_box: 'post_box',
  postal_code_prefix: 'postal_code_prefix',
  postal_code_suffix: 'postal_code_suffix',
  postal_code: 'postal_code',
  postal_town: 'postal_town',
  premise: 'premise',
  room: 'room',
  route: 'route',
  street_address: 'street_address',
  street_number: 'street_number',
  sublocality_level_1: 'sublocality_level_1',
  sublocality_level_2: 'sublocality_level_2',
  sublocality_level_3: 'sublocality_level_3',
  sublocality_level_4: 'sublocality_level_4',
  sublocality_level_5: 'sublocality_level_5',
  subpremise: 'subpremise',
  town_square: 'town_square',
  country: 'country',
  locality: 'locality',
  sublocality: 'sublocality',
  neighborhood: 'neighborhood',
  geocode: 'geocode',
  establishment: 'establishment',
  address: 'address',
  cities: 'cities',
} as const;

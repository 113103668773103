import {
  safePanic
} from "./chunk-3W4JOJMB.js";
import {
  BusinessArea,
  CountryCode,
  Currency,
  Language,
  MeasurementSystem,
  PropertyKind,
  PropertyMarketingType,
  PropertyType
} from "./chunk-VQQW7DNQ.js";
import {
  displayIdPrefixes
} from "./chunk-6DVOEGZZ.js";

// src/utils/object.ts
import { isPlainObject } from "lodash-es";
var isRecord = (value) => isPlainObject(value);
var isRangeFilter = (value) => {
  if (!isRecord(value)) return false;
  return "min" in value || "max" in value;
};
var isKey = (x, k) => {
  return k in x;
};
var getEntries = (obj) => Object.entries(obj);

// src/utils/filters.ts
import { isEmpty, mergeWith, omitBy } from "lodash-es";
var DEFAULT_FILTERS = {
  businessArea: [BusinessArea.residential],
  propertyMarketingType: [PropertyMarketingType.sale]
};
var DEFAULT_OPTIONS = {
  language: Language.en
};
function mapPriceFilter(filter, propertyMarketingType, currency) {
  const priceType = propertyMarketingType === PropertyMarketingType.rent ? "rentTotal" : "salesPrice";
  const currencySuffix = currency[0] + currency.slice(1).toLowerCase();
  const priceDtoKey = `${priceType}${currencySuffix}`;
  return {
    [priceDtoKey]: {
      ...filter
    }
  };
}
function mapSurfaceFilter(filter, name, measurementSystem) {
  const unitSuffix = measurementSystem === MeasurementSystem.metric ? "Sqmt" : "Sqft";
  const surfaceDtoKey = `${name}${unitSuffix}`;
  return {
    [surfaceDtoKey]: {
      ...filter
    }
  };
}
function mapFilters(filters, options) {
  const { filters: refinedFilters, options: refinedOptions } = refineFiltersAndOptions(filters, options);
  const { price, plotSurface, livingSurface, totalSurface, ...restFilters } = refinedFilters;
  for (const key in restFilters) {
    if (isKey(restFilters, key) && restFilters[key] === false) {
      delete restFilters[key];
    }
  }
  return {
    ...restFilters,
    // TODO double check with BE team
    // TODO remove property kind from filters
    propertyKind: refinedFilters.propertyType?.[0] === PropertyType.group ? PropertyKind.group : PropertyKind.object,
    ...price ? Object.fromEntries(
      refinedFilters.propertyMarketingType.flatMap(
        (mt) => Object.entries(
          mapPriceFilter(
            price,
            mt,
            refinedOptions.currency ?? safePanic("Currency is required when price filter is present", Currency.EUR)
          )
        )
      )
    ) : {},
    ...plotSurface && mapSurfaceFilter(
      plotSurface,
      "plotSurface",
      refinedOptions.measurementSystem ?? safePanic("Measurement system is required when surface filter is present", MeasurementSystem.metric)
    ),
    ...livingSurface && mapSurfaceFilter(
      livingSurface,
      "livingSurface",
      refinedOptions.measurementSystem ?? safePanic("Measurement system is required when surface filter is present", MeasurementSystem.metric)
    ),
    ...totalSurface && mapSurfaceFilter(
      totalSurface,
      "totalSurface",
      refinedOptions.measurementSystem ?? safePanic("Measurement system is required when surface filter is present", MeasurementSystem.metric)
    )
  };
}
function getSimilarPropertiesPriceRange(property) {
  if (!property.prices) return safePanic("Property does not have a prices field", {});
  const [, priceEntry] = Object.entries(property.prices).find(([currency]) => currency === property.baseCurrency) ?? [];
  if (!priceEntry) return safePanic("Property prices field is empty", {});
  const priceValue = property.propertyMarketingType === PropertyMarketingType.rent ? priceEntry.rentTotal : priceEntry.salesPrice;
  if (!priceValue) return safePanic("Property does not have a rentTotal or salesPrice value", {});
  return { min: priceValue * 0.8, max: priceValue * 1.2 };
}
var cleanEmptyObjects = (o) => {
  if (!isRecord(o)) return o;
  return Object.fromEntries(
    Object.entries(o).map(([k, v]) => [k, isRecord(v) && isEmpty(v) ? void 0 : cleanEmptyObjects(v)])
  );
};
var deleteEmptyObjects = (o) => {
  if (!isRecord(o)) return o;
  for (const key of Object.keys(o)) {
    const value = o[key];
    if (value === void 0 || isRecord(value) && isEmpty(value)) {
      delete o[key];
    }
  }
  return o;
};
function mergeState(state, refinement) {
  const _refinement = cleanEmptyObjects(refinement);
  const mergedObject = mergeWith({}, state, _refinement, (targetValue, srcValue, key, target) => {
    if (Array.isArray(srcValue)) {
      return srcValue;
    }
    if (targetValue !== srcValue && srcValue === void 0) {
      target[key] = srcValue;
    }
  });
  return omitBy(mergedObject, (value) => value === void 0 || value === false);
}
function refineFiltersAndOptions(filters, options) {
  const refinedFilters = {
    ...DEFAULT_FILTERS,
    ...filters
  };
  const refinedOptions = {
    ...DEFAULT_OPTIONS,
    ...options
  };
  validateWithDefaults(refinedFilters, DEFAULT_FILTERS);
  validateWithDefaults(refinedOptions, DEFAULT_OPTIONS);
  const hasSurface = Boolean(refinedFilters.livingSurface || refinedFilters.plotSurface || refinedFilters.totalSurface);
  if (refinedFilters.price && !refinedOptions.currency) {
    console.warn("Price filter is removed because currency is not provided");
    delete refinedFilters.price;
  }
  if (hasSurface && !refinedOptions.measurementSystem) {
    console.warn("Surface filters are removed because measurement system is not provided");
    delete refinedFilters.livingSurface;
    delete refinedFilters.plotSurface;
    delete refinedFilters.totalSurface;
  }
  return {
    filters: refinedFilters,
    options: refinedOptions
  };
}
function validateWithDefaults(data, defaults) {
  for (const key of Object.keys(defaults)) {
    if (!isKey(data, key)) continue;
    const defaultValue = defaults[key];
    const value = data[key];
    if (isEmpty(value) || Array.isArray(value) && value.some((item) => isEmpty(item.trim()))) {
      data[key] = defaultValue;
    }
  }
}

// src/utils/strings.ts
var decodeHTML = (str) => {
  const htmlEntities = {
    "&#34;": '"',
    "&amp;": "&",
    "&#39;": "'",
    "&gt;": ">",
    "&lt;": "<"
  };
  const entityRegex = new RegExp(`(${Object.keys(htmlEntities).join("|")})`, "g");
  return str.replaceAll(entityRegex, (entity) => htmlEntities[entity]);
};
var formatShopPhoneNumber = (num) => num.replaceAll("-", " ");
var formatLocation = ({ subLocality, city, region, country }) => {
  return [subLocality, .../* @__PURE__ */ new Set([city, region]), country].filter(Boolean).join(", ");
};
var createSessionToken = () => {
  return Math.random().toString(36).slice(2);
};
var shortenMasterDataId = async (uuid) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(uuid);
  const hash = await crypto.subtle.digest("SHA-1", data);
  return [...new Uint8Array(hash)].map((byte) => byte.toString(16).padStart(2, "0")).join("").slice(0, 8);
};
var shortenMasterDataIds = async (uuids) => await Promise.all(uuids.map(async (shopId) => isUUID(shopId) ? await shortenMasterDataId(shopId) : shopId));
var hasDisplayIdPrefix = (id) => {
  const regex = new RegExp(`^(${displayIdPrefixes.join("|")})`);
  return regex.test(id.toUpperCase());
};
var CurrencySymbolMap = {
  EUR: "\u20AC",
  USD: "$",
  CAD: "CA$",
  GBP: "\xA3",
  CHF: "CHF",
  AED: "AED",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CZK: "CZK",
  DKK: "DKK",
  HKD: "HKD",
  HUF: "HUF",
  OMR: "OMR",
  MUR: "MUR",
  QAR: "QAR",
  RUB: "RUB",
  SEK: "SEK",
  TRY: "TRY",
  ZAR: "ZAR",
  BSD: "BSD",
  BZD: "BZD",
  CRC: "CRC",
  KYD: "KYD",
  MXN: "MXN",
  PAB: "PAB"
};
var UnitSymbolMap = {
  [MeasurementSystem.metric]: "m\xB2",
  [MeasurementSystem.imperial]: "sq ft"
};
var SurfaceUnitMap = {
  [MeasurementSystem.metric]: "sqmt",
  [MeasurementSystem.imperial]: "sqft"
};
var isUUID = (uuid) => {
  return /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i.test(uuid);
};

// src/utils/searchParams.ts
import qs from "query-string";

// src/utils/validation.ts
var validFilters = {
  price: void 0,
  livingSurface: void 0,
  plotSurface: void 0,
  totalSurface: void 0,
  agentId: void 0,
  bathrooms: void 0,
  bedrooms: void 0,
  boundingBox: void 0,
  businessArea: void 0,
  constructionYear: void 0,
  countryCode: void 0,
  excludedPropertyIds: void 0,
  fallbackSearch: void 0,
  hasAirConditioning: void 0,
  hasBalcony: void 0,
  hasBasement: void 0,
  hasBuiltInKitchen: void 0,
  hasElevator: void 0,
  hasGarage: void 0,
  hasGarden: void 0,
  hasPatio: void 0,
  hasSeaOrLakeView: void 0,
  hasSecuritySystem: void 0,
  hasSwimmingPool: void 0,
  hasTennisCourt: void 0,
  hasTerrace: void 0,
  hasVtour: void 0,
  hasWaterfront: void 0,
  propertyMarketingType: void 0,
  masterDataLiPaIds: void 0,
  masterDataShopIds: void 0,
  parentId: void 0,
  placeId: void 0,
  postalCode: void 0,
  priceOnRequest: void 0,
  propertySubType: void 0,
  propertyType: void 0,
  rooms: void 0,
  searchRadius: void 0,
  shopIds: void 0,
  since: void 0,
  sortByDistanceToPoint: void 0,
  sortingOptions: void 0,
  statuses: void 0
};
var validOptions = {
  currency: void 0,
  language: void 0,
  measurementSystem: void 0,
  page: void 0,
  pageSize: void 0
};
var DEPRECATED_FIELDS = [
  { deprecatedKey: "marketingType", newKey: "propertyMarketingType" }
  // NS-6759
];
var replaceDeprecatedFilters = (params) => {
  const refinedFilters = { ...params };
  for (const { deprecatedKey, newKey } of DEPRECATED_FIELDS) {
    if (deprecatedKey in refinedFilters && newKey in refinedFilters) {
      throw new Error(`Both ${deprecatedKey} and ${newKey} are present in the filters. Please use only ${newKey}.`);
    }
    if (deprecatedKey in refinedFilters) {
      refinedFilters[newKey] = refinedFilters[deprecatedKey];
      delete refinedFilters[deprecatedKey];
    }
  }
  return refinedFilters;
};
function parseParams(params) {
  const refinedFilters = replaceDeprecatedFilters(params);
  const filters = Object.fromEntries(Object.entries(refinedFilters).filter(([key]) => key in validFilters));
  const options = Object.fromEntries(Object.entries(refinedFilters).filter(([key]) => key in validOptions));
  return { filters, options };
}
function validateLanguage(language) {
  return typeof language === "string" && Object.values(Language).includes(language);
}
function validateCountryCode(countryCode) {
  return typeof countryCode === "string" && Object.values(CountryCode).includes(countryCode);
}

// src/utils/searchParams.ts
var extractParamsFromUrl = (url) => {
  const _url = typeof url === "string" ? url : url.toString();
  if (_url.startsWith("?")) return { query: _url.slice(1) };
  const pathRx = /\/(?:(?<countryCode>[A-Za-z]{2})\/)?(?<language>[A-Za-z]{2})(?:\/[^?]*)?(?:\?(?<query>.*))?$/;
  const match = _url.match(pathRx);
  if (!match?.groups) {
    console.error(
      `Invalid url. Should be '.../<country>/<language>/...?<query>', for example: 'http://example.com/de/en/propertysearch?currency=EUR' but got: '${url}'`
    );
    return {};
  }
  const countryCode = match.groups.countryCode?.toUpperCase();
  const language = match.groups.language?.toLowerCase();
  return {
    countryCode: validateCountryCode(countryCode) ? countryCode : void 0,
    language: validateLanguage(language) ? language : safePanic(`Invalid language: ${language}. Falling back to Language.en`, Language.en),
    query: match.groups.query
  };
};
function flattenNestedValues(obj, prefix = "") {
  let result = {};
  for (const [key, value] of Object.entries(obj)) {
    if (isRecord(value)) {
      result = {
        ...result,
        ...flattenNestedValues(value, `${prefix}${key}.`)
      };
    } else {
      result[`${prefix}${key}`] = value;
    }
  }
  return result;
}
function expandNestedValues(obj) {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    const keys = key.split(".");
    if (keys.some((k) => k === "__proto__" || k === "constructor" || k === "prototype")) {
      continue;
    }
    if (keys.length > 1) {
      const lastKey = keys.pop();
      let nestedObj = result;
      for (const k of keys) {
        nestedObj[k] = nestedObj[k] || {};
        nestedObj = nestedObj[k];
      }
      nestedObj[lastKey] = value;
    } else {
      result[key] = value;
    }
  }
  return result;
}
var stringifySearchParams = (params, currentParams) => {
  const searchParams = currentParams instanceof URLSearchParams ? currentParams : new URLSearchParams(currentParams);
  const _currentParams = searchParams.toString();
  const nestedValues = flattenNestedValues(params);
  const newParams = qs.stringify(nestedValues, {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: "bracket-separator"
  });
  if (!_currentParams && !newParams) return "";
  return `?${newParams}${_currentParams ? `&${_currentParams}` : ""}`;
};
var getUrlWithParams = (url, params, preserveExistingParams = false) => {
  const _url = url instanceof URL ? url : new URL(url);
  _url.search = stringifySearchParams(params, preserveExistingParams ? _url.search : "");
  return _url.href;
};
function parseSearchParams(url, addFallbacksForRequiredFields) {
  const { language, query } = extractParamsFromUrl(url);
  const parsedQuery = qs.parse(query ?? "", {
    parseBooleans: true,
    parseNumbers: true,
    arrayFormat: "bracket-separator",
    types: {
      masterDataShopIds: "string[]",
      masterDataLiPaIds: "string[]",
      shopIds: "string[]"
    }
  });
  const expandedParams = expandNestedValues(parsedQuery);
  let { filters, options } = parseParams({
    ...expandedParams,
    ...language && { language }
  });
  if (addFallbacksForRequiredFields) {
    const { filters: refinedFilters, options: refinedOptions } = refineFiltersAndOptions(filters, options);
    options = refinedOptions;
    filters = refinedFilters;
  }
  return { options, filters };
}

// src/utils/urls.ts
function ensureHttpsPrefix(url) {
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url;
  }
  return url.replace(/^http:\/\//i, "https://");
}
var videoUrls = [
  "service-dev.engelvoelkers.com",
  "service.engelvoelkers.com",
  "video.engelvoelkers.com",
  "oohembed.com",
  "vimeo.",
  "oohembed.com",
  "youtube.",
  "youtu.be",
  "youtube.com"
];
function isVideoUrl(url) {
  return videoUrls.some((videoUrl) => url.includes(videoUrl));
}
function getVideoEmbedUrl(url) {
  const youtubeRegex = /(?:(?:youtube\.com)|(?:youtu\.be))\/.*([\w-]{11}).*/;
  const vimeoRegex = /^((http|https):\/\/)?(www.)?vimeo/;
  const youtubeVideoId = url.match(youtubeRegex)?.[1];
  const isVimeoUrl = url.match(vimeoRegex);
  if (youtubeVideoId) {
    return `https://www.youtube.com/embed/${youtubeVideoId}`;
  }
  if (isVimeoUrl) {
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match && match[1]) {
      const videoId = match[1];
      return `https://player.vimeo.com/video/${videoId}`;
    }
  }
  url = ensureHttpsPrefix(url);
  return url;
}
function getExposePath(isDevelopmentProject, propertyId) {
  return `/${isDevelopmentProject ? "projects" : "exposes"}/${propertyId}`;
}
function prependLocaleToPath(path, language, countryCode) {
  const prefix = [countryCode?.toLowerCase(), language].filter(Boolean).join("/");
  if (path.startsWith("/") || path.startsWith("?")) {
    return `/${prefix}${path}`;
  }
  return `/${prefix}/${path}`;
}
var replaceLocaleInPath = (path, language, countryCode) => {
  const regex = /^\/(?:(?<countryCode>[A-Za-z]{2})(?:\/|$))?(?<language>[A-Za-z]{2})(?:\/|$)/;
  return regex.test(path) ? path.replace(regex, (_, ...args) => {
    const { countryCode: oldCountryCode } = args.at(-1);
    return prependLocaleToPath("", language, countryCode || oldCountryCode);
  }) : prependLocaleToPath(path, language, countryCode);
};
var COUNTRY_LANGUAGE_MAP = /* @__PURE__ */ new Map([
  ["AD", { languages: ["es", "en", "ca"], defaultLanguage: "en" }],
  ["AE", { languages: ["en", "de"], defaultLanguage: "en" }],
  ["AT", { languages: ["de", "en"], defaultLanguage: "en" }],
  ["BE", { languages: ["nl", "en", "fr"], defaultLanguage: "en" }],
  ["BS", { languages: ["en"], defaultLanguage: "en" }],
  ["BZ", { languages: ["es", "en"], defaultLanguage: "en" }],
  ["CA", { languages: ["en", "fr"], defaultLanguage: "en" }],
  ["CH", { languages: ["de", "en", "fr", "it"], defaultLanguage: "en" }],
  ["CL", { languages: ["es", "en"], defaultLanguage: "en" }],
  ["CO", { languages: ["es", "en"], defaultLanguage: "en" }],
  ["CR", { languages: ["es", "en"], defaultLanguage: "en" }],
  ["CZ", { languages: ["cs", "en"], defaultLanguage: "en" }],
  ["DE", { languages: ["de", "en"], defaultLanguage: "en" }],
  ["DK", { languages: ["da", "en"], defaultLanguage: "en" }],
  ["ES", { languages: ["es", "ca", "de", "en"], defaultLanguage: "en" }],
  ["FR", { languages: ["fr", "en"], defaultLanguage: "en" }],
  ["GB", { languages: ["en"], defaultLanguage: "en" }],
  ["GR", { languages: ["el", "de", "en"], defaultLanguage: "en" }],
  ["HK", { languages: ["en"], defaultLanguage: "en" }],
  ["HR", { languages: ["en", "de"], defaultLanguage: "en" }],
  ["HU", { languages: ["hu", "en"], defaultLanguage: "en" }],
  ["IE", { languages: ["en"], defaultLanguage: "en" }],
  ["IT", { languages: ["it", "en", "de"], defaultLanguage: "en" }],
  ["KY", { languages: ["en"], defaultLanguage: "en" }],
  ["LI", { languages: ["de", "en"], defaultLanguage: "en" }],
  ["LU", { languages: ["fr", "de", "en"], defaultLanguage: "en" }],
  ["MF", { languages: ["en"], defaultLanguage: "en" }],
  ["MU", { languages: ["fr", "de", "en"], defaultLanguage: "en" }],
  ["MX", { languages: ["es", "en"], defaultLanguage: "en" }],
  ["NL", { languages: ["nl", "en"], defaultLanguage: "en" }],
  ["PA", { languages: ["es", "en", "fr"], defaultLanguage: "en" }],
  ["PT", { languages: ["pt", "en"], defaultLanguage: "en" }],
  ["TC", { languages: ["en"], defaultLanguage: "en" }],
  ["UY", { languages: ["es", "en"], defaultLanguage: "en" }],
  ["US", { languages: ["en"], defaultLanguage: "en" }],
  ["VG", { languages: ["en"], defaultLanguage: "en" }],
  ["ZA", { languages: ["en"], defaultLanguage: "en" }]
]);
var validateLanguageForCountry = (path) => {
  const { language, countryCode } = extractParamsFromUrl(path);
  const fallbackPath = replaceLocaleInPath(path, Language.en, CountryCode.DE);
  if (!language || !countryCode) {
    console.error("Language or country code missing in search path");
    return fallbackPath;
  }
  if (COUNTRY_LANGUAGE_MAP.has(countryCode) && COUNTRY_LANGUAGE_MAP.get(countryCode)?.languages.includes(language)) {
    return replaceLocaleInPath(path, language, countryCode);
  } else if (COUNTRY_LANGUAGE_MAP.has(countryCode)) {
    return replaceLocaleInPath(path, COUNTRY_LANGUAGE_MAP.get(countryCode).defaultLanguage, countryCode);
  } else {
    console.error("Invalid countryCode");
    return fallbackPath;
  }
};

export {
  isRecord,
  isRangeFilter,
  isKey,
  getEntries,
  DEFAULT_FILTERS,
  mapFilters,
  getSimilarPropertiesPriceRange,
  deleteEmptyObjects,
  mergeState,
  refineFiltersAndOptions,
  decodeHTML,
  formatShopPhoneNumber,
  formatLocation,
  createSessionToken,
  shortenMasterDataId,
  shortenMasterDataIds,
  hasDisplayIdPrefix,
  CurrencySymbolMap,
  UnitSymbolMap,
  SurfaceUnitMap,
  isUUID,
  extractParamsFromUrl,
  flattenNestedValues,
  expandNestedValues,
  stringifySearchParams,
  getUrlWithParams,
  parseSearchParams,
  ensureHttpsPrefix,
  isVideoUrl,
  getVideoEmbedUrl,
  getExposePath,
  prependLocaleToPath,
  replaceLocaleInPath,
  COUNTRY_LANGUAGE_MAP,
  validateLanguageForCountry
};

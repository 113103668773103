import {
  getProperties
} from "./chunk-KBIRBND2.js";

// src/getPropertiesByAgentId/getPropertiesByAgentId.ts
async function getPropertiesByAgentId(agentId, filters, options, apiConfig) {
  if (agentId === "") {
    console.warn("agentId is an empty string.");
  }
  return await getProperties(
    {
      ...filters,
      agentId
    },
    options,
    apiConfig
  );
}

export {
  getPropertiesByAgentId
};

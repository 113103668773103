import { type ButtonProps, Button, styled } from '@mui/material';
import React, { forwardRef } from 'react';

import { StyledRoundButton } from './Button.styles';

export const OutlinedButton = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }>): React.ReactElement => {
  return <Button variant="outlined" {...props} />;
};

export const PrimaryButton = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }>): React.ReactElement => {
  return <Button variant="contained" color="primary" {...props} />;
};

const RoundButton = (props: ButtonProps): React.ReactElement => {
  return <StyledRoundButton variant="outlined" {...props} />;
};

export const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref): React.ReactElement => {
  return <Button ref={ref} variant="contained" color="secondary" {...props} />;
});

export const TextButton = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref): React.ReactElement => {
  return <Button ref={ref} variant="text" {...props} />;
});

export const SelectableRoundButton = styled(RoundButton)<{ selected: boolean }>(({ theme, selected }) => ({
  padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  margin: theme.spacing(0.75, 1, 0.75, 0),
  color: selected ? theme.palette.primary.contrastText : '',
  backgroundColor: selected ? theme.palette.neutral.dark : '',
}));

OutlinedButton.displayName = 'OutlinedButton';
SecondaryButton.displayName = 'SecondaryButton';
TextButton.displayName = 'TextButton';

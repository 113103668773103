import { type PropsWithChildren, type ReactNode } from 'react';

import { SkeletonContainer } from './Skeleton.styles';

type SkeletonProps = PropsWithChildren<{
  loading: boolean;
  className?: string;
}>;

export const Skeleton = ({ loading, className, children }: SkeletonProps): ReactNode => {
  return loading ? <SkeletonContainer className={className}>{children}</SkeletonContainer> : <>{children}</>;
};

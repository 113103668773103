import { type ReactNode } from 'react';
import { css, styled } from 'styled-components';

export const copySizes = {
  xl: css`
    font-size: var(--font-size-dynamic-xs);
    line-height: var(--line-height-static-xl);
  `,
  l: css`
    font-size: var(--font-size-static-m);
    line-height: var(--line-height-static-l);
  `,
  m: css`
    font-size: var(--font-size-static-s);
    line-height: var(--line-height-static-m);
  `,
  s: css`
    font-size: var(--font-size-static-xs);
    line-height: var(--line-height-static-s);
  `,
} as const;

type CopySize = keyof typeof copySizes;

export const copyBaseStyles = css`
  margin: 0;
  color: currentColor;
`;

type CopyTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'div' | 'span' | 'address' | 'li' | 'cite' | 'dt' | 'dd' | 'label';

interface StyledCopyProps {
  $bold?: boolean;
  $italic?: boolean;
  $size: CopySize;
  $as: CopyTagName;
}

const StyledCopy = styled.p<StyledCopyProps>`
  ${copyBaseStyles}

  ${({ $bold }) =>
    $bold
      ? css`
          font-weight: var(--font-weight-heavy);
        `
      : css`
          font-weight: var(--font-weight-regular);
        `}

    ${({ $italic }) =>
    $italic
      ? css`
          font-style: italic;
        `
      : css`
          font-style: normal;
        `}

    ${({ $size }) => copySizes[$size]}
`;

export interface ICopyProps {
  size?: CopySize;
  className?: string;
  text: ReactNode;
  as?: CopyTagName;
  bold?: boolean;
  italic?: boolean;
  id?: string;
  'data-test-id'?: string;
}

export const Copy = ({ className, size = 'l', as = 'p', text, bold, italic, id, 'data-test-id': dataTestId }: ICopyProps) => (
  <StyledCopy className={className} $size={size} $as={as} $bold={bold} $italic={italic} id={id} data-test-id={dataTestId}>
    {text}
  </StyledCopy>
);

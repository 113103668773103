import {
  type BusinessDivisionType,
  getDivisionContext,
  getPageContext,
  getPropertyFilterContext,
  getShopContext,
  getSortContext,
} from '@ev/snowplow-library';
import { type GetPropertiesOptions, type PlaceLocation, type PropertiesResponse } from '@pkgs/api';
import { type SearchGeoPointResponse } from '@pkgs/api';
import { type SearchModuleFilters, SearchModule } from '@pkgs/components';
import { getSortValues } from '@pkgs/components/utils/stringUtils';
import { mapSearchStateToFilterContext } from '@pkgs/components/utils/tracking';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { Head, IndexFollowEnum, metaDescriptionsIndex } from '@/components/common/Head/Head';
import { ImageComponent } from '@/components/common/ImageComponent/ImageComponent';
import { Layout } from '@/components/common/Layout/Layout';
import { DEFAULT_MAP_BOUNDING_BOX } from '@/consts/map';
import { SEARCH_API_CONFIG } from '@/consts/search-api';
import { useGAEvent, useGAEventOnce, useGTMTackingIdsEvents } from '@/hooks/useGAEvent';
import { useGTMDataLayer } from '@/hooks/useGTMDataLayer';
import { useMapFeature } from '@/hooks/useMapFeature';
import { useSettings } from '@/hooks/useSettings';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import { useWatchlist } from '@/hooks/useWatchlist';
import { useGTMTracking } from '@/hooks/usGTMTracking';
import { type AppPageProps } from '@/pages/_app';
import { PageTypeEnum } from '@/types/tracking';

import { StyledContainer, StyledPageContainer } from './styles';

export type PropertySearchPageProps = {
  initialFilters: SearchModuleFilters;
  initialResults?: PropertiesResponse;
  initialGeoResults?: SearchGeoPointResponse;
  initialPlaceDetails?: PlaceLocation;
  initialOptions: GetPropertiesOptions;
  hasLicencePartnerGAAccount: boolean;
  shopNames: string[];
  isLipaPortfolioPage: boolean;
  isMapEnabled: boolean;
};

export const PropertySearchPage = ({
  isSnowplowTrackerInit,
  hasLicencePartnerGAAccount,
  spearHeadNavigation,
  pageLanguages,
  initialResults,
  initialFilters,
  initialOptions,
  initialPlaceDetails,
  shopNames,
  isLipaPortfolioPage,
  initialGeoResults,
  isMapEnabled: isMapEnabledProp,
}: PropertySearchPageProps & AppPageProps) => {
  const { currency, measurementSystem, countryCode, language } = useSettings();
  const { toggleWatchlistItem, watchlist } = useWatchlist();
  const [isMapView, setIsMapView] = useState(false);
  const { t: tCommon } = useTranslation('common');
  const { t: tSearchModule } = useTranslation('search_module');
  useMapFeature();

  useGTMTracking();

  useTrackPageView(isSnowplowTrackerInit, [
    getPageContext({
      isHQPage: true,
      type: isLipaPortfolioPage ? PageTypeEnum.LIPA_SEARCH_RESULT : PageTypeEnum.SEARCH_RESULT,
    }),
    getPropertyFilterContext(mapSearchStateToFilterContext(initialFilters, currency, measurementSystem)),
    getSortContext({ ...getSortValues(initialFilters.sortingOptions?.[0]) }),
    getDivisionContext({
      name: initialFilters.businessArea[0] as BusinessDivisionType,
    }),
    getShopContext({ id: initialFilters.shopIds?.[0] ?? '' }),
  ]);

  useGTMTackingIdsEvents();
  useGAEventOnce('searchResult');
  useGAEvent('searchResultLicensePartner', hasLicencePartnerGAAccount);
  useGTMDataLayer('type', 'SEARCH_RESULTS_NEW');

  return (
    <StyledPageContainer $isHeaderFixed={isMapView} $isLegacyHeader={!spearHeadNavigation?.headerData}>
      <Head
        title={tCommon('search.head.index.title')}
        description={tCommon('search.head.index.description', metaDescriptionsIndex(tCommon, initialFilters, initialPlaceDetails?.name))}
        index={IndexFollowEnum.NOINDEXFOLLOW}
      />
      <Layout
        spearHeadNavigation={spearHeadNavigation}
        pageLanguages={pageLanguages}
        withContactButton
        filters={initialFilters}
        stickyHeader={false}
        isPropertySearchPage
      >
        <StyledContainer>
          <SearchModule
            countryCode={countryCode}
            initialFilters={initialFilters}
            initialOptions={initialOptions}
            initialPlaceDetails={initialPlaceDetails}
            initialResults={initialResults}
            initialGeoResults={initialGeoResults}
            currency={currency}
            measurementSystem={measurementSystem}
            language={language}
            apiConfig={SEARCH_API_CONFIG}
            translationResolver={tSearchModule as () => string}
            imageComponent={ImageComponent}
            linkComponent={(props) => <Link {...props} />}
            watchlist={watchlist}
            events={{
              onWatchlistClick: (property) => toggleWatchlistItem(property, PageTypeEnum.SEARCH_RESULT, false),
              onIsMapViewChange: setIsMapView,
            }}
            searchAlertBaseUrl={(process.env.NEXT_PUBLIC_VERCEL_DOMAIN ?? '') + '/api/'}
            tracking={{
              snowplowApplicationId: 'new-search',
              isSnowplowInitialized: isSnowplowTrackerInit,
            }}
            shopNames={shopNames}
            isMapEnabled={isMapEnabledProp}
            mapTilerApiKey={process.env.NEXT_PUBLIC_MAP_TILER_API_KEY}
            friendlyCaptchaSiteKey={process.env.NEXT_PUBLIC_FRIENDLY_CAPTCHA_SITE_KEY!}
            vercelProtectionBypassToken={process.env.NEXT_PUBLIC_VERCEL_PROTECTION_BYPASS_TOKEN}
            mapBoundingBoxFallback={DEFAULT_MAP_BOUNDING_BOX}
            webpackPublicRelativePath="/_next/"
            webpackPublicAbsolutePath={`${process.env.NEXT_PUBLIC_ASSET_PREFIX}/_next/`}
            isFilterBarSticky
          />
        </StyledContainer>
      </Layout>
    </StyledPageContainer>
  );
};

import { StyledTooltip } from '@pkgs/components/components/SearchAlertCtaCard/SearchAlertCtaCard.styles';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import React, { type ReactNode, useRef } from 'react';
import { type AriaTooltipProps, useTooltip } from 'react-aria';
import { type TooltipTriggerState } from 'react-stately';

type SearchAlertTooltipProps = {
  children: ReactNode;
  state: TooltipTriggerState;
};
export const SearchAlertTooltip: React.FC<SearchAlertTooltipProps> = ({ children, state }) => {
  const { noLocationOrShopFilter } = useSearch();

  const tooltipRef = useRef(null);

  return (
    <div
      style={{ position: 'relative' }}
      ref={tooltipRef}
      onTouchStart={noLocationOrShopFilter ? () => state.open(true) : () => {}}
      onMouseEnter={noLocationOrShopFilter ? () => state.open(true) : () => {}}
      onMouseLeave={noLocationOrShopFilter ? () => state.close(true) : () => {}}
    >
      {children}
    </div>
  );
};

export const Tooltip = ({ props, state, isCentered }: { props: AriaTooltipProps; state: TooltipTriggerState; isCentered: boolean }) => {
  const { t } = useAppContext();
  const { tooltipProps } = useTooltip(props, state);
  return (
    <StyledTooltip {...tooltipProps} $isCentered={isCentered}>
      <span>{t('search.searchAlert.ctaCard.button.disabled.message')}</span>
    </StyledTooltip>
  );
};

import ArrowLeftOutlined from '@pkgs/components/assets/icons/small/Left_Arrow_Outlined.svg';
import ArrowRightOutlined from '@pkgs/components/assets/icons/small/Right_Arrow_Outlined.svg';
import { type UseCarouselActions } from '@pkgs/components/hooks/useCarouselActions';
import React, { forwardRef, type PropsWithChildren } from 'react';

import {
  CarouselContainerStyled as DefaultCarouselContainer,
  LeftCarouselButtonStyled as DefaultLeftCarouselButton,
  RightCarouselButtonStyled as DefaultRightCarouselButton,
  SliderContainerStyled as DefaultSliderContainer,
  SliderWrapperStyled as DefaultSliderWrapper,
} from './Carousel.styled';
import { CarouselThumb as DefaultCarouselThumb, type CarouselThumbProps } from './CarouselThumb';

export type CarouselButtonProps = {
  onClick: (e: MouseEvent) => void;
  icon?: JSX.Element;
};

export type CarouselProps = {
  children: React.ReactNode;
  carouselActions: UseCarouselActions;
  carouselThumbProps?: CarouselThumbProps;
  carouselContainer?: React.ComponentType<PropsWithChildren>;
  leftCarouselButton?: React.ComponentType<CarouselButtonProps>;
  rightCarouselButton?: React.ComponentType<CarouselButtonProps>;
  sliderWrapper?: React.ComponentType<PropsWithChildren>;
  carouselThumb?: React.ComponentType<CarouselThumbProps>;
  dotButton?: React.ComponentType<CarouselButtonProps>;
  sliderContainer?: React.ComponentType<PropsWithChildren>;
};

export const Carousel = forwardRef<HTMLDivElement, CarouselProps>(
  (
    {
      children,
      carouselActions,
      carouselThumbProps,
      carouselContainer: CarouselContainer = DefaultCarouselContainer,
      leftCarouselButton: LeftCarouselButton = DefaultLeftCarouselButton,
      rightCarouselButton: RightCarouselButton = DefaultRightCarouselButton,
      sliderWrapper: SliderWrapper = DefaultSliderWrapper,
      carouselThumb: CarouselThumb = DefaultCarouselThumb,
      sliderContainer: SliderContainer = DefaultSliderContainer,
    },
    ref,
  ) => {
    const {
      selectedIndex,
      scrollSnaps,
      onDotButtonClick,
      onPreviousClick,
      onNextClick,
      emblaCarouselUtils: [emblaRef, emblaApi],
    } = carouselActions;

    return (
      <CarouselContainer ref={ref}>
        {scrollSnaps.length > 1 && (
          <>
            <LeftCarouselButton
              variant="transparent"
              label="Previous"
              onClick={(e) => {
                e.stopPropagation();
                onPreviousClick();
              }}
              icon={<ArrowLeftOutlined />}
            />
            <RightCarouselButton
              onClick={(e) => {
                e.stopPropagation();
                onNextClick();
              }}
              label="Next"
              icon={<ArrowRightOutlined />}
            />
          </>
        )}
        <SliderWrapper ref={emblaRef}>
          <SliderContainer>{children}</SliderContainer>
        </SliderWrapper>
        <CarouselThumb
          scrollSnaps={scrollSnaps}
          selectedIndex={selectedIndex}
          onDotButtonClick={onDotButtonClick}
          emblaApi={emblaApi}
          {...carouselThumbProps}
        />
      </CarouselContainer>
    );
  },
);

Carousel.displayName = 'Carousel';

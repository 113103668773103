import {
  getDefaultParams
} from "./chunk-LN3ZTW4I.js";
import {
  getListingById
} from "./chunk-PLW25FCF.js";
import {
  getErrorResult,
  refineProperty
} from "./chunk-52TBLELU.js";

// src/getPropertyByListingId/getPropertyByListingId.ts
async function getPropertyByListingId(listingId, options, apiConfig) {
  let property;
  let parentProject;
  let projectUnits;
  const params = getDefaultParams(options);
  try {
    const listingDto = await getListingById(listingId, params, apiConfig);
    property = refineProperty(listingDto, options.language);
    projectUnits = property.project?.units;
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
  if (options.includeParentProject && property.parentID) {
    try {
      const parentProjectDto = await getListingById(property.parentID, params, apiConfig);
      parentProject = refineProperty(parentProjectDto, options.language);
      projectUnits = parentProject.project?.units?.filter((unit) => unit.id !== property.id);
    } catch (error) {
      apiConfig.onError?.(error);
      console.error("Error fetching parent project:", error, "Returning only the child property data.");
    }
  }
  return {
    status: "success",
    data: {
      property,
      ...parentProject && { parentProject },
      ...projectUnits && {
        projectUnits: projectUnits.map((unit) => refineProperty(unit, options.language))
      }
    }
  };
}

export {
  getPropertyByListingId
};

import { css, styled } from 'styled-components';

import { Copy } from '../Copy/Copy';
import type { InputTextSizesProps } from './InputText';
export const InputTextSizes = {
  s: css`
    height: 40px;
    font-size: var(--font-size-static-m);
    line-height: var(--line-height-static-m);
  `,
  m: css`
    height: 48px;
    font-size: var(--font-size-static-m);
    line-height: var(--line-height-static-m);
  `,
  l: css`
    height: 64px;
    font-size: var(--font-size-dynamic-xs);
    line-height: var(--line-height-static-xl);
  `,
};

interface StyledInputTextProps {
  $disabled?: boolean;
  $startAdornment?: boolean;
  $endAdornment?: boolean;
  $size?: InputTextSizesProps;
  $error?: boolean;
}

const StyledBaseAdornment = css<StyledInputTextProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--distance-static-xxl);

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: var(--color-input-text-disabled-icon);
    `}
`;

export const StyledStartAdornment = styled.div<StyledInputTextProps>`
  ${StyledBaseAdornment};
  left: 0;
`;

export const StyledEndAdornment = styled.div<StyledInputTextProps>`
  ${StyledBaseAdornment};
  right: 0;
`;

export const StyledInputText = styled.input<StyledInputTextProps>`
  display: block;
  border: 1px solid var(${({ $error }) => ($error ? '--color-input-border-error' : '--color-input-border-idle')});
  padding: 0;
  width: 100%;
  caret-color: var(--color-highlight);
  color: var(--color-input-text);
  background-color: var(--color-light);
  border-radius: var(--border-radius-s);
  appearance: none;

  ${({ $size }) => $size && InputTextSizes[$size]}

  ${({ $startAdornment }) =>
    $startAdornment
      ? css`
          padding-left: var(--distance-static-xxl);
        `
      : css`
          padding-left: var(--distance-static-s);
        `}

  ${({ $endAdornment }) =>
    $endAdornment
      ? css`
          padding-right: var(--distance-static-xxl);
        `
      : css`
          padding-right: var(--distance-static-s);
        `}

  &:not(:disabled) {
    ::placeholder {
      color: var(--color-input-placeholder);
    }
  }

  &:disabled {
    background-color: var(--color-input-text-disabled);
    border-color: var(--color-input-text-disabled-border);
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      border-color: 1px solid var(${({ $error }) => ($error ? '--color-input-border-error' : '--color-input-border-hover')});
      box-shadow: var(--shadow-m);
    }
  }

  &:focus {
    outline: 2px solid var(--color-focus-dark);
    outline-offset: -1px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
  }
  /* override safari user styles */
  &[disabled] {
    opacity: 1;
    -webkit-text-fill-color: var(--color-input-text);
  }
`;

export const StyledRootInputText = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledCopy = styled(Copy)<StyledInputTextProps>`
  ${({ $error }) =>
    $error &&
    css`
      color: var(--color-input-text-error);
    `}
`;

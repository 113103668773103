import {
  getDefaultSearchListingsParams
} from "./chunk-LN3ZTW4I.js";
import {
  searchListings,
  searchListingsMinified
} from "./chunk-PLW25FCF.js";
import {
  getErrorResult,
  refineProperty
} from "./chunk-Q7UZCASA.js";
import {
  mapFilters
} from "./chunk-GDLBJXEV.js";

// src/getProperties/getProperties.ts
async function getProperties(filters, options, apiConfig, useCompactData = false) {
  try {
    const params = getDefaultSearchListingsParams(options);
    const { listing, listingTotal, widerArea } = useCompactData ? await searchListingsMinified(mapFilters(filters, options), params, apiConfig) : await searchListings(mapFilters(filters, options), params, apiConfig);
    const data = {
      hits: listing?.map((i) => refineProperty(i, options.language)) ?? [],
      totalHits: listingTotal ?? 0,
      totalPages: listingTotal ? Math.ceil(listingTotal / params.limit) : 0,
      widerAreaHits: widerArea?.map((i) => refineProperty(i, options.language)) ?? []
    };
    return {
      status: "success",
      data
    };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  getProperties
};

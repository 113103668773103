import { type EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { type CSSProperties, useEffect } from 'react';
import { VisuallyHidden } from 'react-aria';
import { type IStyledComponentBase, type Substitute } from 'styled-components/dist/types';

import {
  CarouselThumbContainerStyled,
  CarouselThumbSlideStyled,
  CarouselThumbStyled,
  CarouselThumbViewportStyled,
  DotButtonStyled,
} from './CarrouselThumb.styled';

type ThumbButtonProps = IStyledComponentBase<
  'web',
  Substitute<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, HTMLButtonElement> & {
    $active?: boolean;
    $activeColor?: string;
    $inActiveColor?: string;
  }
>;

export type CarouselThumbProps = {
  scrollSnaps?: number[];
  selectedIndex?: number;
  onDotButtonClick?: (index: number) => void;
  emblaApi?: EmblaCarouselType;
  maxThumbs?: number;
  className?: string;
  style?: CSSProperties;
  thumbButtonProps?: ThumbButtonProps;
};

export const CarouselThumb = ({
  scrollSnaps = [],
  selectedIndex,
  onDotButtonClick,
  emblaApi,
  maxThumbs = 5,
  ...props
}: CarouselThumbProps) => {
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  });
  useEffect(() => {
    if (!emblaApi) return;
    emblaThumbsApi?.scrollTo(emblaApi.selectedScrollSnap());
  }, [selectedIndex, emblaThumbsApi, emblaApi]);

  const { thumbButtonProps, ...thumbProps } = props;

  return (
    <CarouselThumbStyled {...thumbProps} $maxThumbs={maxThumbs}>
      <CarouselThumbViewportStyled ref={emblaThumbsRef}>
        <CarouselThumbContainerStyled $scrollSnaps={scrollSnaps.length} $maxThumbs={maxThumbs}>
          {scrollSnaps.length > 1 &&
            scrollSnaps.map((_, index) => (
              <CarouselThumbSlideStyled key={index}>
                <DotButtonStyled
                  onClick={(e) => {
                    e.stopPropagation();
                    onDotButtonClick?.(index);
                  }}
                  $active={index === selectedIndex}
                  {...thumbButtonProps}
                >
                  <VisuallyHidden>{`carousel index ${index} button`}</VisuallyHidden>
                </DotButtonStyled>
              </CarouselThumbSlideStyled>
            ))}
        </CarouselThumbContainerStyled>
      </CarouselThumbViewportStyled>
    </CarouselThumbStyled>
  );
};

import {
  type AddressComponent,
  type Image,
  Language,
  type ListingDtoProfiles,
  type Profile,
  PropertyKind,
  PropertyType,
} from '@generated/search-bff';
import {
  type ExposeLanguageProfile,
  type ExposePath,
  type ImageWithTitle,
  type Property,
  type PropertyLocation,
  type RawListing,
  type WithRequired,
} from '@pkgs/api/types';

import { getEntries } from './object';
import { safePanic } from './panic';
import { decodeHTML, formatShopPhoneNumber } from './strings';
import { ensureHttpsPrefix, getExposePath, getVideoEmbedUrl, prependLocaleToPath } from './urls';

export const DEFAULT_IMAGE = 'https://uploadcare.engelvoelkers.com/4a6e9b16-f2a8-4afc-ba26-a7d0cbcb1dbb/';

export function refineProperty(rawProperty: RawListing, userLanguage: Language): Property {
  const { profiles, agentMobile, shopPhoneNumber, shopWebURL, floorPlanImages, propertyImages, language, ...propertyData } = rawProperty;

  const images = (propertyImages && removeDuplicateImageObjects(propertyImages)) ?? getPropertyImages(rawProperty);

  const profileLanguage = getAvailableLanguage(rawProperty);

  const formattedLocation = getLocationBreadCrumb(rawProperty, userLanguage).filter(Boolean).join(', ');

  const paths = getExposePaths(rawProperty);
  const canonicalPath = paths.find((path) => path.language === profileLanguage)?.path;

  const imagesWithTitles = getImagesWithTitles(images, profileLanguage, rawProperty.useWaterMark);

  const floorPlanImagesWithTitles = floorPlanImages
    ? getImagesWithTitles(removeDuplicateImageObjects(floorPlanImages), profileLanguage, rawProperty.useWaterMark)
    : [];

  const vtourURL = rawProperty.vtourURL ? getVideoEmbedUrl(rawProperty.vtourURL) : '';

  const exposePath = getExposePath(
    propertyData.isDevelopmentProject || propertyData.objectType === PropertyType.group || propertyData.kind === PropertyKind.group,
    rawProperty.id,
  );

  const location = getPropertyLocation(rawProperty, userLanguage);

  return {
    profileLanguage,
    ...propertyData,
    language: language ?? [],
    ...(profiles && {
      profile: getProfileForLanguage(profiles, profileLanguage),
    }),
    ...(shopWebURL && { shopWebURL: ensureHttpsPrefix(shopWebURL) }),
    ...(shopPhoneNumber && {
      shopPhoneNumber: formatShopPhoneNumber(shopPhoneNumber),
    }),
    ...(agentMobile && {
      agentPhoneNumber: formatShopPhoneNumber(agentMobile),
    }),
    ...location,
    formattedLocation,
    paths,
    ...(canonicalPath && { canonicalPath }),
    images: imagesWithTitles,
    floorPlans: floorPlanImagesWithTitles,
    vtourURL,
    exposePath,
  };
}

export function removeDuplicateImageObjects(imageArray: Image[]): Image[] {
  const seen = new Set<string>();
  return imageArray.filter((item) => {
    if (!item.id) {
      return false;
    }
    const duplicate = seen.has(item.id);
    seen.add(item.id);
    return !duplicate;
  });
}

export function getPropertyImages(property: RawListing): Image[] {
  // ListingDto only has images field
  if (property.images) {
    return removeDuplicateImageObjects(property.images);
  } else if (property.uploadCareImageIds) {
    // SearchListingResponse only has uploadCareImageIds field
    const duplicatedImageIdsRemoved = [...new Set(property.uploadCareImageIds)];
    return duplicatedImageIdsRemoved.map((img, index) => {
      return {
        id: img,
        main: false,
        type: 'image',
        position: index,
      };
    });
  }
  return [];
}

export function getImagesWithTitles(images: Image[], availableLanguage?: Language, useWaterMark?: boolean): ImageWithTitle[] {
  const IMAGE_WATERMARK_ID = '131ecb57-f36f-4015-aee3-5851eb9bfa9d';
  return images
    .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
    .map((img) => ({
      title: availableLanguage && img.titles?.[availableLanguage] ? img.titles[availableLanguage] : '',
      url: `https://uploadcare.engelvoelkers.com/${img.id}/${useWaterMark ? `-/overlay/${IMAGE_WATERMARK_ID}/60px60p/50p,50p/60p/` : ''}`,
    }));
}

export function getPropertyLocation(property: RawListing, language: Language): PropertyLocation {
  let subLocality = (property.neighborhoodOverwrite || property.subLocality?.[language]) ?? '';

  // workaround for this town, as the licence partner does not want to show the sublocality on  new search
  if (property.city?.[Language.en]?.toLowerCase() === 'alaró') {
    subLocality = '';
  }

  const city = property.city?.[language] ?? '';
  const region = property.region?.[language] ?? '';
  const country = property.country?.[language] ?? '';

  return {
    subLocality,
    city,
    region,
    country,
  };
}

export function getAvailableLanguage(property: RawListing): Language {
  if (!property.profiles) {
    return safePanic('No profile available', Language.en);
  }
  return (Object.keys(property.profiles)[0] as Language | undefined) ?? safePanic('No available language found', Language.en);
}

export function getProfilesForLanguages(profiles: ListingDtoProfiles, languages: Language[]): ExposeLanguageProfile[] {
  const exposeLanguageProfiles: ExposeLanguageProfile[] = [];
  languages.map((l) => {
    if (!profiles[l]) return safePanic('Invalid available language', {});
    const profile = profiles[l];
    exposeLanguageProfiles.push({ language: l, profile: decodeProfileValues(profile) });
  });
  return exposeLanguageProfiles.length > 1 ? exposeLanguageProfiles : [];
}

function getProfileForLanguage(profiles: ListingDtoProfiles, availableLanguage?: Language): Profile {
  if (!availableLanguage || !profiles[availableLanguage]) return safePanic('Invalid available language', {});

  const profile = profiles[availableLanguage];

  const decodedProfile = decodeProfileValues(profile);
  if (decodedProfile.shopDataSecurityURL) {
    decodedProfile.shopDataSecurityURL = ensureHttpsPrefix(decodedProfile.shopDataSecurityURL);
  }
  return decodedProfile;
}

export function decodeProfileValues(profile: Profile): Profile {
  const decodedProfile: Partial<Profile> = {};
  for (const [key, value] of getEntries(profile)) {
    if (value) {
      decodedProfile[key as keyof Profile] = decodeHTML(value);
    }
  }
  return decodedProfile as Profile;
}

function getExposePaths(property: RawListing): ExposePath[] {
  if (!property.language || property.language.length === 0 || !property.id) {
    return [];
  }

  return property.language.map((language) => ({
    language,
    path: prependLocaleToPath(
      getExposePath(
        property.isDevelopmentProject || property.objectType === PropertyType.group || property.kind === PropertyKind.group,
        property.id,
      ),
      language,
      property.countryAlpha2,
    ),
  }));
}

export function getLocationBreadCrumb(property: RawListing, language: Language): string[] {
  const { neighborhoodOverwrite } = property;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const addressComponents = property.addressComponents ?? [];

  return addressComponents
    .filter((comp): comp is WithRequired<AddressComponent, 'text'> => Boolean(comp.text))
    .map(({ text }, index, arr) => {
      const isLastAdministrativeLevel = index === arr.length - 1;
      if (isLastAdministrativeLevel && neighborhoodOverwrite) return neighborhoodOverwrite;

      return text[language] ?? Object.values(text)[0] ?? '';
    })
    .reverse();
}

import { css, styled } from 'styled-components';

import { Alert } from '@/components/ui-core-components-clone/Alert';
import { InlineLink } from '@/components/ui-core-components-clone/InlineLink';
import { to } from '@/utils/mediaQueries';

export const StyledSnackbar = styled(Alert)<{
  fadeOut: boolean;
}>`
  position: fixed;
  bottom: var(--distance-static-l);
  border-radius: var(--distance-static-3xs);
  padding: var(--distance-static-xs) var(--distance-static-xs) var(--distance-static-xs) var(--distance-static-m);
  width: unset;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  box-shadow: var(--shadow-xs);
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;
  @media (${to.tabletLandscape}) {
    bottom: var(--distance-static-xxs);
  }
  @media (${to.tabletPortrait}) {
    min-width: calc(100% - var(--distance-static-xl));
  }

  ${({ fadeOut }) =>
    fadeOut &&
    css`
      animation: fadeIn 0.2s ease-in-out forwards reverse;
    `}

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, var(--distance-static-l));
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`;

export const StyledLink = styled(InlineLink)`
  margin-left: var(--distance-static-xs);
  align-self: center;
  cursor: pointer;
`;

import { CountryCode, Language } from '@generated/search-bff';

import { extractParamsFromUrl } from './searchParams';

export function ensureHttpsPrefix(url: string): string {
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url;
  }

  return url.replace(/^http:\/\//i, 'https://');
}

const videoUrls = [
  'service-dev.engelvoelkers.com',
  'service.engelvoelkers.com',
  'video.engelvoelkers.com',
  'oohembed.com',
  'vimeo.',
  'oohembed.com',
  'youtube.',
  'youtu.be',
  'youtube.com',
] as const;

export function isVideoUrl(url: string): boolean {
  return videoUrls.some((videoUrl) => url.includes(videoUrl));
}

export function getVideoEmbedUrl(url: string): string {
  const youtubeRegex = /(?:(?:youtube\.com)|(?:youtu\.be))\/.*([\w-]{11}).*/;
  const vimeoRegex = /^((http|https):\/\/)?(www.)?vimeo/;
  const youtubeVideoId = url.match(youtubeRegex)?.[1];
  const isVimeoUrl = url.match(vimeoRegex);

  if (youtubeVideoId) {
    return `https://www.youtube.com/embed/${youtubeVideoId}`;
  }

  if (isVimeoUrl) {
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match && match[1]) {
      const videoId = match[1];
      return `https://player.vimeo.com/video/${videoId}`;
    }
  }

  url = ensureHttpsPrefix(url);

  return url;
}

export function getExposePath(isDevelopmentProject: boolean, propertyId: string): string {
  return `/${isDevelopmentProject ? 'projects' : 'exposes'}/${propertyId}`;
}

export function prependLocaleToPath(path: string, language: Language, countryCode?: CountryCode) {
  const prefix = [countryCode?.toLowerCase(), language].filter(Boolean).join('/');

  if (path.startsWith('/') || path.startsWith('?')) {
    return `/${prefix}${path}`;
  }
  return `/${prefix}/${path}`;
}

export const replaceLocaleInPath = (path: string, language: Language, countryCode?: CountryCode) => {
  const regex = /^\/(?:(?<countryCode>[A-Za-z]{2})(?:\/|$))?(?<language>[A-Za-z]{2})(?:\/|$)/;
  return regex.test(path)
    ? path.replace(regex, (_, ...args) => {
        const { countryCode: oldCountryCode } = args.at(-1);
        return prependLocaleToPath('', language, countryCode || oldCountryCode);
      })
    : prependLocaleToPath(path, language, countryCode);
};

/**
 * Map that associates a countryCode with an object containing an array of available languages and a default language.
 * This needs to be in sync with the loadbalancer settings: https://git.engelvoelkers.com/ev/cloud-platform/lb-backend-services/-/blob/main/prod/url-maps/main.tf
 *
 * @typeParam CountryCode - The type of the country code.
 * @typeParam Language - The type of the language.
 */
export const COUNTRY_LANGUAGE_MAP = new Map<CountryCode, { languages: Language[]; defaultLanguage: Language }>([
  ['AD', { languages: ['es', 'en', 'ca'], defaultLanguage: 'en' }],
  ['AE', { languages: ['en', 'de'], defaultLanguage: 'en' }],
  ['AT', { languages: ['de', 'en'], defaultLanguage: 'en' }],
  ['BE', { languages: ['nl', 'en', 'fr'], defaultLanguage: 'en' }],
  ['BS', { languages: ['en'], defaultLanguage: 'en' }],
  ['BZ', { languages: ['es', 'en'], defaultLanguage: 'en' }],
  ['CA', { languages: ['en', 'fr'], defaultLanguage: 'en' }],
  ['CH', { languages: ['de', 'en', 'fr', 'it'], defaultLanguage: 'en' }],
  ['CL', { languages: ['es', 'en'], defaultLanguage: 'en' }],
  ['CO', { languages: ['es', 'en'], defaultLanguage: 'en' }],
  ['CR', { languages: ['es', 'en'], defaultLanguage: 'en' }],
  ['CZ', { languages: ['cs', 'en'], defaultLanguage: 'en' }],
  ['DE', { languages: ['de', 'en'], defaultLanguage: 'en' }],
  ['DK', { languages: ['da', 'en'], defaultLanguage: 'en' }],
  ['ES', { languages: ['es', 'ca', 'de', 'en'], defaultLanguage: 'en' }],
  ['FR', { languages: ['fr', 'en'], defaultLanguage: 'en' }],
  ['GB', { languages: ['en'], defaultLanguage: 'en' }],
  ['GR', { languages: ['el', 'de', 'en'], defaultLanguage: 'en' }],
  ['HK', { languages: ['en'], defaultLanguage: 'en' }],
  ['HR', { languages: ['en', 'de'], defaultLanguage: 'en' }],
  ['HU', { languages: ['hu', 'en'], defaultLanguage: 'en' }],
  ['IE', { languages: ['en'], defaultLanguage: 'en' }],
  ['IT', { languages: ['it', 'en', 'de'], defaultLanguage: 'en' }],
  ['KY', { languages: ['en'], defaultLanguage: 'en' }],
  ['LI', { languages: ['de', 'en'], defaultLanguage: 'en' }],
  ['LU', { languages: ['fr', 'de', 'en'], defaultLanguage: 'en' }],
  ['MF', { languages: ['en'], defaultLanguage: 'en' }],
  ['MU', { languages: ['fr', 'de', 'en'], defaultLanguage: 'en' }],
  ['MX', { languages: ['es', 'en'], defaultLanguage: 'en' }],
  ['NL', { languages: ['nl', 'en'], defaultLanguage: 'en' }],
  ['PA', { languages: ['es', 'en', 'fr'], defaultLanguage: 'en' }],
  ['PT', { languages: ['pt', 'en'], defaultLanguage: 'en' }],
  ['TC', { languages: ['en'], defaultLanguage: 'en' }],
  ['UY', { languages: ['es', 'en'], defaultLanguage: 'en' }],
  ['US', { languages: ['en'], defaultLanguage: 'en' }],
  ['VG', { languages: ['en'], defaultLanguage: 'en' }],
  ['ZA', { languages: ['en'], defaultLanguage: 'en' }],
]);

/**
 * Validates the combination of language and countryCode in a search path. It applies a fallback language if the provided combination of countryCode and language is invalid.
 *
 * @param path - The search path to validate.
 * @returns A validated search path, with prepended fallback language and countryCode if necessary. Will fallback to "/de/en" if countryCode is missing or invalid.
 */
export const validateLanguageForCountry = (path: string): string => {
  const { language, countryCode } = extractParamsFromUrl(path);

  const fallbackPath = replaceLocaleInPath(path, Language.en, CountryCode.DE);

  if (!language || !countryCode) {
    console.error('Language or country code missing in search path');
    return fallbackPath;
  }

  if (COUNTRY_LANGUAGE_MAP.has(countryCode) && COUNTRY_LANGUAGE_MAP.get(countryCode)?.languages.includes(language)) {
    return replaceLocaleInPath(path, language, countryCode);
  } else if (COUNTRY_LANGUAGE_MAP.has(countryCode)) {
    return replaceLocaleInPath(path, COUNTRY_LANGUAGE_MAP.get(countryCode)!.defaultLanguage, countryCode);
  } else {
    console.error('Invalid countryCode');
    return fallbackPath;
  }
};

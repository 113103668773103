import { type PropertySubType, type PropertyType } from '@ev/search-modules-api';

import { propertySubtypes } from '../../consts/consts';
import { useAppContext } from '../../hooks/useAppContext';
import { type FilterRefinement } from '../../types/types';
import { Select } from '../Select/Select';

type PropertySubTypeSelectItem = {
  id: PropertySubType | 'reset';
  label: string;
};

type PropertySubTypeFilterProps = {
  selectedSubType?: PropertySubType;
  propertyType?: PropertyType;
  onChange: (arg: FilterRefinement) => void;
  isInModal?: boolean;
  'data-test-id'?: string;
};
export const PropertySubTypeFilter = ({
  selectedSubType,
  propertyType,
  onChange,
  isInModal,
  'data-test-id': dataTestId,
}: PropertySubTypeFilterProps) => {
  const { t } = useAppContext();
  const items: PropertySubTypeSelectItem[] = propertySubtypes.reduce((acc, subtype) => {
    if (!propertyType || subtype.propertyType.includes(propertyType)) {
      acc.push({
        id: subtype.id,
        label: t(subtype.label),
      });
    }
    return acc;
  }, [] as PropertySubTypeSelectItem[]);

  if (items.length === 0) {
    return null;
  }
  return (
    <Select
      items={items}
      placeholder={t('search.searchbar.advFilter.propertySubType.allPropertySubTypes')}
      label={t('search.searchbar.advFilter.propertySubType')}
      selectedKey={selectedSubType}
      size="s"
      isInModal={isInModal}
      onSelectionChange={(value) =>
        onChange({
          propertySubType: value === 'reset' ? undefined : (value as PropertySubType),
        })
      }
      data-test-id={dataTestId ?? 'search-components_property-subtype-filter'}
    />
  );
};

// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { createTheme } from '@mui/material/styles';

const ThemeColors = {
  primary: '#C80000',
  primaryText: '#262626',
  mediumGrey: '#404040',
  doveGrey: '#666666',
  lightGrey: '#808080',
  fullLightGrey: 'hsl(0deg 0% 0% / 20%)',
  lighterGrey: '#F6F6F4',
  white: '#fff',
  black: '#0D0D0D',
  redB3: '#640A00',
  red: '#E60000',
  totemPole: '#910F05',
  mercury: '#E5E5E5',
};

// NOTE: see https://material-ui.com/customization/theming/#custom-variables
declare module '@mui/material/styles/createTheme' {
  interface Theme {
    icons: {
      width: number;
      height: number;
    };
  }

  // allow configuration using `createMuiTheme`
  interface DeprecatedThemeOptions {
    icons: {
      width?: number;
      height?: number;
    };
  }
}

const spacing = 8;
const fontFamily = '"Engel Voelkers Text VF App", Arial, Helvetica, sans-serif';
const headlineFontFamily = '"Engel Voelkers Head VF App", Georgia, "Times New Roman", Times, serif';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    red: Palette['grey'];
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    red: PaletteOptions['grey'];
    neutral: PaletteOptions['primary'];
  }
}

const muiCustomTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: ThemeColors.primary + 'AA',
      main: ThemeColors.primary,
      dark: ThemeColors.red,
      contrastText: ThemeColors.white,
    },
    secondary: {
      light: ThemeColors.mediumGrey,
      main: ThemeColors.primaryText,
      dark: ThemeColors.primaryText + 'DD',
      contrastText: ThemeColors.white,
    },
    grey: {
      100: ThemeColors.lighterGrey,
      200: ThemeColors.mercury,
      300: ThemeColors.fullLightGrey,
      400: ThemeColors.lightGrey,
      500: ThemeColors.doveGrey,
      600: ThemeColors.mediumGrey,
      900: ThemeColors.primaryText,
    },
    red: {
      100: ThemeColors.red,
      200: ThemeColors.redB3,
    },
    neutral: {
      main: ThemeColors.white,
      dark: ThemeColors.black,
    },
    text: {
      primary: ThemeColors.primaryText,
      secondary: ThemeColors.mediumGrey,
      disabled: ThemeColors.lightGrey,
    },
    divider: ThemeColors.mercury,
    background: {
      default: ThemeColors.white,
      paper: ThemeColors.white,
    },
  },
  spacing,
  shadows: [
    'none',
    //1 Card:
    '0px 0px 24px rgba(0, 0, 0, 0.1)',
    //2 Select & Input:
    '0px 0px 6px rgba(0, 0, 0, 0.2)',
    //3:
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    //4:
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    //5:
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    //6:
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    //7:
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    //8:
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    //9:
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    //10:
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    //11:
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    //12:
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    //13:
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    //14:
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    //15:
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    //16:
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    //17:
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    //18:
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    //19:
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    //20:
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    //21:
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    //22:
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    //23:
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    //24:
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid',
          borderBottomColor: ThemeColors.lightGrey,
          '&.Mui-expanded': {
            margin: 0,
          },
          '&&.Mui-expanded::before': {
            opacity: 1,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .Mui-expanded.MuiAccordionSummary-expandIcon': {
            transition: '.2s ease-in-out',
          },
        },
        content: {
          marginBottom: 16,
          marginTop: 16,
          '& h3, & a': {
            width: '100%',
          },
          '& a': {
            display: 'block',
            color: ThemeColors.primaryText,
            textDecoration: 'none',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },
          '& .MuiInputBase-adornedEnd': {
            paddingRight: 14,
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: 14.5,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
          fontSize: 16,
          lineHeight: '28px',
          fontWeight: 400,
          letterSpacing: '0.03em',
          textTransform: 'none',
          padding: `${spacing * 0.875}px ${spacing * 3}px`,
          borderRadius: 2,
        },
        sizeLarge: {
          padding: `${spacing * 1.125}px ${spacing * 3}px`,
        },
        contained: {
          boxShadow: 'none',
        },
        iconSizeMedium: {
          '& > :first-of-type': {
            fontSize: '24px',
          },
        },
        iconSizeLarge: {
          '& > :first-of-type': {
            fontSize: '28px',
          },
        },
        containedPrimary: {
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: ThemeColors.totemPole,
            border: 'none',
          },
          '&:active': {
            backgroundColor: ThemeColors.redB3,
            boxShadow: 'none',
          },
          '&:focus-visible': {
            outline: `2px solid ${ThemeColors.doveGrey}`,
            outlineOffset: '1px',
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: ThemeColors.lightGrey,
            backgroundColor: ThemeColors.lighterGrey,
            '&:focus': {
              border: 'none',
            },
          },
        },
        containedSecondary: {
          backgroundColor: ThemeColors.black,
          '&:hover': {
            backgroundColor: ThemeColors.mediumGrey,
            boxShadow: 'none',
          },
          '&:focus-visible': {
            outline: `2px solid ${ThemeColors.doveGrey}`,
            outlineOffset: '1px',
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: ThemeColors.doveGrey,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: ThemeColors.lightGrey,
            backgroundColor: ThemeColors.lighterGrey,
            '&:focus': {
              border: 'none',
            },
          },
        },
        textSizeLarge: {
          '&&&': {
            padding: `${spacing * 1.25}px ${spacing * 3}px`,
            height: 48,
          },
        },
        text: {
          height: 36,
          textDecoration: 'underline',
          color: ThemeColors.black,
          padding: '0',
          background: 'none',
          '&:focus-visible': {
            outline: `2px solid ${ThemeColors.doveGrey}`,
            outlineOffset: '1px',
          },
          '&:hover': {
            color: ThemeColors.mediumGrey,
            textDecoration: 'underline',
            background: 'none',
          },
          '&:active': {
            color: ThemeColors.doveGrey,
          },
          '&.Mui-disabled': {
            color: ThemeColors.lightGrey,
          },
        },
        outlinedSizeLarge: {
          '&&&': {
            padding: `${spacing * 1.25}px ${spacing * 3}px`,
            height: 48,
          },
        },
        outlined: {
          height: 36,
          padding: `${spacing * 0.875}px ${spacing * 3}px`,
          lineHeight: `${spacing * 2.75}px`,
          border: 'none',
          boxShadow: `inset 0px 0px 0px 1px ${ThemeColors.black}`,
          color: ThemeColors.black,

          '&:focus-visible': {
            outline: `2px solid ${ThemeColors.doveGrey}`,
            outlineOffset: '1px',
          },
          '&:hover': {
            backgroundColor: ThemeColors.mediumGrey,
            color: ThemeColors.white,
            border: 'none',
            boxShadow: `none`,
          },
          '&:active': {
            boxShadow: `none`,
            backgroundColor: ThemeColors.doveGrey,
          },
          '&.Mui-disabled': {
            border: 'none',
            color: ThemeColors.lightGrey,
            backgroundColor: ThemeColors.lighterGrey,
            boxShadow: `none`,
            '&:focus': {
              border: 'none',
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&&&.MuiCheckbox-root:hover, &.Mui-checked:hover': {
            backgroundColor: 'transparent',
          },
          '&:not(.Mui-checked):hover > .MuiIconButton-label > span': {
            border: `1px solid ${ThemeColors.lightGrey}`,
            backgroundColor: ThemeColors.fullLightGrey,
          },
          '& > .MuiIconButton-label > span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '&.Mui-disabled .MuiIconButton-label > span': {
            border: `1px solid ${ThemeColors.lightGrey}`,
            backgroundColor: ThemeColors.fullLightGrey,
            '& > svg': {
              color: ThemeColors.lightGrey,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily,
        },
        iconSmall: {
          width: 28,
          height: 28,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: 1.375,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            paddingTop: 0,
          },
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          fontSize: 12,
          lineHeight: '1.25rem',
          fontWeight: 400,
          letterSpacing: '0.03em',
          '&.Mui-error': {
            color: ThemeColors.primary,
            backgroundColor: 'transparent',
          },
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          lineHeight: '1.25rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$error': {
            color: ThemeColors.primary,
          },
          '&.MuiInputLabel-root': {
            lineHeight: '1rem',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: ThemeColors.lightGrey,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          background: 'none',
          color: ThemeColors.doveGrey,
        },
        outlined: {
          '&.MuiInputLabel-shrink': {
            color: ThemeColors.doveGrey,
            position: 'inherit',
            transform: 'none',
            fontSize: 14,
            marginBottom: 8,
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
          '&.Mui-error.MuiInputLabel-shrink': {
            color: ThemeColors.primary,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textDecorationColor: 'inherit',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#f0f0f0',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: '1.1876em',
          padding: 14.5,
          color: ThemeColors.mediumGrey,
        },
        adornedEnd: {
          paddingRight: 14,
        },
        root: {
          padding: 0,
          fontSize: '1rem',
          '&:not(.Mui-disabled):hover fieldset, &.Mui-focused:not(.Mui-disabled) fieldset': {
            border: '1px solid ' + ThemeColors.mediumGrey,
            marginLeft: '-1px',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.32)',
          },
          '&.Mui-focused:not(.Mui-disabled) fieldset': {
            border: '1px solid ' + ThemeColors.mediumGrey,
          },
          'fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: ThemeColors.lightGrey,
          },
          '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: ThemeColors.primary,
          },
          '.Mui-disabled': {
            backgroundColor: ThemeColors.fullLightGrey,
            '&:hover': {
              borderColor: `${ThemeColors.lightGrey}`,
            },
            '& .MuiInputAdornment-root': {
              color: ThemeColors.red,
            },
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: ThemeColors.primaryText,
          color: ThemeColors.white,
          padding: `${spacing * 1.875}px ${spacing * 1.5}px`,
          minWidth: `${spacing * 43.25}px`,
          height: `${spacing * 7}px`,
          display: 'flex',
          alignContent: 'center',
        },
        message: {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
      styleOverrides: {
        fontSizeLarge: {
          fontSize: '2.25rem',
        },
        fontSizeMedium: {
          fontSize: '1.75rem',
        },
        fontSizeSmall: {
          fontSize: '1.5rem',
        },
      },
    },
  },
});

// Desktop: Heading-XL
// Mobile: Heading-L
muiCustomTheme.typography.h1 = {
  fontFamily: headlineFontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(34),
  lineHeight: muiCustomTheme.typography.pxToRem(44),
  fontWeight: 700,
  letterSpacing: 0,
  [muiCustomTheme.breakpoints.down('md')]: {
    fontSize: muiCustomTheme.typography.pxToRem(28),
    lineHeight: muiCustomTheme.typography.pxToRem(32),
  },
};

// Desktop: Heading-M
// Mobile: Heading-S-400
muiCustomTheme.typography.h2 = {
  fontFamily: headlineFontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(28),
  lineHeight: muiCustomTheme.typography.pxToRem(36),
  fontWeight: 700,
  letterSpacing: 0,
  [muiCustomTheme.breakpoints.down('md')]: {
    fontSize: muiCustomTheme.typography.pxToRem(20),
    lineHeight: muiCustomTheme.typography.pxToRem(28),
  },
};

// Desktop: Heading-M (Expose headlines)
muiCustomTheme.typography.h3 = {
  fontFamily: headlineFontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(24),
  lineHeight: muiCustomTheme.typography.pxToRem(32),
  fontWeight: 700,
  letterSpacing: 0,
};

// Desktop: Heading-S-400
muiCustomTheme.typography.h4 = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(18),
  lineHeight: muiCustomTheme.typography.pxToRem(28),
  fontWeight: 400,
  letterSpacing: '0.03em',
};

// Desktop: Heading-XS-400
muiCustomTheme.typography.h5 = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(18),
  lineHeight: muiCustomTheme.typography.pxToRem(32),
  fontWeight: 400,
  letterSpacing: '0.03em',
};

// Desktop: Body-M-400-Naked
muiCustomTheme.typography.h6 = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(16),
  lineHeight: muiCustomTheme.typography.pxToRem(28),
  fontWeight: 400,
  letterSpacing: '0.03em',
};

// Desktop: Detail-400
muiCustomTheme.typography.subtitle1 = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(12),
  lineHeight: muiCustomTheme.typography.pxToRem(20),
  fontWeight: 400,
  letterSpacing: '0.03em',
};

// Desktop: Detail-S-400
muiCustomTheme.typography.subtitle2 = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(12),
  lineHeight: muiCustomTheme.typography.pxToRem(20),
  fontWeight: 400,
  letterSpacing: '0.03em',
};

// Desktop: Body-M-Loose-400-Naked
// Mobile: Body-S-Narrow-400
muiCustomTheme.typography.body1 = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(16),
  lineHeight: muiCustomTheme.typography.pxToRem(28),
  fontWeight: 400,
  letterSpacing: '0.03em',
  [muiCustomTheme.breakpoints.down('md')]: {
    fontSize: muiCustomTheme.typography.pxToRem(14),
    lineHeight: muiCustomTheme.typography.pxToRem(24),
  },
};

// Desktop: Body-S-500-Naked
muiCustomTheme.typography.body2 = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(14),
  lineHeight: muiCustomTheme.typography.pxToRem(24),
  fontWeight: 400,
  letterSpacing: '0.03em',
};

// Desktop: Detail-S-400
muiCustomTheme.typography.caption = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(14),
  lineHeight: muiCustomTheme.typography.pxToRem(24),
  fontWeight: 400,
  letterSpacing: '0.03em',
};

// Desktop: Detail-XS-500-Caps
muiCustomTheme.typography.overline = {
  fontFamily: fontFamily,
  fontSize: muiCustomTheme.typography.pxToRem(12),
  lineHeight: muiCustomTheme.typography.pxToRem(20),
  fontWeight: 400,
  letterSpacing: muiCustomTheme.typography.pxToRem(2),
};

export { muiCustomTheme };

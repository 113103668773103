import {
  isUUID,
  refineFiltersAndOptions,
  replaceLocaleInPath,
  shortenMasterDataIds,
  stringifySearchParams
} from "./chunk-GXCAL3EM.js";

// src/getSearchResultPageUrl/getSearchResultPageUrl.ts
var DEFAULT_PRESERVED_PARAMS = ["gclid", /^gad_/, /^utm_/, "msclkid", "fbclid", "ga4id", "gbraid", "wbraid"];
async function getSearchResultPageUrl(url, filters, options, preservedParams = []) {
  const _url = url instanceof URL ? url : new URL(url);
  const { filters: refinedFilters } = refineFiltersAndOptions(filters, options);
  if (refinedFilters.shopIds?.some((id) => isUUID(id)))
    console.warn("masterDataShopId was passed as shopId. Please use the masterDataShopIds filter instead.");
  if (refinedFilters.masterDataShopIds) {
    refinedFilters.masterDataShopIds = await shortenMasterDataIds(refinedFilters.masterDataShopIds);
  }
  const { language, countryCode, pageSize, ...otherOptions } = options;
  delete refinedFilters.fallbackSearch;
  const keys = [..._url.searchParams.keys()];
  for (const key of keys) {
    const matches = [...DEFAULT_PRESERVED_PARAMS, ...preservedParams].some(
      (param) => typeof param === "string" ? key === param : param.test(key)
    );
    if (!matches) {
      _url.searchParams.delete(key);
    }
  }
  _url.pathname = replaceLocaleInPath(_url.pathname, language, countryCode);
  _url.search = stringifySearchParams({ ...refinedFilters, ...otherOptions }, _url.searchParams);
  return _url;
}

export {
  getSearchResultPageUrl
};

import { useRef } from 'react';
import { type AriaButtonProps, useButton } from 'react-aria';

import Filters from '../../../spearhead/lib/assets/icons/small/Filters.svg';
import FiltersActive from '../../../spearhead/lib/assets/icons/small/FiltersActive.svg';
import { useAppContext } from '../../hooks/useAppContext';
import { StyledDesktopButton, StyledMobileButton } from './AdvancedFilters.styled';

export const AdvancedFiltersButton = ({
  isActive,
  variant,
  className,
  ...props
}: AriaButtonProps & { className?: string; isActive: boolean; variant: 'default' | 'map-view' }) => {
  const { t } = useAppContext();
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { children, ...restButtonProps } = buttonProps;
  const icon = isActive ? <FiltersActive /> : <Filters />;
  return (
    <>
      {variant === 'default' && (
        <StyledDesktopButton
          {...restButtonProps}
          className={className}
          variant="tertiary"
          refObject={ref}
          label={t('search.searchbar.filters')}
          icon={icon}
        />
      )}
      <StyledMobileButton
        {...restButtonProps}
        className={className}
        ref={ref}
        icon={icon}
        variant={variant === 'map-view' ? 'primaryAlt' : 'secondary'}
        shape="square"
        size={variant === 'map-view' ? 'small' : 'medium'}
        label={t('search.searchbar.filters')}
        $isMapView={variant === 'map-view'}
      />
    </>
  );
};

/* eslint-disable no-console */
export function joinUs() {
  console.log(
    `
@@@@@@@@@@@    %c#####    %c@@         @@ 
@@            %c##  ##    %c@@@       @@ 
@@             %c####      %c@@      @@@  
@@@@@@@@@    %c######  ##   %c@@     @@   
@@           %c##   ####     %c@@   @@   
@@            %c#####  #     %c@@  @@@    
@@                          @@@@@    
@@@@@@@@@@@                  @@@     

Join us!:
https://www.engelvoelkers.com/de/en/company/careers/technology`,
    'color: #E60000;',
    'color: inherit;',
    'color: #E60000;',
    'color: inherit;',
    'color: #E60000;',
    'color: inherit;',
    'color: #E60000;',
    'color: inherit;',
    'color: #E60000;',
    'color: inherit;',
    'color: #E60000;',
    'color: inherit;'
  );
}

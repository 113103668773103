/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type Currency = (typeof Currency)[keyof typeof Currency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Currency = {
  EUR: 'EUR',
  AED: 'AED',
  BSD: 'BSD',
  BZD: 'BZD',
  CAD: 'CAD',
  CHF: 'CHF',
  CLF: 'CLF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CZK: 'CZK',
  DKK: 'DKK',
  GBP: 'GBP',
  HKD: 'HKD',
  HUF: 'HUF',
  KYD: 'KYD',
  MUR: 'MUR',
  MXN: 'MXN',
  OMR: 'OMR',
  PAB: 'PAB',
  QAR: 'QAR',
  RUB: 'RUB',
  SEK: 'SEK',
  TRY: 'TRY',
  USD: 'USD',
  ZAR: 'ZAR',
} as const;

import { styled } from 'styled-components';

import { Copy } from '../../../spearhead/lib/components/elements/Copy/Copy';
import { Headline } from '../../../spearhead/lib/components/elements/Headline/Headline';
import { to } from '../../utils/mediaQueries';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  gap: var(--distance-static-l);
  padding-top: var(--distance-dynamic-s);
  padding-bottom: var(--distance-dynamic-m);
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: var(--distance-static-l);
`;

export const StyledTextContainer = styled.div`
  display: flex;
  max-width: 664px;
  flex-direction: column;
  align-items: center;
  gap: var(--distance-static-xs);
`;

export const StyledHeadline = styled(Headline)`
  text-align: center;
`;

export const StyledSubtitle = styled(Copy)`
  color: var(--color-dark-colored);
  text-align: center;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: var(--distance-static-m);
  justify-content: center;

  @media screen and (${to.tabletLandscape}) {
    flex-direction: column-reverse;
  }
  @media screen and (${to.tabletPortrait}) {
    width: 100%;
  }
`;

import { type BigDecimalRangeDto } from '@ev/search-modules-api';
import { useState } from 'react';

import { useAppContext } from '../../hooks/useAppContext';
import { validateRangeValues } from '../../utils/numberFormatters';
import { formatRangeLabel } from '../../utils/stringUtils';
import { RangeDropdown } from '../RangeDropdown/RangeDropdown';
import { TotalSurfaceInput } from '../RangeInputs/TotalSurfaceInput';
import { type SurfaceFilterDropdownProps } from './FilterDropdown.types';

export const TotalSurfaceFilterDropdown = ({
  value,
  language,
  unitSymbol,
  className,
  onChange,
  'data-test-id': dataTestId,
}: SurfaceFilterDropdownProps) => {
  const { t } = useAppContext();
  const [range, setRange] = useState<BigDecimalRangeDto | undefined>(value ? validateRangeValues(value) : undefined);

  return (
    <RangeDropdown
      className={className}
      label={t('search.searchbar.totalSurface')}
      value={value ? formatRangeLabel(value, unitSymbol, language, true) : ''}
      onDone={() => {
        onChange({ totalSurface: { ...range } });
      }}
      onReset={() => {
        onChange({ totalSurface: undefined });
        setRange(undefined);
      }}
      data-test-id={dataTestId ? `${dataTestId}_dropdown` : 'search-components_total-surface-filter_dropdown'}
    >
      <TotalSurfaceInput unitSymbol={unitSymbol} hiddenLabel={true} defaultRange={value} language={language} onChange={setRange} />
    </RangeDropdown>
  );
};

import { type BoundingBoxDto } from '@pkgs/api';

// Italy bounding box (used if no placeId or bounding box is provided)
export const DEFAULT_MAP_BOUNDING_BOX: BoundingBoxDto = {
  northEast: {
    lat: 47.092,
    lng: 18.7975999,
  },
  southWest: {
    lat: 35.4897,
    lng: 6.6267201,
  },
};

export const DEFAULT_MAP_BOUNDING_BOX_EXTENDED: BoundingBoxDto = {
  southWest: {
    lat: 29.68855,
    lng: 0.5412801999999992,
  },
  northEast: {
    lat: 52.89315,
    lng: 24.883039800000002,
  },
};

import {
  deleteAllSearchAlerts
} from "./chunk-YYWHXEGH.js";
import {
  getErrorResult
} from "./chunk-Q7UZCASA.js";

// src/deleteAllSearchAlert/deleteAllSearchAlert.ts
async function deleteAllSearchAlerts2(accountId, apiConfig) {
  try {
    await deleteAllSearchAlerts(accountId, apiConfig);
    return { status: "success", data: void 0 };
  } catch (error) {
    apiConfig.onError?.(error);
    return getErrorResult(error);
  }
}

export {
  deleteAllSearchAlerts2 as deleteAllSearchAlerts
};

/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type Language = (typeof Language)[keyof typeof Language];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Language = {
  ca: 'ca',
  cs: 'cs',
  da: 'da',
  nl: 'nl',
  en: 'en',
  fr: 'fr',
  de: 'de',
  el: 'el',
  hu: 'hu',
  it: 'it',
  pt: 'pt',
  ru: 'ru',
  es: 'es',
} as const;

import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useSearch } from '@pkgs/components/hooks/useSearch';

import { Headline } from '../Headline/Headline';
import { type SeoPageLink } from '../SearchModule';
import { Skeleton } from '../Skeleton/Skeleton';
import { SortBySelect } from '../SortBySelect/SortBySelect';
import { StyledAutogenInfoRowContainer, StyledInfoContainer } from './SearchInfoRow.styled';
import { SeoLinks } from './SeoLinks';

export const AutogenInfoRow = ({ seoPagesLinks }: { seoPagesLinks: SeoPageLink[] }) => {
  const { t } = useAppContext();
  const { filters, isFetching, placeDetails } = useSearch();

  const label = t(`search.seo.headline.${filters.propertyMarketingType}.${filters.propertyType || filters.businessArea}`, {
    location: placeDetails.name,
  });

  return (
    <StyledAutogenInfoRowContainer>
      <Skeleton loading={isFetching}>
        <Headline label={label} as="h1" size="h5" />
      </Skeleton>
      <StyledInfoContainer>
        {seoPagesLinks.length > 0 && <SeoLinks links={seoPagesLinks} />}
        <SortBySelect />
      </StyledInfoContainer>
    </StyledAutogenInfoRowContainer>
  );
};

/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

export type BusinessArea = (typeof BusinessArea)[keyof typeof BusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessArea = {
  residential: 'residential',
  commercial: 'commercial',
} as const;

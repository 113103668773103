import { Language } from '@ev/search-modules-api';

import { useAppContext } from '../../hooks/useAppContext';
import { FormattedNumberInput } from '../FormattedNumberInput/FormattedNumberInput';
import { RangeInput } from './RangeInput';
import { StyledEndAdornment } from './RangeInput.styled';
import type { SurfaceRangeInputProps } from './RangeInput.types';

export const LivingSurfaceInput = ({ language, unitSymbol, ...restProps }: SurfaceRangeInputProps) => {
  const { t } = useAppContext();
  return (
    <RangeInput
      label={t('search.searchbar.livingArea')}
      id="livingSurface"
      inputComponent={
        <FormattedNumberInput
          language={language ?? Language.en}
          endAdornment={<StyledEndAdornment forwardedAs="span" size="l" text={unitSymbol} />}
        />
      }
      {...restProps}
    />
  );
};

import {
  StyledContainer,
  StyledContentSkeleton,
  StyledImageSkeleton,
  StyledSkeletonContentRow,
  StyledSkeletonContentWrapper,
} from './LoadingResultCard.styles';

export const LoadingResultCard = ({ className, variant = 'default' }: { className?: string; variant?: 'default' | 'preview' }) => {
  const isPreview = variant === 'preview';
  return (
    <StyledContainer className={className} data-test="skeleton">
      <StyledImageSkeleton $isPreview={isPreview} />
      <StyledSkeletonContentWrapper $isPreview={isPreview}>
        <StyledContentSkeleton />
        <StyledContentSkeleton />
        {!isPreview && <StyledContentSkeleton />}
        <StyledSkeletonContentRow>
          <StyledContentSkeleton />
          <StyledContentSkeleton />
        </StyledSkeletonContentRow>
      </StyledSkeletonContentWrapper>
    </StyledContainer>
  );
};

import { type SeoPageLink } from '@pkgs/components/components/SearchModule';
import { useBreakpoint } from '@pkgs/components/hooks/useBreakpoint';
import { InlineLink } from '@spearhead/components/elements/InlineLink/InlineLink';
import { type UIEvent, useLayoutEffect, useRef, useState } from 'react';

import { StyledGradient, StyledLinksContainer, StyledScrollingContainer } from './SearchInfoRow.styled';

const SCROLL_THRESHOLD = 20;

export const SeoLinks = ({ links }: { links: SeoPageLink[] }) => {
  const [showGradient, setShowGradient] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<'start' | 'end' | 'middle'>('start');
  const linksWrapperRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useBreakpoint();

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    const distanceToRightEdge = element.scrollWidth - element.scrollLeft - element.clientWidth;
    if (element.scrollLeft < SCROLL_THRESHOLD) {
      setScrollPosition('start');
    } else if (distanceToRightEdge < SCROLL_THRESHOLD) {
      setScrollPosition('end');
    } else {
      setScrollPosition('middle');
    }
  };

  useLayoutEffect(() => {
    if (linksWrapperRef.current) {
      setShowGradient(linksWrapperRef.current.scrollWidth > linksWrapperRef.current.offsetWidth);
    }
  }, [isMobile]);

  return (
    <StyledScrollingContainer>
      {showGradient && scrollPosition !== 'start' && <StyledGradient $direction="right" />}
      <StyledLinksContainer ref={linksWrapperRef} onScroll={handleScroll}>
        {links.map((link) => {
          return <InlineLink key={link.text} href={link.url} label={link.text} />;
        })}
      </StyledLinksContainer>
      {showGradient && scrollPosition !== 'end' && <StyledGradient $direction="left" />}
    </StyledScrollingContainer>
  );
};

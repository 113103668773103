import { useContext } from 'react';

import { SnackbarContext } from '@/providers/SnackbarProvider';

export const useSnackbar = () => {
  const ctx = useContext(SnackbarContext);
  if (ctx === undefined) {
    throw new Error('useSnackbar used outside of ContextProvider!');
  } else {
    return ctx;
  }
};

/**
  Autogenerated file. Do not edit manually.
  @openapi https://search-bff.envdevel.com/api/v3/api-docs
*/

import { customInstance } from '../../custom-instance';
import type {
  AutoSuggestionPlaceResult,
  AutoSuggestionPropertyResult,
  CommonPropertySuggestParams,
  CommonSuggestParams,
  GetPlaceDetailsV2Params,
  PlaceResult,
} from '../../models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Suggest placeIds for a given input.
 */
export const commonSuggest = (params: CommonSuggestParams, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<AutoSuggestionPlaceResult[]>({ url: `/v1/autosuggestion/suggest`, method: 'GET', params }, options);
};
/**
 * @summary Suggest properties for a given input.
 */
export const commonPropertySuggest = (params: CommonPropertySuggestParams, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<AutoSuggestionPropertyResult[]>({ url: `/v1/autosuggestion/listing/suggest`, method: 'GET', params }, options);
};
/**
 * @summary Return the place details with a given place id
 */
export const getPlaceDetailsV2 = (params: GetPlaceDetailsV2Params, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<PlaceResult>({ url: `/v1/autosuggestion/details`, method: 'GET', params }, options);
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type CommonSuggestResult = NonNullable<Awaited<ReturnType<typeof commonSuggest>>>;
export type CommonPropertySuggestResult = NonNullable<Awaited<ReturnType<typeof commonPropertySuggest>>>;
export type GetPlaceDetailsV2Result = NonNullable<Awaited<ReturnType<typeof getPlaceDetailsV2>>>;

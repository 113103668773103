import { DEFAULT_IMAGE } from '@ev/search-modules-api';
import { useLoadImageValidation } from '@pkgs/components/hooks/useLoadImageValidation';
import { type CSSProperties, memo, type SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SliderSlideStyled } from '../Carousel/Carousel.styled';
import { type ImageComponent } from '../Image/DefaultImageComponent';
import { CarouselImageSkeletonStyled } from './CarouselImage.styled';

type Props = {
  src: string;
  imageComponent: ImageComponent;
  fallbackImage?: string;
  inView: boolean;
  preloadImage?: boolean;
  alt: string;
  onError?: (e: SyntheticEvent<Element, Event>) => void;
  onClick?: (e?: React.MouseEvent<HTMLImageElement>) => void;
  index?: number;
  aspectRatio?: `${number}/${number}`;
  sizes?: string;
  width?: number;
  height?: number;
  imageReference?: React.RefObject<HTMLImageElement>;
  lazyLoadImage?: boolean;
  className?: string;
  imageStyles?: CSSProperties;
};

export const CarouselImage = memo(
  ({
    src,
    imageComponent: Image,
    fallbackImage = DEFAULT_IMAGE,
    inView,
    preloadImage,
    aspectRatio,
    lazyLoadImage = true,
    className,
    imageStyles,
    index = 0,
    imageReference,
    ...restImageProps
  }: Props) => {
    const [dimension, setDimension] = useState({ width: 0, height: 0 });
    const [loadImageInView, setLoadImageInView] = useState(false);

    const internalImageRef = useRef<HTMLImageElement | null>(null);

    const imageRef = imageReference || internalImageRef;

    const hasError = useLoadImageValidation(src);

    const loading = useMemo(() => !loadImageInView && inView, [inView, loadImageInView]);

    const updateImageDimension = useCallback(
      (naturalWidth: number, naturalHeight: number) => {
        if (!loadImageInView) {
          if (dimension.width !== naturalWidth || dimension.height !== naturalHeight || !hasError) {
            setDimension({ width: naturalWidth, height: naturalHeight });
          }
          setLoadImageInView(true);
        }
      },
      [dimension, hasError, loadImageInView],
    );

    const handleImageLoad = useCallback(
      (img: SyntheticEvent<HTMLImageElement, Event>) =>
        updateImageDimension(img.currentTarget.naturalWidth, img.currentTarget.naturalHeight),
      [updateImageDimension],
    );

    // This effect is used to update the image properties when the image is already loaded and onload is not triggered i.e client caching
    useEffect(() => {
      if (imageRef.current) {
        const imageElement = imageRef.current;

        if (imageElement.complete) {
          updateImageDimension(imageElement.naturalWidth, imageElement.naturalHeight);
        }
      }
    }, [src, updateImageDimension, imageRef]);

    return (
      <SliderSlideStyled className={className} $aspectRatio={aspectRatio}>
        {(inView || !lazyLoadImage) && (
          <Image
            {...restImageProps}
            ref={imageRef}
            src={hasError ? fallbackImage : src}
            style={{
              objectFit: dimension.height > dimension.width ? 'contain' : 'cover',
              width: '100%',
              height: 'auto',
              objectPosition: 'center',
              backgroundColor: 'var(--color-dark-decent)',
              ...(aspectRatio && { aspectRatio }),
              ...(imageStyles && imageStyles),
            }}
            {...(index === 0 && preloadImage && { priority: preloadImage })}
            loading={index > 0 || !preloadImage ? 'lazy' : 'eager'}
            data-src={src}
            onLoad={handleImageLoad}
          />
        )}
        <CarouselImageSkeletonStyled $isVisible={loading && lazyLoadImage} />
      </SliderSlideStyled>
    );
  },
);

CarouselImage.displayName = 'CarouselImage';

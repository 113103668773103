import {
  listingLead as generatedListingLead,
  type ListingLeadResult as GeneratedListingLeadResult,
} from '@generated/search-api/controllers/eVSearchV3API';
import { type InputContact } from '@generated/search-api/models/inputContact';
import { type ApiConfig } from '@pkgs/api/types';
/**
 * Lead contact form to request further information, viewing, callback, etc. for a certain listing
 * @summary Lead Contact request form.
 */
export const listingLead = (listingID: string, inputContact: InputContact, apiConfig: ApiConfig) => {
  return generatedListingLead(listingID, inputContact, apiConfig);
};

export type listingLeadResult = GeneratedListingLeadResult;

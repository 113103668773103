import { type AddressTypeDto } from '@ev/search-modules-api';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { type InputTextSizesProps } from '@spearhead/components/elements/InputText/InputText';
import { type Key, useCallback, useEffect, useRef, useState } from 'react';

import { AutoCompleteComboBox } from './AutoCompleteComboBox';
import { AutoCompleteOption } from './AutoCompleteOption';

type Props = {
  initialLocation: string;
  onSelectionChange: (selected: AutoSuggestOption) => void;
  size?: InputTextSizesProps;
  hiddenLabel?: boolean;
  className?: string;
  'data-test-id'?: string;
};

export type AutoSuggestOption =
  | {
      id: string;
      title: string;
      types: AddressTypeDto[];
      isProperty: false;
    }
  | {
      id: string;
      title: string;
      description: string;
      link: string;
      isProperty: true;
    };

export const AutoComplete = ({ initialLocation, onSelectionChange, size, hiddenLabel, className, 'data-test-id': dataTestId }: Props) => {
  const { t, api } = useAppContext();
  const {
    countryCode,
    options: { language },
  } = useSearch();
  const [text, setText] = useState(initialLocation);
  const [options, setOptions] = useState<AutoSuggestOption[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const fetchOptions = useCallback(async () => {
    if (text.length < 3 || text === initialLocation) {
      return;
    }

    const result = await api.getSuggestions(text, {
      language,
      limit: 5,
    });

    if (result.status === 'error') {
      console.error(result.errorMessage);
      return;
    }
    if ('locations' in result.data) {
      setOptions(
        result.data.locations.map(({ types, description, placeId }) => ({
          types: types ?? [],
          title: description ?? '',
          isProperty: false,
          id: placeId ?? '',
        })),
      );
    } else {
      setOptions(
        result.data.properties.map((property) => ({
          id: property.id,
          title: property.displayID ?? '',
          description: property.profile?.title ?? '',
          link: `${countryCode ? `/${countryCode.toLowerCase()}` : ''}/${language}${property.exposePath}`,
          isProperty: true,
        })),
      );
    }
  }, [api, countryCode, text, initialLocation, language]);

  useEffect(() => {
    fetchOptions().catch(console.error);
  }, [text, fetchOptions]);

  useEffect(() => {
    setText(initialLocation);
  }, [initialLocation]);

  const handleSelectionChange = (selection: Key | null) => {
    const option = options.find((suggestion) => suggestion.id === selection);
    if (option && !option.isProperty) {
      setText(option.title);
      onSelectionChange(option);
      setOptions([]);
    }
  };

  const handleInputChange = (value: string) => {
    if (value === '') {
      setOptions([]);
    }
    setText(value);
  };

  return (
    <AutoCompleteComboBox
      className={className}
      inputValue={text}
      onInputChange={handleInputChange}
      items={options}
      onSelectionChange={handleSelectionChange}
      size={size}
      label={hiddenLabel ? '' : t('search.searchbar.locationInput.label')}
      aria-label={t('search.searchbar.locationInput.label')}
      inputRef={inputRef}
      allowsCustomValue
      data-test-id={dataTestId}
    >
      {(item) => (
        <AutoCompleteOption
          key={item.id}
          inputValue={text}
          option={item}
          data-test-id={`search-components_autocomplete_popover_option-${options.indexOf(item)}`}
        />
      )}
    </AutoCompleteComboBox>
  );
};

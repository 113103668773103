// TODO: Remove this component in a separate commit, so we can easily restore it IF we need it...

import { Box, Grid, Typography } from '@mui/material';
import { type Property } from '@pkgs/api';
import { BusinessArea } from '@pkgs/api';
import { DEFAULT_IMAGE } from '@pkgs/api';
import { type ParseKeys } from 'i18next';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useSettings } from '@/hooks/useSettings';
import { useWatchlist } from '@/hooks/useWatchlist';
import { UnitEnum } from '@/types/currency';
import { type PageTypeEnum } from '@/types/tracking';
import { formatNumber } from '@/utils/formatters';
import { getCurrentPriceOrRentProps } from '@/utils/getCurrentPriceOrRentProps';
import { mapLanguageCode, mapMeasurementSymbol } from '@/utils/settings';
import { getSpecificLivingAreaLabel } from '@/utils/translationLabels';

import FavoriteButton from '../FavoriteButton/FavoriteButton';
import {
  StyledDividerMap,
  StyledFactRow,
  StyledFacts,
  StyledHeader,
  StyledLabelTranslation,
  StyledLink,
  StyledLocationText,
  StyledMapCard,
  StyledPinIcon,
  StyledText,
  StyledThumbnail,
  StyledTitle,
} from './PropertyCard.styles';

export const CARD_HEIGHT = 130;

export const isCommercialProperty = (property: Partial<Property>): boolean =>
  Boolean(property.businessArea?.includes(BusinessArea.commercial));

type PropertyCardProps = {
  isMobile?: boolean;
  properties: Property[];
  className?: string;
  hidePlacement?: boolean;
  pageLocation: PageTypeEnum; // used to describe the context page type
};

export const PropertyCard = ({
  isMobile,
  properties,
  className,
  hidePlacement = false,
  pageLocation,
}: PropertyCardProps): React.ReactElement => {
  const { t } = useTranslation('common');
  const { currency, measurementSystem, language } = useSettings();

  const { toggleWatchlistItem, isInWatchlist } = useWatchlist();
  const languageCode = mapLanguageCode[language].code;
  const isSingle = properties.length === 1;

  return (
    <>
      {properties.map((property, i) => {
        const isCommercial = isCommercialProperty(property);
        const url = property.exposePath;

        const ObfuscationHeader = (): React.ReactElement => {
          return (
            <StyledHeader>
              <StyledPinIcon />
              {t('search.map.placement.exact')}
            </StyledHeader>
          );
        };

        const propertyPriceBase = getCurrentPriceOrRentProps(property, languageCode, property.baseCurrency);

        const propertyPriceFormatted =
          propertyPriceBase.value || propertyPriceBase.rentNetValue ? propertyPriceBase.value || 0 : t('search.map.priceOnRequest');

        const convertedPriceBase = getCurrentPriceOrRentProps(property, languageCode, currency);

        const convertedPriceFormatted = convertedPriceBase.value ? `~ ${convertedPriceBase.value || ''}` : t('search.map.priceOnRequest');

        const isBaseCurrency = currency === property.baseCurrency;

        const rooms = property.isDevelopmentProject ? property.project?.aggregate?.minRooms : property.rooms;

        const unit = measurementSystem === 'metric' ? UnitEnum.MT.toLowerCase() : UnitEnum.FT.toLowerCase();

        const surface = property.isDevelopmentProject
          ? property.project?.aggregate?.areas?.[unit].maxLivingSurface
          : property.areas?.[unit].livingSurface;

        const totalSurfaceValue = property.areas?.[unit].totalSurface;
        const plotSurfaceValue = property.areas?.[unit].plotSurface;

        const obfuscatedHeader = `${t('search.map.obfuscated.numberXof' as ParseKeys<'common'>, {
          current: i + 1,
          total: properties.length,
        })} ${t('search.map.listings')}`;

        const imgSrc = property.images[0]?.url ?? DEFAULT_IMAGE;

        const livingAreaLabel = property.isDevelopmentProject
          ? 'search.expose.propertyDetails.label.livingAreaFrom'
          : getSpecificLivingAreaLabel(property);

        const showDivider = Boolean(rooms || surface || (isCommercial && (totalSurfaceValue || plotSurfaceValue)));

        return (
          <div
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
            key={property.id}
            className={className}
          >
            <StyledLink href={url} prefetch={false}>
              {isSingle && !hidePlacement && <ObfuscationHeader />}
              {!isSingle && isMobile && <ObfuscationHeader />}
              <StyledMapCard isSingle={isSingle}>
                <StyledTitle>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StyledLocationText variant="subtitle1" color="secondary" component="span">
                      {isSingle ? property.formattedLocation : obfuscatedHeader}
                    </StyledLocationText>
                    <FavoriteButton
                      filled={isInWatchlist(String(property.id))}
                      onClick={() => toggleWatchlistItem(property, pageLocation)}
                    />
                  </Box>
                </StyledTitle>
                <Grid container direction="row">
                  <Grid item>
                    <StyledThumbnail alt="property image" src={imgSrc} />
                  </Grid>
                  <StyledFacts item container direction="column" xs>
                    {rooms ? (
                      <StyledFactRow item container>
                        <Grid item>
                          <Typography variant="subtitle1" color="textSecondary">
                            {property.isDevelopmentProject
                              ? t('search.expose.propertyDetails.label.roomsFrom', { count: rooms })
                              : t('search.expose.propertyDetails.label.rooms', {
                                  count: rooms,
                                })}
                          </Typography>
                        </Grid>
                        <StyledText item xs>
                          <Typography variant="caption">{rooms}</Typography>
                        </StyledText>
                      </StyledFactRow>
                    ) : (
                      ''
                    )}

                    {surface ? (
                      <StyledFactRow item container>
                        <Grid item>
                          <Typography variant="subtitle1" color="textSecondary">
                            {t(livingAreaLabel)}
                          </Typography>
                        </Grid>
                        <StyledText item xs>
                          <Typography variant="caption" display="inline">
                            {`${formatNumber(surface || 0, languageCode, {
                              maximumSignificantDigits: 3,
                            })} ${mapMeasurementSymbol[measurementSystem]}`}
                          </Typography>
                        </StyledText>
                      </StyledFactRow>
                    ) : (
                      ''
                    )}

                    {isCommercial && totalSurfaceValue ? (
                      <StyledFactRow item container>
                        <Grid item>
                          <Typography variant="subtitle1" color="textSecondary">
                            {t('search.expose.propertyDetails.label.totalSurface')}
                          </Typography>
                        </Grid>
                        <StyledText item xs>
                          <Typography variant="caption" display="inline">
                            {`${formatNumber(totalSurfaceValue, languageCode, {
                              maximumSignificantDigits: 3,
                            })} ${mapMeasurementSymbol[measurementSystem]}`}
                          </Typography>
                        </StyledText>
                      </StyledFactRow>
                    ) : (
                      ''
                    )}

                    {isCommercial && plotSurfaceValue ? (
                      <StyledFactRow item container>
                        <Grid item>
                          <Typography variant="subtitle1" color="textSecondary">
                            {t('search.expose.propertyDetails.label.plotSurface')}
                          </Typography>
                        </Grid>
                        <StyledText item xs>
                          <Typography variant="caption" display="inline">
                            {`${formatNumber(plotSurfaceValue, languageCode, {
                              maximumSignificantDigits: 3,
                            })} ${mapMeasurementSymbol[measurementSystem]}`}
                          </Typography>
                        </StyledText>
                      </StyledFactRow>
                    ) : (
                      ''
                    )}

                    {showDivider && (
                      <Grid item>
                        <StyledDividerMap spacing={0.5} />
                      </Grid>
                    )}

                    <StyledFactRow item container>
                      <StyledLabelTranslation item>
                        <Typography variant="subtitle1" color="textSecondary" noWrap>
                          {t(propertyPriceBase.labelTranslationKey)}
                        </Typography>
                      </StyledLabelTranslation>
                      <StyledText item xs>
                        <Typography variant="caption" display="inline">
                          {isBaseCurrency ? propertyPriceFormatted : convertedPriceFormatted}
                        </Typography>
                      </StyledText>
                    </StyledFactRow>
                  </StyledFacts>
                </Grid>
              </StyledMapCard>
            </StyledLink>
          </div>
        );
      })}
    </>
  );
};

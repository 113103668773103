import { type ApiConfig } from '@pkgs/api/types/api';
import Axios, { type AxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';

import { panic } from '../../src/utils/panic';

const baseUrlMap = {
  dev: 'https://search-bff.envdevel.com/api/',
  stage: 'https://search-bff.envstage.com/api/',
  prod: 'https://search-bff.engelvoelkers.com/api/',
} as const;

const createAxiosInstance = (apiConfig?: ApiConfig) => {
  const { environment = 'prod', basicAuth, applicationKey, axiosConfig } = apiConfig || {};
  return Axios.create(
    merge(
      {},
      {
        baseURL: baseUrlMap[environment],

        paramsSerializer: {
          indexes: null,
        },
        ...(basicAuth && {
          auth: {
            username: basicAuth.username,
            password: basicAuth.password,
          },
        }),
        headers: {
          'x-application-key': applicationKey,
        },
      },
      axiosConfig,
    ),
  );
};
export const customInstance = <T>(config: AxiosRequestConfig, apiConfig?: ApiConfig): Promise<T> => {
  const axiosInstance = createAxiosInstance(apiConfig);

  if (!axiosInstance.defaults.headers['x-application-key']) {
    panic('x-application-key is required');
  }

  return axiosInstance({
    ...config,
  }).then(({ data }) => data);
};

import { useToggleState } from '@react-stately/toggle';
import { type ReactNode, useRef, useState } from 'react';
import { type AriaToggleButtonProps, mergeProps, useFocusRing, useKeyboard, useToggleButton } from 'react-aria';

import Cross from '../../../spearhead/lib/assets/icons/xsmall/Cross-Naked.svg';
import { StyledButton, StyledLabel, StyledLabelContainer, StyledWrapper } from './Chip.styled';

export interface IChipProps extends AriaToggleButtonProps {
  className?: string;
  label: string;
  id?: string;
  name?: string;
  icon?: ReactNode;
  readOnly?: boolean;
  onDelete?: () => void;
  'data-test-id'?: string;
}
export const Chip = ({ className, label, icon, readOnly, onDelete, ...props }: IChipProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const ref = useRef(null);
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const handleOnDelete = () => {
    onDelete?.();
    setIsVisible(false);
  };
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if ((e.key === 'Backspace' || e.key === 'Delete') && onDelete) {
        handleOnDelete();
      }
    },
  });
  if (readOnly) {
    return (
      <StyledWrapper className={className}>
        {icon}
        <StyledLabel forwardedAs="span" size="m" text={label} />
      </StyledWrapper>
    );
  }
  return (
    isVisible && (
      <StyledButton
        {...mergeProps(buttonProps, focusProps, keyboardProps)}
        ref={ref}
        className={className}
        $isSelected={state.isSelected}
        $isFocusVisible={isFocusVisible}
        $isPressed={isPressed}
        data-test-id={props['data-test-id'] ?? 'search-components_chip'}
      >
        <StyledLabelContainer>
          {icon}
          <StyledLabel forwardedAs="span" size="m" text={label} />
          {onDelete && <Cross onClick={handleOnDelete} />}
        </StyledLabelContainer>
      </StyledButton>
    )
  );
};

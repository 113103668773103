import { useEffect, useState } from 'react';

export const useLoadImageValidation = (src: string) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const validateImage = async () => {
      try {
        const res = await fetch(src);
        if (!res.ok) {
          setHasError(true);
        }
      } catch {
        setHasError(true);
      }
    };
    validateImage();
  }, [src]);

  return hasError;
};

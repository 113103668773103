import { UserActionEnum } from '@ev/snowplow-library';
import SearchIcon from '@pkgs/components/assets/icons/small/Search.svg';
import { SearchAlertTooltip, Tooltip } from '@pkgs/components/components/SearchAlert/SearchAlertTooltip';
import { DEFAULT_SHOP_IDS_FILTERS } from '@pkgs/components/consts/consts';
import { useAppContext } from '@pkgs/components/hooks/useAppContext';
import { useCreateSearchAlertButtonClick } from '@pkgs/components/hooks/useCreateSearchAlertButtonClick';
import { useLipaPortfolioTrackButtonClick } from '@pkgs/components/hooks/useLipaPortfolioTrackButtonClick';
import { useSearch } from '@pkgs/components/hooks/useSearch';
import { hasResetableFilters, resetAdvancedFilters } from '@pkgs/components/utils/searchStateUtils';
import { Button, type ButtonProps } from '@spearhead/components/elements/Button/Button';
import { InlineLink } from '@spearhead/components/elements/InlineLink/InlineLink';
import { useRef } from 'react';
import { type AriaButtonProps, mergeProps, useButton } from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';

import SearchCrossed from '../../../spearhead/lib/assets/icons/medium/Search-Crossed.svg';
import BellPlus from '../../../spearhead/lib/assets/icons/small/BellPlus.svg';
import Refresh from '../../../spearhead/lib/assets/icons/small/Refresh.svg';
import User from '../../../spearhead/lib/assets/icons/small/User.svg';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledContentWrapper,
  StyledHeadline,
  StyledSubtitle,
  StyledTextContainer,
} from './NoResultsBanner.styles';

export const NoResultsBanner = (props: AriaButtonProps<'button'>) => {
  const { t } = useAppContext();
  const { filters, updateFilters, isPartnerShops, noLocationOrShopFilter } = useSearch();
  const { trackButtonClick } = useCreateSearchAlertButtonClick();
  const { trackButtonClick: trackSearchGlobalNetworkButtonClick } = useLipaPortfolioTrackButtonClick({
    elementId: 'extend_search_headline',
    action: UserActionEnum.click,
  });
  const tertiaryBtnRef = useRef(null);
  const primaryBtnRef = useRef(null);

  const triggerProps = mergeProps(props, {
    isDisabled: noLocationOrShopFilter,
  });

  const { buttonProps: tertiaryTriggerProps } = useButton(triggerProps, tertiaryBtnRef);
  const { buttonProps: primaryTriggerProps } = useButton(triggerProps, primaryBtnRef);

  const state = useTooltipTriggerState(triggerProps);

  const handleResetClick = () => {
    const filterRefinement = resetAdvancedFilters(filters);
    updateFilters(filterRefinement);
  };

  const handleSearchGlobalButtonClick = () => {
    trackSearchGlobalNetworkButtonClick();
    updateFilters(DEFAULT_SHOP_IDS_FILTERS);
  };

  const contactLink = t('search.results.noResults.contactLink');

  const searchAlertButtonProps: ButtonProps = {
    label: t('search.searchAlert.create.label'),
    icon: <BellPlus />,
    as: 'button',
    'data-test-id': 'search-components_no-results-banner_search-alert-button',
    onClick: () => trackButtonClick('create-search-alert-no-result'),
  };
  const contactButtonProps: ButtonProps = {
    label: t('search.results.noResults.button.contactAgent'),
    icon: <User />,
    as: 'a',
    href: contactLink,
    'data-test-id': 'search-components_no-results-banner_contact-button',
  };

  const searchGlobalNetworkButtonProps: ButtonProps = {
    label: t('search.licencePartner.ctaCard.btn'),
    icon: <SearchIcon />,
    as: 'button',
    onClick: handleSearchGlobalButtonClick,
    'data-test-id': 'search-components_no-results-banner_search-global-network-button',
  };

  const resetButtonProps: ButtonProps = {
    label: t('search.results.noResults.button.resetAllFilters'),
    icon: <Refresh />,
    onClick: handleResetClick,
    as: 'button',
    'data-test-id': 'search-components_no-results-banner_reset-button',
  };

  return (
    <StyledContainer>
      <SearchCrossed />
      <StyledContentWrapper>
        <StyledTextContainer>
          <StyledHeadline label={t('search.results.noResults.title')} size="h5" />
          <StyledSubtitle text={t('search.results.noResults.description.part1')} size="l" />
        </StyledTextContainer>
        <StyledButtonContainer>
          <SearchAlertTooltip state={state}>
            <Button
              refObject={tertiaryBtnRef}
              style={{ width: '100%' }}
              variant="tertiary"
              {...(hasResetableFilters(filters)
                ? isPartnerShops
                  ? searchGlobalNetworkButtonProps
                  : mergeProps(tertiaryTriggerProps, searchAlertButtonProps)
                : contactButtonProps)}
            />
            {state.isOpen && <Tooltip props={triggerProps} state={state} isCentered />}
          </SearchAlertTooltip>
          <Button
            refObject={primaryBtnRef}
            {...(hasResetableFilters(filters)
              ? resetButtonProps
              : isPartnerShops
                ? searchGlobalNetworkButtonProps
                : mergeProps(primaryTriggerProps, searchAlertButtonProps))}
          />
        </StyledButtonContainer>
        {hasResetableFilters(filters) && (
          <InlineLink
            as="a"
            href={contactLink}
            label={t('search.results.noResults.button.contactAgent')}
            data-test-id="search-components_no-results-banner_contact-link"
          />
        )}
      </StyledContentWrapper>
    </StyledContainer>
  );
};

import { Box, Dialog as MuiDialog, styled } from '@mui/material';

import { CloseIcon } from '../Icons/CloseIcon';

export const StyledDialog = styled(MuiDialog)(() => ({
  maxWidth: 'none !important',
  '&.MuiDialog-root': {
    zIndex: '1200 !important',
  },
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(4.75, 16, 6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.25, 3, 4),
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    textAlign: 'center',
  },
}));

export const StyledCloseIcon = styled(CloseIcon)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: 27,
  top: 27,
  background: 'none',
  border: 'none',
  zIndex: 99,
}));

import { type ApiConfig, type GetPropertiesOptions } from '@pkgs/api/types';
import { type ApiResult, type GetPropertiesByAgentIdFilter, type PropertiesResponse } from '@pkgs/api/types';

import { getProperties } from '../getProperties/getProperties';

/**
 * Fetch property listings listed by a specific agent ID.
 * @function
 * @param {string} agentId - The ID of the agent whose properties are being requested.
 * @param {GetPropertiesByAgentIdFilter} filters - Filters to apply to the property search. These include things like place information, price range, and property type.
 * @param {GetPropertiesOptions} options - Options to customize the search: language, currency, measurement unit, and optionally page number and page size (number of results per page).
 * @param {ApiConfig} apiConfig - Configuration for the API. At a minimum, an application key is required. See the Readme for more information.
 * @returns {Promise<ApiResult<PropertiesResponse>>} A promise that resolves to the the properties data or error details.
 *
 * @summary Fetch property listings based on a specific agent's ID.
 */

export async function getPropertiesByAgentId(
  agentId: string,
  filters: GetPropertiesByAgentIdFilter,
  options: GetPropertiesOptions,
  apiConfig: ApiConfig,
): Promise<ApiResult<PropertiesResponse>> {
  if (agentId === '') {
    console.warn('agentId is an empty string.');
  }
  return await getProperties(
    {
      ...filters,
      agentId,
    },
    options,
    apiConfig,
  );
}
